import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SelectComponent from '../../../../../shared/components/SelectComponent';
import { getAvailableExchanges, getTradingBases } from '../../../../../redux/selectors/selectors';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
import { Button, TextField } from '@mui/material';
const WithdrawalForm = ({ url, exchanges, toggleFunc, optionsBase, dispatchLoadMarkets, handleSendWithdrawal, dispatchLoadExchanges, }) => {
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');
    const [currency, setCurrency] = useState({ label: '', value: '' });
    const [exchange, setExchange] = useState({ label: '', value: '' });
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (exchanges.length > 0) {
            setExchange(exchanges[0]);
        }
    }, [exchanges]);
    useEffect(() => {
        if (optionsBase.length > 0) {
            setCurrency(optionsBase[0]);
        }
    }, [optionsBase]);
    const sendNewWithdrawal = () => {
        const payload = {
            amount,
            address,
            currency_code: currency ? currency.value : '',
            exchange_code: exchange ? exchange.value : '',
        };
        handleSendWithdrawal(payload, url);
        setAddress('');
        setAmount('');
        setCurrency({ label: '', value: '' });
        setExchange({ label: '', value: '' });
        toggleFunc();
    };
    const onAddressChange = (evt) => {
        const { value } = evt.target;
        setAddress(value);
    };
    const handleAmountChange = (evt) => {
        if (isNaN(Number(evt.target.value))) {
            return;
        }
        setAmount(evt.target.value);
    };
    const handleExchangeChange = (option) => {
        setExchange(option);
    };
    const handleCurrencyChange = (option) => {
        setCurrency(option);
    };
    const isValidToAdd = (exchange && exchange.value) && address && amount && (currency && currency.value);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "form_container_padding_flex" },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(SelectComponent, { name: "exchange", onChange: handleExchangeChange, label: "Exchange", value: exchange, options: exchanges, isLoading: false }),
                React.createElement(SelectComponent, { name: "currency", onChange: handleCurrencyChange, label: "Currency", value: currency, options: optionsBase, isLoading: false }),
                React.createElement(TextField, { type: "text", name: "amount", label: "Amount", value: amount, onChange: handleAmountChange, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } }),
                React.createElement(TextField, { type: "text", name: "address", label: "Address", inputProps: { maxLength: 255 }, value: address, placeholder: "Type address", onChange: onAddressChange }))),
        React.createElement(Button, { size: "small", type: "button", color: "primary", disabled: !isValidToAdd, onClick: sendNewWithdrawal, className: "icon modal_ok square" }, "Create")));
};
const mapStateToProps = (state) => {
    const { loadingExchanges, loadingMarkets, } = state.placeOrder;
    let exchanges = [];
    let optionsBase = [];
    if (!loadingExchanges && !loadingMarkets) {
        optionsBase = getTradingBases(state);
        exchanges = getAvailableExchanges(state);
    }
    return {
        exchanges,
        optionsBase,
    };
};
const dispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, dispatchToProps)(WithdrawalForm);
