import React from 'react';
import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const ClientSearchSelect = ({ onSelect, selectedValue, clients }) => {
    const handleClientSelect = (client) => {
        if (onSelect) {
            onSelect(client);
        }
    };
    return (React.createElement(Autocomplete, { options: clients, autoComplete: false, value: selectedValue, fullWidth: true, onChange: (event, newValue) => handleClientSelect(newValue), isOptionEqualToValue: (option) => option.value === (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.value), renderOption: (props, option) => (React.createElement(Box, Object.assign({ display: 'flex', alignItems: 'center', component: 'li' }, props, { key: option.value }),
            React.createElement(FiberManualRecordIcon, { fontSize: 'small', color: option.enabled ? 'primary' : 'error' }),
            ' ',
            React.createElement("span", null, option.label))), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { size: 'small', variant: 'outlined', label: 'Client', placeholder: 'Select client', inputProps: Object.assign(Object.assign({}, params.inputProps), { id: 'select-client', autoComplete: 'off' }) }))) }));
};
export default ClientSearchSelect;
