import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import WithdrawalForm from './WithdrawalForm';
import { Modal } from '@mui/material';
const WithdrawalModal = ({ open, theme, handleSendWithdrawal, toggleFunc, url, }) => {
    const [colored] = useState(false);
    const [header] = useState(true);
    const [color] = useState('primary');
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    const backdropTrue = true;
    return (React.createElement(Fragment, null,
        React.createElement(Modal, { open: open, className: `modal-xl modal-dialog--${color} ${modalClass} ${theme.className}` },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "modal__header" },
                    React.createElement(FontAwesomeIcon, { className: "modal__close-btn", icon: faTimes, onClick: toggleFunc }),
                    React.createElement("h4", { className: "text-modal  modal__title" }, "Create new Withdrawal")),
                React.createElement("div", { className: "modal__footer" },
                    React.createElement("div", { className: "loading_block_flex" },
                        React.createElement(WithdrawalForm, { url: url, toggleFunc: toggleFunc, handleSendWithdrawal: handleSendWithdrawal })))))));
};
const mapStateToProps = (state) => ({
    theme: state.theme,
});
export default connect(mapStateToProps)(WithdrawalModal);
