import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogContent from '@mui/material/DialogContent';
import { MaterialPopoverWrap } from '../../../Custody/styling/style';
const CustomerBalancesCellActions = ({ item, actions, clientInfo, acc, masterTenant, }) => {
    const [dialogOpen, setOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (action) => {
        setOpenDialog((prev) => !prev);
        setAction(action);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return React.createElement("div", null, "something went wrong");
        }
        return React.createElement(actions.actionsComponents[userAction], {
            item,
            acc,
            path: userAction,
            toggle: () => toggleDialog(),
            clientCode: (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || '',
            masterTenant,
        });
    };
    useEffect(() => {
        setActionsToRender([...actions.actionsList]);
    }, [item]);
    return (React.createElement(TableCell, { align: 'right' },
        React.createElement(IconButton, { size: 'small', "aria-label": 'more', onClick: handleActionsClick, disabled: acc && acc.type === 'virtual_wallet' || acc && acc.type === 'trading_wallet' && !masterTenant },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: 'nav', "aria-label": 'more actions', dense: true }, actionsToRender.map((a) => (React.createElement(ListItem, { key: `${a.name}_wallets`, onClick: () => toggleDialog(a), button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(CreateIcon, { fontSize: 'small' })),
                    React.createElement(ListItemText, { primary: a.name }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: 'sm', fullWidth: true },
            React.createElement(DialogTitle, { id: 'form-dialog-title' }, action === null || action === void 0 ? void 0 : action.name),
            React.createElement(DialogContent, null, action ? renderActionsContent(action.action) : null))));
};
export default CustomerBalancesCellActions;
