import React, { useEffect, useState } from 'react';
import { Box, Chip, Button, Collapse, TextField, } from '@mui/material';
import DateFnsAdapter from "@date-io/date-fns";
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';
import { Autocomplete } from '@mui/lab';
import { MoreFilters, FiltersReset, SelectedFilters, FiltersContainer, CustodyFilterPanelContainer, } from '../../styling/style';
import { DatePicker } from '@mui/x-date-pickers';
import parseISO from 'date-fns/parseISO';
const useStyles = makeStyles(() => createStyles({
    formGroup: {
        minWidth: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
const defaultStates = [
    { label: 'on-compliance', value: 'on-compliance' },
    { label: 'aml-checked', value: 'aml-checked' },
    { label: 'aml-failed', value: 'aml-failed' },
    { label: 'compliance-pending', value: 'compliance-pending' },
    { label: 'compliance-failed', value: 'compliance-failed' },
    { label: 'skipped', value: 'skipped' },
    { label: 'done', value: 'done' },
];
const nameArr = ['from', 'to'];
const dateFns = new DateFnsAdapter();
const FilterButton = ({ item, onChange }) => {
    if (item.value === '')
        return null;
    const viewValue = item.type === 'date' ? dateFns.format(new Date(item.value), 'dd/MM/yyyy') : item.value;
    return (React.createElement(Chip, { size: "small", color: "primary", label: (React.createElement(Box, { gap: 1, display: 'flex', alignItems: 'center' },
            React.createElement("span", null, nameArr.includes(item.name) ? `${item.label}: ${viewValue}` : item.label),
            React.createElement(CloseIcon, { fontSize: "small", onClick: onChange }))) }));
};
const DeoisitFiltersContent = ({ state, resetFilters, allCurrencies, handleDateChange, handleStateChange, removeSelectedFilter, handleCurrencyChange, }) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [to, setTo] = useState(null);
    const [from, setFrom] = useState(null);
    const [status, setStatus] = useState([]);
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        const objIndexDateFrom = state.filters.findIndex((obj => obj.name === 'from'));
        const fromData = state.filters[objIndexDateFrom];
        setFrom(fromData);
        const objIndexDateTo = state.filters.findIndex((obj => obj.name === 'to'));
        const toData = state.filters[objIndexDateTo];
        setTo(toData);
        const objIndexCurrency = state.filters.findIndex((obj => obj.name === 'currency_code'));
        const currencyData = state.filters[objIndexCurrency];
        setCurrency(currencyData || null);
        const statusData = state.filters.filter(f => f.name === 'state');
        setStatus(statusData || []);
    }, [state]);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    return (React.createElement(CustodyFilterPanelContainer, null,
        React.createElement(FiltersContainer, null,
            React.createElement(MoreFilters, null,
                React.createElement(Button, { size: "small", variant: "outlined", color: "primary", onClick: toggleCollapseFilter, className: "btn-cancel-order square" },
                    collapse ? '-' : '+',
                    " Quick filters")),
            state.filters.length ? (React.createElement(FiltersReset, null,
                React.createElement(Button, { variant: "outlined", size: "small", color: "inherit", className: "btn-cancel-order square", onClick: () => { resetFilters(); setStatus([]); } }, "Reset filters"))) : null,
            React.createElement(SelectedFilters, null,
                React.createElement(Box, { display: 'flex', flexWrap: 'wrap', gap: 1 }, state.filters.map((filter, i) => (React.createElement(FilterButton, { item: filter, key: `${filter.label}_${i}`, onChange: () => removeSelectedFilter(filter) })))))),
        React.createElement(Collapse, { in: collapse },
            React.createElement(Box, { p: 1 },
                React.createElement(Box, { style: { display: 'flex' }, gap: 1, marginBottom: 4 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date From", maxDate: to ? parseISO(to.value) : null, value: from ? parseISO(from.value) : null, slotProps: {
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                },
                                textField: {
                                    size: 'small',
                                    variant: 'outlined',
                                }
                            }, onChange: (event) => handleDateChange(event, 'Date From', 'from') })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date To", minDate: from ? parseISO(from.value) : null, value: to ? parseISO(to.value) : null, slotProps: {
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                },
                                textField: {
                                    size: 'small',
                                    variant: 'outlined',
                                }
                            }, onChange: (event) => handleDateChange(event, 'Date To', 'to') }))),
                React.createElement(Box, { style: { display: 'flex' }, gap: 1 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, options: allCurrencies, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ label: "Currency", name: "currency_code" }, params, { variant: "outlined", value: {
                                    value: currency === null || currency === void 0 ? void 0 : currency.value,
                                    label: 'Currency',
                                }, size: "small" })) })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { multiple: true, loading: false, limitTags: 1, options: defaultStates, value: state.filters.filter(v => v.name === 'state'), getOptionLabel: (option) => option.label, onChange: (e, newValue, reason, detail) => handleStateChange(newValue, reason, detail), renderInput: (params) => React.createElement(TextField, Object.assign({ label: "State", name: "state" }, params, { variant: "outlined", size: "small" })), renderTags: (value, getTagProps) => value.map((option, index) => (React.createElement(Chip, Object.assign({ size: 'small', label: option.label }, getTagProps({ index }))))) })))))));
};
export default DeoisitFiltersContent;
