import React from 'react';
import { Chip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        display: 'block',
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    linkTitle: {
        '&:hover': {},
    },
    linkActive: {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    }
}));
const SidebarLink = ({ title, icon, newLink, route = '/', onClick, style, exact, }) => {
    const classes = useStyles();
    return (React.createElement(NavLink, { className: classes.root, to: route, onClick: onClick, activeClassName: classes.linkActive, exact: exact },
        React.createElement("span", { style: style, className: `${classes.linkTitle}` },
            icon,
            title,
            newLink ? React.createElement(Chip, { size: "small", color: "default", label: React.createElement("span", null, "New") }) : '')));
};
SidebarLink.defaultProps = {
    exact: false,
    newLink: false,
    icon: undefined,
    onClick: () => undefined,
};
export default SidebarLink;
