import React from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { NumericFormat } from 'react-number-format';
import { Icon, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { getExplorerURL } from '../../../../../shared/helpers/getExplorerURL';
const envSettings = getEnvSettings();
/* eslint-disable camelcase */
const RenderWithdrawalTableBody = ({ item, handleCopyReceivedHash, iconReceived, currencies, }) => {
    const sliceHash = (i) => {
        const { tx_hash } = i;
        const tXHashLength = tx_hash ? tx_hash.length : 0;
        const tXHashValue = tx_hash
            ? `${tx_hash.slice(0, 4)}...${tx_hash.slice(tXHashLength - 4, tXHashLength)}` : '';
        return { tXHashValue };
    };
    const hashObj = sliceHash(item);
    const { fee, tx_hash, created_at, currency_code, requested_amount, fee_currency_code, } = item;
    return (React.createElement(TableBody, null,
        React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1 },
            React.createElement(TableCell, { align: "left" }, "Storage"),
            React.createElement(TableCell, { align: "right" }, requested_amount ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(requested_amount).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                currency_code ? currency_code.toUpperCase() : null)) : '-----'),
            React.createElement(TableCell, { align: "right" }, fee ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(fee).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                fee_currency_code
                    ? fee_currency_code.toUpperCase()
                    : null)) : '-----'),
            React.createElement(TableCell, { align: "right" },
                hashObj.tXHashValue ? (React.createElement("span", { className: "common_padding_r_10" },
                    React.createElement("a", { target: "_blank", rel: "noreferrer", className: "custody_hash_link", href: `${getExplorerURL(currency_code, currencies)}${tx_hash}` }, hashObj.tXHashValue))) : '-----',
                hashObj.tXHashValue ? (React.createElement(Icon, { onClick: () => handleCopyReceivedHash(`${tx_hash}`), fontSize: "small" }, iconReceived)) : null),
            React.createElement(TableCell, { align: "right" }, created_at ? moment(created_at).format('YYYY-MM-DD H:mm:ss') : '-----'))));
};
export default RenderWithdrawalTableBody;
