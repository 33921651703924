import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SCHEMA_APPLY_ALL_SETTINGS, SCHEMA_WORKING_SET_UPDATE, } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { Box, Button, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
const CustomersForm = ({ toggleFunc, customerInfo, customers, setCustomerInfo, settings, widgetKey, blockKey, }) => {
    const dispatch = useDispatch();
    const [customer, setCustomer] = useState(null);
    const addNewSetting = () => {
        const CardSettings = settings.find(s => s.id.includes('Card'));
        const cardItems = CardSettings ? CardSettings.value : [];
        if (Array.isArray(cardItems)) {
            dispatch({
                type: SCHEMA_WORKING_SET_UPDATE,
                settingId: `${widgetKey}Card`,
                settingValue: [
                    ...cardItems.map((c) => {
                        if (c.key === blockKey && customer) {
                            return {
                                customer,
                                key: c.key,
                                base: c.base,
                                quote: c.quote,
                                layout: c.layout,
                                guaranteed_qty: c.guaranteed_qty,
                            };
                        }
                        return c;
                    }),
                ],
            });
            dispatch({
                type: SCHEMA_APPLY_ALL_SETTINGS,
                dashboard: 'trading',
            });
            setCustomerInfo(customer);
        }
        toggleFunc();
    };
    const handleCustomerSelect = (option) => {
        setCustomer(option);
    };
    useEffect(() => {
        setCustomer(customerInfo);
    }, []);
    return (React.createElement(Box, null,
        React.createElement(Box, { mb: 2 }, customer ?
            React.createElement(Autocomplete, { disableClearable: true, options: customers, getOptionLabel: (option) => option.label, value: customer, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "Customer", variant: "outlined", size: "small" })), onChange: (event, newValue) => handleCustomerSelect(newValue) }) : null),
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
            React.createElement("div", null,
                React.createElement(Button, { color: "primary", variant: "contained", onClick: () => addNewSetting() }, "Select")),
            React.createElement("div", null,
                React.createElement(Button, { variant: "outlined", onClick: () => toggleFunc() }, "Cancel")))));
};
export default CustomersForm;
