/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import * as types from '../actionTypes/clientActionTypes';
import ClientsService from '../../services/clientsService';
import { getEnvSettings } from '../../config/environmentSettings';
const clientsServiceMe = new ClientsService({ url: '/me', method: 'get' });
export const fetchClientInfo = () => clientsServiceMe.makeRequest()
    .then((responseData) => responseData)
    .catch((e) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(e, new Date());
    throw new Error(`Something went wrong, error: ${e}`);
});
export const fetchClientBalances = (clientCode) => axios
    .get(`/trade/balances/clients/${clientCode}`)
    .then(response => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const fetchCustomerInfo = (customerCode, canReadDetails) => axios
    .get(`/customers/${customerCode}?details=${canReadDetails ? 'true' : 'false'}`)
    .then(response => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    throw new Error(`Something went wrong, error: ${error}`);
});
export const fetchCustomerBalances = (customerCode) => axios
    .get(`/trade/balances/customers/${customerCode}`)
    .then(response => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const fetchExchangesBalances = () => axios
    .get('/trade/balances/exchanges', { baseURL: getEnvSettings().adminApiUrl, withCredentials: true })
    .then(response => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const collapseExchangesBalancesItemOpen = (itemId) => ({
    type: types.COLLAPSE_EXCHANGES_BALANCES_ADD_OPEN,
    itemId,
});
export const collapseExchangesBalancesItemClose = (itemId) => ({
    type: types.COLLAPSE_EXCHANGES_BALANCES_CLOSE,
    itemId,
});
export const collapseBalancesItemOpen = (itemId) => ({
    type: types.COLLAPSE_BALANCES_ADD_OPEN,
    itemId,
});
export const collapseBalancesItemClose = (itemId) => ({
    type: types.COLLAPSE_BALANCES_CLOSE,
    itemId,
});
export const collapseCustomerBalancesItemOpen = (itemId) => ({
    type: types.COLLAPSE_CUSTOMER_BALANCES_ADD_OPEN,
    itemId,
});
export const collapseCustomerBalancesItemClose = (itemId) => ({
    type: types.COLLAPSE_CUSTOMER_BALANCES_CLOSE,
    itemId,
});
