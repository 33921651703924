import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogContent from '@mui/material/DialogContent';
import { MaterialPopoverWrap } from '../../../Custody/styling/style';
const BalancesCellActions = ({ item, actions, clientInfo, }) => {
    const [dialogOpen, setOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        setOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return (React.createElement("div", null, "something went wrong"));
        }
        return (React.createElement(actions.actionsComponents[userAction], {
            item,
            path: userAction,
            toggle: () => toggleDialog(),
            clientCode: (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || '',
        }));
    };
    useEffect(() => {
        setActionsToRender([...actions.actionsList]);
    }, [item]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: actionsToRender.length === 0 },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: "nav", "aria-label": "more actions", dense: true }, actionsToRender.map(a => (React.createElement(ListItem, { key: `${a.name}_wallets`, onClick: () => toggleDialog(a.action), button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(CreateIcon, { fontSize: "small" })),
                    React.createElement(ListItemText, { primary: a.name }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, null, "Create Withdrawal"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action) : null))));
};
export default BalancesCellActions;
