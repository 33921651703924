import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';
/* eslint-disable no-console */
const CustodySidebar = () => {
    const sidebarClass = classNames({
        sidebar: true,
        'sidebar--show': true,
        'sidebar--collapse': false,
    });
    return (React.createElement("div", { className: sidebarClass },
        React.createElement(Scrollbar, { className: "sidebar__scroll scroll" },
            React.createElement("div", { className: "sidebar__wrapper sidebar__wrapper--desktop" },
                React.createElement(SidebarContent, { onClick: () => undefined })))));
};
export default CustodySidebar;
