import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import CryptoDashboard from '../../../Dashboards/Crypto/index';
const TerminalRoot = () => (React.createElement("div", null,
    React.createElement(Layout, null,
        React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: "/dma", component: CryptoDashboard }),
            React.createElement(Route, { exact: true, path: "/rfq", component: CryptoDashboard })))));
export default TerminalRoot;
