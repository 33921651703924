import React from 'react';
import Alert from '@mui/material/Alert';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ClearerWithdrawalsItem from './ClearerWithdrawalsItem';
const ClearerWithdrawalsContent = ({ records }) => {
    if (records.length === 0) {
        return (React.createElement(TableRow, null,
            React.createElement(TableCell, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" },
                    React.createElement("p", null,
                        React.createElement("span", { className: "bold-text" }, "No items found"))))));
    }
    return (React.createElement(React.Fragment, null, records.map((item) => React.createElement(ClearerWithdrawalsItem, { item: item }))));
};
export default ClearerWithdrawalsContent;
