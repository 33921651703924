/* eslint-disable no-return-assign, react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import CustodySidebar from './CustodySidebar/Sidebar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import { Drawer } from '@mui/material';
import SidebarContent from './AdminSidebar/SidebarContent';
import MasterTenantSidebarContent from './AdminSidebar/MasterTenantSidebarContent';
const drawerWidth = 240;
class Layout extends PureComponent {
    render() {
        const { sidebar, children } = this.props;
        if (sidebar === 'manage') {
            return (React.createElement("div", null,
                React.createElement(TopbarWithNavigation, null),
                React.createElement("div", { className: 'root-content-wrapper' },
                    React.createElement(Drawer, { variant: "permanent", style: { width: drawerWidth }, classes: { paper: 'drawer-paper', paperAnchorLeft: 'drawer-paper-anchor' } },
                        React.createElement(SidebarContent, null)),
                    children)));
        }
        if (sidebar === 'custody') {
            return (React.createElement("div", null,
                React.createElement(TopbarWithNavigation, null),
                React.createElement("div", { className: 'root-content-wrapper' },
                    React.createElement(CustodySidebar, null),
                    children)));
        }
        if (sidebar === 'mastertenant') {
            return (React.createElement("div", null,
                React.createElement(TopbarWithNavigation, null),
                React.createElement("div", { className: 'root-content-wrapper' },
                    React.createElement(Drawer, { variant: "permanent", style: { width: drawerWidth }, classes: { paper: 'drawer-paper', paperAnchorLeft: 'drawer-paper-anchor' } },
                        React.createElement(MasterTenantSidebarContent, null)),
                    children)));
        }
        return (React.createElement("div", null,
            React.createElement(TopbarWithNavigation, null),
            React.createElement("div", { className: 'root-content-wrapper' }, children)));
    }
}
export default withRouter(Layout);
