import React from 'react';
import isEqual from 'lodash/isEqual';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { generateLayout } from '../../../../../shared/helpers/layout';
import WidgetBody from '../../../../../shared/renderProps/WidgetContent';
import LayoutContainer from '../../../../../shared/renderProps/LayoutContainer';
import { getWidgets } from '../../../../../redux/selectors/widgetSettingsSelector';
import { SCHEMA_UPDATE_LAYOUT_PROPS } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const ReactGridLayout = WidthProvider(RGL);
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class ManageLogbookDashboard extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            layouts: [],
        };
        this.handleLayoutChange = (layout) => {
            const { widgets, dispatchUpdateLayoutProps } = this.props;
            widgets.forEach((w) => {
                const lItem = layout.find(l => l.i === w.widgetKey);
                if (lItem) {
                    w.layoutProps = Object.assign({}, w.layoutProps, {
                        h: lItem.h,
                        w: lItem.w,
                        x: lItem.x,
                        y: lItem.y,
                    });
                }
            });
            dispatchUpdateLayoutProps(widgets);
        };
    }
    componentDidMount() {
        const { widgets } = this.props;
        if (widgets.length) {
            this.setState({ layouts: generateLayout(widgets) });
        }
    }
    shouldComponentUpdate(nextProps) {
        const nextWidgets = nextProps.widgets;
        const { widgets } = this.props;
        if (!isEqual(widgets, nextWidgets)) {
            this.setState({ layouts: generateLayout(nextWidgets) });
        }
        return true;
    }
    render() {
        const { widgets, loadingWorkspaces, } = this.props;
        const { layouts } = this.state;
        if (loadingWorkspaces) {
            return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4, width: '100%' },
                React.createElement(CircularProgress, { size: "33px" })));
        }
        if (layouts.length === 0) {
            return null;
        }
        return (React.createElement("div", { className: "content-body" },
            React.createElement(ReactGridLayout, { className: "layout", cols: 12, layout: layouts, margin: [5, 5], draggableHandle: ".draggable-header", rowHeight: 30, onLayoutChange: layout => this.handleLayoutChange(layout) }, widgets.map(widget => (React.createElement(LayoutContainer, { key: widget.widgetKey, widgetKey: widget.widgetKey },
                React.createElement(WidgetBody, { widgetKey: widget.widgetKey, widgetType: widget.type })))))));
    }
}
const stateToProps = (state) => {
    const { loading } = state.workspace;
    const widgets = getWidgets(state);
    return {
        widgets,
        loadingWorkspaces: loading,
    };
};
const dispatchToProps = dispatch => ({
    dispatchUpdateLayoutProps: widgets => dispatch({ type: SCHEMA_UPDATE_LAYOUT_PROPS, widgets }),
});
export default connect(stateToProps, dispatchToProps)(ManageLogbookDashboard);
