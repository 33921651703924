/* eslint-disable camelcase, @typescript-eslint/no-explicit-any,
  @typescript-eslint/no-floating-promises, no-useless-return, react/jsx-indent */
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import { useDispatch } from 'react-redux';
import PermissionsCell from './PermissionsCell';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { MaterialPopoverWrap } from '../../../../Custody/styling/style';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, } from '@mui/material';
const centeredColumns = ['create', 'read', 'update', 'delete'];
const PermissionContainer = ({ currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, maxHeight, columns, sortBy, sortDirection, onSortHandle, onSearch, checkedItems, handleFieldChange, resourcesToRender, totalItems, }) => {
    const reduxDispatch = useDispatch();
    const [anchorColumns, setAnchorColumns] = React.useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            reduxDispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            reduxDispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        reduxDispatch(applyAllSettingsChanges('manageTokens'));
    };
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setCurrentPage(0);
    };
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    return (React.createElement(Box, { width: "100%", sx: { overflow: 'hidden' } },
        React.createElement(Box, null,
            React.createElement(Box, { display: 'flex', justifyContent: 'space-between', p: 1, pt: 2 },
                React.createElement(TextField, { autoComplete: 'off', label: "Quick Search", size: 'small', name: "search", onChange: (e) => onSearch(e.target.value) }),
                React.createElement(IconButton, { onClick: handleColumnsClick },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))),
            React.createElement(Box, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, columnsToRender.map((column) => {
                                if (column.key === 'resource' || column.key === 'category') {
                                    return (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'resource' ? 'left' : 'right' },
                                        React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)));
                                }
                                return (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: centeredColumns.includes(column.key) ? 'center' : 'right' }, column.title));
                            }))),
                        React.createElement(TableBody, null, resourcesToRender.map(r => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() }, columnsToRender.map((column, i) => {
                            const key = column.key;
                            const value = key ? r[key] : undefined;
                            return (React.createElement(PermissionsCell, { key: i, item: r, keyOf: key, value: value, checkedItems: checkedItems, handleFieldChange: handleFieldChange }));
                        })))))))),
            React.createElement(TablePagination, { component: "div", count: totalItems, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: handlePageChange, rowsPerPageOptions: [maxHeight] }))));
};
export default PermissionContainer;
