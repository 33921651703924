import React, { useEffect, useState, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import { connect, useDispatch } from 'react-redux';
import { Box, Checkbox, Chip, FormControlLabel, TableContainer, Typography } from '@mui/material';
import { CUSTOMER_INFO_CLEAN, CUSTOMER_INFO_FETCH_REQUEST, } from '../../../../../redux/actionTypes/clientActionTypes';
import CustomerBalances from './CustomerBalances';
import ClientBalancesContainer from './ClientBalancesContainer';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
import CustomerBalancesService from '../../../../../services/customerBalancesService';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const Balances = ({ features, widgetKey, clientInfo, selectedClient, customerInfo, selectedCustomer, customer_code, }) => {
    const dispatch = useDispatch();
    const { pathname } = window.location;
    const [hideZero, setDisplayZero] = useState(true);
    const [customerCode, setCustomerCode] = useState('');
    const [service, setService] = useState(null);
    const [customerBalances, setCustomerBalances] = useState([]);
    const handleCustomerDataChange = (customer, featureArr) => {
        const code = customer ? customer.code : '';
        if (featureArr && Array.isArray(featureArr) && featureArr.length && featureArr.includes('balance.read')) {
            setCustomerCode(code);
        }
    };
    const serviceData = useServiceFetchApis(service, customerCode, 600000);
    const memoBalances = useMemo(() => serviceData.data ? serviceData.data.balances : [], [serviceData]);
    const toggleZeroBalances = (evt) => {
        const { checked } = evt.target;
        setDisplayZero(checked);
    };
    const checkboxId = `${widgetKey}showZeroBalances`;
    useEffect(() => {
        handleCustomerDataChange(selectedCustomer, features);
    }, [selectedCustomer, features]);
    useEffect(() => {
        if (customerCode) {
            const serviceInstance = new CustomerBalancesService({ url: '', method: 'get' }, '/trade/balances/customers/');
            setService(serviceInstance);
        }
        else {
            setService(null);
        }
    }, [customerCode]);
    useEffect(() => {
        if (memoBalances.length) {
            setCustomerBalances(memoBalances);
        }
        else {
            setCustomerBalances([]);
        }
    }, [memoBalances]);
    useEffect(() => {
        if (features && Array.isArray(features) && features.length) {
            const canReadDetails = features.includes('customer_details.read');
            if (canReadDetails) {
                if (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) {
                    dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: selectedCustomer.code });
                }
                else {
                    dispatch({ type: CUSTOMER_INFO_CLEAN });
                }
            }
            else {
                dispatch({ type: CUSTOMER_INFO_CLEAN });
                if (selectedCustomer && selectedCustomer.code) {
                    dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: selectedCustomer.code });
                }
                else {
                    dispatch({ type: CUSTOMER_INFO_CLEAN });
                }
            }
        }
    }, [selectedCustomer]);
    return (React.createElement("div", null,
        React.createElement(Box, { p: 1, display: 'flex', justifyContent: 'space-between' },
            React.createElement(Typography, { variant: "subtitle1" }, "Client balances"),
            React.createElement(Chip, { color: "primary", label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, size: "small" })),
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" }, customer_code ? null : (React.createElement(ClientBalancesContainer, { hideZero: hideZero, client: selectedClient, clientInfo: clientInfo })))),
        React.createElement(Box, { p: 1, display: 'flex', flexDirection: 'column', mt: 2 },
            React.createElement(Typography, { variant: "subtitle1" }, "Customer balances"),
            customerInfo ? React.createElement(Box, { display: 'flex', gap: 1, p: 1 },
                React.createElement(Chip, { color: "primary", label: customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.company_name, size: "small" }),
                React.createElement(Chip, { color: "default", label: customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.code, size: "small" })) : null),
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" }, pathname === '/custody' && !customer_code
                ? null
                : (React.createElement(CustomerBalances, { hideZero: hideZero, balances: customerBalances, customerInfo: customerInfo, customer_code: customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.code })))),
        customerBalances.length > 0 ? null
            : (React.createElement(Box, { p: 1 },
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "Select customer to see customer's balances"))),
        React.createElement(Box, { p: 2 },
            React.createElement(FormControlLabel, { label: "Hide zero balances", control: React.createElement(Checkbox, { checked: hideZero, name: checkboxId, onChange: toggleZeroBalances }) }))));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    const selectedClient = getSelectedClientInputValue(state);
    const { selectedCustomer } = state.customersSearch;
    const { clientInfo, customerInfo, features, customer_code, } = state.client;
    return {
        features,
        widgetKey,
        clientInfo,
        selectedClient,
        customerInfo,
        customer_code,
        selectedCustomer,
    };
};
export default connect(stateToProps)(React.memo(Balances));
