import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import copy from 'copy-to-clipboard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import TableCell from '@mui/material/TableCell';
import DialogTitle from '@mui/material/DialogTitle';
import { useResizeDetector } from 'react-resize-detector';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import BlockchainInfo from '../MegaModal/main';
import { TransfersCellItem } from '../../styling/style';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import getDecimalsByCurrencyCode from '../../../../../config/currencyDecimal';
import { getExplorerURL } from '../../../../../shared/helpers/getExplorerURL';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
const envSettings = getEnvSettings();
/* eslint-disable no-nested-ternary, jsx-a11y/anchor-is-valid */
const DepositTableCell = ({ value, columnKey, currency, item, deposits, currencies, }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [icon, setIcon] = useState('file_copy');
    const [iconCode, setIconCode] = useState('file_copy');
    const [iconHash, setIconHash] = useState('file_copy');
    const history = useHistory();
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    const handleCopyCode = (code) => {
        setIconCode('done');
        setTimeout(() => {
            setIconCode('file_copy');
        }, 3000);
        copy(code);
    };
    const handleCopyRecievedHash = (hash) => {
        setIconHash('done');
        setTimeout(() => {
            setIconHash('file_copy');
        }, 3000);
        copy(hash);
    };
    const toggleDialog = (e) => {
        e && e.preventDefault();
        toggleOpenDialog(prev => !prev);
        history.push('/custody');
    };
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'deposit_address') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'compliance_amount') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(NumericFormat, { displayType: "text", value: new Decimal(value || 0).toFixed(), thousandSeparator: envSettings.thousandDivider }),
            "\u00A0",
            value ? (currency ? currency.toUpperCase() : null) : null));
    }
    if (columnKey === 'compliance_amount_usd') {
        if (value === 'Not convertible' || value === '' || value === '0') {
            return (React.createElement(TableCell, { align: "left" }, "N/A"));
        }
        const decimals = getDecimalsByCurrencyCode('USD', currencies) || 2;
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(NumericFormat, { displayType: "text", value: new Decimal(value || 0).toFixed(decimals), thousandSeparator: envSettings.thousandDivider }),
            " $"));
    }
    if (columnKey === 'compliance_received_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    if (columnKey === 'compliance_tx_hash') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
                React.createElement("a", { target: "_blank", rel: "noreferrer", className: "custody_hash_link", href: `${getExplorerURL(currency, currencies)}${value || ''}` }, value))));
    }
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
                React.createElement("a", { href: "", onClick: e => toggleDialog(e) }, value)),
            React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "md", fullWidth: true },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
                React.createElement(DialogContent, null,
                    React.createElement(BlockchainInfo, { item: item, allDeposits: deposits })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: () => toggleDialog() }, "Close")))));
    }
    if (columnKey === 'state') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData.stateBackgroundsDeposits[(value || '').replace(/-/g, '_')], statusBadgeData.stateBordersDeposits[(value || '').replace(/-/g, '_')], statusBadgeData.stateColorsDeposits[(value || '').replace(/-/g, '_')]).badge })));
    }
    return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
};
export default DepositTableCell;
