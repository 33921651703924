import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
/* eslint-disable class-methods-use-this */
export default class ClearerWithdrawalsService {
    constructor(config, delay) {
        this.model = {
            records: [],
            total: '0',
        };
        this.config = config;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(data) {
        this.model.records = data.data.records;
        this.model.total = data.data.total;
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    handleReject(error) {
        // TODO: do something smarter here
        throw error;
    }
}
