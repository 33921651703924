import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import CustomerSearchContent from './CustomerSearchContent';
const rowsPerPage = 10;
const CustomersSearchResults = ({ handleSelect, workingTotal, onChangePage, pageOfItemsCustomerSearch, }) => (React.createElement("div", null,
    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Name"),
                React.createElement(TableCell, null, "BFID"),
                React.createElement(TableCell, null, "Code"))),
        React.createElement(TableBody, null,
            React.createElement(CustomerSearchContent, { handleSelect: handleSelect, page: pageOfItemsCustomerSearch }))),
    React.createElement(TablePagination, { component: "div", count: +workingTotal, page: pageOfItemsCustomerSearch, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [rowsPerPage] })));
export default CustomersSearchResults;
