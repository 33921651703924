/* eslint-disable no-useless-escape */
/* eslint-disable camelcase, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call,
@typescript-eslint/ban-ts-comment, no-nested-ternary, @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Switch, TextField, IconButton, } from '@mui/material';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import Chip from '@mui/material/Chip';
import { useTimer } from 'react-timer-hook';
import Divider from '@mui/material/Divider';
import Edit from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import TimerIcon from '@mui/icons-material/Timer';
import CachedIcon from '@mui/icons-material/Cached';
import RefreshIcon from '@mui/icons-material/Refresh';
import { red, grey, green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import MarketModal from './MarketModal';
import useRfqService from './RfqService';
import CustomersModal from './CustomersModal';
import ConfirmCardDeleteModal from './ConfirmCardDeleteModal';
import { FlexieContainer } from '../../../Custody/styling/style';
import Subscriber from '../../../../../shared/helpers/subscriber';
const ActionSwitch = withStyles({
    track: {
        backgroundColor: green[300],
    },
    switchBase: {
        color: green[300],
        '&$checked': {
            color: red[500],
        },
        '&$checked + $track': {
            backgroundColor: red[500],
        },
    },
    checked: {},
})(Switch);
const useStyles = makeStyles((theme) => createStyles({
    boxVariant: {
        padding: 0,
        overflow: 'hidden',
        border: '1px solid',
        borderBottom: 0,
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[200],
        '& .paperBox': {
            cursor: 'move',
            width: 'calc(100% - 25px)',
            padding: '10px',
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '10px',
        },
        '& .pairChip > .MuiChip-root': {
            cursor: 'move',
        },
    },
    price: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.43,
    },
    actionButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.7rem',
        borderRadius: 0,
        fontWeight: 500,
        width: '100%',
        minHeight: '56px',
    },
    textQty: {
        fontSize: '0.8rem',
    },
    quantityBlock: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px',
        paddingTop: '0',
        '& > div': {
            marginBottom: '15px',
        },
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerV: {
        margin: theme.spacing(0, 1),
    },
    closeButton: {
        alignSelf: 'center',
        position: 'absolute',
        right: '-15px',
        top: '10px',
    },
    headerActions: {
        display: 'flex',
    },
}));
var OrderType;
(function (OrderType) {
    OrderType["Buy"] = "Buy";
    OrderType["Sell"] = "Sell";
})(OrderType || (OrderType = {}));
function getOrderBook(data, base, channel) {
    const { payload } = data;
    // Narrow down to OrderBookData type or return undefined
    if (payload.bids) {
        const hitBid = payload.bids[0];
        const liftAsk = payload.asks[0];
        const orderBook = {
            hit_bid: hitBid.quote_amount,
            lift_ask: liftAsk.quote_amount,
            total_bid: hitBid.volume,
            total_ask: liftAsk.volume,
            timestamp: payload.timestamp.toString(),
        };
        if (channel) {
            return {
                [channel]: Object.assign({}, orderBook),
            };
        }
        return orderBook;
    }
    return undefined;
}
const BlockReverse = ({ customer, base, quote, guaranteedQty, settings, blockKey, widgetKey, features, removeCard, }) => {
    const classes = useStyles();
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const [qtyAsk, setQtyAsk] = useState('');
    const [qtyBid, setQtyBid] = useState('');
    const [viewBid, setViewBid] = useState('');
    const [viewAsk, setViewAsk] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [action, setAction] = useState(OrderType.Buy);
    const [modalOpen, setModalOpen] = useState(false);
    const [reference_id, setReferenceId] = useState('');
    const [orderAmt, setOrderAmt] = useState(guaranteedQty);
    const [hideMarketBtn, setHideMarketBtn] = useState(false);
    const [marketModalOpen, setMarketModalOpen] = useState(false);
    const [confirmCardRemoveModalOpen, setConfirmCardRemoveModalOpen] = useState(false);
    const [actionSwitch, setActionSwitch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customAmountError, setCustomAmountError] = useState(false);
    const [expired, setExpired] = useState(false);
    const inputPattern = /^(\d*\.|\,)?\d+$/gmi;
    const { seconds, restart, pause, } = useTimer({
        expiryTimestamp: new Date(),
        autoStart: false,
        onExpire: () => setExpired(prev => !prev),
    });
    const [orderBookConsolidated, setOrderBookConsolidated] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const { getCustomAmount, customAmount, handleOrderCreate, amountError, } = useRfqService();
    const channel = `rfq-reverse-quotation-orderbook-${base === null || base === void 0 ? void 0 : base.toLowerCase()}/${quote === null || quote === void 0 ? void 0 : quote.toLowerCase()}`;
    const rounding = base === 'btc' ? 2 : 5;
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleRemoveConfirm = (event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setConfirmCardRemoveModalOpen(!confirmCardRemoveModalOpen);
    };
    const hadleRemoveCard = () => {
        removeCard(blockKey);
    };
    const toggleMarketModal = () => {
        setMarketModalOpen(prev => !prev);
    };
    const handleActionChange = (action) => {
        setAction(action === OrderType.Buy ? OrderType.Sell : OrderType.Buy);
        setExpired(false);
        setCustomAmountError(false);
    };
    const getPayload = (amount, action) => ({
        base,
        quote,
        amount,
        action,
        reference_id: '',
        type: 'RFQ_Market',
        customer_code: customerInfo ? customerInfo.value : '',
    });
    const onAmountChange = (event) => {
        const value = event.target.value.trim();
        setActionSwitch(true);
        setExpired(false);
        setOrderAmt(value);
        setCustomAmountError(false);
    };
    const [debouncedValue, setDebouncedValue] = useState(guaranteedQty);
    useEffect(() => {
        let timerId;
        if (orderAmt !== guaranteedQty) {
            setLoading(true);
            timerId = setTimeout(() => {
                setViewAsk('');
                setViewBid('');
                setExpired(false);
                setDebouncedValue(orderAmt);
            }, 500);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [orderAmt]);
    useEffect(() => {
        if (debouncedValue !== guaranteedQty && Number(debouncedValue) > 0) {
            // eslint-disable-next-line no-void
            void getCustomAmount(getPayload(debouncedValue, action));
        }
    }, [debouncedValue, action]);
    const handleRefreshAmount = (act) => {
        if (orderAmt === '0')
            return;
        setViewAsk('');
        setViewBid('');
        setExpired(false);
        setLoading(true);
        // eslint-disable-next-line no-void
        void getCustomAmount(getPayload(orderAmt, act));
    };
    const setUpdatesConsolidatedOrderBook = (data) => {
        const orderBook = getOrderBook(data, base === null || base === void 0 ? void 0 : base.toLowerCase());
        setOrderBookConsolidated(orderBook);
    };
    const onReferenceChange = (evt) => {
        const val = evt.target.value.trim();
        setReferenceId(val);
    };
    const resetCardSettings = () => {
        setActionSwitch(false);
        setExpired(false);
        setViewAsk('');
        setViewBid('');
        setAction(OrderType.Buy);
        setOrderAmt(guaranteedQty);
        setDebouncedValue(guaranteedQty);
        setLoading(false);
        pause();
    };
    const onCreateOrder = () => {
        if (customerInfo && Number(orderAmt) > 0) {
            const payload = {
                base,
                quote,
                action,
                limit_price: '',
                reference_id,
                type: 'RFQ_Market',
                rfq_offered_price_timestamp: (customAmount === null || customAmount === void 0 ? void 0 : customAmount.timestamp) || '',
                customer_code: customerInfo ? customerInfo.value : '',
                rfq_offered_price: '',
                rfq_quote_amount: (customAmount === null || customAmount === void 0 ? void 0 : customAmount.quote_amount_to_spend) || '',
                amount: (customAmount === null || customAmount === void 0 ? void 0 : customAmount.offered_base_amount) || '',
            };
            handleOrderCreate(payload, customerInfo);
            resetCardSettings();
        }
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            setCustomerInfo(customer);
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (amountError) {
            setCustomAmountError(true);
        }
    }, [amountError]);
    useEffect(() => {
        if (!customAmount)
            return;
        setLoading(false);
        const time = new Date();
        time.setSeconds(time.getSeconds() + 5);
        restart(time);
        if (action === OrderType.Buy) {
            setViewAsk(customAmount === null || customAmount === void 0 ? void 0 : customAmount.offered_base_amount);
        }
        else if (action === OrderType.Sell) {
            setViewBid(customAmount === null || customAmount === void 0 ? void 0 : customAmount.offered_base_amount);
        }
    }, [customAmount]);
    useEffect(() => {
        if (features) {
            const restrictOrder = features.includes('restrict_order_size.read');
            setHideMarketBtn(!restrictOrder);
        }
        else {
            setHideMarketBtn(true);
        }
    }, [features]);
    useEffect(() => {
        if (orderAmt === guaranteedQty) {
            resetCardSettings();
        }
    }, [orderAmt]);
    useEffect(() => {
        if (expired) {
            setViewAsk('');
            setViewBid('');
        }
    }, [expired]);
    useEffect(() => {
        if (!orderBookConsolidated) {
            return;
        }
        if (!marketModalOpen) {
            setQtyAsk(orderBookConsolidated.total_ask);
            setQtyBid(orderBookConsolidated.total_bid);
            setTimestamp(orderBookConsolidated.timestamp);
        }
        if (!actionSwitch) {
            setViewAsk(new Decimal(orderBookConsolidated.total_ask || 0).toFixed(rounding));
            setViewBid(new Decimal(orderBookConsolidated.total_bid || 0).toFixed(rounding));
        }
    }, [orderBookConsolidated]);
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        const wsDataCallback = (data) => {
            var _a;
            if (!data)
                return;
            if (((_a = data.payload.bids[0]) === null || _a === void 0 ? void 0 : _a.quote_amount) !== guaranteedQty) {
                return;
            }
            setUpdatesConsolidatedOrderBook(data);
        };
        const consolidatedOrderBook = new Subscriber(wsDataCallback, refId.current, 0);
        OrderBookWS.addSubscription(channel, consolidatedOrderBook);
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(channel, refId.current);
        };
    }, [channel]);
    return (React.createElement(Box, { className: classes.boxVariant },
        marketModalOpen ? (React.createElement(MarketModal, { base: base, quote: quote, action: action, orderAmt: orderAmt, bidUpdate: qtyBid, askUpdate: qtyAsk, timestamp: timestamp, streamType: "reverse_quotation", open: marketModalOpen, customerInfo: customerInfo, reference_id: reference_id, toggleFunc: toggleMarketModal, guaranteed: guaranteedQty })) : null,
        React.createElement("div", { style: { flex: '1', display: 'flex', flexDirection: 'column' } },
            React.createElement("div", { className: "paperBox" },
                React.createElement("div", { className: "pairChip" },
                    React.createElement(Chip, { size: "small", color: "info", style: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }, label: `${base.toUpperCase()}/${quote.toUpperCase()}` })),
                React.createElement("div", { style: { lineHeight: '1rem', fontSize: '0.7rem' } },
                    React.createElement("div", null, "Qty Spread (Guaranteed)"),
                    React.createElement("div", null,
                        guaranteedQty,
                        " ",
                        quote.toUpperCase())),
                React.createElement("div", null,
                    React.createElement(IconButton, { onClick: e => toggleRemoveConfirm(e), className: classes.closeButton, size: "small", "aria-label": "remove-card", style: { marginLeft: '10px' } },
                        React.createElement(CloseIcon, { fontSize: "small" })))),
            React.createElement(Divider, { variant: "fullWidth", className: classes.divider }),
            React.createElement("div", { style: { display: 'flex', alignItems: 'center', padding: '0 10px' } },
                React.createElement("div", { style: { marginRight: '12px' } }, "Customer:"),
                React.createElement(Chip, { size: "medium", variant: "outlined", deleteIcon: React.createElement(Edit, null), onDelete: toggleModal, style: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }, label: `${customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.label}` || '', onClick: toggleModal })),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement("div", { className: classes.quantityBlock },
                actionSwitch ? (React.createElement("div", { style: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    } },
                    "Buy ",
                    React.createElement(ActionSwitch, { onChange: () => handleActionChange(action) }),
                    " Sell",
                    React.createElement(Divider, { orientation: "vertical", flexItem: true, className: classes.dividerV }),
                    "Reset",
                    React.createElement(IconButton, { size: "small", onClick: resetCardSettings },
                        React.createElement(CachedIcon, null)))) : null,
                React.createElement("div", null,
                    React.createElement(TextField, { label: `${quote.toUpperCase()}`, id: "outlined-start-adornment", name: "amount", value: orderAmt, variant: "outlined", size: "small", type: "number", inputProps: { min: 0, step: base === 'btc' ? 0.001 : 1 }, onChange: onAmountChange, error: !orderAmt.match(inputPattern) })),
                React.createElement("div", null,
                    React.createElement(TextField, { label: "Reference Id", name: "reference_id", value: reference_id, onChange: onReferenceChange, variant: "outlined", size: "small" })))),
        React.createElement(ConfirmCardDeleteModal, { toggleFunc: toggleRemoveConfirm, removeCard: hadleRemoveCard, pair: `${base}/${quote}`, open: confirmCardRemoveModalOpen }),
        modalOpen ? (React.createElement(CustomersModal, { open: modalOpen, blockKey: blockKey, settings: settings, widgetKey: widgetKey, toggleFunc: toggleModal, customerInfo: customerInfo, setCustomerInfo: setCustomerInfo })) : null,
        React.createElement(FlexieContainer, null, actionSwitch ? (hideMarketBtn && (React.createElement(React.Fragment, null, Number(orderAmt) > 0 && !customAmountError ? (React.createElement(Button, { variant: "contained", color: expired ? 'inherit' : action === OrderType.Buy ? 'success' : 'error', onClick: () => !loading && (!expired ? onCreateOrder() : handleRefreshAmount(action)), className: classes.actionButton }, loading ? (React.createElement(CircularProgress, { size: "33px" })) : (React.createElement("span", null,
            React.createElement("span", { className: classes.price }, !expired ? (React.createElement("span", null,
                (action === OrderType.Buy ? viewAsk : viewBid) || '--',
                " ",
                base.toUpperCase())) : null),
            React.createElement("div", null, expired ? (React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                "OFFER HAS EXPIRED, REQUEST NEW OFFER ",
                React.createElement(RefreshIcon, { fontSize: "small" }))) : (React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(TimerIcon, { fontSize: "small" }),
                seconds,
                " SECONDS TO ",
                action === OrderType.Buy ? 'BUY' : 'SELL'))))))) : (React.createElement("div", { className: classes.actionButton, style: { backgroundColor: grey[700] } }, "PLEASE PROVIDE VALID AMOUNT"))))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { flex: '1' } },
                React.createElement("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    } }, hideMarketBtn && (React.createElement(Button, { variant: "contained", color: "error", className: classes.actionButton, onClick: () => { viewBid && toggleMarketModal(); setAction(OrderType.Sell); }, style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    } }, viewBid ?
                    React.createElement("div", null,
                        React.createElement("span", { className: classes.price }, viewBid),
                        React.createElement("div", null, "SELL")) : React.createElement(CircularProgress, { size: "24px", style: { color: '#fff' } }))))),
            React.createElement("div", { style: { flex: '1' } },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'center', width: '100%' } }, hideMarketBtn && (React.createElement(Button, { className: classes.actionButton, variant: "contained", color: "success", onClick: () => { viewAsk && toggleMarketModal(); setAction(OrderType.Buy); }, style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    } }, viewAsk ?
                    React.createElement("div", null,
                        React.createElement("span", { className: classes.price }, viewAsk),
                        React.createElement("div", null, "BUY")) : React.createElement(CircularProgress, { size: '24px', style: { color: '#fff' } }))))))))));
};
export default BlockReverse;
