import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch, } from 'react-redux';
import Alert from '@mui/material/Alert';
import buildUrl from 'build-url';
import { Box, CircularProgress } from '@mui/material';
import { WORKING_CLIENTS_SET_UPDATE, SET_SELECTED_CLIENT, CLIENT_WIDGET_SEARCH_STRING_SET_UPDATE, ADMIN_CLIENTS_PAGE_OF_ITEMS_SET_UPDATE, } from '../../../../../redux/actionTypes/clientSearchActionTypes';
import ClientModal from './ClientModal';
import SearchInput from './SearchInput';
import ClientDetails from './ClientDetails';
import ClientsSearchResults from './ClientSearchResults';
import Messages from '../../../../../shared/helpers/errorMessages';
import ClientsService from '../../../../../services/clientsService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import filterByObjectValues from '../../../../../shared/helpers/filterByObjectValues';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { CUSTOMER_SEARCH_FETCH_REQUEST } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable react/no-unused-prop-types, @typescript-eslint/no-unsafe-return,
  @typescript-eslint/no-non-null-assertion, camelcase */
const searchStringUpdate = (searchString) => ({
    type: CLIENT_WIDGET_SEARCH_STRING_SET_UPDATE,
    searchString,
});
const pageOfItemsClientSearchUpdate = (pageOfItemsClientSearch) => ({
    type: ADMIN_CLIENTS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsClientSearch,
});
const workingClientUpdate = (clientsTotal, workingClients) => ({
    type: WORKING_CLIENTS_SET_UPDATE,
    workingClients,
    clientsTotal,
});
const selectClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const customersUpdate = (params) => ({
    type: CUSTOMER_SEARCH_FETCH_REQUEST,
    params,
});
const ClientSearch = ({ features, selectedClient, searchString, masterTenant, customersUpdateDispatch, pageOfItemsClientSearch, searchStringUpdateDispatch, selectClientUpdateDispatch, workingClientUpdateDispatch, pageOfItemsClientSearchUpdateDispatch, }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [totalClients, setTotalClients] = useState('0');
    const [clients, setClients] = useState([]);
    const [fullClient, setFullClient] = useState(null);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const createClient = (payload, setErr) => {
        const clientsService = new ClientsService({
            url: 'user/clients',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            fetchClients();
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'Client successfully created',
            });
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CLIENT_CREATE);
            setErr(message);
        });
    };
    const updateClient = (payload, setErr) => {
        if (!fullClient) {
            return;
        }
        const clientsService = new ClientsService({
            url: `user/clients/${fullClient === null || fullClient === void 0 ? void 0 : fullClient.code}`,
            method: 'patch',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            fetchClients();
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'Client successfully updated',
            });
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CLIENT_CREATE);
            setErr(message);
        });
    };
    const fetchClients = () => {
        const urlOptions = {
            queryParams: {
                page: '1',
                limit: '1000',
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/user/clients',
        };
        const endpointUrl = buildUrl('', urlOptions);
        setLoading(true);
        const clientsService = new ClientsService({
            url: endpointUrl,
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService.makeRequest()
            .then((responseData) => {
            const { records, total } = responseData;
            setClients(records);
            setTotalClients(total);
            setLoading(false);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CLIENTS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const customerUrlParams = (canReadDetails, client_code) => buildUrl('', {
        queryParams: {
            page: '1',
            search: '',
            client_code,
            limit: '1000',
            status: 'enabled',
            sort_by: 'created',
            details: canReadDetails ? 'true' : 'false',
        },
    });
    const removeSelectedClient = () => {
        searchStringUpdateDispatch('');
        workingClientUpdateDispatch(clients.length.toString(), clients);
        selectClientUpdateDispatch(null);
        const canReadDetails = (features === null || features === void 0 ? void 0 : features.includes('customer_details.read')) || false;
        const params = customerUrlParams(canReadDetails, '');
        customersUpdateDispatch(params);
    };
    const editClient = () => {
        setModalOpen(true);
    };
    const handleClientSelect = (client) => {
        selectClientUpdateDispatch(client.code);
        const canReadDetails = (features === null || features === void 0 ? void 0 : features.includes('customer_details.read')) || false;
        const params = customerUrlParams(canReadDetails, client.code);
        customersUpdateDispatch(params);
    };
    const handleSearchChange = (evt) => {
        const { value } = evt.target;
        searchStringUpdateDispatch(value);
        const newClientsArrFiltered = filterByObjectValues(clients, value);
        workingClientUpdateDispatch(newClientsArrFiltered.length.toString(), newClientsArrFiltered);
    };
    const onChangePage = (event, pageNumber) => {
        if (pageNumber > -1) {
            pageOfItemsClientSearchUpdateDispatch(pageNumber);
        }
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        workingClientUpdateDispatch(totalClients, clients);
    }, [clients]);
    useEffect(() => {
        if (masterTenant) {
            fetchClients();
        }
    }, []);
    useEffect(() => {
        if (selectedClient) {
            const val = clients.find(c => c.code === selectedClient.value);
            setFullClient(val || null);
            const canReadDetails = (features === null || features === void 0 ? void 0 : features.includes('customer_details.read')) || false;
            const params = customerUrlParams(canReadDetails, selectedClient.value);
            customersUpdateDispatch(params);
        }
        else {
            setFullClient(null);
            const canReadDetails = (features === null || features === void 0 ? void 0 : features.includes('customer_details.read')) || false;
            const params = customerUrlParams(canReadDetails, '');
            customersUpdateDispatch(params);
        }
    }, [selectedClient, clients]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    if (clients.length === 0) {
        return (React.createElement(Alert, { severity: "info", variant: "outlined" },
            React.createElement("p", null,
                React.createElement("span", { className: "bold-text" }, "No items found for this Client"))));
    }
    if (!masterTenant) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        modalOpen ? (React.createElement(ClientModal, { user: "client", open: modalOpen, currentUser: fullClient, toggleFunc: toggleModal, createClient: createClient, updateClient: updateClient })) : null,
        fullClient
            ? React.createElement(ClientDetails, { client: fullClient, onButtonClick: removeSelectedClient, onEditClick: editClient })
            : (React.createElement(React.Fragment, null,
                React.createElement(SearchInput, { features: features, toggleModal: toggleModal, searchString: searchString, onChange: handleSearchChange }),
                React.createElement(ClientsSearchResults, { onChangePage: onChangePage, clientsTotal: totalClients, handleSelect: handleClientSelect, pageOfItemsClientSearch: pageOfItemsClientSearch })))));
};
const mapStateToProps = (state) => {
    const { features } = state.client;
    const { masterTenant } = state.client;
    const { searchString, pageOfItemsClientSearch } = state.clientSearch;
    const selectedClient = getSelectedClientInputValue(state);
    return {
        features,
        selectedClient,
        masterTenant,
        searchString,
        pageOfItemsClientSearch,
    };
};
const mapDispatchToProps = dispatch => ({
    customersUpdateDispatch: (params) => dispatch(customersUpdate(params)),
    searchStringUpdateDispatch: (searchString) => dispatch(searchStringUpdate(searchString)),
    selectClientUpdateDispatch: (client) => dispatch(selectClientUpdate(client)),
    pageOfItemsClientSearchUpdateDispatch: (pageOfItemsClientSearch) => dispatch(pageOfItemsClientSearchUpdate(pageOfItemsClientSearch)),
    workingClientUpdateDispatch: (workingTotal, workingClients) => dispatch(workingClientUpdate(workingTotal, workingClients)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientSearch);
