/* eslint-disable camelcase */
export const CLIENT_INFO_FETCH_REQUEST = 'CLIENT_INFO_FETCH_REQUEST';
export const CLIENT_INFO_FETCH_SUCCESS = 'CLIENT_INFO_FETCH_SUCCESS';
export const CLIENT_INFO_FETCH_FAIL = 'CLIENT_INFO_FETCH_FAIL';
export const CLIENT_BALANCES_FETCH_REQUEST = 'CLIENT_BALANCES_FETCH_REQUEST';
export const CLIENT_BALANCES_FETCH_SUCCESS = 'CLIENT_BALANCES_FETCH_SUCCESS';
export const CLIENT_BALANCES_FETCH_FAIL = 'CLIENT_BALANCES_FETCH_FAIL';
export const CUSTOMER_INFO_FETCH_REQUEST = 'CUSTOMER_INFO_FETCH_REQUEST';
export const CUSTOMER_INFO_FETCH_SUCCESS = 'CUSTOMER_INFO_FETCH_SUCCESS';
export const CUSTOMER_INFO_FETCH_FAIL = 'CUSTOMER_INFO_FETCH_FAIL';
export const CUSTOMER_INFO_CLEAN = 'CUSTOMER_INFO_CLEAN';
export const CUSTOMER_BALANCES_FETCH_REQUEST = 'CUSTOMER_BALANCES_FETCH_REQUEST';
export const CUSTOMER_BALANCES_FETCH_SUCCESS = 'CUSTOMER_BALANCES_FETCH_SUCCESS';
export const CUSTOMER_BALANCES_FETCH_FAIL = 'CUSTOMER_BALANCES_FETCH_FAIL';
export const EXCHANGES_BALANCES_FETCH_REQUEST = 'EXCHANGES_BALANCES_FETCH_REQUEST';
export const EXCHANGES_BALANCES_FETCH_SUCCESS = 'EXCHANGES_BALANCES_FETCH_SUCCESS';
export const EXCHANGES_BALANCES_FETCH_FAIL = 'EXCHANGES_BALANCES_FETCH_FAIL';
export const COLLAPSE_EXCHANGES_BALANCES_CLOSE = 'COLLAPSE_EXCHANGES_BALANCES_CLOSE';
export const COLLAPSE_EXCHANGES_BALANCES_ADD_OPEN = 'COLLAPSE_EXCHANGES_BALANCES_ADD_OPEN';
export const COLLAPSE_BALANCES_CLOSE = 'COLLAPSE_BALANCES_CLOSE';
export const COLLAPSE_BALANCES_ADD_OPEN = 'COLLAPSE_BALANCES_ADD_OPEN';
export const COLLAPSE_CUSTOMER_BALANCES_CLOSE = 'COLLAPSE_CUSTOMER_BALANCES_CLOSE';
export const COLLAPSE_CUSTOMER_BALANCES_ADD_OPEN = 'COLLAPSE_CUSTOMER_BALANCES_ADD_OPEN';
