var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback } from 'react';
import buildUrl from 'build-url';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Withdrawals from './Withdrawals';
import Addresses from './Addresses';
import UsersService from '../../../../../services/usersService';
import Messages from '../../../../../shared/helpers/errorMessages';
import { getWithdrawalsToRender } from '../Wallets/WidgetWithdrawals';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { AddressDelete, AddressSign } from '../Whitelist/components/AddressesActions';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import CustodyClientWithdrawalsService from '../../../../../services/custodyWithdrawalsService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { Tab, Tabs } from '@mui/material';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const WidgetApprove = ({ masterTenant, meCode, features, selectedClient, walletsLoading, columns, urlOptionsWithdrawals, approveWidgetHeight, currencies, clientInfo, }) => {
    const [state, setState] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientCode, setClientCode] = useState('');
    const [usersRead, setUsersRead] = useState(false);
    const [maxHeightA, setMaxHeightA] = useState(480);
    const [maxHeightW, setMaxHeightW] = useState(480);
    const [rowsPerPageA, setRowsPerPageA] = useState(10);
    const [rowsPerPageW, setRowsPerPageW] = useState(10);
    const [pageAddresses, setPageAddresses] = useState(0);
    const [clientsRead, setClientsRead] = useState(false);
    const [totalAddresses, setTotalAddresses] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [initBulk, setInitBulk] = useState([]);
    const [pageWithdrawals, setPageWithdrawals] = useState(0);
    const [totalWithdrawals, setTotalWithdrawals] = useState(0);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [users, setUsers] = useState([]);
    const [sortDirectionA, setSortDirectionA] = useState('desc');
    const [sortDirectionW, setSortDirectionW] = useState('asc');
    const [addresses, setAddresses] = useState([]);
    const [anchorColumnsA, setAnchorColumnsA] = useState(null);
    const [anchorColumnsW, setAnchorColumnsW] = useState(null);
    const [withdrawals, setWithdrawals] = useState([]);
    const [sortByW, setSortByW] = useState('created');
    const [columnsToRenderA, setColumnsToRenderA] = useState([]);
    const [columnsToRenderW, setColumnsToRenderW] = useState([]);
    const [withdrawalsToRender, setWithdrawalsToRender] = useState([]);
    const [addressesToRender, setAddressesToRender] = useState([]);
    const [sortByA, setSortByA] = useState('created_at');
    const columnsOpenA = Boolean(anchorColumnsA);
    const columnsOpenW = Boolean(anchorColumnsW);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClickA = (event) => {
        setAnchorColumnsA(event.currentTarget);
    };
    const handleColumnsClickW = (event) => {
        setAnchorColumnsW(event.currentTarget);
    };
    const handleColumnsCloseA = () => {
        setAnchorColumnsA(null);
    };
    const handleColumnsCloseW = () => {
        setAnchorColumnsW(null);
    };
    const onSortHandleA = (key) => {
        setPageAddresses(0);
        setSortByA(key);
        setSortDirectionA(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const onSortHandleW = (key) => {
        setPageWithdrawals(0);
        setSortByW(key);
        setSortDirectionW(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const onChangePageA = (event, pageNumber) => {
        setPageAddresses(pageNumber);
    };
    const onChangePageW = (event, pageNumber) => {
        setPageWithdrawals(pageNumber);
    };
    const changeTab = (tab) => {
        setActiveTab(tab);
    };
    const hideShowColumnW = (colId) => {
        const column = columns.find(c => c.id === colId);
        const colIndex = columnsToRenderW.findIndex(c => c.id === (column === null || column === void 0 ? void 0 : column.id));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRenderW([...columnsToRenderW]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const hideShowColumnA = (colId) => {
        const column = columns.find(c => c.id === colId);
        const colIndex = columnsToRenderA.findIndex(c => c.id === (column === null || column === void 0 ? void 0 : column.id));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRenderA([...columnsToRenderA]);
        }
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllWithDrawals = () => {
        const url = `/custody/withdrawals${urlOptionsWithdrawals}`;
        const service = new CustodyClientWithdrawalsService({ url, method: 'get' });
        setLoading(true);
        service.makeRequest()
            .then((data) => {
            setLoading(false);
            setWithdrawals(data.records);
            setTotalWithdrawals(+data.total);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchAllAddresses = (code, cancelToken) => {
        const url = '/custody/withdrawals/addresses';
        const params = {
            limit: rowsPerPageA,
            clientCode: code,
            state,
            sort_by: sortByA === null || sortByA === void 0 ? void 0 : sortByA.replace('_at', ''),
            sort_direction: sortDirectionA,
            page: pageAddresses + 1,
        };
        const service = new WithdrawalsAddressesService({
            url,
            method: 'get',
            cancelToken,
            params,
        });
        setLoadingAddresses(true);
        service.makeRequest()
            .then((data) => {
            setLoadingAddresses(false);
            setAddresses(data.records);
            setTotalAddresses(+data.total);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoadingAddresses(false);
            setAddresses([]);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchUsers = (code) => __awaiter(void 0, void 0, void 0, function* () {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                search: '',
                client_code: code,
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/users',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const usersService = new UsersService({
            method: 'get',
            url: endpointUrl,
            baseURL: getEnvSettings().adminApiUrl,
        });
        const usersByClient = yield usersService.makeRequest();
        return usersByClient;
    });
    const cellActions = {
        actions: {
            sign: () => fetchAllAddresses(clientCode),
            approve: () => fetchAllAddresses(clientCode),
            confirm: () => fetchAllAddresses(clientCode),
            masterSign: () => fetchAllAddresses(clientCode),
            deleteItem: () => fetchAllAddresses(clientCode),
        },
        actionsComponents: {
            sign: AddressSign,
            approve: AddressSign,
            confirm: AddressDelete,
            masterSign: AddressSign,
            deleteItem: AddressDelete,
        },
        actionsList: [
            { name: 'Sign', action: 'sign' },
            { name: 'Approve', action: 'approve' },
            { name: 'Confirm', action: 'confirm' },
            { name: 'Delete', action: 'deleteItem' },
            { name: 'Master sign', action: 'masterSign' },
        ],
    };
    const sortStoredAddresses = (addressesToSort, direction, by) => {
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const k = key;
            let valueA;
            let valueB;
            if (k) {
                valueA = a[k];
                valueB = b[k];
                if (valueA && valueB) {
                    // value is of a string type
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        return sorter(dir, valueA, valueB);
                    }
                }
            }
            // or maybe throw exception
            return 0;
        };
        return addressesToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    useEffect(() => {
        setClientCode((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '');
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (state) {
            fetchAllAddresses(clientCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (state) {
            fetchAllAddresses(clientCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [state]);
    useEffect(() => {
        const status = masterTenant ? 'pending_admin_approve' : 'pending_sign';
        setState(status);
    }, [masterTenant]);
    useEffect(() => {
        const canReadUsers = features && Array.isArray(features) && features.length && features.includes('users.read');
        setUsersRead(canReadUsers || false);
        const canReadClients = features && Array.isArray(features) && features.length && features.includes('tenants.read');
        setClientsRead(canReadClients || false);
        const canUpdateAddresses = features && Array.isArray(features) && features.length
            && features.includes('client_custody_withdrawal_address.update');
        const canSignAddresses = features && Array.isArray(features) && features.length
            && features.includes('client_custody_address_master_sign.create');
        if (!canUpdateAddresses && !canSignAddresses) {
            setActiveTab(1);
        }
    }, [features]);
    useEffect(() => {
        if (meCode) {
            let filtered = addresses.filter(a => a.creator_code !== meCode && a.state === state);
            if (users.length !== 0) {
                filtered = filtered.map((a) => {
                    const creatorUser = users.find(user => user.code === a.creator_code);
                    const creatorName = creatorUser
                        ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                        : '';
                    return Object.assign({}, a, { creator: creatorName });
                });
            }
            setAddressesToRender([...filtered]);
        }
    }, [addresses]);
    useEffect(() => {
        const items = getWithdrawalsToRender(withdrawals);
        let filtered = items.filter(w => w.state === 'pending_sign');
        if (users.length !== 0) {
            filtered = filtered.map((a) => {
                const item = withdrawals.find(w => w.code === a.code);
                const creatorUser = item ? users.find(user => user.code === item.creator_code) : null;
                const creatorName = creatorUser
                    ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                    : '';
                return Object.assign({}, a, { creator: creatorName });
            });
        }
        setWithdrawalsToRender([...filtered]);
        setTotalWithdrawals(filtered.length);
    }, [withdrawals, rowsPerPageW]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (state) {
            fetchAllAddresses(clientCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [pageAddresses, sortByA, sortDirectionA, rowsPerPageA]);
    useEffect(() => {
        const items = getWithdrawalsToRender(withdrawals);
        let filtered = items.filter(w => w.state === 'pending_sign');
        if (users.length !== 0) {
            filtered = filtered.map((a) => {
                const item = withdrawals.find(w => w.code === a.code);
                const creatorUser = item ? users.find(user => user.code === item.creator_code) : null;
                const creatorName = creatorUser
                    ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                    : '';
                return Object.assign({}, a, { creator: creatorName });
            });
        }
        setWithdrawalsToRender([...filtered]);
    }, [withdrawals, columnsToRenderW]);
    useEffect(() => {
        if (usersRead && clientsRead && masterTenant) {
            setLoading(true);
            const fetchClientUser = () => __awaiter(void 0, void 0, void 0, function* () {
                const clientUsers = yield fetchUsers(clientCode);
                setUsers(clientUsers.records);
                setLoading(false);
            });
            fetchClientUser().catch((e) => {
                setLoading(false);
                const message = composeErrorMessage(e, Messages.USERS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [masterTenant, clientCode, usersRead, clientsRead]);
    useEffect(() => {
        if (columns && columns.length) {
            const colsA = columns.filter(c => c.value).filter(c => c.id.includes('addressesApproveColumns'));
            const colsW = columns.filter(c => c.value).filter(c => c.id.includes('withdrawalsApproveColumns'));
            setColumnsToRenderA([...colsA]);
            setColumnsToRenderW([...colsW]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(approveWidgetHeight, setRowsPerPageA, setMaxHeightA);
        resizeWidgetHeight(approveWidgetHeight, setRowsPerPageW, setMaxHeightW);
    }, [approveWidgetHeight]);
    useEffect(() => {
        const mapped = addressesToRender.map(a => ({
            value: false,
            code: a.code,
        }));
        setInitBulk(mapped);
    }, [addressesToRender]);
    return (React.createElement("div", { className: "tabs tabs--bordered-bottom" },
        React.createElement("div", { className: "tabs__wrap" },
            React.createElement(Tabs, { value: activeTab },
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_address_master_sign.create', 'client_custody_withdrawal_address.update'] },
                    React.createElement(Tab, { label: "Addresses", onClick: () => { changeTab(0); } })),
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_withdrawal_master_sign.create', 'client_custody_transaction.update'] },
                    React.createElement(Tab, { label: "Withdrawals", onClick: () => { changeTab(1); } }))),
            activeTab === 0 ?
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_address_master_sign.create', 'client_custody_withdrawal_address.update'] },
                    React.createElement(Addresses, { meCode: meCode, sortBy: sortByA, columns: columns, features: features, admin: masterTenant, maxHeight: maxHeightA, clientInfo: clientInfo, initialValue: initBulk, selectedClient: selectedClient, cellActions: cellActions, loading: loadingAddresses, columnsOpen: columnsOpenA, rowsPerPage: rowsPerPageA, onSortHandle: onSortHandleA, pageAddresses: pageAddresses, onChangePageA: onChangePageA, sortDirection: sortDirectionA, anchorColumns: anchorColumnsA, totalAddresses: totalAddresses, hideShowColumn: hideShowColumnA, columnsToRender: columnsToRenderA, fetchAllAddresses: fetchAllAddresses, addressesToRender: addressesToRender, handleColumnsClose: handleColumnsCloseA, handleColumnsClick: handleColumnsClickA })) : null,
            activeTab === 1 ?
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_withdrawal_master_sign.create', 'client_custody_transaction.update'] },
                    React.createElement(Withdrawals, { sortBy: sortByW, loading: loading, columns: columns, features: features, admin: masterTenant, maxHeight: maxHeightW, currencies: currencies, withdrawals: withdrawals, rowsPerPage: rowsPerPageW, columnsOpen: columnsOpenW, onSortHandle: onSortHandleW, onChangePageW: onChangePageW, sortDirection: sortDirectionW, anchorColumns: anchorColumnsW, walletsLoading: walletsLoading, hideShowColumn: hideShowColumnW, pageWithdrawals: pageWithdrawals, columnsToRender: columnsToRenderW, totalWithdrawals: totalWithdrawals, handleColumnsClick: handleColumnsClickW, handleColumnsClose: handleColumnsCloseW, withdrawalsToRender: withdrawalsToRender, fetchAllWithDrawals: fetchAllWithDrawals })) : null)));
};
const mapStateToProps = (state, ownProps) => ({
    meCode: state.client.meCode,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    currencies: state.custody.currencies,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    walletsLoading: state.custody.loadingWallets,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    approveWidgetHeight: state.widgets[ownProps.widgetKey],
    urlOptionsWithdrawals: state.custody.urlOptionsWithdrawals,
});
export default connect(mapStateToProps)(WidgetApprove);
