import React, { useCallback, useEffect, useState, useRef, } from 'react';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import buildUrl from 'build-url';
import axios from 'axios';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, TextField } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import ClientsService from '../../../../../services/clientsService';
import CustomersService from '../../../../../services/customersService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import TradingTransfersService from '../../../../../services/tradingTransfersService';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getTradingQuotes, getTradingBases } from '../../../../../redux/selectors/selectors';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const DepositDialog = ({ toggle, open, optionsBase, fetchTransfers, optionsQuotes, params, features, }) => {
    const inputPattern = /[-]/gmi;
    const dirty = useRef(false);
    const [twoFA, setTwoFA] = useState('');
    const [client, setClient] = useState('');
    const [amount, setAmount] = useState('');
    const [customer, setCustomer] = useState('');
    const [currency, setCurrency] = useState('');
    const [transactionID, setTransactionID] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const dispatch = useDispatch();
    const notice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const helperTextStyles = useHelperTextStyles();
    const onClientChange = (event) => {
        const code = event.target.value;
        setClient(code);
    };
    const onCustomerChange = (event) => {
        const code = event.target.value;
        setCustomer(code);
    };
    const onCurrencyChange = (event) => {
        const value = event.target.value;
        setCurrency(value);
    };
    const onAmountChange = (evt) => {
        const { value } = evt.target;
        if (value.match(inputPattern))
            return;
        if (isNaN(Number(value)))
            return;
        setAmount(value);
    };
    const onTransactionIDChange = (evt) => {
        const { value } = evt.target;
        setTransactionID(value);
    };
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        if (value.match(inputPattern))
            return;
        if (isNaN(Number(value)) || value.length > 6)
            return;
        setTwoFA(value);
    };
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const onAddNewDeposit = () => {
        dirty.current = true;
        const errors = validateFields({ amount, transactionID, twoFA });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const service = new TradingTransfersService({
            url: '/trade/trading_transfer/deposit',
            method: 'post',
            data: {
                amount,
                totp_code: twoFA,
                client_code: client,
                customer_code: customer,
                currency_code: currency,
                transaction_details: transactionID,
            },
            baseURL: getEnvSettings().adminApiUrl,
        });
        service.makeRequest()
            .then(() => {
            showNotification({
                color: 'success',
                dispatch: notice,
                message: 'Deposit successfully created',
            });
            fetchTransfers(params);
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.TRADING_DEPOSIT_CREATE);
            showNotification({
                color: 'error',
                dispatch: notice,
                message: `Error: ${message}`,
            });
            toggle();
        });
    };
    const fetchCustomers = (url, cancelToken) => {
        const service = new CustomersService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((responseData) => {
            const { records } = responseData;
            const mapped = records.map((i) => ({
                value: i.code,
                label: i.company_name || i.code,
            }));
            setCustomerOptions(mapped);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                color: 'error',
                dispatch: notice,
                message: `Error: ${message}`,
            });
        });
    };
    useEffect(() => {
        const urlOptions = {
            queryParams: {
                limit: '10000',
                sort_by: 'created',
                sort_direction: 'desc',
            },
            path: '/user/clients',
        };
        let isCancelled = false;
        const endpointUrl = buildUrl('', urlOptions);
        const clientsService = new ClientsService({
            url: endpointUrl,
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService.makeRequest()
            .then((responseData) => {
            const { records } = responseData;
            const selectOptions = records.map((record) => ({
                label: record.company_name,
                value: record.code,
            }));
            if (!isCancelled) {
                setClientOptions(selectOptions);
            }
        })
            .catch((e) => {
            if (!isCancelled) {
                showNotification({
                    message: `Something went wrong, error: ${e}`,
                    color: 'error',
                    dispatch: notice,
                });
            }
        });
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (client) {
            const urlOptions = {
                queryParams: {
                    limit: '1000',
                    sort_by: 'created',
                    client_code: client,
                    sort_direction: 'desc',
                },
                path: '/customers',
            };
            const canReadDetails = features && Array.isArray(features) && features.includes('customer_details.read');
            if (canReadDetails) {
                urlOptions.queryParams.details = 'true';
            }
            const endpointUrl = buildUrl('', urlOptions);
            fetchCustomers(endpointUrl, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [features, client]);
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({ amount, transactionID, twoFA });
            setInvalidFields([...errors]);
        }
    }, [amount, transactionID, twoFA]);
    return (React.createElement(Dialog, { open: open, onClose: () => toggle(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Create new deposit"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { display: 'flex', gap: 1 },
                React.createElement(TextField, { size: "small", select: true, fullWidth: true, value: client, variant: "outlined", label: "Client", onChange: onClientChange }, clientOptions.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label)))),
                React.createElement(TextField, { size: "small", select: true, fullWidth: true, value: customer, variant: "outlined", label: "Customer", onChange: onCustomerChange, helperText: client && !customerOptions.length ? 'Select customer'
                        : null }, customerOptions.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label)))),
                React.createElement(TextField, { size: "small", select: true, fullWidth: true, value: currency, variant: "outlined", label: "Currency", onChange: onCurrencyChange, helperText: client && !customerOptions.length ? 'Select customer'
                        : null },
                    optionsQuotes.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label))),
                    optionsBase.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label))))),
            React.createElement(TextField, { fullWidth: true, size: "small", value: amount, label: "Amount", margin: "normal", variant: "outlined", onChange: onAmountChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('amount') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", value: transactionID, label: "Transaction Details", onChange: onTransactionIDChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('transactionID') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: "small", name: "twoFA", value: twoFA, label: "Totp", margin: "normal", variant: "outlined", onChange: onTwoFAChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('twoFA') ? 'Field is required' : ''}` })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: onAddNewDeposit, disabled: !client || !currency || !customer
                    || invalidFields.includes('roleName')
                    || invalidFields.includes('transactionID')
                    || invalidFields.includes('twoFA') }, "Create"),
            React.createElement(Button, { size: "small", onClick: toggle, variant: "outlined", color: "inherit" }, "Cancel"))));
};
const mapStateToProps = (state) => ({
    features: state.client.features,
    optionsBase: getTradingBases(state),
    optionsQuotes: getTradingQuotes(state),
});
export default connect(mapStateToProps)(DepositDialog);
