var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Button, Chip, Collapse, LinearProgress, Tab, Tabs, useTheme } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { groupBy } from 'lodash';
import SettlementsTableBody from './SettlementsTableBody';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
const envSettings = getEnvSettings();
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function mapSettlements(settlements, clients) {
    return settlements.map((item) => {
        var _a;
        const command = item.commands.records.find((record) => record.type !== 'account_transfer');
        return {
            settlementCode: item.settlement_code,
            clientId: item.client_custody_wallet.client_code || '',
            clientName: ((_a = clients.find((c) => c.code === item.client_custody_wallet.client_code)) === null || _a === void 0 ? void 0 : _a.company_name) || '',
            customerName: item.client_custody_wallet.customer_code || '',
            walletId: item.client_custody_wallet.code,
            currency: item.client_custody_wallet.currency_code,
            amountToSettle: (command === null || command === void 0 ? void 0 : command.type) === 'pool_wallet_deposit_to_client'
                ? command === null || command === void 0 ? void 0 : command.data.deposit_amount
                : (command === null || command === void 0 ? void 0 : command.data.withdrawal_amount) || 'N/A',
            direction: (command === null || command === void 0 ? void 0 : command.type) === 'pool_wallet_deposit_to_client' ? 'to client' : 'from client',
            status: item.state,
            failedReason: item.failed_reason,
        };
    });
}
const SettlementsTable = () => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('status');
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [groupedSettlements, setGroupedSettlements] = useState([]);
    const [settlements, setSettlements] = useState([]);
    const [statusFilter, setStatusFilter] = useState('created');
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const handlePageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('/custody/wallets/settlements', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                states: statusFilter,
                // limit: statusFilter !== 'created' ? rowsPerPage : null,
                // TODO We don't have total amount, using hardcoded value
                limit: 1000,
                page: statusFilter !== 'created' ? page : null,
            },
        })
            .then((response) => response.data.records)
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SCHEDULERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const fetchClients = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('/user/clients', {
            baseURL: getEnvSettings().adminApiUrl,
            params: { limit: 1000 },
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SCHEDULERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const executeSettlement = (id) => {
        return axios
            .post(`/custody/wallets/settlements/${id}/execute/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SCHEDULERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const executeClientSettlements = (arr) => __awaiter(void 0, void 0, void 0, function* () {
        const ids = arr.map((item) => item.settlementCode);
        if (ids.length) {
            let n = 0;
            while (n < arr.length) {
                const id = ids[n];
                yield executeSettlement(id);
                n += 1;
            }
            showNotification({
                message: `Settlements were executed`,
                color: 'success',
                dispatch: errorNotice,
            });
        }
    });
    const onExecute = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeSettlement(id).finally(() => {
            getData();
        });
    });
    const onExecuteAll = (settlements) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeClientSettlements(settlements).finally(() => {
            getData();
        });
    });
    const handleCollapse = (index) => {
        const updated = [...groupedSettlements];
        updated[index].collapsed = !updated[index].collapsed;
        setGroupedSettlements(updated);
    };
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let clients = [];
        let settlements = [];
        setLoading(true);
        yield fetchData().then((res) => {
            settlements = res;
        });
        yield fetchClients().then((res) => {
            clients = res ? res.data.records : [];
        });
        if (statusFilter === 'created') {
            const grouped = Object.entries(groupBy(mapSettlements(settlements, clients), 'clientId')).map((group) => ({
                name: group[1][0].clientName,
                settlements: group[1],
                collapsed: true,
            }));
            setGroupedSettlements(grouped);
        }
        else {
            setSettlements(mapSettlements(settlements, clients));
        }
        setLoading(false);
        return { clients, settlements };
    });
    useEffect(() => {
        getData();
    }, [statusFilter, page, rowsPerPage]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const visibleRows = React.useMemo(() => settlements, [order, orderBy, page, rowsPerPage, settlements]);
    const onFilterChange = (filter) => {
        setPage(0);
        setStatusFilter(filter);
    };
    return (React.createElement(Box, { sx: { width: '100%' }, p: 0.6 },
        React.createElement(Box, null,
            React.createElement(Box, { height: '5px' }, loading && React.createElement(LinearProgress, null)),
            React.createElement(Box, { mb: 2 },
                React.createElement(Tabs, { value: statusFilter, onChange: (e, value) => onFilterChange(value), "aria-label": 'basic tabs example' },
                    React.createElement(Tab, { label: 'Created', value: 'created', disabled: loading }),
                    React.createElement(Tab, { label: 'Pending', value: 'pending', disabled: loading }),
                    React.createElement(Tab, { label: 'Failed', value: 'failed', disabled: loading }),
                    React.createElement(Tab, { label: 'Declined', value: 'declined', disabled: loading }),
                    React.createElement(Tab, { label: 'Success', value: 'success', disabled: loading })))),
        React.createElement(Paper, { sx: { width: '100%' } },
            React.createElement(Box, null, statusFilter === 'created' ? (React.createElement(Box, null, groupedSettlements.map((client, index) => (React.createElement(React.Fragment, { key: client.name },
                React.createElement(Box, { sx: {
                        backgroundColor: theme.palette.action.selected,
                        cursor: 'pointer',
                        borderBottom: '1px solid',
                    }, onClick: () => handleCollapse(index) },
                    React.createElement(Box, { p: 1 },
                        React.createElement(Box, { justifyContent: 'space-between', display: 'flex' },
                            React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                client.collapsed ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null),
                                React.createElement(Chip, { size: 'small', label: client.name })),
                            React.createElement(Button, { variant: 'contained', size: 'small', onClick: () => onExecuteAll(client.settlements) }, "Execute All")))),
                React.createElement(Box, { sx: { overflow: 'auto', backgroundColor: theme.palette.background.default }, pl: 4 },
                    React.createElement(Collapse, { in: client.collapsed },
                        React.createElement(SettlementsTableBody, { rows: client.settlements, type: statusFilter, executeSettlement: onExecute })))))))) : (React.createElement(Box, { sx: { overflow: 'auto' } },
                React.createElement(SettlementsTableBody, { rows: visibleRows, type: statusFilter })))))));
};
export default SettlementsTable;
