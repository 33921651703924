/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { NEW_ORDER_CREATE_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
/* eslint-disable class-methods-use-this */
const useRfqService = () => {
    const [loading, setLoading] = useState(false);
    const [customAmount, setCustomAmount] = useState();
    const [amountError, setAmountError] = useState(false);
    const dispatchOrder = (dispatch, payload, closeErrorNotice) => {
        dispatch({ type: NEW_ORDER_CREATE_REQUEST, payload, closeErrorNotice });
    };
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const getCustomAmount = (payload) => {
        setLoading(true);
        setAmountError(false);
        axios
            .request({
            method: 'post',
            url: '/orders/rfq/amount',
            data: payload,
        })
            .then((response) => {
            setCustomAmount(response.data);
            setLoading(false);
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.POST_NEW_ORDER);
            setAmountError(true);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    };
    const handleOrderCreate = (orderPayload, customerInfo) => {
        if (customerInfo) {
            dispatchOrder(dispatch, orderPayload, errorNotice);
        }
        else {
            showNotification({
                message: 'Please, select a customer for creating orders',
                color: 'error',
                dispatch: errorNotice,
            });
        }
    };
    return {
        loading,
        customAmount,
        getCustomAmount,
        handleOrderCreate,
        amountError,
    };
};
export default useRfqService;
