import React, { useState, useEffect, useCallback, useContext, } from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { Box, CircularProgress, Container } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer, } from '../../styling/style';
import { getWidgetColumns, getCustodyWallets, getCustodyCustomers, } from '../../../../../redux/selectors/custodySelectors';
import WithdrawalModal from './WithdrawalModal';
import WithdrawalFilters from './WithdrawalFilters';
import WithdrawalTableCell from './WithdrawalTableCell';
import WithdrawalsCellActions from './WithdrawalsCellActions';
import { SpinnerContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { RatesContext } from '../../../../../providers/RatesProvider';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import CustodyClientWithdrawalsService from '../../../../../services/custodyWithdrawalsService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
export const getWithdrawalsToRender = (withdrawals) => {
    if (withdrawals.length === 0) {
        return [];
    }
    return withdrawals.map((r) => {
        const destination = !r.destination_type ? r.address
            : r.destination_type === 'address' ? r.destination_address : r.destination_wallet;
        const address = destination && Object.prototype.hasOwnProperty.call(destination, 'address')
            ? destination.address : '';
        const convert = {
            fee: r.fee,
            usd_fee: '',
            code: r.code,
            state: r.state,
            wallet_label: '',
            customer_code: '',
            tx_hash: r.tx_hash,
            created: r.created_at,
            updated: r.updated_at,
            address: address || '',
            wallet_code: r.wallet_code,
            reference_id: r.reference_id,
            currency_code: r.currency_code,
            failure_reason: r.failure_reason,
            source_address: r.source_address,
            requested_amount: r.requested_amount,
            failure_raw_error: r.failure_raw_error,
            fee_currency_code: r.fee_currency_code,
            destination_type: r.destination_type || '',
            requested_amount_usd: r.requested_amount_usd,
            address_label: destination ? destination.label : '',
            replaced_withdrawal_code: r.replaced_withdrawal_code || '',
        };
        return convert;
    });
};
const Withdrawals = ({ features, selectedClient, columns, masterTenant, currencies, allWallets, walletsLoading, allCustomers, withdrawalsWidgetHeight, }) => {
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    const [clientCode, setClientCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [urlWithdrawals, setUrlWithdrawals] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingWithdrawal, setLoadingWithdrawal] = useState(false);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortBy, setSortBy] = useState('created');
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [withdrawals, setWithdrawals] = useState([]);
    const [withdrawalsToRender, setWithdrawalsToRender] = useState([]);
    const ctx = useContext(RatesContext);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const forbidForSorting = [
        'usd_fee', 'wallet_label', 'customer_code', 'tx_hash', 'destination_type', 'address', 'address_label',
    ];
    const updateUrl = useCallback((url) => {
        setUrlWithdrawals(url);
    }, [urlWithdrawals]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllWithDrawals = (cancelToken) => {
        const url = `/custody/withdrawals${urlWithdrawals}`;
        const service = new CustodyClientWithdrawalsService({ url, method: 'get', cancelToken });
        setLoading(true);
        service.makeRequest()
            .then((data) => {
            setLoading(false);
            setTotalPages(+data.total);
            setWithdrawals(data.records);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const handleSendWithdraw = (payload) => {
        const reportWithdrawalsService = new CustodyClientWithdrawalsService({
            url: '/custody/withdrawals', method: 'post', data: payload,
        });
        setLoadingWithdrawal(true);
        reportWithdrawalsService.makeRequest()
            .then(() => {
            setLoadingWithdrawal(false);
            toggleModal();
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'Withdrawal successfully created',
            });
            fetchAllWithDrawals();
        })
            .catch((e) => {
            setLoadingWithdrawal(false);
            fetchAllWithDrawals();
            toggleModal();
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_CREATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onChangePage = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        setClientCode(code);
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlWithdrawals !== '') {
            fetchAllWithDrawals(cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlWithdrawals]);
    useEffect(() => {
        if (withdrawals.length) {
            const items = getWithdrawalsToRender(withdrawals);
            const mapped = items.map((item) => {
                const wallet = allWallets.find(w => w.code === item.wallet_code);
                const customer = (wallet === null || wallet === void 0 ? void 0 : wallet.customer_code) ? allCustomers.find(c => c.code === (wallet === null || wallet === void 0 ? void 0 : wallet.customer_code)) : null;
                const canReadDetails = features && (features === null || features === void 0 ? void 0 : features.length) && features.includes('customer_details.read');
                const customerData = canReadDetails && customer ? customer.company_name : (wallet === null || wallet === void 0 ? void 0 : wallet.customer_code) || '';
                const labelData = wallet ? wallet.label : 'N/A';
                return Object.assign({}, item, { wallet_label: labelData, customer_code: customerData });
            });
            setWithdrawalsToRender([...mapped]);
            if (ctx.service) {
                const conversionService = ctx.service;
                const mappedW = mapped.map((i) => Object.assign({}, i, {
                    usd_fee: conversionService.convertInBase(i.currency_code.toLowerCase(), i.fee),
                }));
                setWithdrawalsToRender([...mappedW]);
            }
        }
        else {
            setWithdrawalsToRender([]);
            setTotalPages(0);
        }
    }, [withdrawals, allWallets, allCustomers, features, ctx.service]);
    useEffect(() => {
        if (columns && columns.length) {
            let cols = [];
            cols = columns.filter(c => c.value);
            if (withdrawals.length && !Object.prototype.hasOwnProperty.call(withdrawals[0], 'destination_type')) {
                cols = columns.filter(c => c.id !== 'destination_type').filter(c => c.value);
            }
            setColumnsToRender([...cols]);
        }
    }, [columns, masterTenant, withdrawals]);
    useEffect(() => {
        resizeWidgetHeight(withdrawalsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [withdrawalsWidgetHeight]);
    return (React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
        modalOpen ? (React.createElement(WithdrawalModal, { open: modalOpen, toggleFunc: toggleModal, selectedClient: selectedClient, loading: loadingWithdrawal, handleSendWithdraw: handleSendWithdraw })) : null,
        React.createElement(WithdrawalFilters, { sortBy: sortBy, features: features, clientCode: clientCode, rowsPerPage: rowsPerPage, currentPage: currentPage, toggleModal: toggleModal, onFiltersChange: updateUrl, sortDirection: sortDirection, setCurrentPage: setCurrentPage }),
        loading ? (React.createElement(SpinnerContainer, null,
            React.createElement(CircularProgress, null))) : (React.createElement(React.Fragment, null,
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null))),
                    React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                        React.createElement(MaterialPopoverWrap, null,
                            React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                React.createElement(ListItemText, { primary: col.title }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
            withdrawalsToRender.length === 0 ? (React.createElement(Container, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" },
                    React.createElement(Box, null, "No items found")))) : (React.createElement(React.Fragment, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map((column) => {
                                    if (forbidForSorting.includes(column.key || '')) {
                                        return (React.createElement(TableCell, { align: "left", key: column.key, className: "withdrawal_disable_sorting", style: { minWidth: column.minWidth } },
                                            React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc' }, column.title)));
                                    }
                                    return (React.createElement(TableCell, { key: column.key, align: 'left', style: { minWidth: column.minWidth } },
                                        React.createElement(TableSortLabel, { direction: column.key === sortBy ? sortDirection : 'asc', active: column.key === sortBy, onClick: () => onSortHandle(column.key) }, column.title)));
                                }),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, withdrawalsToRender.map(withdrawal => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: withdrawal.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? withdrawal[key] : undefined;
                                return (React.createElement(WithdrawalTableCell, { key: i, value: value, columnKey: key, item: withdrawal, currencies: currencies, withdrawals: withdrawals, walletsLoading: walletsLoading, currency: withdrawal.currency_code, currencyFee: withdrawal.fee_currency_code }));
                            }),
                            React.createElement(WithdrawalsCellActions, { item: withdrawal, features: features, admin: masterTenant, currencies: currencies, fetchAllWithDrawals: fetchAllWithDrawals }))))))),
                React.createElement(TablePagination, { component: "div", count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    currencies: state.custody.currencies,
    allWallets: getCustodyWallets(state),
    masterTenant: state.client.masterTenant,
    allCustomers: getCustodyCustomers(state),
    selectedClient: getSelectedClientInputValue(state),
    walletsLoading: state.custody.loadingWallets,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    withdrawalsWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(Withdrawals);
