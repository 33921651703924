import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import PlaceOrderBody from './components/PlaceOrderBody';
import PlaceOrderHead from './components/PlaceOrderHead';
import TradingToolPair from './components/TradingToolPair';
import CustomerSearch from './components/CustomerSearch/index';
import Messages from '../../../../../shared/helpers/errorMessages';
import { cTypes } from '../../../Custody/components/Wallets/WidgetWallets';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { TRADING_CURRENCIES_UPDATE } from '../../../../../redux/actionTypes/placeOrderActionTypes';
import showNotification from '../../../../../shared/helpers/notifications';
import { Box } from '@mui/material';
const PlaceOrder = ({ open, error, customer_code }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleFetchCurrencies = () => {
        const { ErrorLogger } = window;
        axios.get('/custody/currencies')
            .then((response) => {
            if (response.data.length) {
                const mapped = response.data
                    .map(c => Object
                    .assign({}, c, { label: `${c.code} - ${c.label}${cTypes[c.type] ? ` ${cTypes[c.type]}` : ''}` }));
                const btcIndex = mapped.findIndex(b => b.code === 'BTC');
                const btc = mapped.splice(btcIndex, 1)[0];
                const ethIndex = mapped.findIndex(b => b.code === 'ETH');
                const eth = mapped.splice(ethIndex, 1)[0];
                mapped.sort((a, b) => a.code.localeCompare(b.code));
                mapped.unshift(eth);
                mapped.unshift(btc);
                dispatch({ type: TRADING_CURRENCIES_UPDATE, currencies: mapped });
            }
            else {
                dispatch({ type: TRADING_CURRENCIES_UPDATE, currencies: [] });
            }
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.CUSTODY_CURRENCY_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        handleFetchCurrencies();
    }, []);
    useEffect(() => {
        if (open) {
            showNotification({
                message: `Error: ${error}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
    }, [open]);
    return (React.createElement(Box, { p: 1, display: 'flex', flexDirection: 'column', gap: 1 },
        customer_code ? null : React.createElement(CustomerSearch, null),
        React.createElement(PlaceOrderHead, null),
        React.createElement(TradingToolPair, null),
        React.createElement(PlaceOrderBody, null)));
};
const stateToProps = (state) => {
    const { customer_code } = state.client;
    const { open, error } = state.apiErrors;
    return {
        open,
        error,
        customer_code,
    };
};
export default connect(stateToProps)(PlaceOrder);
