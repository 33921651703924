/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import RfqOrders from './RfqOrders';
import showNotification from '../../../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getWidgetsSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { Box, Tab, Tabs } from '@mui/material';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const WidgetRfqOrders = ({ settings, widgetKey, customers, clientInfo, features, selectedClient, dashboard, masterTenant, }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [disableReverse, setDisableReverse] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const changeTab = (tab, showAlert) => {
        setActiveTab(tab);
        if (showAlert) {
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: 'You have no right to open the tab. Reset restrict_order_size.read permission',
            });
        }
    };
    useEffect(() => {
        if (features && features.length && features.includes('restrict_order_size.read')) {
            setDisableReverse(true);
        }
        else {
            setDisableReverse(false);
        }
    }, [features]);
    return (React.createElement(Box, null,
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement(Tabs, { value: activeTab, indicatorColor: "primary" },
                    React.createElement(Tab, { label: "RFQ orders", onClick: () => { changeTab(0, false); } }),
                    React.createElement(Tab, { disabled: disableReverse, label: "RFQ reverse quotation", onClick: () => { changeTab(disableReverse ? 0 : 1, disableReverse); } })),
                activeTab === 0 ?
                    React.createElement(RfqOrders, { rfqType: "standard", widgetKey: widgetKey, features: features, settings: settings, customers: customers, selectedClient: selectedClient, clientInfo: clientInfo, dashboard: dashboard, masterTenant: masterTenant }) : null,
                activeTab === 1 ?
                    React.createElement(RfqOrders, { rfqType: "reverse_quotation", widgetKey: widgetKey, features: features, settings: settings, customers: customers, selectedClient: selectedClient, clientInfo: clientInfo, dashboard: dashboard, masterTenant: masterTenant }) : null))));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        // @ts-ignore
        features: state.client.features,
        clientInfo: state.client.clientInfo,
        selectedClient: getSelectedClientInputValue(state),
        settings: getWidgetsSettingsByKey(state, widgetKey),
        customers: getCustomersSelectedInputInterface(state),
        dashboard: state.workspace.dashboards.find(d => d.name === 'tradingRfq'),
        masterTenant: state.client.masterTenant,
    };
};
export default connect(stateToProps)(WidgetRfqOrders);
