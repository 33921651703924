import React, { useEffect, useState, useMemo } from 'react';
import ClientBalances from './ClientBalances';
import ClientBalancesService from '../../../../../services/clientBalancesService';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
const ClientBalancesContainer = ({ clientInfo, hideZero, client, }) => {
    const [clientCode, setClientCode] = useState('');
    const [balances, setBalances] = useState([]);
    const [service, setService] = useState(null);
    const serviceData = useServiceFetchApis(service, clientCode, 10000);
    const memoBalances = useMemo(() => serviceData.data ? serviceData.data.balances : [], [serviceData]);
    const handleClientDataChange = (option) => {
        const code = option ? (option === null || option === void 0 ? void 0 : option.code) || (option === null || option === void 0 ? void 0 : option.value) : '';
        setClientCode(code);
    };
    useEffect(() => {
        const serviceInstance = new ClientBalancesService({ url: '', method: 'get' }, '/trade/balances/clients/');
        setService(serviceInstance);
    }, []);
    useEffect(() => {
        if (memoBalances.length) {
            setBalances(memoBalances);
        }
    }, [memoBalances]);
    useEffect(() => {
        const clientData = client || clientInfo;
        handleClientDataChange(clientData);
    }, [clientInfo, client]);
    return React.createElement(ClientBalances, { hideZero: hideZero, balances: balances, clientInfo: clientInfo });
};
export default ClientBalancesContainer;
