import React from 'react';
import WidgetHoldings from './components/Holdings/WidgetHoldings';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class PortfolioDashboard extends React.Component {
    render() {
        return (React.createElement("div", { className: "content-body" },
            React.createElement(WidgetHoldings, null)));
    }
}
export default PortfolioDashboard;
