import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import themeReducer from '../../redux/reducers/themeReducer';
import LogIn from '../Account/Login';
import MainLayout from './MainWrapper';
const rootReducer = combineReducers({
    theme: themeReducer,
});
const store = createStore(rootReducer);
const Container = () => (React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, { basename: "/" },
        React.createElement(MainLayout, null,
            React.createElement(LogIn, null)))));
export default Container;
