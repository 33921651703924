import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import { CUSTOMER_INFO_FETCH_REQUEST, CUSTOMER_INFO_FETCH_SUCCESS, } from '../../../../../redux/actionTypes/clientActionTypes';
import { selectCustomer } from '../../../../../redux/actions/customersSearchActions';
import { getAllCustomers } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SELECT_SET_UPDATE } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { Box, Button, TextField, Autocomplete } from '@mui/material';
const useStyles = makeStyles((theme) => createStyles({
    filterBox: {
        backgroundColor: theme.palette.background.default,
    },
    formGroup: {
        minWidth: 200,
    },
    formButton: {
        margin: 0,
        marginLeft: 8,
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flex: '1 0 auto',
    },
}));
/* eslint-disable @typescript-eslint/no-unsafe-call, camelcase */
const CreateRfq = ({ createRfq, arrangeCards, customers, selectedCustomer, rfqType, allCustomers, rfqSettings, loadingRfqSettings, customerCode, selectedClient, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [settingsOptions, setSettingsOptions] = useState([]);
    const [cardSetting, setCardSetting] = useState(null);
    const handleCardSettingChange = (option) => {
        setCardSetting(option);
    };
    const handleCustomerSelect = (option) => {
        const data = allCustomers.find((c) => c.code === (option === null || option === void 0 ? void 0 : option.value));
        if (data) {
            dispatch({ type: CUSTOMER_SELECT_SET_UPDATE, customer: data });
        }
        else {
            dispatch({ type: CUSTOMER_SELECT_SET_UPDATE, customer: null });
        }
    };
    const createRfqOrder = () => {
        const customer = {
            value: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
            label: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.company_name) || (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
        };
        if (cardSetting) {
            createRfq(customer, cardSetting.value);
        }
    };
    const onArrangeCards = () => {
        arrangeCards();
    };
    const sortByBase = (arr) => {
        arr.sort((a, b) => a.base.localeCompare(b.base));
        const sorter = (a, b) => {
            if (a.base === 'btc') {
                return -1;
            }
            if (a.base === 'eth' && b.base !== 'btc') {
                return -1;
            }
            return a.base.localeCompare(b.base);
        };
        arr.sort(sorter);
    };
    const getStreamCurrency = (item) => {
        if (rfqType === 'standard') {
            return item.base;
        }
        else if (rfqType === 'reverse_quotation') {
            return item.quote;
        }
    };
    useEffect(() => {
        if (rfqSettings.length) {
            sortByBase(rfqSettings);
            const settingsData = rfqSettings
                .filter((i) => i.status !== 'pendingDelete' && i.stream_type === rfqType)
                .map((s) => {
                const val = `${s.base.toUpperCase()}/${s.quote.toUpperCase()} (${s.guaranteed_qty} ${getStreamCurrency(s)} Guaranteed Spread)`;
                return {
                    label: val,
                    value: s.settings_code,
                };
            });
            setSettingsOptions(settingsData);
        }
        else {
            setSettingsOptions([]);
        }
    }, [rfqSettings]);
    useEffect(() => {
        if (selectedClient) {
            setCardSetting(null);
            dispatch({ type: CUSTOMER_SELECT_SET_UPDATE, customer: null });
        }
    }, [selectedClient]);
    useEffect(() => {
        const customerData = selectedCustomer ? allCustomers.find((c) => c.code === selectedCustomer.code) : null;
        if (customerData) {
            dispatch(selectCustomer(customerData));
            dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: customerData.code });
        }
        else if (customerCode) {
            const strictCustomerData = allCustomers.find((c) => c.code === customerCode);
            if (strictCustomerData) {
                dispatch(selectCustomer(strictCustomerData));
                dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: strictCustomerData.code });
            }
        }
        else {
            dispatch(selectCustomer(null));
            dispatch({ type: CUSTOMER_INFO_FETCH_SUCCESS, customer: null });
        }
    }, [selectedCustomer, allCustomers, customerCode]);
    return (React.createElement(Box, { px: 2, py: 3, className: classes.filterBox },
        React.createElement(Box, { style: { display: 'flex' } },
            React.createElement(Box, { className: classes.formGroup, mr: 1 },
                React.createElement(Autocomplete, { value: cardSetting, options: settingsOptions, loading: loadingRfqSettings, getOptionLabel: (option) => option.label, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.value }), option.label)), onChange: (event, newValue) => handleCardSettingChange(newValue), renderInput: (params) => (React.createElement(TextField, Object.assign({ name: 'cardSetting' }, params, { variant: 'outlined', size: 'small', label: 'Trading pair', value: cardSetting }))) })),
            React.createElement(Box, { className: classes.formGroup },
                React.createElement(Autocomplete, { value: {
                        value: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
                        label: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.company_name) || (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
                    }, options: customers, loading: loadingRfqSettings, getOptionLabel: (option) => option.label, getOptionDisabled: () => !!selectedCustomer, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.value }), option.label)), onChange: (event, newValue) => handleCustomerSelect(newValue), renderInput: (params) => (React.createElement(TextField, Object.assign({ name: 'cardSetting' }, params, { variant: 'outlined', value: {
                            value: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
                            label: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.company_name) || (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '',
                        }, size: 'small', label: 'Select customer' }))) })),
            React.createElement("div", { className: classes.formButton },
                React.createElement(Button, { variant: 'contained', color: 'primary', onClick: createRfqOrder, style: { marginRight: '10px' }, disabled: !cardSetting || !selectedCustomer }, "Add new card"),
                React.createElement(Button, { color: 'inherit', variant: 'outlined', onClick: onArrangeCards }, "Auto Arrange")))));
};
const stateToProps = (state) => ({
    allCustomers: getAllCustomers(state),
    customerCode: state.client.customer_code,
    selectedCustomer: state.customersSearch.selectedCustomer,
});
export default connect(stateToProps)(CreateRfq);
