import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { TableBody, PanelTitle, TitleContainer, PanelContainer, } from '../../styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import FileArchiveService from '../../../../../services/fileArchiveService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const File = ({ record, selectedFile, handler }) => {
    const handleSelectFile = (name) => {
        handler(name);
    };
    const activeClass = classNames({
        'file-active': record.name === selectedFile,
    });
    return (React.createElement("tr", { className: activeClass, role: "button", onClick: () => handleSelectFile(record.name) },
        React.createElement("td", null,
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faFileArchive, className: "file_archive_icon_margin" })),
            " ",
            record.name)));
};
const Files = ({ records, selectedFile, handler, }) => {
    if (records.length === 0) {
        return (React.createElement("div", null, "No items"));
    }
    return (React.createElement("table", { className: "table--bordered table--head-accent table table-hover" },
        React.createElement(TableBody, null, records.map((record, i) => (React.createElement(File, { key: i, handler: handler, selectedFile: selectedFile, record: record }))))));
};
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const FileArchiveContainer = () => {
    const { ErrorLogger } = window;
    const [records, setRecords] = useState([]);
    const [fileContent, setFileContent] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const handleSelectFile = useCallback((name) => {
        setSelectedFile(name);
    }, [selectedFile]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleFetchContent = (name) => {
        axios.get(`/report/archive/get/${name}`)
            .then((response) => {
            const { data: contentData } = response;
            setFileContent(contentData.content);
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            showNotification({
                message: `Failed to load files content: ${e}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        const serviceInstance = new FileArchiveService({
            url: '/report/archive/list',
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        serviceInstance.makeRequest()
            .then((response) => {
            if (response.records.length) {
                setRecords(response.records);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.FETCH_FILES);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    }, []);
    useEffect(() => {
        if (selectedFile) {
            handleFetchContent(selectedFile);
        }
    }, [selectedFile]);
    return (React.createElement("div", { className: "container-fluid file_archive_main" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "dashboard col-md-4" },
                React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
                    React.createElement(PanelTitle, null,
                        React.createElement("h5", { className: "bold-text" },
                            React.createElement(TitleContainer, null, "Files"))),
                    React.createElement("div", { className: "card-body" },
                        React.createElement(Files, { handler: handleSelectFile, selectedFile: selectedFile, records: records })))),
            React.createElement("div", { className: "dashboard col-md-8" },
                React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
                    React.createElement(PanelTitle, null,
                        React.createElement("h5", { className: "bold-text" },
                            React.createElement(TitleContainer, null,
                                "File preview",
                                selectedFile
                                    ? React.createElement("span", { title: "selected-file" },
                                        ": ",
                                        selectedFile)
                                    : null))),
                    React.createElement("div", { className: "card-body" }, fileContent))))));
};
export default FileArchiveContainer;
