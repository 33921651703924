import * as types from '../actionTypes/adminActionTypes';
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
export const initialState = {
    orders: [],
    total: 0,
    error: '',
    isLoading: false,
    totalInvites: 0,
    unusedInvites: [],
    wallets: [],
    deposits: [],
    withdrawals: [],
    currencies: [],
    depositsLoading: false,
    isLoadingInvites: false,
    withdrawalsLoading: false,
    rowsPerPageInvites: 10,
    changedPermissions: [],
    clientFromUsers: null,
    withdrawalAddresses: [],
    loadingAddresses: false,
    lineChartData: null,
    approvalsWithdrawals: [],
    pageOfItemsAdminOrders: 0,
    pageOfItemsAdminUsers: 0,
    pageOfItemsAdminInvites: 0,
    urlOptionsAdminOrders: '',
    complianceSubOrders: [],
    complianceRegularOrders: [],
    loadingRegularOrders: false,
    loadingSuborders: false,
    permissionsActiveTab: 0,
    urlOptionsTradingTransfers: '',
};
const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_SELECT_CLIENT_FROM_USERS:
            return Object.assign(Object.assign({}, state), { clientFromUsers: action.clientFromUsers });
        case types.CHANGED_PERMISSIONS_SET_UPDATE: {
            const arr = state.changedPermissions.filter(p => p.code !== action.changedPermission.code);
            return Object.assign(Object.assign({}, state), { changedPermissions: [
                    ...arr,
                    action.changedPermission,
                ] });
        }
        case types.ADMIN_DEPOSITS_UPDATE:
            return Object.assign(Object.assign({}, state), { deposits: action.deposits });
        case types.ADMIN_WITHDRAWALS_UPDATE:
            return Object.assign(Object.assign({}, state), { withdrawals: action.withdrawals });
        case types.ADMIN_DEPOSITS_LOADING_UPDATE:
            return Object.assign(Object.assign({}, state), { depositsLoading: action.depositsLoading });
        case types.ADMIN_WITHDRAWALS_LOADING_UPDATE:
            return Object.assign(Object.assign({}, state), { withdrawalsLoading: action.withdrawalsLoading });
        case types.ADMIN_APPROVALS_WITHDRAWALS_UPDATE:
            return Object.assign(Object.assign({}, state), { approvalsWithdrawals: action.approvalsWithdrawals });
        case types.ADMIN_WITHDRAWAL_ADDRESSES_UPDATE:
            return Object.assign(Object.assign({}, state), { withdrawalAddresses: action.withdrawalAddresses });
        case types.LOADING_ADDRESSES_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingAddresses: action.loadingAddresses });
        case types.ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsAdminOrders: action.pageOfItemsAdminOrders });
        case types.ADMIN_USERS_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsAdminUsers: action.pageOfItemsAdminUsers });
        case types.ADMIN_INVITES_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsAdminInvites: action.pageOfItemsAdminInvites });
        case types.ADMIN_ORDERS_URL_OPTIONS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { urlOptionsAdminOrders: action.urlOptionsAdminOrders });
        case types.TRADING_TRANSFERS_URL_OPTIONS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { urlOptionsTradingTransfers: action.urlOptionsTradingTransfers });
        case types.ADMIN_ORDERS_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { isLoading: true });
        }
        case types.ADMIN_ORDERS_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { orders: action.orders, total: action.total, isLoading: false });
        }
        case types.ADMIN_ORDERS_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { orders: [], total: 10, isLoading: false, error: action.message });
        }
        case types.INVITES_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { isLoadingInvites: true });
        }
        case types.INVITES_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { unusedInvites: action.unusedInvites, totalInvites: action.totalInvites, isLoadingInvites: false });
        }
        case types.INVITES_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { unusedInvites: [], totalInvites: 0, isLoadingInvites: false, error: action.message });
        }
        case types.ROWS_PER_PAGE__INVITES_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { rowsPerPageInvites: action.rowsPerPageInvites });
        case types.COMPLIANCE_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { wallets: action.wallets });
        case types.ADMIN_REGULAR_ORDERS_UPDATE:
            return Object.assign(Object.assign({}, state), { complianceRegularOrders: action.complianceRegularOrders });
        case types.ADMIN_SUB_ORDERS_UPDATE:
            return Object.assign(Object.assign({}, state), { complianceSubOrders: action.complianceSubOrders });
        case types.ADMIN_LINE_CHART_DATA:
            return Object.assign(Object.assign({}, state), { lineChartData: action.lineChartData });
        case types.ADMIN_LOADING_SUB_ORDERS_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingSuborders: action.loadingSuborders });
        case types.ADMIN_LOADING_REGULAR_ORDERS_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingRegularOrders: action.loadingRegularOrders });
        case types.PERMISSIONS_ACTIVETAB_UPDATE:
            return Object.assign(Object.assign({}, state), { permissionsActiveTab: action.permissionsActiveTab });
        case types.ADMIN_CURRENCIES_UPDATE:
            return Object.assign(Object.assign({}, state), { currencies: action.currencies });
        default:
            return state;
    }
};
export default adminReducer;
