import { createSelector } from 'reselect';
/* eslint-disable import/prefer-default-export */
const getDashboards = (state) => state.workspace.dashboards;
const getWorksppaces = (state) => state.workspace.workspaces;
const mappedDefaultWorkspaceRecord = (state) => state.workspace.mappedDefaultWorkspaceRecord;
const getDashboardByName = (state, dashboardName) => state.workspace.dashboards.find(d => d.name === dashboardName);
export const getWorkspace = createSelector(mappedDefaultWorkspaceRecord, workSpace => workSpace);
export const tradingWorkspaces = createSelector(getDashboards, getWorksppaces, (dashboards, workspaces) => workspaces
    .filter(w => { var _a; return (_a = dashboards.find(d => d.name === 'trading')) === null || _a === void 0 ? void 0 : _a.workspaces.includes(w.id); }));
export const custodyWorkspaces = createSelector(getDashboards, getWorksppaces, (dashboards, workspaces) => workspaces
    .filter(w => { var _a; return (_a = dashboards.find(d => d.name === 'custody')) === null || _a === void 0 ? void 0 : _a.workspaces.includes(w.id); }));
export const getWorkspacesForDashboard = createSelector(getDashboardByName, getWorksppaces, (dashboard, workspaces) => dashboard ? workspaces
    .filter(w => dashboard.workspaces.some(dw => dw === w.id)) : []);
