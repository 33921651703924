import React from 'react';
import { Link } from 'react-router-dom';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const Image404 = `${process.env.PUBLIC_URL}/img/404/404.png`;
const NotFound404 = () => (React.createElement("div", { className: "not-found" },
    React.createElement("div", { className: "not-found__content" },
        React.createElement("img", { className: "not-found__image", src: Image404, alt: "404" }),
        React.createElement("h3", { className: "not-found__info" }, "Ooops! The page you are looking for could not be found :("),
        React.createElement(Link, { className: "btn btn-primary", to: "/dashboard_default" }, "Back Home"))));
export default NotFound404;
