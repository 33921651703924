var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useRef, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import { Box, CircularProgress, useTheme } from '@mui/material';
import shortid from 'shortid';
import renderFilterParams from '../../../../shared/helpers/renderFilterParams';
import { collectParamsFromSettings } from '../../../../shared/helpers/createUrlFromParams';
import { getWidgetsSettingsByKey } from '../../../../redux/selectors/widgetSettingsSelector';
const getSettingByKey = (settings, settingId) => {
    if (settings && settings.length === 0) {
        return null;
    }
    const find = settings.find(item => item && item.id.includes(settingId));
    return find && find.value;
};
let tvScriptLoadingPromise;
const TradingViewWidget = (_a) => {
    var props = __rest(_a, []);
    const onLoadScriptRef = useRef(null);
    const widgetId = shortid.generate();
    onLoadScriptRef.current = initWidget;
    const theme = useTheme();
    const ref = createRef();
    function initWidget() {
        if (document.getElementById(widgetId) && 'TradingView' in window) {
            new window.TradingView.widget({
                autosize: true,
                symbol: props.symbol,
                interval: "D",
                theme: theme.palette.mode,
                style: "1",
                locale: "en",
                backgroundColor: theme.palette.background.paper.slice(0, -2),
                enable_publishing: true,
                allow_symbol_change: true,
                container_id: widgetId,
                useWidgetHost: false,
                hide_side_toolbar: false,
            });
        }
    }
    useEffect(() => {
        let refValue;
        requestAnimationFrame(initWidget);
        refValue = ref.current;
        return () => {
            if (refValue) {
                while (refValue.firstChild) {
                    refValue.removeChild(refValue.firstChild);
                }
            }
        };
    }, [theme, props.symbol]);
    useEffect(() => {
        if (!tvScriptLoadingPromise) {
            tvScriptLoadingPromise = new Promise((resolve) => {
                const script = document.createElement('script');
                script.id = 'tradingview-widget-loading-script';
                script.src = 'https://s3.tradingview.com/tv.js';
                script.type = 'text/javascript';
                document.head.appendChild(script);
                script.onload = (event) => { resolve(); };
            });
        }
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
        return () => { onLoadScriptRef.current ? onLoadScriptRef.current = null : null; };
    }, []);
    return (React.createElement("div", { className: 'tradingview-widget-container', style: { height: '100%' } },
        React.createElement("div", { ref: ref, id: widgetId, style: { height: '100%' } })));
};
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/restrict-template-expressions */
const TradingviewRealTimeChartComponent = ({ settings, widgetKey, widgetHeight, selectedBase, selectedQuote, themeClassName, loadingMarkets, selectedExchange, exchangeNotAvailable, }) => {
    const [exchange, setExchange] = useState('');
    const [ignoreGlobal, setIgnoreGlobal] = useState(false);
    const [settingsSymbol, setSettingsSymbol] = useState('');
    const [paramKeys, setParamsKeys] = useState([]);
    const [containerHeight, setContainerHeight] = useState(300);
    const [globalPairSymbol, setGlobalPairSymbol] = useState('');
    const [tradingViewSymbol, setTradingViewSymbol] = useState('');
    const [settingsTradingPair, setSettingsTradingPair] = useState('');
    const [filterParams, setFilterParams] = useState(null);
    const isIgnoreGlobalChecked = (set) => {
        const ignore = set.find(s => s && s.id.includes('IgnoreGlobal'));
        return ignore ? ignore.value : false;
    };
    useEffect(() => {
        if (ignoreGlobal && settingsSymbol) {
            setTradingViewSymbol(settingsSymbol);
        }
        else if (globalPairSymbol) {
            setTradingViewSymbol(globalPairSymbol);
        }
    }, [ignoreGlobal, globalPairSymbol, settingsSymbol]);
    useEffect(() => {
        if (widgetHeight) {
            setContainerHeight(widgetHeight - 50);
        }
    }, [widgetHeight]);
    useEffect(() => {
        if (!loadingMarkets && selectedExchange) {
            let exchangeVal = selectedExchange.value.toUpperCase();
            if (exchangeVal === 'FAKE_BITSTAMP') {
                exchangeVal = 'BITSTAMP';
            }
            if (exchangeVal === 'FAKE_KRAKEN') {
                exchangeVal = 'KRAKEN';
            }
            setExchange(exchangeVal);
        }
    }, [loadingMarkets, selectedExchange]);
    useEffect(() => {
        if (exchange && selectedBase && selectedQuote) {
            const globalPairSymbolVal = `${exchange}:${selectedBase.value}${selectedQuote.value}`;
            setGlobalPairSymbol(globalPairSymbolVal);
        }
    }, [exchange, selectedBase, selectedQuote]);
    useEffect(() => {
        if (settings.length) {
            const isIgnoreGlobal = isIgnoreGlobalChecked(settings);
            setIgnoreGlobal(isIgnoreGlobal);
            const params = collectParamsFromSettings(settings, widgetKey);
            const keys = Object.keys(params);
            setParamsKeys(keys);
            setFilterParams(params);
            const settingsBaseArr = getSettingByKey(settings, 'Base');
            const settingsQuoteArr = getSettingByKey(settings, 'Quote');
            if (settingsBaseArr && settingsQuoteArr) {
                const settingsTradingPairVal = settingsBaseArr.value
                    + settingsQuoteArr.value;
                setSettingsTradingPair(settingsTradingPairVal);
            }
        }
    }, [settings]);
    useEffect(() => {
        if (settings.length) {
            const settingsExchangeArr = getSettingByKey(settings, 'Exchanges');
            let tradingExchange = settingsExchangeArr.value.toUpperCase();
            if (tradingExchange === 'FAKE_BITSTAMP') {
                tradingExchange = 'BITSTAMP';
            }
            if (tradingExchange === 'FAKE_KRAKEN') {
                tradingExchange = 'KRAKEN';
            }
            if (settingsTradingPair && tradingExchange) {
                setSettingsSymbol(`${tradingExchange}:${settingsTradingPair}`);
            }
        }
    }, [settings, settingsTradingPair]);
    if (!tradingViewSymbol) {
        return null;
    }
    if (!tradingViewSymbol) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { height: containerHeight },
        ignoreGlobal ? renderFilterParams(filterParams, paramKeys) : null,
        React.createElement(Box, { height: '100%' },
            React.createElement(TradingViewWidget, { autosize: true, symbol: tradingViewSymbol })),
        exchangeNotAvailable
            && (React.createElement(Alert, { severity: "error", variant: "outlined" }, "Chart is not available"))));
};
const mapStateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    const { selectedBase, selectedQuote, selectedExchange, loadingMarkets, loadingExchanges, exchangeNotAvailable, } = state.placeOrder;
    return {
        widgetKey,
        /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        settings: getWidgetsSettingsByKey(state, widgetKey),
        selectedBase,
        selectedQuote,
        loadingMarkets,
        loadingExchanges,
        selectedExchange,
        exchangeNotAvailable,
        themeClassName: state.theme.className,
        widgetHeight: state.widgets[ownProps.widgetKey],
    };
};
export default connect(mapStateToProps)(TradingviewRealTimeChartComponent);
