import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { Icon, TableCell } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../../../Custody/styling/style';
const CustomersTableCell = ({ value, columnKey }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [icon, setIcon] = useState('file_copy');
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'client_code') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value),
            value ? (React.createElement(Icon, { onClick: () => handleCopy(`${value}`), fontSize: "small" }, icon)) : null));
    }
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default CustomersTableCell;
