import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
const CustomerInfoItem = ({ customerInfo, customer_code }) => (React.createElement(TableHead, null,
    React.createElement(TableRow, null,
        React.createElement(TableCell, null, "Currency"),
        React.createElement(TableCell, { scope: "col" }, "Available"),
        React.createElement(TableCell, { scope: "col" }, "Locked"),
        React.createElement(TableCell, null, "Total"),
        customer_code ? (React.createElement(TableCell, { key: "actions", align: "right" }, "Actions")) : null)));
export default CustomerInfoItem;
