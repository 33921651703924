/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import buildUrl from 'build-url';
import CircularProgress from '@mui/material/CircularProgress';
import Messages from '../../../../../shared/helpers/errorMessages';
import ReportsService from '../../../../../services/reportsService';
import ClientsService from '../../../../../services/clientsService';
import CustomersService from '../../../../../services/customersService';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import DateFnsAdapter from '@date-io/date-fns';
import { DatePicker, TimePicker, } from '@mui/x-date-pickers';
const reportTypeOptions = [
    { label: 'Wallets', value: 'wallets' },
    { label: 'Fee', value: 'fee' },
    { label: 'Balances (old)', value: 'balances' },
    { label: 'Balances', value: 'balances-new' },
    { label: 'Settlement', value: 'settlement' },
    { label: 'Assets under custody (old)', value: 'auc' },
    { label: 'Assets under custody', value: 'auc-new' },
    { label: 'Assets under custody Admin', value: 'auc-admin' },
    { label: 'Assets under custody Admin (timed)', value: 'auc-admin-timed' },
    { label: 'Reconciliation', value: 'reconciliation' },
    { label: 'Custody transactions', value: 'transactions' },
    { label: 'Trading transfers', value: 'trading-transfers' },
].sort((a, b) => a.label.localeCompare(b.label));
const ExportFile = ({ superAdmin, features, userClientCode = '', customerCode }) => {
    const dateFns = new DateFnsAdapter();
    const defaultDateTill = new Date();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const dateTypes = ['auc', 'balances', 'balances-new', 'auc-new', 'auc-admin'];
    const checboxTypes = ['reconciliation', 'settlement'];
    const defaultDateFrom = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [extendedInfo, setExtendedInfo] = useState(false);
    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTill, setDateTill] = useState(defaultDateTill);
    const [timeTill, setTimeTill] = useState(null);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeUnit, setTimeUnit] = useState('day');
    const [selectedType, setSelectType] = useState(reportTypeOptions[0]);
    const [clientOptions, setClientOptions] = useState([{ label: 'All', value: 'all' }]);
    const [settlementOptions, setSettlementOptions] = useState(reportTypeOptions);
    const [clientSelected, setSelectClient] = useState({ label: 'All', value: 'all' });
    const handleCheckboxChange = (evt) => {
        const { checked } = evt.target;
        setExtendedInfo(checked);
    };
    const handleTimeUnitChange = (evt) => {
        setTimeUnit(evt.target.value);
    };
    const handleDateFromChange = (date, context) => {
        setDateFrom(!context.validationError ? date : null);
    };
    const handleDateTillChange = (date, context) => {
        setDateTill(!context.validationError ? date : null);
    };
    const setTimeFromChange = (date, context) => {
        setTimeFrom(!context.validationError ? date : null);
    };
    const setTimeTillChange = (date, context) => {
        setTimeTill(!context.validationError ? date : null);
    };
    const handleTypeChange = (event) => {
        setSelectType({ value: event.target.value, label: event.target.value });
        setSelectClient({ label: 'All', value: '' });
    };
    const handleClientSelect = (event) => {
        var _a;
        setSelectClient({
            value: event.target.value,
            label: ((_a = clientOptions.find((o) => o.value === event.target.value)) === null || _a === void 0 ? void 0 : _a.label) || '',
        });
    };
    const validateFields = () => {
        const map = {
            dateFrom,
            dateTill,
            timeFrom,
            timeTill,
        };
        const errs = [];
        for (const [key, value] of Object.entries(map)) {
            if (!value) {
                errs.push(key);
            }
        }
        if (errs.length) {
            setErrors(() => [...errs]);
        }
        else {
            setErrors(() => []);
        }
    };
    useEffect(() => {
        validateFields();
    }, [dateFrom, dateTill, timeFrom, timeTill]);
    let baseURL = '';
    const handleDownload = () => {
        let reportTypePath = '';
        const queryParams = {};
        const dateFromFormatted = dateFrom ? dateFns.format(dateFrom, 'yyyy-MM-dd') : null;
        const dateTillFormatted = dateTill ? dateFns.format(dateTill, 'yyyy-MM-dd') : null;
        validateFields();
        if (!dateFromFormatted || !dateTillFormatted || !timeFrom || !timeTill) {
            return;
        }
        const queryTimeFrom = timeFrom ? dateFns.format(timeFrom, 'HH:mm:ss') : '';
        const queryTimeTill = timeTill ? dateFns.format(timeTill, 'HH:mm:ss') : '';
        const queryClientCode = clientSelected.value === 'all' ? '' : clientSelected.value;
        switch (selectedType.value) {
            case 'wallets':
                reportTypePath = 'report/client/wallet';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.format = 'csv';
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'auc-new':
                reportTypePath = 'report/custody/accounting_auc';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.date = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.format = 'csv';
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'auc-admin':
                reportTypePath = 'report/custody/accounting_auc_dep_holder/report';
                queryParams.date = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.format = 'csv';
                baseURL = getEnvSettings().adminApiUrl;
                break;
            case 'auc-admin-timed':
                reportTypePath = 'report/custody/auc_per_time_unit/report';
                queryParams.from = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.to = `${dateTillFormatted}T${queryTimeTill}`;
                queryParams.timelapse = timeUnit;
                baseURL = getEnvSettings().adminApiUrl;
                break;
            case 'auc':
                reportTypePath = 'report/custody/auc';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.date = `${dateFromFormatted}T${queryTimeFrom}`;
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'balances-new':
                reportTypePath = 'client/accounting_balances/report';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.date = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.format = 'csv';
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'transactions':
                reportTypePath = 'report/custody/transactions';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.start = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.end = `${dateTillFormatted}T${queryTimeTill}`;
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'fee':
                reportTypePath = 'report/custody/fee';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.from = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.to = `${dateTillFormatted}T${queryTimeTill}`;
                baseURL = getEnvSettings().apiUrl;
                break;
            case 'balances':
                if (superAdmin) {
                    reportTypePath = 'report/client/balances/report';
                    baseURL = getEnvSettings().adminApiUrl;
                }
                else {
                    reportTypePath = 'report/custody/balances';
                    baseURL = getEnvSettings().apiUrl;
                }
                if (clientSelected && queryClientCode) {
                    queryParams.client_code = clientSelected.value;
                }
                queryParams.date = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.format = 'csv';
                break;
            case 'trading-transfers':
                reportTypePath = 'report/custody/trading_transfers';
                queryParams.clientCode = superAdmin ? queryClientCode : userClientCode;
                queryParams.format = 'csv';
                queryParams.from = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.to = `${dateTillFormatted}T${queryTimeTill}`;
                baseURL = getEnvSettings().apiUrl;
                break;
            default:
                reportTypePath = `report/${selectedType.value}`;
                queryParams.extendedInfo = `${extendedInfo}`;
                queryParams.start = `${dateFromFormatted}T${queryTimeFrom}`;
                queryParams.end = `${dateTillFormatted}T${queryTimeTill}`;
                if (clientSelected && queryClientCode !== '') {
                    queryParams[`${superAdmin ? 'clientCode' : 'customerCode'}`] = queryClientCode;
                }
                baseURL = getEnvSettings().apiUrl;
        }
        const reportUrlOptions = {
            queryParams,
            path: `${reportTypePath}`,
        };
        const reportService = new ReportsService({
            url: buildUrl('', reportUrlOptions),
            method: 'get',
            headers: { accept: 'text/csv' },
            baseURL,
        });
        setLoading(true);
        reportService
            .makeRequest()
            .then((responseData) => {
            setLoading(false);
            const date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
            const file = new File([responseData.blob || ''], `${date}_${selectedType.label}_DLT_Finance.csv`, {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(file, `${date}_${selectedType.label}_DLT_Finance.csv`);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CREATE_REPORT);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                sort_by: 'created',
                sort_direction: 'desc',
                details: !features ? 'false' : features.includes('customer_details.read') ? 'true' : 'false',
            },
            path: `/${superAdmin ? 'user/clients' : 'customers'}`,
        };
        const endpointUrl = buildUrl('', urlOptions);
        if (superAdmin) {
            const serviceInstance = new ClientsService({
                url: endpointUrl,
                method: 'get',
                baseURL: getEnvSettings().adminApiUrl,
            });
            serviceInstance
                .makeRequest()
                .then((response) => {
                if (response.records.length) {
                    const records = response.records.map((record) => ({
                        label: record.company_name || '',
                        value: record.code,
                    }));
                    const options = [{ label: 'All', value: 'all' }, ...records];
                    setClientOptions(options);
                }
            })
                .catch((e) => {
                const message = composeErrorMessage(e, Messages.CLIENTS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
        else {
            const serviceInstance = new CustomersService({ url: endpointUrl, method: 'get' });
            serviceInstance
                .makeRequest()
                .then((response) => {
                if (response.records.length) {
                    const records = response.records.map((record) => ({
                        label: record.company_name || '',
                        value: record.code,
                    }));
                    const options = [{ label: 'All', value: 'all' }, ...records];
                    setClientOptions(options);
                }
            })
                .catch((e) => {
                const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [superAdmin, features]);
    useEffect(() => {
        const m = dateFns.date(new Date());
        m.setMinutes(0);
        m.setHours(0);
        m.setSeconds(0);
        setTimeFrom(m);
        setTimeTill(m);
        if ((features === null || features === void 0 ? void 0 : features.length) !== 0 && !superAdmin) {
            setSettlementOptions((prev) => {
                const filtered = reportTypeOptions
                    .filter((t) => t.value === 'auc-admin' || t.value === 'auc-admin-timed')
                    .map((f) => f.value);
                const arr = prev.filter((p) => !filtered.includes(p.value));
                setSelectType(arr[0]);
                return [...arr];
            });
        }
        if ((features === null || features === void 0 ? void 0 : features.length) !== 0 && !(features === null || features === void 0 ? void 0 : features.find((f) => f === 'custody_report.create'))) {
            setSettlementOptions((prev) => {
                const filtered = reportTypeOptions
                    .filter((t) => t.value === 'wallets' ||
                    t.value === 'balances' ||
                    t.value === 'auc' ||
                    t.value === 'transactions' ||
                    t.value === 'fee' ||
                    t.value.includes('auc'))
                    .map((f) => f.value);
                const arr = prev.filter((p) => !filtered.includes(p.value));
                setSelectType(arr[0]);
                return [...arr];
            });
        }
        if ((features === null || features === void 0 ? void 0 : features.length) !== 0 && !(features === null || features === void 0 ? void 0 : features.find((f) => f === 'report.create'))) {
            setSettlementOptions((prev) => {
                const filtered = reportTypeOptions
                    .filter((t) => t.value === 'settlement' || t.value === 'reconciliation')
                    .map((f) => f.value);
                const arr = prev.filter((p) => !filtered.includes(p.value));
                setSelectType(arr[0]);
                return [...arr];
            });
        }
        if ((features === null || features === void 0 ? void 0 : features.length) !== 0 && !(features === null || features === void 0 ? void 0 : features.find((f) => f === 'fee_report.create'))) {
            setSettlementOptions((prev) => {
                const filtered = reportTypeOptions.filter((t) => t.value === 'fee').map((f) => f.value);
                const arr = prev.filter((p) => !filtered.includes(p.value));
                setSelectType(arr[0]);
                return [...arr];
            });
        }
    }, [features]);
    useEffect(() => {
        if (customerCode) {
            const filtered = settlementOptions.filter((t) => t.label !== 'Fee');
            setSelectType(filtered[0]);
            setSettlementOptions(filtered);
        }
    }, [customerCode]);
    return (React.createElement(Box, { pt: 3, display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { maxWidth: '460px', display: 'flex', flexDirection: 'column', gap: 2 },
            React.createElement(Box, { display: 'flex', gap: 1 },
                React.createElement(Box, { flex: '50%' },
                    React.createElement(TextField, { label: 'Type', select: true, fullWidth: true, size: 'small', variant: 'outlined', value: selectedType.value, name: 'selectSettlementType', onChange: handleTypeChange }, settlementOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))))),
                React.createElement(Box, { flex: '50%' }, selectedType.value === 'balances' ||
                    (customerCode && selectedType.value === 'auc') ||
                    (customerCode && selectedType.value === 'transactions') ? null : clientSelected ? (React.createElement(TextField, { select: true, fullWidth: true, label: superAdmin ? 'Client' : 'Customer', variant: 'outlined', size: 'small', name: 'selectClient', value: clientSelected.value, onChange: handleClientSelect }, clientOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))))) : null)),
            selectedType.value === 'auc-admin-timed' && (React.createElement(Box, { flex: '50%' },
                React.createElement(TextField, { label: 'Iteration', select: true, fullWidth: true, size: 'small', variant: 'outlined', value: timeUnit, name: 'selectTimeUnit', onChange: handleTimeUnitChange },
                    React.createElement(MenuItem, { key: 'Day', value: 'day' }, "Day"),
                    React.createElement(MenuItem, { key: 'Hour', value: 'hour' }, "Hour"),
                    React.createElement(MenuItem, { key: 'Minute', value: 'minute' }, "Minute")))),
            checboxTypes.includes(selectedType === null || selectedType === void 0 ? void 0 : selectedType.value) && superAdmin && (React.createElement(Box, null,
                React.createElement(FormControlLabel, { label: 'Extended Info', control: React.createElement(Checkbox, { name: 'extendedInfo', checked: extendedInfo, size: 'small', onChange: handleCheckboxChange }) }))),
            selectedType.value !== 'wallets' && (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: 'flex', gap: 1 },
                    React.createElement(DatePicker, { disableFuture: true, format: 'dd/MM/yyyy', label: `Date ${dateTypes.includes(selectedType === null || selectedType === void 0 ? void 0 : selectedType.value) ? '' : 'from'}`, slotProps: {
                            actionBar: {
                                actions: ['clear', 'cancel', 'today'],
                            },
                            textField: {
                                size: 'small',
                                error: errors.includes('dateFrom'),
                                helperText: errors.includes('dateFrom') ? 'Field is required' : '',
                            },
                        }, value: dateFrom, onClose: validateFields, onChange: handleDateFromChange }),
                    React.createElement(TimePicker, { label: `Time ${dateTypes.includes(selectedType === null || selectedType === void 0 ? void 0 : selectedType.value) ? '' : 'from'}`, value: timeFrom, ampm: false, slotProps: {
                            actionBar: {
                                actions: ['clear', 'cancel', 'today'],
                            },
                            textField: {
                                size: 'small',
                                error: errors.includes('timeFrom'),
                                helperText: errors.includes('timeFrom') ? 'Field is required' : '',
                            },
                        }, onChange: setTimeFromChange })),
                React.createElement(Box, { display: 'flex', gap: 1 },
                    !dateTypes.includes(selectedType === null || selectedType === void 0 ? void 0 : selectedType.value) ? (React.createElement(DatePicker, { disableFuture: true, format: 'dd/MM/yyyy', label: 'Date untill', value: dateTill, onClose: validateFields, slotProps: {
                            actionBar: {
                                actions: ['clear', 'cancel', 'today'],
                            },
                            textField: {
                                size: 'small',
                                error: errors.includes('dateTill'),
                                helperText: errors.includes('dateTill') ? 'Field is required' : '',
                            },
                        }, onChange: handleDateTillChange })) : null,
                    !dateTypes.includes(selectedType === null || selectedType === void 0 ? void 0 : selectedType.value) ? (React.createElement(TimePicker, { label: 'Time untill', value: timeTill, ampm: false, slotProps: {
                            actionBar: {
                                actions: ['clear', 'cancel', 'today'],
                            },
                            textField: {
                                size: 'small',
                                error: errors.includes('timeTill'),
                                helperText: errors.includes('timeTill') ? 'Field is required' : '',
                            },
                        }, onChange: setTimeTillChange })) : null)))),
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end' },
            React.createElement(Button, { variant: 'contained', type: 'button', color: 'primary', disabled: loading, onClick: handleDownload },
                loading ? 'Downloading' : 'Download CSV',
                "\u00A0",
                loading ? React.createElement(CircularProgress, { size: 18 }) : ''))));
};
export default ExportFile;
