import React, { useEffect, useState } from 'react';
import CustomerBalanceItem from './CustomerBalanceItem';
const CustomerBalancesContent = ({ balances, hideZero, customer_code }) => {
    const [balancesTorender, setBalancesToRender] = useState([]);
    useEffect(() => {
        if (balances.length) {
            setBalancesToRender([...balances]);
        }
    }, [balances]);
    if (hideZero) {
        return (React.createElement(React.Fragment, null, balancesTorender.map((balanceItem, i) => {
            if (balanceItem.total === '0') {
                return null;
            }
            return React.createElement(CustomerBalanceItem, { key: i, balanceItem: balanceItem, customer_code: customer_code });
        })));
    }
    return (React.createElement(React.Fragment, null, balancesTorender.map((balanceItem, i) => (React.createElement(CustomerBalanceItem, { key: i, balanceItem: balanceItem, customer_code: customer_code })))));
};
export default CustomerBalancesContent;
