/* eslint-disable react/require-default-props, no-nested-ternary, camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, MenuItem, TextField, } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, connect } from 'react-redux';
import OrdersService from '../../../../../services/ordersService';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getAvailableExchanges, getTradingBases, getTradingQuotes } from '../../../../../redux/selectors/selectors';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
const actionOptions = [
    { label: 'Buy', value: 'Buy' },
    { label: 'Sell', value: 'Sell' },
];
const typeOptions = [
    { label: 'Market', value: 'Market' },
    { label: 'Limit', value: 'Limit' },
];
const PlaceOrderForm = ({ exchanges, loadingExchanges, loadingMarkets, optionsBase, optionsQuotes, }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [limitPrice, setLimitPrice] = useState('');
    const [base, setBase] = useState(null);
    const [quote, setQuote] = useState(null);
    const [type, setType] = useState(typeOptions[0]);
    const [action, setAction] = useState(null);
    const [exchange, setExchange] = useState(null);
    console.log(exchange, 'exchange0000');
    const getLabel = (arr, val) => { var _a; return ((_a = arr.find(i => i.value === val)) === null || _a === void 0 ? void 0 : _a.label) || ''; };
    const handleExchangeChange = (e) => {
        const { value } = e.target;
        if (value) {
            const option = { value: value, label: getLabel(exchanges, value) };
            console.log(option, 'option1111');
            setExchange(option);
        }
    };
    const handleActionChange = (e) => {
        const { value } = e.target;
        if (value) {
            const option = { value: value, label: getLabel(actionOptions, value) };
            setAction(option);
        }
    };
    const handleTypeChange = (e) => {
        const { value } = e.target;
        if (value) {
            const option = { value: value, label: getLabel(typeOptions, value) };
            setType(option);
        }
    };
    const handleChangeBase = (e) => {
        const { value } = e.target;
        if (value) {
            const option = { value: value, label: getLabel(optionsBase, value) };
            setBase(option);
        }
    };
    const handleChangeQuote = (e) => {
        const { value } = e.target;
        if (value) {
            const option = { value: value, label: getLabel(optionsQuotes, value) };
            setQuote(option);
        }
    };
    const handleInputChange = (evt) => {
        const { value, name } = evt.target;
        if (isNaN(Number(value))) {
            return;
        }
        if (name === 'amount') {
            setAmount(value);
        }
        if (name === 'limitPrice') {
            setLimitPrice(value);
        }
    };
    const handlePlaceOrder = () => {
        const payload = {
            amount,
            type: type.value,
            reference_id: '',
            limit_price: limitPrice,
            base: (base === null || base === void 0 ? void 0 : base.value) || '',
            quote: (quote === null || quote === void 0 ? void 0 : quote.value) || '',
            action: (action === null || action === void 0 ? void 0 : action.value) || '',
            exchange_code: (exchange === null || exchange === void 0 ? void 0 : exchange.value) || '',
        };
        const service = new OrdersService({
            url: '/trade/autohedger/orders',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            setAmount('');
            setBase(null);
            setQuote(null);
            setAction(null);
            setLimitPrice('');
            setExchange(null);
            showNotification({
                message: 'Order successfully created',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.POST_NEW_ORDER);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        dispatch({ type: FETCH_MARKETS_REQUEST });
        dispatch({ type: FETCH_EXCHANGES_REQUEST });
    }, []);
    useEffect(() => {
        if (!amount || !base || !quote || !exchange || !action || (type.value === 'Limit' && !limitPrice)) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [amount, base, quote, exchange, action, limitPrice, type]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            React.createElement(TextField, { select: true, fullWidth: true, size: "small", label: "Exchange", variant: "outlined", value: exchange === null || exchange === void 0 ? void 0 : exchange.value, onChange: handleExchangeChange }, exchanges.map(option => (React.createElement(MenuItem, { value: option.value }, option.label))))),
        React.createElement(Box, null,
            React.createElement(TextField, { select: true, fullWidth: true, name: "base", size: "small", label: "Base", variant: "outlined", value: base === null || base === void 0 ? void 0 : base.value, onChange: handleChangeBase }, optionsBase.map(option => (React.createElement(MenuItem, { value: option.value }, option.label))))),
        React.createElement(Box, null,
            React.createElement(TextField, { select: true, fullWidth: true, name: "quote", size: "small", label: "Quote", variant: "outlined", value: quote === null || quote === void 0 ? void 0 : quote.value, onChange: handleChangeQuote }, optionsQuotes.map(option => (React.createElement(MenuItem, { value: option.value }, option.label))))),
        React.createElement(Box, null,
            React.createElement(TextField, { fullWidth: true, size: "small", name: "amount", value: amount, label: "Amount", placeholder: "0", variant: "outlined", onChange: handleInputChange, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } })),
        React.createElement(Box, null,
            React.createElement(TextField, { select: true, fullWidth: true, name: "type", size: "small", label: "Type", variant: "outlined", value: type.value, onChange: handleTypeChange }, typeOptions.map(option => (React.createElement(MenuItem, { value: option.value }, option.label))))),
        (type === null || type === void 0 ? void 0 : type.value) === 'Limit' ? (React.createElement(Box, null,
            React.createElement(TextField, { fullWidth: true, size: "small", label: "Price", placeholder: "0", name: "limitPrice", variant: "outlined", value: limitPrice, onChange: handleInputChange, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } }))) : null,
        React.createElement(Box, null,
            React.createElement(TextField, { select: true, fullWidth: true, name: "action", size: "small", label: "Action", variant: "outlined", value: action === null || action === void 0 ? void 0 : action.value, onChange: handleActionChange }, actionOptions.map(option => (React.createElement(MenuItem, { value: option.value }, option.label))))),
        React.createElement(Box, { textAlign: 'center' },
            loadingExchanges || loadingMarkets
                ? React.createElement(CircularProgress, { size: "33px" }) : null,
            React.createElement(Button, { color: "primary", variant: "contained", disabled: disabled, onClick: handlePlaceOrder }, "Place Order"))));
};
const stateToProps = (state) => {
    const { loadingExchanges, loadingMarkets } = state.placeOrder;
    let exchanges = [];
    let optionsBase = [];
    let optionsQuotes = [];
    if (!loadingExchanges && !loadingMarkets) {
        optionsBase = getTradingBases(state);
        optionsQuotes = getTradingQuotes(state);
        exchanges = getAvailableExchanges(state);
    }
    return {
        exchanges,
        optionsBase,
        optionsQuotes,
        loadingMarkets,
        loadingExchanges,
    };
};
export default connect(stateToProps)(PlaceOrderForm);
