import React from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import Table from '@mui/material/Table';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Box, ButtonGroup, Chip, Typography } from '@mui/material';
/* eslint-disable camelcase */
const ClientDetails = ({ client, onButtonClick, onEditClick }) => (React.createElement("div", null,
    React.createElement(Box, { p: 1, display: 'flex', justifyContent: 'space-between' },
        React.createElement(Typography, { variant: "subtitle1" }, "Client Details"),
        React.createElement("div", null,
            React.createElement(ButtonGroup, { color: "primary" },
                React.createElement(IconButton, { onClick: onEditClick, size: "small" },
                    React.createElement(EditIcon, { fontSize: "small" })),
                React.createElement(IconButton, { onClick: onButtonClick, size: "small" },
                    React.createElement(CloseIcon, { fontSize: "small" }))))),
    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
        React.createElement(TableBody, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Name"),
                React.createElement(TableCell, null,
                    React.createElement(Chip, { label: client === null || client === void 0 ? void 0 : client.company_name, size: "small", color: "primary" }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Contact"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.contact_person_name)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Phone"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.phone_number)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Code"),
                React.createElement(TableCell, null,
                    React.createElement(Chip, { size: "small", color: "default", label: (client === null || client === void 0 ? void 0 : client.code) || '' }))),
            (client === null || client === void 0 ? void 0 : client.ibans) && client.ibans.map(iban => (React.createElement(TableRow, { key: iban.iban },
                React.createElement(TableCell, null,
                    "IBAN(",
                    iban.currency,
                    ")"),
                React.createElement(TableCell, null,
                    iban.iban,
                    " ",
                    React.createElement("span", null, iban.is_enabled ? '' : ' (disabled)'))))),
            (client === null || client === void 0 ? void 0 : client.bfid)
                && (React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "BFID"),
                    React.createElement(TableCell, null, client.bfid))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "VAT"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.vat)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Address"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.address)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Zip Code"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.zip_code)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "City"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.city)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Country"),
                React.createElement(TableCell, null, client === null || client === void 0 ? void 0 : client.country))))));
export default ClientDetails;
