import React from 'react';
import { Box, Chip, Table, TableRow, TableBody, TableCell, Typography, IconButton, ButtonGroup, } from '@mui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import EditIcon from '@mui/icons-material/Edit';
/* eslint-disable camelcase */
const CustomerDetails = ({ customer, onButtonClick, onEditClick }) => (React.createElement("div", null,
    React.createElement(Box, { p: 1, display: 'flex', justifyContent: 'space-between' },
        React.createElement(Typography, { variant: "subtitle1" }, "Customer Details"),
        React.createElement("div", null,
            React.createElement(ButtonGroup, { color: "primary" },
                React.createElement(IconButton, { onClick: onEditClick, size: "small" },
                    React.createElement(EditIcon, { fontSize: "small" })),
                React.createElement(IconButton, { onClick: onButtonClick, size: "small" },
                    React.createElement(CloseIcon, { fontSize: "small" }))))),
    React.createElement(Table, { size: "small" },
        React.createElement(TableBody, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Name"),
                React.createElement(TableCell, null,
                    React.createElement(Chip, { size: "small", color: "primary", label: customer.company_name ? customer.company_name : 'n/a' }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Contact"),
                React.createElement(TableCell, null, customer.contact_person_name ? customer.contact_person_name : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Phone"),
                React.createElement(TableCell, null, customer.phone_number ? customer.phone_number : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Code"),
                React.createElement(TableCell, null,
                    React.createElement(Chip, { size: "small", color: "default", label: customer.code ? customer.code : '-----' }))),
            customer.ibans && customer.ibans.map(iban => (React.createElement(TableRow, { key: iban.iban },
                React.createElement(TableCell, null,
                    "IBAN(",
                    iban.currency,
                    ")"),
                React.createElement(TableCell, null,
                    iban.iban,
                    " ",
                    React.createElement("span", null, iban.is_enabled ? '' : ' (disabled)'))))),
            customer.bfid
                && (React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "BFID"),
                    React.createElement(TableCell, null, customer.bfid))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "VAT"),
                React.createElement(TableCell, null, customer.vat ? customer.vat : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Address"),
                React.createElement(TableCell, null, customer.address ? customer.address : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Zip Code"),
                React.createElement(TableCell, null, customer.zip_code ? customer.zip_code : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "City"),
                React.createElement(TableCell, null, customer.city ? customer.city : 'n/a')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Country"),
                React.createElement(TableCell, null, customer.country ? customer.country : 'n/a'))))));
export default CustomerDetails;
