import React from 'react';
import SettlementsTable from './SettlementsTable';
import { Box, Paper, Typography } from '@mui/material';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class Settlements extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            layouts: [],
        };
    }
    render() {
        return (React.createElement("div", { className: 'content-body side-bar' },
            React.createElement(Box, { p: 0.6 },
                React.createElement(Paper, null,
                    React.createElement(Typography, { variant: 'h6', p: 1 }, "Settlements"),
                    React.createElement(SettlementsTable, null)))));
    }
}
export default Settlements;
