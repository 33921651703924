import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../../../Layout';
import MainContainer from '../../../Dashboards/Admin/index';
import LogBook from '../../../Dashboards/Admin/components/LogBook/main';
import ReportsContainer from '../../../Dashboards/Admin/components/Reports/main';
import ClientsContainer from '../../../Dashboards/Admin/components/Clients/main';
import Whitelisting from '../../../Dashboards/Admin/components/Whitelisting/main';
import Customers from '../../../Dashboards/Admin/components/Clients/Customers/main';
import ApiTokensContainer from '../../../Dashboards/Admin/components/ApiTokens/main';
import ClientsUsersList from '../../../Dashboards/Admin/components/Clients/Users/main';
import ClientsDetails from '../../../Dashboards/Admin/components/Clients/Details/main';
import ClearerWithdrawals from '../../../Dashboards/Admin/components/Withdrawals/main';
import FileArchiveContainer from '../../../Dashboards/Admin/components/FileArchive/main';
import ClientsOrdersList from '../../../Dashboards/Admin/components/Clients/Orders/main';
import PermissionsComponent from '../../../Dashboards/Admin/components/Clients/Permissions/main';
import TradingTransfersDashboard from '../../../Dashboards/Admin/components/TradingTransfers/main';
export default (props) => {
    const { features } = props;
    const ordersEnabled = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && (features === null || features === void 0 ? void 0 : features.includes('order.read'));
    return (React.createElement("div", null,
        React.createElement(Layout, { sidebar: "manage" },
            React.createElement(React.Fragment, null,
                React.createElement(Route, { path: "/manage/logbook", component: LogBook }),
                React.createElement(Route, { exact: true, path: "/manage", component: MainContainer }, !ordersEnabled
                    && React.createElement(Redirect, { exact: true, from: "/manage", to: "/manage/clients/details" })),
                React.createElement(Route, { path: "/manage/reports", component: ReportsContainer }),
                React.createElement(Route, { path: "/manage/tokens", component: ApiTokensContainer }),
                React.createElement(Route, { path: "/manage/whitelisting", component: Whitelisting }),
                React.createElement(Route, { path: "/manage/clients/customers", component: Customers }),
                React.createElement(Route, { exact: true, path: "/manage/clients", component: ClientsContainer }),
                React.createElement(Route, { path: "/manage/clients/details", component: ClientsDetails }),
                React.createElement(Route, { path: "/manage/file-archive", component: FileArchiveContainer }),
                React.createElement(Route, { path: "/manage/transfers", component: TradingTransfersDashboard }),
                React.createElement(Route, { path: "/manage/clients/:id/orders", component: ClientsOrdersList }),
                React.createElement(Route, { exact: true, path: "/manage/clients/users", component: ClientsUsersList }),
                React.createElement(Route, { path: "/manage/clearer/withdrawals", component: ClearerWithdrawals }),
                React.createElement(Route, { path: "/manage/clients/permissions", component: PermissionsComponent })))));
};
