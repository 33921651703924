var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import CustodyClientDepositsService from '../../../../../../services/custodyDepositsService';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
export const DepositReferenceId = ({ item, toggle, cellAction, urlDeposits, fetchDeposits, }) => {
    const [loading, setLoading] = useState(false);
    const [referenceId, setReferenceId] = useState('');
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleReferenceIdChange = (evt) => {
        const { value } = evt.target;
        setReferenceId(value);
    };
    const onReferenceIdChangeDone = () => __awaiter(void 0, void 0, void 0, function* () {
        const depositService = new CustodyClientDepositsService({
            url: `/custody/${item.client_uuid ? item.client_uuid : ''}/deposits/${item.code}`,
            method: 'PUT',
            data: { reference_id: referenceId },
        });
        setLoading(true);
        yield depositService.makeRequest()
            .then(() => {
            setLoading(false);
            fetchDeposits(urlDeposits);
            showNotification({
                message: 'Reference Id successfully updated',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_DEPOSIT_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setReferenceId('');
        });
        cellAction(Object.assign({}, item, { reference_id: referenceId }));
        toggle();
    });
    useEffect(() => {
        if (item) {
            setReferenceId(item.reference_id);
        }
    }, []);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", value: referenceId, InputLabelProps: { shrink: true }, onChange: handleReferenceIdChange, helperText: "Add deposit reference id" }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", variant: "outlined", onClick: onReferenceIdChangeDone }, "Update"),
            React.createElement(Button, { color: "inherit", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
