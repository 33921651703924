import React, { useEffect, useState } from 'react';
import { Box, Menu, List, Switch, Button, Divider, ListItem, useTheme, } from '@mui/material';
import shortid from 'shortid';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import { connect } from 'react-redux';
import { DASHBOARD_CREATE, DASHBOARD_DELETE, DASHBOARD_UPDATE, REMOVE_WORKSPACE, ADD_NEW_WORKSPACE, WORKSPACE_UPDATE_REQUEST, } from '../../../redux/actionTypes/workspaceActions';
import ErrorsModal from './ErrorsModal';
import TopBarWidgets from './TopbarWidgets';
import TopBarMenuItem from './TopBarMenuItem';
import Permissions from '../AdminSidebar/Permissions';
import LogoutService from '../../../services/logoutService';
import SidebarCategory from '../AdminSidebar/SidebarCategory';
import CreateLayoutModal from '../../Dashboards/Crypto/components/CreateLayout';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { getWorkspacesForDashboard } from '../../../redux/selectors/workspaceSelector';
import { CHANGE_THEME_TO_DARK, CHANGE_THEME_TO_LIGHT } from '../../../redux/actionTypes/themeActionTypes';
import { createStyles, makeStyles } from '@mui/styles';
import ProfileCode from './ProfileCode';
/* eslint-disable @typescript-eslint/restrict-template-expressions, no-param-reassign */
const avatar = `${process.env.PUBLIC_URL}/img/generic_avatar.png`;
const useStyles = makeStyles(() => createStyles({
    root: {
        fontSize: '0.875rem',
    },
    avatar: {
        width: '36px',
        height: '36px',
        filter: 'contrast(0.1)',
    },
}));
const TopbarProfile = ({ widgets, features, settings, dashboards, clientInfo, workspaces, currentDashboard, dashboardWorkspaces, dispatchAddWorkspace, dispatchUpdateWorkspace, dispatchRemoveWorkspace, dispatchUpdateDashboard, dispatchDeleteDashboard, dispatchCreateDashboard, dispatchCloseErrorNotice, dispatchThemeDarkApply, dispatchThemeLightApply, mappedDefaultWorkspaceRecord, }) => {
    var _a;
    const [collapse, setCollapse] = useState(false);
    const [collapseWorkspaces, setCollapseWorkspaces] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
    const [isLightTheme, setIsLightTheme] = useState(false);
    const logoutService = new LogoutService();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const classes = useStyles();
    const theme = useTheme();
    useEffect(() => {
        setIsLightTheme(localStorage.getItem('theme') === 'theme-light');
    }, []);
    const toggleWorkspaceModal = () => {
        setWorkspaceModalOpen(!workspaceModalOpen);
    };
    const toggle = () => {
        setCollapse(!collapse);
    };
    const handleCollapseWorkspaces = () => {
        setCollapseWorkspaces(!collapseWorkspaces);
    };
    const toggleError = () => {
        setErrorModal(!errorModal);
    };
    const handleGenerateReport = () => {
        toggle();
        window.location.href = '/manage/reports';
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRemoveWorkspace = (workspaceId) => {
        const index = workspaces.findIndex(w => w.id === workspaceId);
        dispatchRemoveWorkspace(index);
        const activeDashboard = dashboards.find((d) => d.name === currentDashboard);
        const records = [...workspaces.slice(0, index), ...workspaces.slice(index + 1)];
        const indexRfq = records.findIndex(w => w.id === 'systemRfq');
        const filteredRecords = indexRfq > -1 ? [...records.slice(0, indexRfq), ...records.slice(indexRfq + 1)] : records;
        const filteredDashboards = dashboards.map((item) => {
            if (item.name !== currentDashboard) {
                return item;
            }
            return Object.assign(Object.assign({}, item), { workspaces: item.workspaces.filter(w => w !== workspaceId) });
        });
        const workspace = {
            workspaces: filteredRecords,
            dashboards: filteredDashboards,
            active: activeDashboard ? activeDashboard.activeWorkspace : '',
        };
        const payload = {
            settings: JSON.stringify(workspace),
        };
        dispatchUpdateWorkspace(payload, dispatchCloseErrorNotice, 'removed');
        dispatchDeleteDashboard(workspaceId, currentDashboard);
    };
    const handleLogOut = () => {
        // eslint-disable-next-line no-void
        void logoutService.logout();
    };
    const handleSelectWorkspace = (workspaceId) => {
        if (!workspaceId) {
            return;
        }
        const systemId = mappedDefaultWorkspaceRecord === null || mappedDefaultWorkspaceRecord === void 0 ? void 0 : mappedDefaultWorkspaceRecord.id;
        const record = workspaceId === systemId
            ? mappedDefaultWorkspaceRecord
            : workspaces.find(w => w.id === workspaceId) || null;
        const filteredDashboards = dashboards.map((item) => {
            if (item.name !== currentDashboard) {
                return item;
            }
            return Object.assign(Object.assign({}, item), { activeWorkspace: workspaceId });
        });
        const indexRfq = workspaces.findIndex(w => w.id === 'systemRfq');
        const records = indexRfq > -1 ? [...workspaces.slice(0, indexRfq), ...workspaces.slice(indexRfq + 1)] : workspaces;
        dispatchUpdateWorkspace({
            settings: JSON.stringify({
                workspaces: records,
                active: workspaceId,
                dashboards: filteredDashboards,
            }),
        }, dispatchCloseErrorNotice, undefined, record);
        dispatchUpdateDashboard(workspaceId, currentDashboard);
        toggle();
    };
    const handleCreateWorkspace = (name) => {
        const id = shortid.generate();
        const workSpaceRecord = {
            id,
            title: name,
            widgets,
            widgetSettings: settings,
        };
        const filteredDashboards = dashboards.map((item) => {
            if (item.name !== currentDashboard) {
                return item;
            }
            return Object.assign(Object.assign({}, item), { activeWorkspace: id, workspaces: [...item.workspaces, id] });
        });
        const workspace = {
            workspaces: [...workspaces, workSpaceRecord],
            active: id,
            dashboards: filteredDashboards,
        };
        const payload = {
            settings: JSON.stringify(workspace),
        };
        dispatchAddWorkspace(workSpaceRecord);
        dispatchUpdateWorkspace(payload, dispatchCloseErrorNotice, 'created');
        dispatchCreateDashboard(id, currentDashboard);
        toggle();
    };
    const handleSaveWorkspace = () => {
        const activeDashboard = dashboards.find((d) => d.name === currentDashboard);
        if (activeDashboard) {
            const recordIndex = workspaces.findIndex(w => w.id === activeDashboard.activeWorkspace);
            workspaces[recordIndex].widgets = widgets;
            workspaces[recordIndex].widgetSettings = settings;
            const indexRfq = workspaces.findIndex(w => w.id === 'systemRfq');
            const records = indexRfq > -1
                ? [...workspaces.slice(0, indexRfq), ...workspaces.slice(indexRfq + 1)] : workspaces;
            const workspace = {
                workspaces: records,
                active: activeDashboard.activeWorkspace,
                dashboards,
            };
            const payload = {
                settings: JSON.stringify(workspace),
            };
            dispatchUpdateWorkspace(payload, dispatchCloseErrorNotice, 'saved');
        }
        toggle();
    };
    const handleThemeSwitch = (event) => {
        const isLight = event.target.checked;
        setIsLightTheme(isLight);
        isLight ? dispatchThemeLightApply() : dispatchThemeDarkApply();
    };
    const systemId = mappedDefaultWorkspaceRecord === null || mappedDefaultWorkspaceRecord === void 0 ? void 0 : mappedDefaultWorkspaceRecord.id;
    const activeDashboard = dashboards.find((d) => d.name === currentDashboard);
    const clientName = clientInfo.loggedClientName || (clientInfo.clientInfo && clientInfo.clientInfo.company_name);
    const systems = [systemId, 'systemRfq'];
    return (React.createElement(Box, { display: 'flex', alignItems: 'center', className: classes.root },
        errorModal ? (React.createElement(ErrorsModal, { open: errorModal, toggleFunc: toggleError, classProps: "two_fa_modal_padding" })) : null,
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 },
            React.createElement("img", { className: classes.avatar, src: avatar, alt: "avatar" }),
            clientName,
            React.createElement(Button, { onClick: handleMenu }, menuOpen ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null))),
        collapse && React.createElement("button", { className: "topbar__back", type: "button", onClick: toggle }),
        React.createElement(Menu, { slotProps: { paper: { style: { width: '270px', background: theme.palette.background.default, fontSize: '0.875rem' } } }, anchorEl: anchorEl, anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, keepMounted: true, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: menuOpen, onClose: handleClose },
            React.createElement(Box, { p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
                "Theme:",
                React.createElement(Box, { display: 'flex', alignItems: 'center' },
                    React.createElement(Brightness2Icon, null),
                    React.createElement(Switch, { onChange: handleThemeSwitch, checked: isLightTheme, color: "default" }),
                    " ",
                    React.createElement(Brightness5Icon, null))),
            React.createElement(Divider, null),
            React.createElement(SidebarCategory, { title: "Add widgets" },
                React.createElement(TopBarWidgets, { toggleMenu: toggle })),
            React.createElement(Divider, null),
            workspaceModalOpen ? (React.createElement(CreateLayoutModal, { open: workspaceModalOpen, toggleFunc: toggleWorkspaceModal, handleCreateWorkSpace: handleCreateWorkspace })) : null,
            React.createElement(SidebarCategory, { title: "Workspaces" },
                React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                    React.createElement(List, null,
                        React.createElement(ListItem, null,
                            React.createElement(TopBarMenuItem, { submenu: true, title: "System workspace", isActive: activeDashboard && activeDashboard.activeWorkspace === systemId, onClick: () => handleSelectWorkspace(systemId) })),
                        dashboardWorkspaces.map((workspace) => {
                            let removable = true;
                            if (workspace.id === 'systemRfq') {
                                removable = false;
                            }
                            else if (activeDashboard && activeDashboard.activeWorkspace !== workspace.id) {
                                removable = true;
                            }
                            return (React.createElement(ListItem, { key: workspace.id },
                                React.createElement(TopBarMenuItem, { submenu: true, removable: removable, title: workspace.title, onClick: () => handleSelectWorkspace(workspace.id), onCloseClick: () => handleRemoveWorkspace(workspace.id), isActive: activeDashboard && activeDashboard.activeWorkspace === workspace.id })));
                        }),
                        React.createElement(ListItem, null,
                            React.createElement(Button, { onClick: toggleWorkspaceModal, fullWidth: true, variant: 'outlined' }, "Create workspace")),
                        !systems.includes(activeDashboard && activeDashboard.activeWorkspace) ? (React.createElement(ListItem, null,
                            React.createElement(Button, { onClick: handleSaveWorkspace, fullWidth: true, variant: 'outlined' }, "Save workspace"))) : null))),
            React.createElement(Divider, null),
            React.createElement(Permissions, { oneOf: true, features: features, permissions: [
                    'custody_report.create',
                    'fee_report.create',
                    'report.create',
                    'report_trading.create',
                    'report.read',
                    'report_trading.read',
                ] },
                React.createElement(TopBarMenuItem, { title: "Generate report", onClick: handleGenerateReport })),
            React.createElement(Divider, null),
            React.createElement(TopBarMenuItem, { title: "Error report", onClick: toggleError }),
            React.createElement(Divider, null),
            React.createElement(Box, { p: 2 },
                React.createElement(Box, { mb: 1 },
                    React.createElement(ProfileCode, { code: clientInfo.meCode, title: 'User code' })),
                clientInfo.clientInfo ?
                    React.createElement(ProfileCode, { code: (_a = clientInfo.clientInfo) === null || _a === void 0 ? void 0 : _a.code, title: 'Client code' }) : null),
            React.createElement(Divider, null),
            React.createElement(TopBarMenuItem, { title: "Log out", onClick: handleLogOut, icon: React.createElement(ExitToAppIcon, null) }))));
};
const stateToProps = (state) => ({
    clientInfo: state.client,
    features: state.client.features,
    widgets: state.widgetSettings.widgets,
    workspaces: state.workspace.workspaces,
    dashboards: state.workspace.dashboards,
    settings: state.widgetSettings.settings,
    currentDashboard: state.workspace.activeDashboard,
    mappedDefaultWorkspaceRecord: state.workspace.mappedDefaultWorkspaceRecord,
    dashboardWorkspaces: getWorkspacesForDashboard(state, state.workspace.activeDashboard),
});
const dispatchToProps = dispatch => ({
    dispatchCloseErrorNotice: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
    dispatchRemoveWorkspace: index => dispatch({ type: REMOVE_WORKSPACE, index }),
    dispatchUpdateWorkspace: (payload, dispatchCloseErrorNotice, notificationText, workspaceRecord) => dispatch({
        type: WORKSPACE_UPDATE_REQUEST,
        payload,
        dispatchCloseErrorNotice,
        notificationText,
        workspaceRecord,
    }),
    dispatchUpdateDashboard: (dashboardId, dashboardName) => dispatch({
        type: DASHBOARD_UPDATE, dashboardId, dashboardName,
    }),
    dispatchDeleteDashboard: (dashboardId, dashboardName) => dispatch({
        type: DASHBOARD_DELETE, dashboardId, dashboardName,
    }),
    dispatchCreateDashboard: (dashboardId, dashboardName) => dispatch({
        type: DASHBOARD_CREATE, dashboardId, dashboardName,
    }),
    dispatchAddWorkspace: workspaceRecord => dispatch({ type: ADD_NEW_WORKSPACE, workspaceRecord }),
    dispatchThemeDarkApply: () => dispatch({ type: CHANGE_THEME_TO_DARK }),
    dispatchThemeLightApply: () => dispatch({ type: CHANGE_THEME_TO_LIGHT }),
});
export default connect(stateToProps, dispatchToProps)(TopbarProfile);
