import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import buildUrl from 'build-url';
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import WithdrawalModal from './WithdrawalModal';
import { PanelContainer, PanelTitle } from '../../styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import ClearerWithdrawalsContent from './ClearerWithdrawalsContent';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import ClearerWithdrawalsService from '../../../../../services/clearerWithdrawalsService';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const ClearerWithdrawals = ({ clientInfo, selectedClient, masterTenant, clientFromUsers, }) => {
    const [rowsPerPage] = useState(10);
    const [url, setUrl] = useState('');
    const [page, setPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState('0');
    const [modalOpen, setModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchWithdrawals = (endpointUrl, cancelToken) => {
        let isCancelled = false;
        const usersService = new ClearerWithdrawalsService({ url: endpointUrl, method: 'get', cancelToken });
        usersService.makeRequest()
            .then((responseData) => {
            const { records, total } = responseData;
            if (!isCancelled) {
                setUsers(records);
                setTotalUsers(total);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            if (!isCancelled) {
                const message = composeErrorMessage(e, Messages.CLEARER_WITHDRAWAL_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            }
        });
        return () => {
            isCancelled = true;
        };
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const urlOptions = {
            queryParams: {
                limit: '10',
                page: `${page}`,
                sort_by: 'created',
                sort_direction: 'desc',
            },
            path: '/trade/exchanges/withdrawals',
        };
        const urlEndpoint = buildUrl('', urlOptions);
        setUrl(urlEndpoint);
        fetchWithdrawals(urlEndpoint, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [page]);
    const onChangePage = (event, pageNumber) => {
        if (pageNumber) {
            setPage(pageNumber);
        }
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const addWithdrawal = (payload, fetchUrl) => {
        const clearerService = new ClearerWithdrawalsService({
            url: '/trade/exchanges/withdrawals', method: 'post', data: payload,
        });
        clearerService.makeRequest()
            .then(() => {
            fetchWithdrawals(fetchUrl);
            showNotification({
                message: 'Withdrawal successfully created',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CLEARER_WITHDRAWAL_CREATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            regularClient ? (React.createElement(PanelTitle, { className: "panel__title" },
                React.createElement("h5", { className: "bold-text" },
                    "Withdrawals:",
                    React.createElement("span", { className: "badge badge-warning" }, clientFromUsers ? clientFromUsers.label : selectedClient
                        ? selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.label : clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name)))) : null,
            modalOpen ? (React.createElement(WithdrawalModal, { url: url, open: modalOpen, toggleFunc: toggleModal, handleSendWithdrawal: addWithdrawal })) : null,
            React.createElement("div", { className: "card-body" },
                React.createElement("span", { className: "common_float_right" },
                    React.createElement(Button, { variant: "outlined", size: "small", type: "button", color: "primary", onClick: () => toggleModal() }, "Add withdrawal")),
                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Address"),
                            React.createElement(TableCell, null, "Exchange"),
                            React.createElement(TableCell, null, "Currency"),
                            React.createElement(TableCell, null, "Created at"),
                            React.createElement(TableCell, null, "Status"))),
                    React.createElement(TableBody, null,
                        React.createElement(ClearerWithdrawalsContent, { records: users }))),
                React.createElement(TablePagination, { page: page, component: "div", count: +totalUsers, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [rowsPerPage] })))));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    clientFromUsers: state.admin.clientFromUsers,
});
export default connect(mapStateToProps)(ClearerWithdrawals);
