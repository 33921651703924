import { call, put, select, takeLatest, } from 'redux-saga/effects';
import deepmerge from 'deepmerge';
import forEach from 'lodash/forEach';
import { defaultDashboards, defaultCustodyRecord, defaultPortfolioRecord, defaultWorkspaceRecord, defaultPermissionsRecord, defaultHedgeOrdersRecord, defaultManageUsersRecord, defaultStrategiessRecord, defaultRfqWorkspaceRecord, defaultManageTokensRecord, defaultManageOrdersRecord, defaultManageLogbookRecord, defaultRFQManagementRecord, defaultAdminExchangeRecord, defaultCurrencyConfigRecord, defaultManageCustomersRecord, defaultManageWhitelistRecord, defaultManageSchedulersRecord, defaultManageComplianceRecord, defaultTradingTransfersRecord, defaultAutohedgerBalanceRecord, defaultRFQClientsManagementRecord, defaultManageComplianceTradingRecord, } from '../config/dataModels';
import Messages from '../shared/helpers/errorMessages';
import * as types from '../redux/actionTypes/workspaceActions';
import showNotification from '../shared/helpers/notifications';
import { fetchCustomerInfo } from '../redux/actions/clientActions';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
import { fetchExchanges, fetchMarkets } from '../redux/actions/placeOrderActions';
import { fetchWorkspaces, updateWorkspace } from '../redux/actions/workspaceActions';
import { SCHEMA_APPLY_WORKSPACE } from '../redux/actionTypes/widgetSettingsActionTypes';
import { CUSTOMER_SELECT_SET_UPDATE } from '../redux/actionTypes/customerSearchActionsTypes';
import { getClient } from '../redux/selectors/clientSelectors';
import { getEnvSettings } from '../config/environmentSettings';
const finoaEnv = getEnvSettings().env === 'finoa';
/* eslint-disable no-param-reassign, no-unused-vars, import/prefer-default-export,
  no-nested-ternary, @typescript-eslint/no-unsafe-call, camelcase */
const mergeMissedSettings = (workspaceRecord, defaultWidgets, defaultRecord) => {
    workspaceRecord.widgets.forEach((item) => {
        const defaultWidget = defaultWidgets.find(w => w.type === item.type);
        if (defaultWidget && defaultWidget.settings.length === item.settings.length) {
            return;
        }
        // we do merge
        defaultWidget && defaultWidget.settings.forEach((settingId) => {
            const settingName = settingId.replace(defaultWidget.widgetKey, '');
            const settingToWidgetId = `${item.widgetKey}${settingName}`;
            if (item.settings.findIndex(id => id === settingToWidgetId) === -1) {
                item.settings.push(settingToWidgetId);
            }
            if (typeof workspaceRecord.widgetSettings.byId[settingToWidgetId] === 'undefined') {
                // Merge system widget settings and assign widget Id
                workspaceRecord.widgetSettings.byId[settingToWidgetId] = Object.assign({}, defaultRecord.widgetSettings.byId[settingId], { id: settingToWidgetId });
            }
        });
    });
};
const filterCustodyWidgets = (record, custodyFeatures) => {
    const filtered = record === null || record === void 0 ? void 0 : record.widgets.filter((w) => {
        if (!custodyFeatures.canReadTransactions && (w.name === 'Deposits' || w.name === 'Withdrawals')) {
            return false;
        }
        if (!custodyFeatures.canReadWallets && w.name === 'Wallets') {
            return false;
        }
        if (!custodyFeatures.canReadWAddresses && w.name === 'Addresses') {
            return false;
        }
        if (!custodyFeatures.admin && w.name === 'Clients') {
            return false;
        }
        if (!custodyFeatures.canReadInternalTransfers && w.name === 'Internal Transfers') {
            return false;
        }
        if (!custodyFeatures.canUpdateWithdrawals && !custodyFeatures.canSignWithdrawals
            && !custodyFeatures.canSignAddresses && !custodyFeatures.canUpdateAddresses && w.name === 'Approve') {
            return false;
        }
        return true;
    });
    return filtered || [];
};
const filterTradingWidgets = (record, tradingFeatures) => {
    const filtered = record === null || record === void 0 ? void 0 : record.widgets.filter((w) => {
        if (!tradingFeatures.canReadReserves && (w.name === 'Exchange balances')) {
            return false;
        }
        if (!tradingFeatures.canReadOrders && w.name === 'Orders list') {
            return false;
        }
        if ((!tradingFeatures.canReadUsers && w.name === 'Client search')
            || (tradingFeatures.restrictedCustomer && w.name === 'Client search')) {
            return false;
        }
        if (tradingFeatures.restrictedCustomer && w.name === 'Customers search') {
            return false;
        }
        if (w.name === 'TradingView chart' && record.id === 'systemRfq' && !finoaEnv) {
            return false;
        }
        return true;
    });
    return filtered || [];
};
export const getTradingExchanges = (exchanges) => exchanges
    .map(exchange => ({ value: exchange.code, label: exchange.name }));
export const getTradingBases = (markets) => markets
    .map(market => ({ value: market.base, label: market.base }))
    .filter((market, index, self) => index === self.findIndex(m => (m.value === market.value && m.label === market.label)));
export const getTradingQuotes = (markets) => markets
    .map(market => ({ value: market.quote, label: market.quote }))
    .filter((market, index, self) => index === self.findIndex(m => (m.value === market.value && m.label === market.label)));
export const loopSettings = (set, exchanges, optionsBase, optionsQuotes, workspace) => {
    const mappedSet = {};
    forEach(set, (val, key) => {
        if (val.id.includes('Exchanges')) {
            val.options = exchanges;
            if (workspace === 'system') {
                const label = exchanges.length > 0 ? exchanges[0].label : '';
                const value = exchanges.length > 0 ? exchanges[0].value : '';
                val.value = { label, value };
            }
        }
        if (val.id.includes('Base')) {
            val.options = optionsBase;
            if (workspace === 'system') {
                const label = optionsBase.length > 0 ? optionsBase[0].label : '';
                const value = optionsBase.length > 0 ? optionsBase[0].value : '';
                val.value = { label, value };
            }
        }
        if (val.id.includes('Quote')) {
            val.options = optionsQuotes;
            if (workspace === 'system') {
                const eur = optionsQuotes.find(q => q.value === 'EUR');
                const label = optionsQuotes.length > 0 ? (eur ? eur.label : optionsQuotes[0].label) : '';
                const value = optionsQuotes.length > 0 ? (eur ? eur.value : optionsQuotes[0].value) : '';
                val.value = { label, value };
            }
        }
        mappedSet[key] = val;
    });
    return mappedSet;
};
function* fetchAllWorkspaces(action) {
    let markets = [];
    let allFeatures = [];
    let exchanges = [];
    let isAdmin = false;
    let customerCode = '';
    // TODO: automate this process
    const systemRecordCustody = defaultCustodyRecord();
    const systemRecordTrading = defaultWorkspaceRecord();
    const systemRecordPortfolio = defaultPortfolioRecord();
    const systemRecordPermissions = defaultPermissionsRecord();
    const systemRecordStrategies = defaultStrategiessRecord();
    const systemRecordTradingRfq = defaultRfqWorkspaceRecord();
    const systemRecordManageUsers = defaultManageUsersRecord();
    const systemRecordHedgerOrders = defaultHedgeOrdersRecord();
    const systemRecordManageOrders = defaultManageOrdersRecord();
    const systemRecordManageTokens = defaultManageTokensRecord();
    const systemRecordAdminExchange = defaultAdminExchangeRecord();
    const systemRecordManageLogbook = defaultManageLogbookRecord();
    const systemRecordRFQManagement = defaultRFQManagementRecord();
    const systemRecordCurrencyConfig = defaultCurrencyConfigRecord();
    const systemRecordManageCustomers = defaultManageCustomersRecord();
    const systemRecordManageSchedulers = defaultManageSchedulersRecord();
    const systemRecordManageCompliance = defaultManageComplianceRecord();
    const systemRecordManageWhitelisting = defaultManageWhitelistRecord();
    const systemRecordTradingTransfers = defaultTradingTransfersRecord();
    const systemRecordAutohedgerBalance = defaultAutohedgerBalanceRecord();
    const systemRecordRFQClientsManagement = defaultRFQClientsManagementRecord();
    const systemRecordManageComplianceTrading = defaultManageComplianceTradingRecord();
    const systemDashboards = new Map();
    systemDashboards.set('trading', systemRecordTrading);
    systemDashboards.set('tradingRfq', systemRecordTradingRfq);
    systemDashboards.set('custody', systemRecordCustody);
    systemDashboards.set('portfolio', systemRecordPortfolio);
    systemDashboards.set('permissions', systemRecordPermissions);
    systemDashboards.set('strategies', systemRecordStrategies);
    systemDashboards.set('manageUsers', systemRecordManageUsers);
    systemDashboards.set('placeOrders', systemRecordHedgerOrders);
    systemDashboards.set('manageOrders', systemRecordManageOrders);
    systemDashboards.set('manageTokens', systemRecordManageTokens);
    systemDashboards.set('quoteMonitor', systemRecordRFQManagement);
    systemDashboards.set('adminExchange', systemRecordAdminExchange);
    systemDashboards.set('manageLogbook', systemRecordManageLogbook);
    systemDashboards.set('currencyConfig', systemRecordCurrencyConfig);
    systemDashboards.set('manageCustomers', systemRecordManageCustomers);
    systemDashboards.set('manageSchedulers', systemRecordManageSchedulers);
    systemDashboards.set('manageCompliance', systemRecordManageCompliance);
    systemDashboards.set('tradingTransfers', systemRecordTradingTransfers);
    systemDashboards.set('manageWhitelist', systemRecordManageWhitelisting);
    systemDashboards.set('autohedgerBalance', systemRecordAutohedgerBalance);
    systemDashboards.set('clientsManagement', systemRecordRFQClientsManagement);
    systemDashboards.set('manageComplianceTrading', systemRecordManageComplianceTrading);
    // we need features for displaying all widgets even if an all-important request failed
    try {
        const clientData = yield select(getClient);
        const { features, masterTenant, customer_code } = clientData;
        allFeatures = features;
        isAdmin = masterTenant;
        customerCode = customer_code || '';
        if (customer_code) {
            const canReadDetails = features.includes('customer_details.read');
            const customer = yield call(fetchCustomerInfo, customer_code, canReadDetails);
            yield put({ type: CUSTOMER_SELECT_SET_UPDATE, customer });
        }
    }
    catch (e) {
        allFeatures = [];
        isAdmin = false;
        customerCode = '';
    }
    try {
        markets = yield call(fetchMarkets);
        if (isAdmin) {
            exchanges = yield call(fetchExchanges);
        }
    }
    catch (e) {
        markets = [];
        exchanges = [];
    }
    const canReadTransactions = allFeatures.includes('client_custody_transaction.read');
    const canReadWAddresses = allFeatures.includes('client_custody_withdrawal_address.read');
    const canReadWallets = allFeatures.includes('client_custody_wallet.read');
    const canReadReserves = allFeatures.includes('reserves.read');
    const canReadOrders = allFeatures.includes('order.read');
    const canReadUsers = allFeatures.includes('tenants.read');
    const canUpdateWithdrawals = allFeatures.includes('client_custody_transaction.update');
    const canSignAddresses = allFeatures.includes('client_custody_address_master_sign.create');
    const canUpdateAddresses = allFeatures.includes('client_custody_withdrawal_address.update');
    const canSignWithdrawals = allFeatures.includes('client_custody_withdrawal_master_sign.create');
    const canReadInternalTransfers = allFeatures.includes('internal_transfers.read');
    const restrictedCustomer = !!customerCode;
    const custodyFeatures = {
        admin: isAdmin,
        canReadWallets,
        canSignAddresses,
        canReadWAddresses,
        canUpdateAddresses,
        canSignWithdrawals,
        canReadTransactions,
        canUpdateWithdrawals,
        canReadInternalTransfers,
    };
    const tradingFeatures = {
        canReadUsers,
        canReadOrders,
        canReadReserves,
        restrictedCustomer,
    };
    try {
        if (action.type === types.WORKSPACE_FETCH_REQUEST) {
            const optionsExchange = getTradingExchanges(exchanges);
            const optionsBase = getTradingBases(markets);
            const optionsQuote = getTradingQuotes(markets);
            const response = yield call(fetchWorkspaces);
            const workspaceContainer = JSON.parse(response.settings);
            const record = systemDashboards.get(action.dashboard);
            if (action.dashboard === 'trading' && record) {
                record.widgetSettings.byId = loopSettings(systemRecordTrading.widgetSettings.byId, optionsExchange, optionsBase, optionsQuote, 'system');
            }
            systemRecordTradingRfq.widgetSettings.byId = loopSettings(systemRecordTradingRfq.widgetSettings.byId, optionsExchange, optionsBase, optionsQuote, 'system');
            yield put({ type: types.DEFAULT_WORKSPACE_UPDATE, workspace: record, activeDashboard: action.dashboard });
            // will apply system workspace
            if (!workspaceContainer.workspaces || workspaceContainer.workspaces.length === 0) {
                // filter by permissions
                if (record) {
                    record.widgets = action.dashboard === 'custody'
                        ? filterCustodyWidgets(record, custodyFeatures)
                        : action.dashboard === 'trading'
                            ? filterTradingWidgets(record, tradingFeatures)
                            : record.widgets;
                }
                else {
                    // potentially throw
                    return;
                }
                yield put({ type: SCHEMA_APPLY_WORKSPACE, workspaceRecord: record });
                yield put({ type: types.DASHBOARD_POPULATE, dashboards: defaultDashboards() });
                yield put({ type: types.WORKSPACE_FETCH_SUCCESS, workspaces: [systemRecordTradingRfq] });
            }
            else {
                const sRecord = systemDashboards.get(action.dashboard);
                let activeDashboard;
                // Set initial Null for fallback to system workspaces
                // in case dashboards are corrupt
                // TODO: find better solution
                let activeWorkspace = null;
                const defaultDashBoards = defaultDashboards();
                const dashboardsResponse = workspaceContainer.dashboards;
                let mergedDashboards = [];
                if (dashboardsResponse && dashboardsResponse.length) {
                    // sync with with system dashboards
                    mergedDashboards = defaultDashBoards.map((d) => {
                        const r = dashboardsResponse.find(c => c.name === d.name);
                        return r ? deepmerge(d, r) : d;
                    });
                    activeDashboard = mergedDashboards.find((d) => d.name === action.dashboard);
                    activeWorkspace = workspaceContainer.workspaces
                        .find((w) => w.id === (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.activeWorkspace));
                }
                else {
                    workspaceContainer.dashboards = defaultDashBoards;
                }
                // go through workspace widgets
                // and check if all system settings are in sync
                workspaceContainer.workspaces.forEach((w) => {
                    if (sRecord) {
                        mergeMissedSettings(w, sRecord.widgets, sRecord);
                    }
                });
                // Handles /trading
                // apply available markets: only trading
                if (action.dashboard === 'trading') {
                    const activeWorkspaceId = activeWorkspace ? activeWorkspace.id : '';
                    workspaceContainer.workspaces.forEach((w) => {
                        w.widgetSettings.byId = loopSettings(w.widgetSettings.byId, optionsExchange, optionsBase, optionsQuote, activeWorkspaceId);
                    });
                }
                if (activeWorkspace) {
                    // only for '/trading
                    if (action.dashboard === 'trading') {
                        activeWorkspace.widgets = filterTradingWidgets(activeWorkspace, tradingFeatures);
                    }
                    // only for 'custody'
                    if (action.dashboard === 'custody') {
                        activeWorkspace.widgets = filterCustodyWidgets(activeWorkspace, custodyFeatures);
                    }
                    yield put({ type: SCHEMA_APPLY_WORKSPACE, workspaceRecord: activeWorkspace });
                }
                else if (sRecord) {
                    // falls back to system workspace record
                    if (action.dashboard === 'trading') {
                        sRecord.widgets = filterTradingWidgets(sRecord, tradingFeatures);
                    }
                    if (action.dashboard === 'custody') {
                        sRecord.widgets = filterCustodyWidgets(sRecord, custodyFeatures);
                    }
                    if ((activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.activeWorkspace) === 'systemRfq') {
                        const rfqRecord = systemRecordTradingRfq;
                        rfqRecord.widgets = filterTradingWidgets(rfqRecord, tradingFeatures);
                        yield put({ type: SCHEMA_APPLY_WORKSPACE, workspaceRecord: rfqRecord });
                    }
                    else {
                        yield put({ type: SCHEMA_APPLY_WORKSPACE, workspaceRecord: sRecord });
                    }
                }
                else {
                    const message = 'Could not found any workspace';
                    yield put({ type: OPEN_ERROR_NOTICE, message });
                }
                yield put({ type: types.DASHBOARD_POPULATE, dashboards: mergedDashboards });
                yield put({
                    type: types.WORKSPACE_FETCH_SUCCESS,
                    workspaces: [...workspaceContainer.workspaces],
                });
            }
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.LAYOUT_SCHEMA_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.WORKSPACE_FETCH_FAIL });
    }
}
function* updateWorkspaces(action) {
    try {
        if (action.type === types.WORKSPACE_UPDATE_REQUEST) {
            yield call(updateWorkspace, action.payload);
            if (action.workspaceRecord) {
                yield put({ type: SCHEMA_APPLY_WORKSPACE, workspaceRecord: action.workspaceRecord });
            }
            yield put({ type: types.WORKSPACE_UPDATE_SUCCESS });
            if (action.notificationText) {
                showNotification({
                    message: `Workspace successfully ${action.notificationText}`,
                    color: 'success',
                    dispatch: action.dispatchCloseErrorNotice,
                });
            }
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.LAYOUT_SCHEMA_SAVE);
        yield put({ type: OPEN_ERROR_NOTICE, message });
    }
}
export const workspacesSaga = [
    takeLatest(types.WORKSPACE_FETCH_REQUEST, fetchAllWorkspaces),
    takeLatest(types.WORKSPACE_UPDATE_REQUEST, updateWorkspaces),
];
