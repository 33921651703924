import React from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
const ConfirmDeleteCardModal = ({ open, toggleFunc, pair, removeCard, }) => {
    const handleRemove = () => {
        removeCard();
    };
    return (React.createElement(Dialog, { open: open, maxWidth: "sm" },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Are you sure?"),
        React.createElement(DialogContent, null,
            "You are going to remove the card with this selected pair:",
            React.createElement("p", null, pair.toUpperCase())),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: handleRemove }, "Remove"),
            React.createElement(Button, { size: "small", variant: "outlined", onClick: toggleFunc }, "Cancel"))));
};
export default ConfirmDeleteCardModal;
