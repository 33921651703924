/* eslint-disable no-nested-ternary, jsx-a11y/anchor-is-valid, react/jsx-indent */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TableCell from '@mui/material/TableCell';
import DialogTitle from '@mui/material/DialogTitle';
import { useResizeDetector } from 'react-resize-detector';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AmlCheck from './AmlCheck';
import WalletNote from './components/TransactionsActions';
import { TransfersCellItem } from '../../../../Custody/styling/style';
import { getExplorerURL } from '../../../../../../shared/helpers/getExplorerURL';
const TxHashCell = ({ item, value, currencies }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (!value) {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement("span", null, "N/A")));
    }
    const expUrl = getExplorerURL(item.currency_code, currencies);
    // const tXHashVal = `${value.slice(0, 4)}...${value.slice(value.length - 4, value.length)}`;
    return (React.createElement(TableCell, { align: "right", ref: ref }, expUrl !== '' ? (React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
        React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: `${expUrl}${value}`, className: "custody_hash_link" }, value))) : (React.createElement("span", null, value))));
};
const Main = ({ value, columnKey, item, loading, currencies, }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const toggleDialog = (e) => {
        e && e.preventDefault();
        toggleOpenDialog(prev => !prev);
    };
    if (Array.isArray(value)) {
        return (React.createElement(TableCell, { align: "right" }, "N/A"));
    }
    if (columnKey === 'created_at') {
        const d = value ? new Date(value) : null;
        const dateToRender = d ? `${d === null || d === void 0 ? void 0 : d.toLocaleDateString()}, ${d.toLocaleTimeString()}` : 'N/A';
        return (React.createElement(TableCell, { align: "right" }, dateToRender));
    }
    if (columnKey === 'note') {
        return React.createElement(WalletNote, { entityCode: value });
    }
    if (columnKey === 'amount_usd') {
        return (React.createElement(TableCell, { align: "right" }, loading ? 'loading..' : value));
    }
    if (columnKey === 'tx_hash') {
        return React.createElement(TxHashCell, { item: item, value: value, currencies: currencies });
    }
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left" },
            value
                ? (React.createElement("a", { href: "#", onClick: e => toggleDialog(e) }, value))
                : 'N/A',
            React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "md", fullWidth: true },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
                React.createElement(DialogContent, null,
                    React.createElement(AmlCheck, { item: item })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: () => toggleDialog() }, "Close")))));
    }
    if (columnKey === 'risk_score') {
        return (React.createElement(TableCell, { align: "right", className: `${value && +value <= 0 ? 'transactions_risk_score' : ''}` }, value || 'N/A'));
    }
    return (React.createElement(TableCell, { align: "right", className: `${columnKey === 'currency_code' ? 'text_transform_uppercase' : ''}` }, value || 'N/A'));
};
export default Main;
