import React, { useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect } from 'react-redux';
import ClientBalancesContent from './ClientBalancesContent';
const memoOpenItems = (opentItems) => opentItems;
const ClientBalances = ({ balances, hideZero, clientInfo, currencies, balanceOpenItems, }) => {
    const items = useMemo(() => memoOpenItems(balanceOpenItems), [balanceOpenItems]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, { scope: "col" }, "Currency"),
                React.createElement(TableCell, { scope: "col" }, "Available"),
                React.createElement(TableCell, { scope: "col" }, "Locked"),
                React.createElement(TableCell, { scope: "col" }, "Total"),
                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
        React.createElement(TableBody, null,
            React.createElement(ClientBalancesContent, { balances: balances, hideZero: hideZero, currencies: currencies, clientInfo: clientInfo, balanceOpenItems: items }))));
};
const stateToProps = (state) => ({
    currencies: state.placeOrder.currencies,
    balanceOpenItems: state.client.balanceOpenItems,
});
export default connect(stateToProps)(ClientBalances);
