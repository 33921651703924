import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import Permissions from '../AdminSidebar/Permissions';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/clientActionTypes';
const clientInfoFetch = () => ({
    type: CLIENT_INFO_FETCH_REQUEST,
});
class SidebarContent extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.componentDidMount = () => {
            const { dispatchLoadClientInfo } = this.props;
            let isCancelled = false;
            if (!isCancelled) {
                dispatchLoadClientInfo();
            }
            return () => {
                isCancelled = true;
            };
        };
    }
    render() {
        const { clientInfo, features, masterTenant } = this.props;
        if (!clientInfo) {
            return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                React.createElement(CircularProgress, { size: "33px" })));
        }
        return (React.createElement("div", { className: "sidebar__content" },
            React.createElement("ul", { className: "sidebar__block" },
                React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.read'] },
                    React.createElement(SidebarLink, { exact: true, title: "Wallets", route: "/custody" })),
                React.createElement(Permissions, { features: features, permissions: ['client_custody_withdrawal_address.read'] },
                    React.createElement(SidebarLink, { title: "Whitelist", route: "/custody/whitelist" })),
                React.createElement(Permissions, { features: features, permissions: ['client_custody_withdrawal_address.read'] },
                    React.createElement(SidebarLink, { title: "Approvals", route: "/custody/signature" }))),
            masterTenant ? (React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                React.createElement("ul", { className: "sidebar__block" },
                    React.createElement(SidebarLink, { title: "Admin", route: "/custody/admin" })))) : null));
    }
}
const mapStateToProps = (state) => {
    const { clientInfo, features, masterTenant } = state.client;
    return {
        features,
        clientInfo,
        masterTenant,
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchLoadClientInfo: () => dispatch(clientInfoFetch()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
