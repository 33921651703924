import React, { useState, useEffect, useRef } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import { Box, Button, TableRow, TableCell, Collapse, Chip, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Status } from './RfqModels';
import TableCellContainer from './TableCellContainer';
import Subscriber from '../../../../../../shared/helpers/subscriber';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import { accessMap } from './accessMap';
import { useTheme } from '@mui/styles';
const getOrderBook = (data, channel) => {
    const { payload } = data;
    // Narrow down to OrderBookData type
    // or return undefined
    if ('total_ask' in payload) {
        if (channel) {
            return {
                [channel]: {
                    hit_bid: payload.hit_bid,
                    lift_ask: payload.lift_ask,
                },
            };
        }
        return {
            hit_bid: payload.hit_bid,
            lift_ask: payload.lift_ask,
        };
    }
    return undefined;
};
const QuoteMonitorItem = ({ setting, columnsToRender, updateRfqSetting, deleteRfqSetting, features, selectedClient, customers, assigned, access, clients, onClientSelect, onManageAccess, }) => {
    var _a;
    const refId = useRef(shortid.generate());
    const [notional, setNotional] = useState('');
    const [bidPrice, setBidPrice] = useState('');
    const [askPrice, setAskPrice] = useState('');
    const [refPrice, setRefPrice] = useState('');
    const [usedPrice, setUsedPrice] = useState('');
    const [showAccessItems, setShowAccessItems] = useState(false);
    const [accessItems, setAccessItems] = useState();
    const [orderBookSingle, setOrderBookSingle] = useState(null);
    const [isCurrentClientAssigned, setIsCurrentClientAssigned] = useState(false);
    const theme = useTheme();
    const setUpdatesOrderBookSingle = (data) => {
        const orderBook = getOrderBook(data, data.channel);
        setOrderBookSingle((prevItems) => (Object.assign(Object.assign({}, prevItems), orderBook)));
    };
    const handleShowAccessItems = () => {
        setShowAccessItems(!showAccessItems);
    };
    useEffect(() => {
        const assigned = access.findIndex((a) => a.clientCode === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value)) > -1;
        setIsCurrentClientAssigned(assigned);
    }, [selectedClient, access]);
    useEffect(() => {
        let accessMapped;
        if (access === null || access === void 0 ? void 0 : access.length) {
            accessMapped = accessMap(access, customers || [], clients || [], selectedClient);
        }
        setAccessItems(accessMapped);
    }, [access, customers]);
    useEffect(() => {
        const { OrderBookWS } = window;
        const singleOrderBook = new Subscriber(setUpdatesOrderBookSingle, refId.current, 200);
        const channel = `orderbook-simple-${setting.exchange_code.toLowerCase()}-${setting.base}/${setting.quote}`;
        OrderBookWS.addSubscription(channel, singleOrderBook);
        // Cleanup all subscriptions
        return () => {
            OrderBookWS.removeSubscription(channel, refId.current);
        };
    }, [setting]);
    useEffect(() => {
        const channel = `orderbook-simple-${setting.exchange_code.toLowerCase()}-${setting.base}/${setting.quote}`;
        if (orderBookSingle && orderBookSingle[channel]) {
            setBidPrice(orderBookSingle[channel].hit_bid);
            setAskPrice(orderBookSingle[channel].lift_ask);
        }
    }, [orderBookSingle, setting]);
    useEffect(() => {
        if (askPrice && bidPrice) {
            const price = new Decimal(new Decimal(askPrice || 0).plus(bidPrice || 0)).div(2).toFixed(2);
            setRefPrice(price);
        }
    }, [askPrice, bidPrice]);
    useEffect(() => {
        if (refPrice) {
            setNotional(new Decimal(refPrice || 0).mul(setting.guaranteed_qty || 0).toFixed(2));
            const price = new Decimal(refPrice || 0)
                .mul(new Decimal(1).plus(new Decimal(setting.ul_shift_bps || 0).div(10000)))
                .toFixed(2);
            setUsedPrice(price);
        }
    }, [refPrice, setting]);
    const handleClientSelect = (code) => {
        onClientSelect(code);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { role: 'checkbox', tabIndex: -1, key: setting.settings_code },
            React.createElement(React.Fragment, null,
                React.createElement(TableCell, { align: 'left', sx: { borderLeft: isCurrentClientAssigned ? `4px solid ${theme.palette.primary.main}` : 'none' } },
                    React.createElement(Permissions, { features: features, permissions: [assigned === Status.ASSIGNED ? 'client_rfq_settings.delete' : 'client_rfq_settings.create'] },
                        React.createElement(Box, { display: 'flex', gap: 1, p: 1, onClick: () => handleShowAccessItems(), sx: { cursor: 'pointer' } },
                            showAccessItems ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null),
                            React.createElement(Badge, { badgeContent: accessItems === null || accessItems === void 0 ? void 0 : accessItems.clients.length, color: (accessItems === null || accessItems === void 0 ? void 0 : accessItems.clients.length) ? 'primary' : 'default' },
                                React.createElement(AccountCircleIcon, { color: ((_a = accessItems === null || accessItems === void 0 ? void 0 : accessItems.clients) === null || _a === void 0 ? void 0 : _a.length) ? 'action' : 'disabled' })))))),
            columnsToRender.map((column, i) => {
                const key = column.key;
                const value = key ? setting[key] : '';
                return (React.createElement(TableCellContainer, { key: i, value: value, item: setting, columnKey: key, columnName: columnsToRender[i].title || '', features: features, refPrice: refPrice, notional: notional, usedPrice: usedPrice, updateRfqSetting: updateRfqSetting }));
            }),
            React.createElement(TableCell, { align: 'right' }, setting.status !== 'pendingDelete' ? (React.createElement(Permissions, { features: features, permissions: ['client_rfq_settings.delete'] },
                React.createElement(Button, { size: 'small', variant: 'outlined', onClick: () => deleteRfqSetting(setting.settings_code) }, "delete"))) : null)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { colSpan: columnsToRender.length + 2, style: { padding: 0, borderBottom: 0 } },
                React.createElement(Collapse, { in: showAccessItems, unmountOnExit: true, key: setting.id },
                    React.createElement(Box, { display: 'flex', p: 2, gap: 2 },
                        React.createElement(Box, { display: 'flex', gap: 2 },
                            (accessItems === null || accessItems === void 0 ? void 0 : accessItems.clients.length) ? (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1, justifyItems: 'flex-start' },
                                "Assigned to Clients:", accessItems === null || accessItems === void 0 ? void 0 :
                                accessItems.clients.map((item, index) => (React.createElement(Chip, { key: index, label: `${item.name} (${item.customersCount})`, size: 'small', onClick: () => handleClientSelect(item.code), color: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) === item.code ? 'primary' : 'default' }))))) : null,
                            (accessItems === null || accessItems === void 0 ? void 0 : accessItems.customers.length) ? (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                                "Assigned to Customers:", accessItems === null || accessItems === void 0 ? void 0 :
                                accessItems.customers.map((item, index) => (React.createElement(Chip, { key: index, label: item.name, size: 'small' }))))) : null,
                            (accessItems === null || accessItems === void 0 ? void 0 : accessItems.otherCustomers.length) ? (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                                "Assigned to Customers without a client assigned:", accessItems === null || accessItems === void 0 ? void 0 :
                                accessItems.otherCustomers.map((item, index) => (React.createElement(Chip, { key: index, label: item.name, size: 'small' }))))) : null),
                        React.createElement(Box, { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' },
                            React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => onManageAccess() }, "Manage access"))))))));
};
export default QuoteMonitorItem;
