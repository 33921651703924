import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { connect } from 'react-redux';
import recordsDependsOnPage from '../../../../../shared/helpers/recordsDependsOnPage';
const CustomerSearchContent = ({ loading, handleSelect, features, workingCustomers, page, }) => {
    if (loading)
        return React.createElement(TableRow, null,
            React.createElement(TableCell, { colSpan: 9 }, "Loading.."));
    const custumersDependOnPage = recordsDependsOnPage(workingCustomers, page + 1);
    const renderCustomers = (customer, customerCode) => (React.createElement(TableRow, { role: "button", tabIndex: -42, key: customer.code, onKeyDown: () => undefined, onClick: () => handleSelect(customer) }, features.includes('customer_details.read')
        ? (React.createElement(React.Fragment, null,
            React.createElement(TableCell, null,
                React.createElement("div", null, customer.company_name)),
            React.createElement(TableCell, null,
                React.createElement("span", null, customer.bfid)),
            React.createElement(TableCell, null,
                React.createElement("span", null, customerCode)))) : (React.createElement(TableCell, null, customer.code))));
    return (React.createElement(React.Fragment, null, custumersDependOnPage.map((customer) => {
        const codeLength = customer.code.length;
        const customerCode = `${customer.code.slice(0, 4)}...${customer.code.slice(codeLength - 4, codeLength)}`;
        const renderData = renderCustomers(customer, customerCode);
        return renderData;
    })));
};
const stateToProps = (state) => {
    const { features } = state.client;
    const { loading, workingCustomers } = state.customersSearch;
    return {
        loading,
        features,
        workingCustomers,
    };
};
export default connect(stateToProps)(CustomerSearchContent);
