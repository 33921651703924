import React, { useEffect, useState, useMemo } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import { Box, Table, TableRow, TableCell, TableHead, TableBody, IconButton, TableContainer } from '@mui/material';
import { bindActionCreators } from 'redux';
import { NumericFormat } from 'react-number-format';
import { useResizeDetector } from 'react-resize-detector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux';
import TradeWithdrawal from './BalancesActions';
import { TransfersCellItem } from '../../../Custody/styling/style';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import getDecimalsByCurrencyCode from '../../../../../config/currencyDecimal';
import CustomerBalancesCellActions from './CustomerBalancesCellActions';
import { collapseCustomerBalancesItemClose, collapseCustomerBalancesItemOpen, } from '../../../../../redux/actions/clientActions';
import CreateSettlement from './CustomerBalancesActions';
/* eslint-disable camelcase */
const envSettings = getEnvSettings();
const memoOpenItems = (opentItems) => opentItems;
const mappedTypes = {
    virtual_wallet: 'virtual',
    trading_wallet: 'wallet',
};
export const BalanceAccountsItem = ({ acc, actions, item, clientInfo, masterTenant, }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(100);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 10);
        }
    }, [width]);
    return (React.createElement(TableRow, { key: acc.code },
        React.createElement(TableCell, { scope: 'col', ref: ref },
            React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
                React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
                    React.createElement(Box, null, acc.code)),
                React.createElement(Box, null, acc.code ? React.createElement(CopyValue, { value: acc.code }) : null))),
        React.createElement(TableCell, { scope: 'col' }, acc.available),
        React.createElement(TableCell, { scope: 'col' }, acc.locked),
        React.createElement(TableCell, { scope: 'col' }, acc.total),
        React.createElement(TableCell, { scope: 'col' }, mappedTypes[acc.type]),
        React.createElement(CustomerBalancesCellActions, { item: item, acc: acc, actions: actions, clientInfo: clientInfo, masterTenant: masterTenant })));
};
const CustomerBalanceItem = ({ balanceItem, currencies, clientInfo, customer_code, dispatchUpdateCollapseOpen, dispatchUpdateCollapseClose, customerBalanceOpenItems, masterTenant, }) => {
    const items = useMemo(() => memoOpenItems(customerBalanceOpenItems), [customerBalanceOpenItems]);
    const decimals = getDecimalsByCurrencyCode('USD', currencies) || 2;
    Decimal.set({ rounding: Decimal.ROUND_DOWN });
    const available = balanceItem.is_fiat ? new Decimal(balanceItem.available).toFixed(decimals) : balanceItem.available;
    const locked = balanceItem.is_fiat ? new Decimal(balanceItem.locked).toFixed(decimals) : balanceItem.locked;
    const total = balanceItem.is_fiat ? new Decimal(balanceItem.total).toFixed(decimals) : balanceItem.total;
    Decimal.set({ rounding: Decimal.ROUND_UP });
    const cellActions = {
        actions: {
            withdrawal: () => undefined,
        },
        actionsComponents: {
            withdrawal: TradeWithdrawal,
        },
        actionsList: [{ name: 'Withdrawal', action: 'withdrawal' }],
    };
    const collapsedCellActions = {
        actions: {
            createSettlement: () => undefined,
        },
        actionsComponents: {
            createSettlement: CreateSettlement,
        },
        actionsList: [{ name: 'Create Settlement', action: 'createSettlement' }],
    };
    const isItemOpen = () => {
        const openedItem = items.find((i) => i === balanceItem.name);
        return openedItem;
    };
    const Icon = () => {
        if (isItemOpen()) {
            return React.createElement(ExpandLessIcon, null);
        }
        return React.createElement(ExpandMoreIcon, null);
    };
    const onItemClick = () => {
        if (!isItemOpen()) {
            dispatchUpdateCollapseOpen(balanceItem.name);
        }
        else {
            dispatchUpdateCollapseClose(balanceItem.name);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { key: shortid.generate() },
            React.createElement(TableCell, null,
                React.createElement(Box, { display: 'flex', flexDirection: 'row', onClick: onItemClick },
                    React.createElement(Box, { marginTop: '5px' }, balanceItem.code),
                    balanceItem.accounts && balanceItem.accounts.length > 1 ? (React.createElement(Box, null,
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(Icon, null)))) : null)),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: available, displayType: 'text', thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: locked, displayType: 'text', thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: total, displayType: 'text', thousandSeparator: envSettings.thousandDivider })),
            customer_code ? (React.createElement(CustomerBalancesCellActions, { item: balanceItem, actions: cellActions, clientInfo: clientInfo, masterTenant: masterTenant })) : null),
        isItemOpen() && balanceItem.accounts && balanceItem.accounts.length > 1 ? (React.createElement(TableRow, { key: shortid.generate() },
            React.createElement(TableCell, { colSpan: 5 },
                React.createElement(TableContainer, null,
                    React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { scope: 'col' }, "Code"),
                                React.createElement(TableCell, { scope: 'col' }, "Available"),
                                React.createElement(TableCell, { scope: 'col' }, "Locked"),
                                React.createElement(TableCell, { scope: 'col' }, "Total"),
                                React.createElement(TableCell, { scope: 'col' }, "Type"),
                                React.createElement(TableCell, { scope: 'col' }, "Actions"))),
                        React.createElement(TableBody, null, balanceItem.accounts && balanceItem.accounts.length
                            ? balanceItem.accounts.map((acc) => (React.createElement(BalanceAccountsItem, { key: acc.code, acc: acc, item: balanceItem, clientInfo: clientInfo, masterTenant: masterTenant, actions: collapsedCellActions })))
                            : null)))))) : null));
};
const stateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    currencies: state.placeOrder.currencies,
    customerBalanceOpenItems: state.client.customerBalanceOpenItems,
    masterTenant: state.client.masterTenant,
});
const dispatchToProps = (dispatch) => ({
    dispatchUpdateCollapseOpen: bindActionCreators(collapseCustomerBalancesItemOpen, dispatch),
    dispatchUpdateCollapseClose: bindActionCreators(collapseCustomerBalancesItemClose, dispatch),
});
export default connect(stateToProps, dispatchToProps)(CustomerBalanceItem);
