import * as types from '../actionTypes/customerSearchActionsTypes';
const initialState = {
    customers: [],
    total: '0',
    workingCustomers: [],
    workingTotal: '0',
    loading: false,
    selectedCustomer: null,
    error: '',
    pageOfItemsCustomerSearch: 0,
    searchString: '',
};
/* eslint-disable @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-return */
const customerSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTOMER_SEARCH_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { loading: true });
        }
        case types.CUSTOMER_SEARCH_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { customers: action.customers, total: action.total, workingCustomers: action.customers, workingTotal: action.total, loading: false });
        }
        case types.CUSTOMER_SEARCH_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message, loading: false });
        }
        case types.CUSTOMER_SELECT_SET_UPDATE: {
            return Object.assign(Object.assign({}, state), { selectedCustomer: action.customer });
        }
        case types.WORKING_CUSTOMER_SEARCH_SET_UPDATE: {
            return Object.assign(Object.assign({}, state), { workingCustomers: action.workingCustomers, workingTotal: action.workingTotal });
        }
        case types.CUSTOMER_SEARCH_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsCustomerSearch: action.pageOfItemsCustomerSearch });
        case types.CUSTOMER_WIDGET_SEARCH_STRING_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { searchString: action.searchString });
        default:
            return state;
    }
};
export default customerSearchReducer;
