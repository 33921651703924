import React from 'react';
import { Chip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const SidebarLink = ({ title, icon, newLink = false, route, onClick, style, exact, }) => (React.createElement(NavLink, { to: route, exact: exact, onClick: onClick, activeClassName: "sidebar__link-active" },
    React.createElement("li", { className: "sidebar__link", style: style },
        icon ? (React.createElement(FontAwesomeIcon, { icon: icon, className: "sidebar_category_fa_styles" })) : '',
        React.createElement("p", { className: "sidebar__link-title" },
            title,
            newLink ? React.createElement(Chip, { size: "small", color: "default", label: React.createElement("span", null, "New") }) : ''))));
SidebarLink.defaultProps = {
    style: undefined,
    icon: undefined,
    newLink: false,
    exact: false,
    onClick: () => undefined,
};
export default SidebarLink;
