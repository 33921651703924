import React, { useReducer, useEffect } from 'react';
import moment from 'moment';
import buildUrl from 'build-url';
import DeoisitFiltersContent from './DepositFiltersContent';
import { collectParamsFromFilters } from '../../../../../shared/helpers/createUrlFromParams';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["UPDATE"] = "UPDATE";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
const init = () => ({
    filters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { filters: [...state.filters, action.item] };
        case FilterActionType.DELETE:
            return {
                filters: state.filters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.UPDATE: {
            const objIndex = state.filters
                .findIndex((obj => obj.name === action.item.name));
            const newArr = state.filters;
            newArr[objIndex].value = action.item.value;
            newArr[objIndex].label = action.item.label;
            return { filters: newArr };
        }
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const DepositFilters = ({ page, sortBy, clientCode, selectedClient, rowsPerPage, initialValue, allCurrencies, sortDirection, setCurrentPage, onFiltersChange, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const addSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const updateSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.UPDATE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const handleDateChange = (newDate, label, name) => {
        const item = {
            label,
            name,
            type: 'date',
            value: moment(newDate).isValid() ? moment(newDate).format() : '',
        };
        const isFilter = state.filters.find(f => f.name === name);
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleCurrencyChange = (option) => {
        const item = {
            name: 'currency_code',
            type: 'currency',
            label: option ? option.value : '',
            value: option ? option.value.toLowerCase() : '',
        };
        const isFilter = state.filters.find(f => f.name === 'currency_code');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleStateChange = (options, reason, details) => {
        if (reason === 'selectOption') {
            const last = options && options[options.length - 1];
            const item = {
                name: 'state',
                type: 'state',
                label: (last === null || last === void 0 ? void 0 : last.value) || '',
                value: (last === null || last === void 0 ? void 0 : last.value) || '',
            };
            addSelectedFilter(item);
        }
        else if (reason === 'removeOption') {
            const item = {
                name: 'state',
                type: 'state',
                label: (details === null || details === void 0 ? void 0 : details.option.label) || '',
                value: (details === null || details === void 0 ? void 0 : details.option.value) || '',
            };
            removeSelectedFilter(item);
        }
        else if (reason === 'clear') {
            const isStates = state.filters.filter(f => f.name === 'state');
            if (isStates.length > 0) {
                isStates.forEach((f) => {
                    removeSelectedFilter(f);
                });
            }
        }
    };
    const buildUrlFromParams = (params, by, curentPage, direction, limit, code) => {
        const paramsNames = Object.keys(params);
        const urlOptions = {
            queryParams: {
                sort_by: by,
                clientCode: code,
                limit: `${limit}`,
                page: `${curentPage + 1}`,
                sort_direction: direction,
            },
        };
        paramsNames.forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 1) {
                    urlOptions.queryParams[key] = params[key].join('');
                }
                else {
                    urlOptions.queryParams[key] = params[key].join('|');
                }
            }
        });
        return buildUrl('', urlOptions);
    };
    useEffect(() => {
        setCurrentPage(0);
    }, [state]);
    useEffect(() => {
        resetFilters();
    }, [selectedClient]);
    useEffect(() => {
        const buildUrlOptions = () => {
            const collectedParams = collectParamsFromFilters(state.filters);
            const adminFilterUrl = buildUrlFromParams(collectedParams, sortBy, page, sortDirection, rowsPerPage, clientCode);
            return adminFilterUrl;
        };
        const url = buildUrlOptions();
        onFiltersChange(url);
    }, [state, page, selectedClient, sortBy, sortDirection, rowsPerPage, clientCode]);
    return (React.createElement(DeoisitFiltersContent, { state: state, resetFilters: resetFilters, allCurrencies: allCurrencies, handleDateChange: handleDateChange, handleStateChange: handleStateChange, removeSelectedFilter: removeSelectedFilter, handleCurrencyChange: handleCurrencyChange }));
};
export default DepositFilters;
