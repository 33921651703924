import React from 'react';
import ClientBalanceItem from './ClientBalanceItem';
const ClientBalancesContent = ({ balances, hideZero, clientInfo, currencies, balanceOpenItems, }) => {
    if (hideZero) {
        return (React.createElement(React.Fragment, null, balances.map((balanceItem, i) => {
            if (balanceItem.available === '0') {
                return null;
            }
            return (React.createElement(ClientBalanceItem, { key: i, currencies: currencies, clientInfo: clientInfo, balanceItem: balanceItem, balanceOpenItems: balanceOpenItems }));
        })));
    }
    return (React.createElement(React.Fragment, null, balances.map((balanceItem, i) => (React.createElement(ClientBalanceItem, { key: i, currencies: currencies, clientInfo: clientInfo, balanceItem: balanceItem, balanceOpenItems: balanceOpenItems })))));
};
export default ClientBalancesContent;
