import React, { useEffect, Fragment, useState, useCallback, } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { CUSTOMER_SEARCH_PAGE_OF_ITEMS_SET_UPDATE, WORKING_CUSTOMER_SEARCH_SET_UPDATE, CUSTOMER_WIDGET_SEARCH_STRING_SET_UPDATE, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import SearchInput from './SearchInput';
import ClientModal from '../ClientSearch/ClientModal';
import CustomerDetails from './CustomerDetails';
import CustomersSearchResults from './CustomerSearchResults';
import Messages from '../../../../../shared/helpers/errorMessages';
import CustomersService from '../../../../../services/customersService';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { selectCustomer } from '../../../../../redux/actions/customersSearchActions';
import filterByObjectValues from '../../../../../shared/helpers/filterByObjectValues';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getCustomersSearch } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_INFO_FETCH_REQUEST, CUSTOMER_INFO_CLEAN } from '../../../../../redux/actionTypes/clientActionTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const CustomerSearch = ({ features, customers, appCustomer, searchString, workingTotal, pageOfItemsCustomerSearch, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [customerInfo, setUpdateCustomer] = useState(null);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const createCustomer = (payload, setErr) => {
        const service = new CustomersService({
            url: '/customers',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().apiUrl,
        });
        service.makeRequest()
            .then(() => {
            showNotification({
                message: 'Customer successfully created',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_CREATE);
            setErr(message);
        });
    };
    const updateCustomer = (payload, setErr) => {
        if (!appCustomer) {
            return;
        }
        const requestConfig = {
            url: `/customers/${appCustomer === null || appCustomer === void 0 ? void 0 : appCustomer.code}`,
            method: 'patch',
            data: payload,
            baseURL: getEnvSettings().apiUrl,
        };
        axios.request(requestConfig)
            .then((response) => {
            setUpdateCustomer(Object.assign(Object.assign({}, response.data), { client_code: appCustomer.client_code }));
            dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: response.data.code });
            showNotification({
                message: 'Customer successfully updated',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_CREATE);
            setErr(message);
        });
    };
    const removeSelectedCustomer = () => {
        dispatch({ type: CUSTOMER_WIDGET_SEARCH_STRING_SET_UPDATE, searchString: '' });
        const newCustomersArrFiltered = filterByObjectValues(customers, '');
        dispatch({
            type: WORKING_CUSTOMER_SEARCH_SET_UPDATE,
            workingCustomers: newCustomersArrFiltered,
            workingTotal: newCustomersArrFiltered.length,
        });
        dispatch(selectCustomer(null));
        dispatch({ type: CUSTOMER_INFO_CLEAN });
    };
    const handleCustomerSelect = (customer) => {
        dispatch(selectCustomer(customer));
        dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: customer.code });
    };
    const handleSearchChange = (evt) => {
        const { value } = evt.target;
        dispatch({ type: CUSTOMER_WIDGET_SEARCH_STRING_SET_UPDATE, searchString: value });
        const newCustomersArrFiltered = filterByObjectValues(customers, value);
        dispatch({
            type: WORKING_CUSTOMER_SEARCH_SET_UPDATE,
            workingCustomers: newCustomersArrFiltered,
            workingTotal: newCustomersArrFiltered.length,
        });
    };
    const onChangePage = (event, page) => {
        if (page) {
            dispatch({ type: CUSTOMER_SEARCH_PAGE_OF_ITEMS_SET_UPDATE, pageOfItemsCustomerSearch: page });
        }
    };
    const editCustomer = () => {
        setModalOpen(true);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        if (features) {
            const canReadDetails = features.includes('customer_details.read');
            const customerPersisted = canReadDetails ? appCustomer : null;
            dispatch(selectCustomer(customerPersisted));
        }
    }, [features]);
    useEffect(() => {
        setUpdateCustomer(appCustomer);
    }, [appCustomer]);
    return (React.createElement(Fragment, null,
        modalOpen ? (React.createElement(ClientModal, { user: "customer", currentUser: customerInfo, open: modalOpen, toggleFunc: toggleModal, updateClient: updateCustomer, createClient: createCustomer })) : null,
        customerInfo
            ? (React.createElement(CustomerDetails, { customer: customerInfo, onButtonClick: removeSelectedCustomer, onEditClick: editCustomer }))
            : (React.createElement(Fragment, null,
                React.createElement(SearchInput, { features: features, toggleModal: toggleModal, searchString: searchString, onChange: handleSearchChange }),
                React.createElement(CustomersSearchResults, { onChangePage: onChangePage, workingTotal: workingTotal, handleSelect: handleCustomerSelect, pageOfItemsCustomerSearch: pageOfItemsCustomerSearch })))));
};
const stateToProps = (state) => {
    const { loading, searchString, workingTotal, selectedCustomer, pageOfItemsCustomerSearch, } = state.customersSearch;
    const { features } = state.client;
    let customers = [];
    if (!loading) {
        customers = getCustomersSearch(state);
    }
    return {
        loading,
        features,
        customers,
        searchString,
        workingTotal,
        pageOfItemsCustomerSearch,
        appCustomer: selectedCustomer,
    };
};
export default connect(stateToProps)(CustomerSearch);
