import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout';
import MainContainer from '../../../Dashboards/MasterTenant/index';
import Exchanges from '../../../Dashboards/MasterTenant/components/Exchanges/main';
import Compliance from '../../../Dashboards/MasterTenant/components/Compliance/main';
import StrategiesDashboard from '../../../Dashboards/MasterTenant/components/DeFi/main';
import RFQManagement from '../../../Dashboards/MasterTenant/components/RFQ/Streams/main';
import CurrencyConfig from '../../../Dashboards/MasterTenant/components/CurrencyConfig/main';
import HedgeOrdersDashboard from '../../../Dashboards/MasterTenant/components/PlaceOrder/index';
import ApiSchedulersContainer from '../../../Dashboards/MasterTenant/components/Schedulers/main';
import AutohedgerBalance from '../../../Dashboards/MasterTenant/components/AutohedgerBalance/main';
import ComplianceTrading from '../../../Dashboards/MasterTenant/components/Compliance/mainTrading';
import Settlements from '../../../Dashboards/MasterTenant/components/Settlements/main';
export default () => (React.createElement("div", null,
    React.createElement(Layout, { sidebar: "mastertenant" },
        React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: "/mastertenant", component: MainContainer }),
            React.createElement(Route, { path: "/mastertenant/exchanges", component: Exchanges }),
            React.createElement(Route, { exact: true, path: "/mastertenant/rfq", component: RFQManagement }),
            React.createElement(Route, { path: "/mastertenant/compliance-custody", component: Compliance }),
            React.createElement(Route, { path: "/mastertenant/currency-config", component: CurrencyConfig }),
            React.createElement(Route, { path: "/mastertenant/risk-manager", component: AutohedgerBalance }),
            React.createElement(Route, { path: "/mastertenant/strategies", component: StrategiesDashboard }),
            React.createElement(Route, { path: "/mastertenant/place-order", component: HedgeOrdersDashboard }),
            React.createElement(Route, { path: "/mastertenant/schedulers", component: ApiSchedulersContainer }),
            React.createElement(Route, { path: "/mastertenant/compliance-trading", component: ComplianceTrading }),
            React.createElement(Route, { path: "/mastertenant/settlements", component: Settlements })))));
