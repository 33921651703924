import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import ClientSearchContent from './ClientSearchContent';
import recordsDependsOnPage from '../../../../../shared/helpers/recordsDependsOnPage';
const rowsPerPage = 10;
const ClientsSearchResults = ({ workingClients, handleSelect, clientsTotal, onChangePage, pageOfItemsClientSearch, }) => {
    const clientsDependOnPage = recordsDependsOnPage(workingClients, pageOfItemsClientSearch + 1);
    return (React.createElement("div", null,
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Name"),
                    React.createElement(TableCell, null, "BFID"),
                    React.createElement(TableCell, null, "Code"))),
            React.createElement(TableBody, null, clientsDependOnPage.map((client) => (React.createElement(ClientSearchContent, { key: client.code, client: client, handleSelect: handleSelect }))))),
        React.createElement(TablePagination, { component: "div", count: +clientsTotal, page: pageOfItemsClientSearch, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [rowsPerPage] })));
};
const mapStateToProps = (state) => ({
    workingClients: state.clientSearch.workingClients,
});
export default connect(mapStateToProps)(ClientsSearchResults);
