import React, { PureComponent } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import Permissions from './Permissions';
import SidebarCategory from './SidebarCategory';
import { WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/clientActionTypes';
/* eslint-disable camelcase, @typescript-eslint/no-unsafe-return */
const clientInfoFetch = () => ({
    type: CLIENT_INFO_FETCH_REQUEST,
});
class SidebarContent extends PureComponent {
    constructor() {
        super(...arguments);
        this.handleDashBoardChange = (dashboardName) => {
            const { dispatchWorkspaceRequest } = this.props;
            dispatchWorkspaceRequest(dashboardName);
        };
    }
    render() {
        const { clientInfo, features, role, masterTenant, customer_code, } = this.props;
        if (!clientInfo) {
            return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                React.createElement(CircularProgress, { size: "33px" })));
        }
        const isClearer = role === 'clearer';
        return (React.createElement(Box, null,
            React.createElement("ul", null,
                React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                    React.createElement(SidebarLink, { exact: true, title: "Orders", route: "/manage", onClick: () => this.handleDashBoardChange('manageOrders') })),
                React.createElement(SidebarLink, { title: "Client details", route: "/manage/clients/details" }),
                (customer_code || !masterTenant) ? null : (React.createElement(Permissions, { features: features, permissions: ['roles_permissions.read'] },
                    React.createElement(SidebarLink, { title: "Permissions", route: "/manage/clients/permissions", onClick: () => this.handleDashBoardChange('permissions') }))),
                customer_code || !masterTenant ? null : (React.createElement(Permissions, { features: features, permissions: ['users.read'] },
                    React.createElement(SidebarLink, { title: "Users", route: "/manage/clients/users", onClick: () => this.handleDashBoardChange('manageUsers') }))),
                customer_code ? null : (React.createElement(Permissions, { features: features, permissions: ['customer.read'] },
                    React.createElement(SidebarLink, { title: "Customers", route: "/manage/clients/customers", onClick: () => this.handleDashBoardChange('manageCustomers') }))),
                (customer_code || !masterTenant) ? null : (React.createElement(Permissions, { features: features, permissions: ['archive_settlement_info.read'] },
                    React.createElement(SidebarLink, { title: "SFTP file archive", route: "/manage/file-archive" }))),
                React.createElement(Permissions, { oneOf: true, features: features, permissions: [
                        'custody_report.create',
                        'fee_report.create',
                        'report.create',
                        'report_trading.create',
                        'report.read',
                        'report_trading.read',
                    ] },
                    React.createElement(SidebarLink, { title: "Create reports", route: "/manage/reports" })),
                React.createElement(Permissions, { features: features, permissions: ['custody.read'] },
                    React.createElement(SidebarCategory, { title: "Custody" },
                        React.createElement(SidebarLink, { title: "Withdrawals", route: "/manage/custody/withdrawals" }),
                        React.createElement(SidebarLink, { title: "Deposits", route: "/manage/custody/deposits" }))),
                isClearer ? (React.createElement(SidebarLink, { title: "Clearer Withdrawals", route: "/manage/clearer/withdrawals" })) : null,
                React.createElement(Permissions, { features: features, permissions: ['api_tokens.read'] },
                    React.createElement(SidebarLink, { title: "API Tokens", route: "/manage/tokens", onClick: () => this.handleDashBoardChange('manageTokens') })),
                React.createElement(Permissions, { features: features, permissions: ['audit_event.read'] },
                    React.createElement(SidebarLink, { title: "LogBook", route: "/manage/logbook", onClick: () => this.handleDashBoardChange('manageLogbook') })),
                React.createElement(Permissions, { features: features, permissions: ['trading_transfers.read'] },
                    React.createElement(SidebarLink, { title: "Transfers", route: "/manage/transfers", onClick: () => this.handleDashBoardChange('tradingTransfers') })),
                React.createElement(SidebarLink, { title: "Whitelisting", route: "/manage/whitelisting", onClick: () => this.handleDashBoardChange('manageWhitelist') }))));
    }
}
const stateToProps = (state) => {
    const { selectedClient } = state.clientSearch;
    const { clientInfo, clientInfoLoading, features, role, masterTenant, customer_code, } = state.client;
    return {
        role,
        features,
        clientInfo,
        selectedClient,
        masterTenant,
        customer_code,
        clientInfoLoading,
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchLoadClientInfo: () => dispatch(clientInfoFetch()),
    dispatchWorkspaceRequest: (dashboard) => dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard }),
});
export default connect(stateToProps, mapDispatchToProps)(SidebarContent);
