import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { createStyles, makeStyles } from '@mui/styles';
import { TransfersCellItem } from '../../../Custody/styling/style';
const useStyles = makeStyles(() => createStyles({
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
        }
    }
}));
const ClientSearchContent = ({ handleSelect, client }) => {
    const classes = useStyles();
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(50);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 10);
        }
    }, [width]);
    return (React.createElement(TableRow, { className: classes.tableRow, role: "button", key: client.code, onKeyDown: () => undefined, onClick: () => handleSelect(client) },
        React.createElement(TableCell, null,
            React.createElement("div", null, client.company_name)),
        React.createElement(TableCell, null,
            React.createElement("span", null, client.bfid)),
        React.createElement(TableCell, { ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, client.code))));
};
export default ClientSearchContent;
