import React, { useState } from 'react';
import { connect } from 'react-redux';
import CustomersForm from './CustomersForm';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles((theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    }
}));
const CustomersModal = ({ open, theme, toggleFunc, customers, customerInfo, setCustomerInfo, settings, widgetKey, blockKey, }) => {
    const [colored] = useState(false);
    const [header] = useState(true);
    const [color] = useState('primary');
    const classes = useStyles();
    return (React.createElement(Dialog, { open: open, fullWidth: true, maxWidth: "sm" },
        React.createElement(DialogTitle, null,
            "Select customer",
            React.createElement(IconButton, { size: "small", "aria-label": "close", className: classes.closeButton, onClick: toggleFunc },
                React.createElement(CloseIcon, { fontSize: "small" }))),
        React.createElement(Box, { p: 2 },
            React.createElement(CustomersForm, { settings: settings, blockKey: blockKey, widgetKey: widgetKey, customers: customers, toggleFunc: toggleFunc, customerInfo: customerInfo, setCustomerInfo: setCustomerInfo }))));
};
const mapStateToProps = (state) => ({
    theme: state.theme,
    customers: getCustomersSelectedInputInterface(state),
});
export default connect(mapStateToProps)(CustomersModal);
