import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, } from 'react-router-dom';
import { connect, useDispatch, useSelector, } from 'react-redux';
import MainWrapper from '../MainWrapper';
import AdminRoot from './WrappedRoutes/AdminRoot';
import CustodyRoot from './WrappedRoutes/CustodyRoot';
import NotFound404 from '../../DefaultPage/404/index';
import TerminalRoot from './WrappedRoutes/TerminalRoot';
import PortfolioRoot from './WrappedRoutes/PortfolioRoot';
import MasterTenantRoot from './WrappedRoutes/MasterTenantRoot';
import { RatesProvider } from '../../../providers/RatesProvider';
import showNotification from '../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/clientActionTypes';
import { getClientInfo } from '../../../redux/selectors/clientSelectors';
import { getEnvSettings } from '../../../config/environmentSettings';
import { usePrevious } from '../../../shared/hooks/usePrevious';
const finoaEnv = getEnvSettings().env === 'finoa';
const Router = ({ features, dispatchFetchClientInfo, dispatchFetchAllWorkspaces, }) => {
    const location = useLocation();
    const prevLocation = usePrevious(location);
    const dispatch = useDispatch();
    const { OrderBookWS } = window;
    OrderBookWS.authenticateWS();
    const clientInfo = useSelector(getClientInfo);
    useEffect(() => {
        if (!clientInfo) {
            return;
        }
        loadWorkspace();
    }, [location, features, clientInfo]);
    useEffect(() => {
        if (!clientInfo) {
            dispatchFetchClientInfo();
        }
    }, []);
    const loadWorkspace = () => {
        if (!features || features.length === 0) {
            return;
        }
        const { pathname } = window.location;
        if (pathname === '/dma') {
            if (features.includes('order.read')) {
                dispatchFetchAllWorkspaces('trading');
            }
            else {
                dispatchFetchAllWorkspaces('custody');
                showNotification({
                    message: 'Error: Not enough permissions',
                    color: 'error',
                    dispatch: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
                });
            }
        }
        else if (pathname.includes('/custody')) {
            if ((prevLocation === null || prevLocation === void 0 ? void 0 : prevLocation.key) === (location === null || location === void 0 ? void 0 : location.key)) {
                dispatchFetchAllWorkspaces('custody');
            }
        }
        else if (pathname === '/rfq') {
            dispatchFetchAllWorkspaces('tradingRfq');
        }
        else if (pathname === '/manage') {
            dispatchFetchAllWorkspaces('manageOrders');
        }
        else if (pathname === '/manage/clients/customers') {
            dispatchFetchAllWorkspaces('manageCustomers');
        }
        else if (pathname === '/manage/clients/users') {
            dispatchFetchAllWorkspaces('manageUsers');
        }
        else if (pathname === '/manage/logbook') {
            dispatchFetchAllWorkspaces('manageLogbook');
        }
        else if (pathname === '/manage/tokens') {
            dispatchFetchAllWorkspaces('manageTokens');
        }
        else if (pathname === '/manage/transfers') {
            dispatchFetchAllWorkspaces('tradingTransfers');
        }
        else if (pathname === '/mastertenant/schedulers') {
            dispatchFetchAllWorkspaces('manageSchedulers');
        }
        else if (pathname === '/mastertenant/rfq') {
            dispatchFetchAllWorkspaces('quoteMonitor');
        }
        else if (pathname === '/mastertenant/autohedger') {
            dispatchFetchAllWorkspaces('autohedger');
        }
        else if (pathname === '/mastertenant/currency-config') {
            dispatchFetchAllWorkspaces('currencyConfig');
        }
        else if (pathname === '/mastertenant/risk-manager') {
            dispatchFetchAllWorkspaces('autohedgerBalance');
        }
        else if (pathname === '/mastertenant/compliance-custody') {
            dispatchFetchAllWorkspaces('manageCompliance');
        }
        else if (pathname === '/mastertenant/compliance-trading') {
            dispatchFetchAllWorkspaces('manageComplianceTrading');
        }
        else if (pathname === '/portfolio') {
            dispatchFetchAllWorkspaces('portfolio');
        }
        else if (pathname === '/mastertenant') {
            dispatchFetchAllWorkspaces('manageSchedulers');
        }
        else if (pathname === '/mastertenant/place-order') {
            dispatchFetchAllWorkspaces('placeOrders');
        }
        else if (pathname === '/manage/whitelisting') {
            dispatchFetchAllWorkspaces('manageWhitelist');
        }
        else if (pathname === '/manage/clients/permissions') {
            dispatchFetchAllWorkspaces('permissions');
        }
        else if (pathname === '/mastertenant/strategies') {
            dispatchFetchAllWorkspaces('strategies');
        }
        else if (pathname === '/mastertenant/exchanges') {
            dispatchFetchAllWorkspaces('adminExchange');
        }
    };
    const hideTradingSection = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && !features.includes('order.read');
    const rfqAvailable = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && features.includes('client_rfq_settings.read');
    return (React.createElement("div", null, features && (features === null || features === void 0 ? void 0 : features.length) > 0 && clientInfo ? (React.createElement(RatesProvider, null,
        React.createElement(MainWrapper, null,
            React.createElement("main", null,
                React.createElement(Switch, null,
                    React.createElement(Redirect, { exact: true, from: "/", to: rfqAvailable ? '/rfq' : '/dma' }),
                    React.createElement(Route, { exact: true, path: "/dma", component: TerminalRoot }, finoaEnv ? (React.createElement(Redirect, { exact: true, from: "/dma", to: "/rfq" }))
                        : (hideTradingSection && React.createElement(Redirect, { exact: true, from: "/dma", to: "/custody" }))),
                    React.createElement(Route, { exact: true, path: "/rfq", component: TerminalRoot }, hideTradingSection && React.createElement(Redirect, { exact: true, from: "/rfq", to: "/custody" })),
                    React.createElement(Route, { path: "/manage", render: () => React.createElement(AdminRoot, { features: features }) }),
                    React.createElement(Route, { path: "/custody", component: CustodyRoot }),
                    React.createElement(Route, { path: "/portfolio", component: PortfolioRoot }),
                    React.createElement(Route, { path: "/404", component: NotFound404 }),
                    React.createElement(Route, { path: "/mastertenant", component: MasterTenantRoot })))))) : null));
};
const stateToProps = (state) => ({
    features: state.client.features,
});
const dispatchToProps = dispatch => ({
    dispatchFetchClientInfo: () => dispatch({ type: CLIENT_INFO_FETCH_REQUEST }),
    dispatchFetchAllWorkspaces: dashboard => dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard }),
});
export default connect(stateToProps, dispatchToProps)(Router);
