import React from 'react';
import Select from 'react-select';
const blockStyle = {
    label: {
        fontWeight: 'bold',
    },
};
const SelectComponent = ({ name, label, onChange, isLoading, value, options, filtersClass = '', disabled = false, }) => {
    const handleSelectChange = (option) => {
        onChange(option);
    };
    return (React.createElement("div", { className: "form__form-group" },
        React.createElement("span", { className: "form__form-group-label", style: blockStyle.label }, label),
        React.createElement("div", { className: "form__form-group-field" },
            React.createElement(Select, { name: name, value: value, options: options, isLoading: isLoading, isDisabled: disabled, onChange: handleSelectChange, classNamePrefix: "react-select", className: `react-select ${filtersClass}` }))));
};
export default SelectComponent;
