import CustomersService from '../../services/customersService';
import { CUSTOMER_SELECT_SET_UPDATE } from '../actionTypes/customerSearchActionsTypes';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const fetchCustomers = (params) => {
    const { ErrorLogger } = window;
    const customersService = new CustomersService({ url: `/customers${params}`, method: 'get' });
    return customersService.makeRequest()
        .then((responseData) => responseData)
        .catch((e) => {
        ErrorLogger.setErrors(e, new Date());
        throw new Error(`Something went wrong, error: ${e}`);
    });
};
export const selectCustomer = (customer) => ({
    type: CUSTOMER_SELECT_SET_UPDATE,
    customer,
});
