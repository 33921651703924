import React, { PureComponent } from 'react';
import axios from 'axios';
import { CircularProgress, Box } from '@mui/material';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import Permissions from './Permissions';
import SidebarCategory from './SidebarCategory';
import { getEnvSettings } from '../../../config/environmentSettings';
import { WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
class MasterTenantSidebar extends PureComponent {
    constructor() {
        super(...arguments);
        this.componentDidMount = () => {
            axios.defaults.baseURL = getEnvSettings().apiUrl;
        };
        this.handleDashBoardChange = (dashboardName) => {
            const { dispatchWorkspaceRequest } = this.props;
            dispatchWorkspaceRequest(dashboardName);
        };
    }
    render() {
        const { clientInfo, features, } = this.props;
        if (!clientInfo) {
            return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                React.createElement(CircularProgress, { size: "33px" })));
        }
        return (React.createElement(Box, null,
            React.createElement("ul", null,
                React.createElement(SidebarLink, { title: "Schedulers", route: "/mastertenant/schedulers", onClick: () => this.handleDashBoardChange('manageSchedulers') }),
                React.createElement(SidebarCategory, { title: "Compliance" },
                    React.createElement(SidebarLink, { title: "Trading", route: "/mastertenant/compliance-trading", onClick: () => this.handleDashBoardChange('manageComplianceTrading') }),
                    React.createElement(SidebarLink, { title: "Custody", route: "/mastertenant/compliance-custody", onClick: () => this.handleDashBoardChange('manageCompliance') })),
                React.createElement(SidebarLink, { title: "Exchanges", route: "/mastertenant/exchanges", onClick: () => this.handleDashBoardChange('adminExchange') }),
                React.createElement(Permissions, { features: features, permissions: ['client_rfq_settings.read'] },
                    React.createElement(SidebarLink, { title: "RFQ Streams", route: "/mastertenant/rfq", onClick: () => this.handleDashBoardChange('quoteMonitor') })),
                React.createElement(Permissions, { features: features, permissions: ['auto_hedger.read'] },
                    React.createElement(SidebarLink, { title: "Risk Manager", route: "/mastertenant/risk-manager", onClick: () => this.handleDashBoardChange('autohedgerBalance') })),
                React.createElement(Permissions, { features: features, permissions: ['admin_hedge_order.create'] },
                    React.createElement(SidebarLink, { title: "Place order", route: "/mastertenant/place-order", onClick: () => this.handleDashBoardChange('placeOrders') })),
                React.createElement(Permissions, { features: features, permissions: ['custody_currency_configuration.read'] },
                    React.createElement(SidebarLink, { title: "Currency config", route: "/mastertenant/currency-config", onClick: () => this.handleDashBoardChange('currencyConfig') })),
                React.createElement(SidebarLink, { title: "Settlements", route: "/mastertenant/settlements" }),
                React.createElement(Permissions, { features: features, permissions: ['custody_currency_configuration.read'] },
                    React.createElement(SidebarLink, { title: "DeFi", route: "/mastertenant/strategies", onClick: () => this.handleDashBoardChange('strategies') })))));
    }
}
const stateToProps = (state) => {
    const { clientInfo, clientInfoLoading, masterTenant, features, } = state.client;
    return {
        features,
        clientInfo,
        masterTenant,
        clientInfoLoading,
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchWorkspaceRequest: (dashboard) => dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard }),
});
export default connect(stateToProps, mapDispatchToProps)(MasterTenantSidebar);
