/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ExportFile from './ExportFile';
import CreateFee from './CreateFee';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
const ReportsContainer = ({ features, clientInfo, masterTenant, customerCode, }) => {
    const [activeTab, setActiveTab] = useState(0);
    const changeTab = (tab) => {
        setActiveTab(tab);
    };
    return (React.createElement("div", { className: "content-body" },
        React.createElement(Box, { p: 0.6 },
            React.createElement(Paper, null,
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, { variant: "subtitle1" }, "Create reports"),
                    React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement(Tabs, { value: activeTab },
                                React.createElement(Tab, { label: "Export file", id: "0", onClick: () => { changeTab(0); } }),
                                (customerCode || !masterTenant) ? null : (React.createElement(Permissions, { features: features, permissions: ['fee_report.create'] },
                                    React.createElement(Tab, { label: "Fee report", id: "1", onClick: () => { changeTab(1); } })))),
                            React.createElement("div", { hidden: activeTab !== 0, id: '0' },
                                React.createElement(ExportFile, { features: features, superAdmin: masterTenant, customerCode: customerCode, userClientCode: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code })),
                            React.createElement("div", { hidden: activeTab !== 1, id: '1' },
                                React.createElement(CreateFee, null)))))))));
};
const mapStateToProps = (state) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    customerCode: state.client.customer_code,
});
export default connect(mapStateToProps)(ReportsContainer);
