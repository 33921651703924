import * as types from '../actionTypes/clientActionTypes';
export const initialState = {
    clientInfo: null,
    loggedClientName: '',
    customer_code: '',
    role: '',
    meCode: '',
    features: [],
    masterTenant: false,
    customerInfo: null,
    balances: [],
    exchangesBalances: [],
    customerBalances: [],
    clientInfoLoading: false,
    clientBalancesLoading: false,
    exchangesBalancesLoading: false,
    customerLoading: false,
    customerBalanceLoading: false,
    openItems: [],
    balanceOpenItems: [],
    customerBalanceOpenItems: [],
    error: '',
};
/* eslint-disable @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-return,
   @typescript-eslint/no-unsafe-call,
   @typescript-eslint/restrict-plus-operands */
const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLIENT_INFO_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { clientInfoLoading: true });
        }
        case types.CLIENT_INFO_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { role: action.role, meCode: action.meCode, clientInfo: action.client, loggedClientName: action.clientName, features: action.features, clientInfoLoading: false, masterTenant: action.masterTenant, customer_code: action.customer_code });
        }
        case types.CLIENT_INFO_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { clientInfoLoading: false, error: action.message });
        }
        case types.CLIENT_BALANCES_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { clientBalancesLoading: true });
        }
        case types.CLIENT_BALANCES_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { balances: action.balances, clientBalancesLoading: false });
        }
        case types.CLIENT_BALANCES_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message });
        }
        case types.CUSTOMER_INFO_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { customerLoading: true });
        }
        case types.CUSTOMER_INFO_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { customerInfo: action.customer });
        }
        case types.CUSTOMER_INFO_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message, customerLoading: false });
        }
        case types.CUSTOMER_INFO_CLEAN: {
            return Object.assign(Object.assign({}, state), { customerInfo: null });
        }
        case types.CUSTOMER_BALANCES_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { customerBalanceLoading: true });
        }
        case types.CUSTOMER_BALANCES_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { customerBalances: action.balances, customerBalanceLoading: false });
        }
        case types.CUSTOMER_BALANCES_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message, customerBalanceLoading: false });
        }
        case types.EXCHANGES_BALANCES_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { exchangesBalancesLoading: true });
        }
        case types.EXCHANGES_BALANCES_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { exchangesBalances: action.exchangesBalances, exchangesBalancesLoading: false });
        }
        case types.EXCHANGES_BALANCES_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message, exchangesBalancesLoading: false });
        }
        case types.COLLAPSE_EXCHANGES_BALANCES_ADD_OPEN: {
            return Object.assign(Object.assign({}, state), { openItems: [...state.openItems, action.itemId] });
        }
        case types.COLLAPSE_EXCHANGES_BALANCES_CLOSE: {
            const index = state.openItems.findIndex(i => i === action.itemId);
            return Object.assign(Object.assign({}, state), { openItems: [...state.openItems.slice(0, index), ...state.openItems.slice(index + 1)] });
        }
        case types.COLLAPSE_BALANCES_ADD_OPEN: {
            return Object.assign(Object.assign({}, state), { balanceOpenItems: [...state.balanceOpenItems, action.itemId] });
        }
        case types.COLLAPSE_BALANCES_CLOSE: {
            const index = state.balanceOpenItems.findIndex(i => i === action.itemId);
            return Object.assign(Object.assign({}, state), { balanceOpenItems: [...state.balanceOpenItems.slice(0, index), ...state.balanceOpenItems.slice(index + 1)] });
        }
        case types.COLLAPSE_CUSTOMER_BALANCES_ADD_OPEN: {
            return Object.assign(Object.assign({}, state), { customerBalanceOpenItems: [...state.customerBalanceOpenItems, action.itemId] });
        }
        case types.COLLAPSE_CUSTOMER_BALANCES_CLOSE: {
            const index = state.customerBalanceOpenItems.findIndex(i => i === action.itemId);
            return Object.assign(Object.assign({}, state), { customerBalanceOpenItems: [...state.customerBalanceOpenItems.slice(0, index), ...state.customerBalanceOpenItems.slice(index + 1)] });
        }
        default:
            return state;
    }
};
export default clientReducer;
