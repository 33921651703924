import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment, Container, Paper, Typography, InputLabel, } from '@mui/material';
import QRCode from 'qrcode.react';
import LockIcon from 'mdi-react/LockIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Visibility from '@mui/icons-material/Visibility';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EMAIL_PATTERN } from '../../regex/emailPattern';
const blockStyle = {
    qrLabel: {
        margin: '15px 0 10px 0',
        textAlign: 'center',
    },
};
const formSchema = yup
    .object({
    email: yup.string().matches(EMAIL_PATTERN, 'Incorrect email').required('Field is required'),
    password: yup.string().required('Field is required'),
    twoFACode: yup
        .string()
        .required('Field is required')
        .matches(/^[0-9]+$/, 'Only digits allowed')
        .min(6, 'TOTP Code must be 6 digits')
        .max(6),
})
    .required();
const RegisterTOTPForm = ({ submit, qrData, email, loading }) => {
    var _a, _b, _c;
    const [showPassword, setShowPassword] = useState(false);
    const { control, formState: { errors, isValid }, register, handleSubmit, trigger, setValue, getFieldState, } = useForm({
        resolver: yupResolver(formSchema),
    });
    const theme = useTheme();
    const handleClickShowPassword = () => setShowPassword((prev) => !prev);
    const onSubmit = handleSubmit((data) => submit(data));
    const onEmailChange = (event) => {
        setValue('email', event.target.value);
        trigger('email');
        if (!getFieldState('email').invalid) {
            email(event.target.value);
        }
    };
    return (React.createElement(Container, { maxWidth: 'xs' },
        React.createElement(Paper, null,
            React.createElement("form", { onSubmit: onSubmit, noValidate: true },
                React.createElement(Box, { p: 4, display: 'flex', flexDirection: 'column', gap: 1 },
                    React.createElement(Typography, { variant: 'h5', align: 'center' }, "Reset TOTP"),
                    React.createElement(Box, null,
                        React.createElement(InputLabel, { shrink: true }, "Email"),
                        React.createElement(TextField, Object.assign({ fullWidth: true, id: 'email', size: 'small', type: 'email', variant: 'outlined', placeholder: 'john.smith@mail.com', error: !!errors.email }, register('email'), { onChange: (e) => {
                                onEmailChange(e);
                            }, helperText: ((_a = errors.email) === null || _a === void 0 ? void 0 : _a.message) || ' ', InputLabelProps: { shrink: true }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: 'start' },
                                    React.createElement(EmailIcon, { size: '18', color: errors.email ? theme.palette.error.main : theme.palette.text.primary }))),
                            } }))),
                    React.createElement(Box, null,
                        React.createElement(InputLabel, { shrink: true }, "Password"),
                        React.createElement(TextField, Object.assign({ fullWidth: true, size: 'small', variant: 'outlined', placeholder: 'Password', InputLabelProps: { shrink: true }, type: showPassword ? 'text' : 'password' }, register('password'), { onChange: (e) => {
                                setValue('password', e.target.value), trigger('password');
                            }, helperText: ((_b = errors.password) === null || _b === void 0 ? void 0 : _b.message) || ' ', error: !!errors.password, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: 'start' },
                                    React.createElement(KeyVariantIcon, { size: 18, color: errors.password ? theme.palette.error.main : theme.palette.text.primary }))),
                                endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                                    React.createElement(IconButton, { edge: 'end', "aria-label": 'toggle password visibility', onClick: () => handleClickShowPassword(), size: 'small' }, showPassword ? React.createElement(VisibilityOff, { fontSize: 'small' }) : React.createElement(Visibility, { fontSize: 'small' })))),
                            } }))),
                    qrData ? (React.createElement("span", null,
                        React.createElement("div", { style: blockStyle.qrLabel }, "Scan QR-code with Google Authenticator"),
                        React.createElement("div", { style: { width: '100%', textAlign: 'center' } },
                            React.createElement(QRCode, { value: qrData })))) : null,
                    qrData && (React.createElement(Box, { mb: 2 },
                        React.createElement(InputLabel, { shrink: true }, "TOTP Code"),
                        React.createElement(TextField, Object.assign({ fullWidth: true, size: 'small', variant: 'outlined', placeholder: '6 Digit Code' }, register('twoFACode'), { onChange: (e) => {
                                setValue('twoFACode', e.target.value), trigger('twoFACode');
                            }, helperText: ((_c = errors.twoFACode) === null || _c === void 0 ? void 0 : _c.message) || ' ', error: !!errors.twoFACode, inputProps: { maxLength: 6 }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: 'start' },
                                    React.createElement(LockIcon, { size: 18, color: errors.twoFACode ? theme.palette.error.main : theme.palette.text.primary }))),
                            } })))),
                    React.createElement("div", null,
                        React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                            React.createElement(LoadingButton, { type: 'submit', variant: 'contained', loading: loading, disabled: !isValid || loading, style: { width: '100%' } }, "Reset"),
                            React.createElement(Typography, { variant: 'subtitle2' },
                                React.createElement("a", { href: 'mailto:contact@dlt-finance.com' }, "Not working? Please, contact us!")))))),
            React.createElement(DevTool, { control: control }))));
};
export default RegisterTOTPForm;
