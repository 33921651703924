/* eslint-disable camelcase, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, no-useless-escape,
@typescript-eslint/ban-ts-comment, no-nested-ternary, @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { grey, } from '@mui/material/colors';
import { makeStyles, createStyles, } from '@mui/styles';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import Chip from '@mui/material/Chip';
import Edit from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MarketModal from './MarketModal';
import CustomersModal from './CustomersModal';
import ConfirmCardDeleteModal from './ConfirmCardDeleteModal';
import { FlexieContainer } from '../../../Custody/styling/style';
import Subscriber from '../../../../../shared/helpers/subscriber';
function getOrderBook(data, base, channel) {
    const { payload } = data;
    // Narrow down to OrderBookData type or return undefined
    if (payload.bids) {
        const hitBid = payload.bids[0];
        const liftAsk = payload.asks[0];
        const orderBook = {
            hit_bid: hitBid.price,
            lift_ask: liftAsk.price,
            total_bid: hitBid.volume,
            total_ask: liftAsk.volume,
            timestamp: payload.timestamp.toString(),
        };
        if (channel) {
            return {
                [channel]: Object.assign({}, orderBook),
            };
        }
        return orderBook;
    }
    return undefined;
}
const useStyles = makeStyles((theme) => createStyles({
    boxVariant: {
        padding: 0,
        overflow: 'hidden',
        border: '1px solid',
        borderBottom: 0,
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[200],
        '& .paperBox': {
            cursor: 'move',
            width: 'calc(100% - 25px)',
            padding: '10px',
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '10px',
        },
        '& .pairChip > .MuiChip-root': {
            cursor: 'move',
        },
    },
    price: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.43,
    },
    actionButton: {
        borderRadius: '0',
        textAlign: 'center',
        fontSize: '0.7rem',
        fontWeight: 500,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    textQty: {
        fontSize: '0.8rem',
    },
    quantityBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        '& > div': {
            marginBottom: '15px',
        },
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    closeButton: {
        alignSelf: 'center',
        position: 'absolute',
        right: '-15px',
        top: '10px',
    },
    headerActions: {
        display: 'flex',
    },
}));
const Block = ({ customer, base, quote, guaranteedQty, settings, blockKey, widgetKey, features, removeCard, }) => {
    const classes = useStyles();
    const prevChannel = useRef('');
    const [loading] = useState(false);
    const [total, setTotal] = useState('');
    const refId = useRef(shortid.generate());
    const [action, setAction] = useState('');
    const [bidUpdate, setBidUpdate] = useState('');
    const [askUpdate, setAskUpdate] = useState('');
    const [viewBid, setViewBid] = useState('');
    const [viewAsk, setViewAsk] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [orderAmt, setOrderAmt] = useState(base === 'btc' ? '0.001' : '1');
    const [reference_id, setReferenceId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [hideMarketBtn, setHideMarketBtn] = useState(false);
    const [marketModalOpen, setMarketModalOpen] = useState(false);
    const [confirmCardRemoveModalOpen, setConfirmCardRemoveModalOpen] = useState(false);
    const [orderBookConsolidated, setOrderBookConsolidated] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const channel = `rfq-orderbook-${base === null || base === void 0 ? void 0 : base.toLowerCase()}/${quote === null || quote === void 0 ? void 0 : quote.toLowerCase()}`;
    const inputPattern = /^(\d*\.|\,)?\d+$/gmi;
    const rounding = base === 'btc' ? 2 : 5;
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleRemoveConfirm = (event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setConfirmCardRemoveModalOpen(!confirmCardRemoveModalOpen);
    };
    const hadleRemoveCard = () => {
        removeCard(blockKey);
    };
    const toggleMarketModal = () => {
        setMarketModalOpen(prev => !prev);
    };
    const onAmountChange = (evt) => {
        const val = evt.target.value.trim();
        val.replace(/^0+/, '0');
        if (val && !inputPattern.exec(val)) {
            evt.preventDefault();
            return;
        }
        setOrderAmt(val);
    };
    const onReferenceChange = (evt) => {
        const val = evt.target.value.trim();
        setReferenceId(val);
    };
    const setUpdatesConsolidatedOrderBook = (data) => {
        const orderBook = getOrderBook(data, base === null || base === void 0 ? void 0 : base.toLowerCase());
        setOrderBookConsolidated(orderBook);
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            setCustomerInfo(customer);
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (features) {
            const restrictOrder = features.includes('restrict_order_size.read');
            setHideMarketBtn(!restrictOrder);
        }
        else {
            setHideMarketBtn(true);
        }
    }, [features]);
    useEffect(() => {
        const val = new Decimal(bidUpdate || 0).add(askUpdate || 0).div(2).mul(orderAmt || 0)
            .toFixed(rounding);
        setTotal(val);
    }, [bidUpdate, askUpdate, orderAmt, base]);
    useEffect(() => {
        if (!orderBookConsolidated) {
            return;
        }
        if (!marketModalOpen) {
            setBidUpdate(orderBookConsolidated.hit_bid);
            setAskUpdate(orderBookConsolidated.lift_ask);
            setTimestamp(orderBookConsolidated.timestamp);
        }
        const hitBid = new Decimal(orderBookConsolidated.hit_bid).toFixed(rounding);
        const liftAsk = new Decimal(orderBookConsolidated.lift_ask).toFixed(rounding);
        setViewBid(hitBid);
        setViewAsk(liftAsk);
    }, [orderBookConsolidated]);
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        const wsDataCallback = (data) => {
            var _a;
            if (!data)
                return;
            if (((_a = data.payload.bids[0]) === null || _a === void 0 ? void 0 : _a.volume) !== guaranteedQty) {
                return;
            }
            setUpdatesConsolidatedOrderBook(data);
        };
        const consolidatedOrderBook = new Subscriber(wsDataCallback, refId.current, 0);
        OrderBookWS.addSubscription(channel, consolidatedOrderBook);
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(channel, refId.current);
        };
    }, [channel]);
    return (React.createElement(Box, { className: classes.boxVariant },
        marketModalOpen ? (React.createElement(MarketModal, { base: base, quote: quote, action: action, orderAmt: orderAmt, bidUpdate: bidUpdate || '', askUpdate: askUpdate || '', timestamp: timestamp || '', streamType: "standard", open: marketModalOpen, customerInfo: customerInfo, reference_id: reference_id, toggleFunc: toggleMarketModal, guaranteed: guaranteedQty })) : null,
        React.createElement("div", null,
            React.createElement("div", { style: { flex: '1' } },
                React.createElement("div", { className: "paperBox" },
                    React.createElement("div", { className: "pairChip" },
                        React.createElement(Chip, { size: "small", color: "info", style: { width: '100%', display: 'flex', justifyContent: 'space-between' }, label: `${base.toUpperCase()}/${quote.toUpperCase()}` })),
                    React.createElement("div", { style: { lineHeight: '1rem', fontSize: '0.7rem' } },
                        React.createElement("div", null, "Qty Spread (Guaranteed)"),
                        React.createElement("div", null,
                            guaranteedQty,
                            " ",
                            base.toUpperCase())),
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: e => toggleRemoveConfirm(e), className: classes.closeButton, size: "small", "aria-label": "remove-card", style: { marginLeft: '10px' } },
                            React.createElement(CloseIcon, { fontSize: "small" })))),
                React.createElement(Divider, { variant: "fullWidth", className: classes.divider }),
                React.createElement("div", { style: { display: 'flex', alignItems: 'center', padding: '0 10px' } },
                    React.createElement("div", { style: { marginRight: '12px' } }, "Customer:"),
                    React.createElement(Chip, { size: "medium", variant: "outlined", deleteIcon: React.createElement(Edit, null), onDelete: toggleModal, style: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }, label: `${customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.label}` || '', onClick: toggleModal })),
                React.createElement(Divider, { className: classes.divider }),
                React.createElement("div", { className: classes.quantityBlock },
                    React.createElement("div", null, loading ? React.createElement(CircularProgress, { size: "33px" }) : (React.createElement(TextField, { label: `QTY ${base.toUpperCase()}`, id: "orderAmount", helperText: !orderAmt.match(inputPattern) ? 'Invalid quantity'
                            : `Total estimate: ${total} ${quote.toUpperCase()}`, name: "amount", value: orderAmt, variant: "outlined", size: "small", type: "number", error: !orderAmt.match(inputPattern), onChange: onAmountChange, inputProps: { min: 0, step: base === 'btc' ? 0.001 : 1 } }))),
                    React.createElement("div", null,
                        React.createElement(TextField, { label: "Reference Id", name: "reference_id", value: reference_id, onChange: onReferenceChange, variant: "outlined", size: "small" })))),
            React.createElement(ConfirmCardDeleteModal, { toggleFunc: toggleRemoveConfirm, removeCard: hadleRemoveCard, pair: `${base}/${quote}`, open: confirmCardRemoveModalOpen }),
            modalOpen ? (React.createElement(CustomersModal, { open: modalOpen, blockKey: blockKey, settings: settings, widgetKey: widgetKey, toggleFunc: toggleModal, customerInfo: customerInfo, setCustomerInfo: setCustomerInfo })) : null),
        React.createElement(FlexieContainer, null,
            React.createElement("div", { style: { flex: '1' } },
                React.createElement("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    } }, hideMarketBtn && (React.createElement(Button, { color: "error", variant: "contained", className: classes.actionButton, onClick: () => { toggleMarketModal(); setAction('Sell'); } },
                    React.createElement("div", { className: classes.price }, viewBid || '--'),
                    React.createElement("div", null, "SELL"))))),
            React.createElement("div", { style: { flex: '1' } },
                React.createElement("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    } }, hideMarketBtn && (React.createElement(Button, { color: "success", variant: "contained", className: classes.actionButton, onClick: () => { toggleMarketModal(); setAction('Buy'); } },
                    React.createElement("span", { className: classes.price }, viewAsk || '--'),
                    React.createElement("div", null, "BUY"))))))));
};
export default Block;
