import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, MenuItem, TextField, InputLabel, FormControl, FormHelperText, Alert, capitalize, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import buildUrl from 'build-url';
import Select from '@mui/material/Select';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import CustomerInfoService from '../../../../../services/customerInfoService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { getAllCustomers } from '../../../../../redux/selectors/customerSearchSelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import { LoadingButton } from '@mui/lab';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const TradeWithdrawal = ({ item, toggle, clientCode, path, customers, clientInfo, features, customer_code, masterTenant, selectedClient, }) => {
    const { ErrorLogger } = window;
    const [amount, setAmount] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [requisites, setRequisites] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [allAddresses, setAllAddresses] = useState([]);
    const [clientIBANs, setClientIBANs] = useState([]);
    const [addressesOptions, setAddressesOptions] = useState([]);
    const [customerIBANs, setCustomerIBANs] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [strictCustomers, setStrictCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const helperTextStyles = useHelperTextStyles();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleSelectAddress = (event) => {
        const val = event.target.value;
        setSelectedAddress(val);
    };
    const handleSelectCustomer = (event) => {
        const val = event.target.value;
        setSelectedCustomer(val.trim());
        if (!val.trim()) {
            setRequisites('');
        }
    };
    const handleRequisitesChange = (event) => {
        const val = event.target.value;
        setRequisites(val);
    };
    const onAmountChange = (evt) => {
        const { value } = evt.target;
        const val = value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setAmount(value);
    };
    const fetchAddresses = (currencyCode) => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                state: 'approved',
                sort_by: 'created',
                sort_direction: 'desc',
                currency_code: currencyCode,
            },
            path: '/custody/withdrawals/addresses',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const depositAddressesService = new WithdrawalsAddressesService({
            url: endpointUrl,
            method: 'get',
        });
        depositAddressesService
            .makeRequest()
            .then((response) => {
            const options = response.records.map((r) => ({
                label: `${r.label}; ${r.address}`,
                value: r.code,
                currency: r.currency,
                wallet_code: r.wallet_code,
                customer_code: r.customer_code,
            }));
            setAllAddresses(options);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const newWithdrawal = () => {
        setLoading(true);
        const details = item.is_fiat ? requisites : selectedAddress;
        const payload = {
            amount,
            client_code: masterTenant ? selectedClient || clientCode : clientCode,
            currency_code: item.code,
            requisites_details: details,
            customer_code: selectedCustomer,
        };
        axios
            .post('/trading_transfer/withdrawal', payload)
            .then(() => {
            showNotification({
                message: `${capitalize(path)} successfully created`,
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.TRADING_WITHDRAWAL_CREATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setLoading(false);
            toggle();
        });
    };
    useEffect(() => {
        fetchAddresses(item.code);
    }, []);
    useEffect(() => {
        if (customers.length) {
            setStrictCustomers(customers.map((c) => ({ label: c.company_name || '', value: c.code })));
        }
        else {
            setStrictCustomers([]);
        }
    }, [customers]);
    useEffect(() => {
        var _a;
        if (clientInfo) {
            const ibans = ((_a = clientInfo.ibans) === null || _a === void 0 ? void 0 : _a.length)
                ? clientInfo.ibans
                    .filter((i) => i.currency.toUpperCase() === item.code.toUpperCase() && i.is_enabled)
                    .map((i) => ({ value: i.iban, label: i.iban }))
                : [];
            setClientIBANs(ibans);
        }
    }, [clientInfo]);
    useEffect(() => {
        if (customers.length) {
            const mappedCustomers = customers.map((c) => ({
                label: c.company_name || '',
                value: c.code,
            }));
            setCustomerOptions(mappedCustomers);
        }
    }, [customers]);
    useEffect(() => {
        const canReadDetails = features && features.includes('customer_details.read');
        if (selectedCustomer && item.is_fiat) {
            const customerInfoService = new CustomerInfoService({
                url: `/customers/${selectedCustomer}?details=${canReadDetails ? 'true' : 'false'}`,
                method: 'get',
            });
            setLoadingCustomer(true);
            customerInfoService
                .makeRequest()
                .then((response) => {
                setLoadingCustomer(false);
                const ibans = response.ibans.length
                    ? response.ibans
                        .filter((i) => i.currency.toUpperCase() === item.code.toUpperCase() && i.is_enabled)
                        .map((i) => ({ value: i.iban, label: i.iban }))
                    : [];
                setCustomerIBANs(ibans);
                if (!ibans.length) {
                    setRequisites('');
                }
            })
                .catch((e) => {
                setLoadingCustomer(false);
                setCustomerIBANs([]);
                setRequisites('');
                const message = composeErrorMessage(e, Messages.CUSTOMER_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [selectedCustomer, features, item]);
    useEffect(() => {
        let filtered = allAddresses.filter((a) => a.currency.toLocaleUpperCase() === item.code.toLocaleUpperCase());
        if (selectedCustomer) {
            filtered = filtered.filter((f) => f.customer_code === selectedCustomer);
        }
        setAddressesOptions(filtered);
    }, [allAddresses, item, selectedCustomer]);
    useEffect(() => {
        if (!amount || (!item.is_fiat && !selectedAddress.trim()) || (item.is_fiat && !requisites.trim())) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [amount, selectedAddress, requisites]);
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(FormControl, null,
            React.createElement("div", { style: { height: '10px' } }),
            React.createElement(TextField, { fullWidth: true, autoFocus: true, size: 'small', value: amount, label: 'Amount', variant: 'outlined', onChange: onAmountChange, InputLabelProps: { shrink: true } })),
        React.createElement(FormControl, { variant: 'outlined', size: 'small' },
            React.createElement(TextField, { disabled: loadingCustomer, size: 'small', select: true, value: selectedCustomer, label: 'Customer', onChange: handleSelectCustomer },
                React.createElement(MenuItem, { value: '' },
                    React.createElement("em", null, "None")),
                (customer_code ? strictCustomers : customerOptions).map((c) => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label))))),
        masterTenant ? (React.createElement(FormControl, null,
            React.createElement(TextField, { fullWidth: true, size: 'small', variant: 'outlined', label: 'Destination', InputLabelProps: { shrink: true }, onChange: (event) => item.is_fiat ? setRequisites(event.target.value) : setSelectedAddress(event.target.value) }),
            selectedAddress ? null : !item.is_fiat ? (React.createElement(FormHelperText, { className: helperTextStyles.root }, "Field is required")) : null,
            (selectedCustomer ? customerIBANs.length : clientIBANs.length) ? null : item.is_fiat ? (React.createElement(FormHelperText, { className: helperTextStyles.root },
                "You should provide IBAN for ",
                item.name)) : null)) : item.is_fiat ? (React.createElement(React.Fragment, null, (selectedCustomer ? customerIBANs.length : clientIBANs.length) ? (React.createElement(FormControl, { variant: 'outlined', size: 'small' },
            React.createElement(InputLabel, null, "Destination"),
            React.createElement(Select, { value: requisites, label: 'Destination', size: 'small', disabled: selectedCustomer ? customerIBANs.length === 0 : clientIBANs.length === 0, onChange: handleRequisitesChange }, (selectedCustomer ? customerIBANs : clientIBANs).map((i) => (React.createElement(MenuItem, { key: i.value, value: i.value }, i.label)))))) : (React.createElement(Alert, { severity: 'info', color: 'warning' }, "No IBANs available")))) : (React.createElement(React.Fragment, null,
            addressesOptions.length === 0 ? (React.createElement(Alert, { severity: 'info', color: 'warning' }, "No addresses availble")) : (React.createElement(FormControl, { variant: 'outlined' },
                React.createElement(TextField, { size: 'small', label: 'Address', select: true, disabled: addressesOptions.length === 0, value: selectedAddress, onChange: handleSelectAddress }, addressesOptions.map((a) => (React.createElement(MenuItem, { key: a.value, value: a.value }, a.label)))),
                selectedAddress.trim() ? null : (React.createElement(FormHelperText, { className: helperTextStyles.root }, "Field is required")))),
            React.createElement("div", { style: { height: '20px' } }))),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(LoadingButton, { loading: loading, color: 'primary', variant: 'contained', disabled: disabled, onClick: newWithdrawal }, "Withdraw"),
            React.createElement(Button, { variant: 'outlined', onClick: () => toggle() }, "Cancel"))));
};
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        features: state.client.features,
        customers: getAllCustomers(state),
        clientInfo: state.client.clientInfo,
        customer_code: state.client.customer_code || ((_a = state.customersSearch.selectedCustomer) === null || _a === void 0 ? void 0 : _a.code),
        masterTenant: state.client.masterTenant,
        selectedClient: (_b = getSelectedClientInputValue(state)) === null || _b === void 0 ? void 0 : _b.value,
    });
};
export default connect(mapStateToProps)(TradeWithdrawal);
