import React from 'react';
import { Box, Icon, TableRow, TableCell, TableBody, } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import Decimal from 'decimal.js';
import { NumericFormat } from 'react-number-format';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { getExplorerURL } from '../../../../../shared/helpers/getExplorerURL';
const useStyles = makeStyles(() => createStyles({
    root: {
        cursor: 'pointer',
        color: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
            color: 'inherit',
        },
    },
}));
const envSettings = getEnvSettings();
/* eslint-disable camelcase */
const RenderBlockchainTableBody = ({ item, iconReceived, iconStore, handleCopyReceivedHash, handleCopyStoreHash, currencies, }) => {
    const classes = useStyles();
    const sliceHash = (i) => {
        const { compliance_tx_hash, custody_tx_hash } = i;
        const complianceTXHashLength = compliance_tx_hash ? compliance_tx_hash.length : 0;
        const complianceTXHashValue = compliance_tx_hash ? `${compliance_tx_hash.slice(0, 4)}...
      ${compliance_tx_hash.slice(complianceTXHashLength - 4, complianceTXHashLength)}` : '';
        const tXHashLength = custody_tx_hash ? custody_tx_hash.length : 0;
        const tXHashValue = custody_tx_hash
            ? `${custody_tx_hash.slice(0, 4)}...${custody_tx_hash.slice(tXHashLength - 4, tXHashLength)}` : '';
        return { tXHashValue, complianceTXHashValue };
    };
    const hashObj = sliceHash(item);
    const { currency_code, custody_amount, custody_tx_hash, compliance_amount, compliance_tx_hash, custody_received_at, compliance_received_at, compliance_to_custody_fee, compliance_to_custody_fee_currency_code, } = item;
    return (React.createElement(TableBody, null,
        React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1 },
            React.createElement(TableCell, { align: "left" }, "Compliance"),
            React.createElement(TableCell, { align: "right" }, compliance_amount ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(compliance_amount).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                currency_code ? currency_code.toUpperCase() : null)) : '-----'),
            React.createElement(TableCell, { align: "right" }, "-----"),
            React.createElement(TableCell, { align: "right" },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'end' },
                    hashObj.complianceTXHashValue ? (React.createElement(Box, { paddingRight: '10px' },
                        React.createElement("a", { target: "_blank", rel: "noreferrer", className: classes.root, href: `${getExplorerURL(currency_code, currencies)}${compliance_tx_hash}` }, hashObj.complianceTXHashValue))) : '-----',
                    hashObj.complianceTXHashValue ? (React.createElement(Box, null,
                        React.createElement(Icon, { onClick: () => handleCopyReceivedHash(`${compliance_tx_hash}`), fontSize: "small" }, iconReceived))) : null)),
            React.createElement(TableCell, { align: "right" }, compliance_received_at ? moment(compliance_received_at).format('YYYY-MM-DD H:mm:ss') : '-----')),
        React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1 },
            React.createElement(TableCell, { align: "left" }, "Storage"),
            React.createElement(TableCell, { align: "right" }, custody_amount ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(custody_amount).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                currency_code ? currency_code.toUpperCase() : null)) : '-----'),
            React.createElement(TableCell, { align: "right" }, compliance_to_custody_fee ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(compliance_to_custody_fee).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                compliance_to_custody_fee_currency_code
                    ? compliance_to_custody_fee_currency_code.toUpperCase()
                    : null)) : '-----'),
            React.createElement(TableCell, { align: "right" },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'end' },
                    hashObj.tXHashValue ? (React.createElement(Box, { paddingRight: '10px' },
                        React.createElement("a", { target: "_blank", rel: "noreferrer", className: classes.root, href: `${getExplorerURL(currency_code, currencies)}${custody_tx_hash}` }, hashObj.tXHashValue))) : '-----',
                    hashObj.tXHashValue ? (React.createElement(Box, null,
                        React.createElement(Icon, { onClick: () => handleCopyStoreHash(`${custody_tx_hash}`), fontSize: "small" }, iconStore))) : null)),
            React.createElement(TableCell, { align: "right" }, custody_received_at ? moment(custody_received_at).format('YYYY-MM-DD H:mm:ss') : '-----'))));
};
export default RenderBlockchainTableBody;
