var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-template-expressions,
  no-useless-return, no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, } from 'react-redux';
import buildUrl from 'build-url';
import axios from 'axios';
import DepositDialog from './DepositDialog';
import TransfersFilters from './TransfersFilters';
import { PanelContainer } from '../../styling/style';
import TradingTransfersContent from './TradingTransfersContent';
import UsersService from '../../../../../services/usersService';
import Messages from '../../../../../shared/helpers/errorMessages';
import ClientsService from '../../../../../services/clientsService';
import CustomersService from '../../../../../services/customersService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { cTypes } from '../../../Custody/components/Wallets/WidgetWallets';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import TradingWithdrawalsService from '../../../../../services/tradingTransfersService';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { ADMIN_CURRENCIES_UPDATE } from '../../../../../redux/actionTypes/adminActionTypes';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import { FETCH_MARKETS_REQUEST, FETCH_EXCHANGES_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const TradingTransfersContainer = ({ masterTenant, columns, transfersWidgetHeight, dispatchLoadMarkets, clientInfo, customerCode, dispatchLoadExchanges, features, meCode, selectedClient, urlOptionsTradingTransfers, }) => {
    const [status, setStatus] = useState('init');
    const [totalItems, setTotalItems] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');
    const [clientFilters, setClientFilters] = useState(null);
    const [activeClient, setActiveClient] = useState(null);
    const [transfersToRender, setTransfersToRender] = useState([]);
    const [sortBy, setSortBy] = useState('updated_at');
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const fetchTransfers = (param, cancelToken) => {
        setStatus('fetching');
        const service = new TradingWithdrawalsService({
            url: `/trading_transfer${param}`,
            method: 'get',
            cancelToken,
        });
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { records, total } = yield service.makeRequest();
                setTotalItems(+total);
                const addressesData = yield fetchAddresses();
                if (addressesData) {
                    const mappedRecords = records.map(r => {
                        var _a, _b;
                        return (Object.assign(Object.assign({}, r), { requisites_details: r.currency_code !== 'eur' && r.currency_code !== 'usd'
                                ? (_a = addressesData === null || addressesData === void 0 ? void 0 : addressesData.records.find(a => a.code === r.requisites_details)) === null || _a === void 0 ? void 0 : _a.address : r.requisites_details, address_label: (_b = addressesData === null || addressesData === void 0 ? void 0 : addressesData.records.find(a => a.code === r.requisites_details)) === null || _b === void 0 ? void 0 : _b.label }));
                    });
                    setTransfersToRender(mappedRecords);
                }
                else {
                    setTransfersToRender(records);
                }
                setStatus('fetched');
            }
            catch (error) {
                setStatus('error');
                const message = composeErrorMessage(error, Messages.TRADING_TRANSFERS_FETCH);
                showNotification({
                    message: `Error: ${message}`,
                    color: 'error',
                    dispatch: errorNotice,
                });
            }
        });
        // eslint-disable-next-line no-void
        void fetchData();
    };
    const customerUrlParams = (canReadDetails, clientCode) => buildUrl('', {
        queryParams: {
            page: '1',
            search: '',
            limit: '1000',
            status: 'enabled',
            sort_by: 'created',
            client_code: clientCode,
            details: canReadDetails ? 'true' : 'false',
        },
    });
    const fetchCustomers = (param, cancelToken) => {
        const service = new CustomersService({
            url: `/customers${param}`,
            method: 'get',
            cancelToken,
        });
        service.makeRequest()
            .then((responseData) => {
            if (responseData) {
                const { records } = responseData;
                setCustomers(records);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchClients = (cancelToken) => {
        const clientUrlOptions = {
            queryParams: {
                page: '1',
                limit: '1000',
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/user/clients',
        };
        const endpointUrl = buildUrl('', clientUrlOptions);
        const service = new ClientsService({
            url: endpointUrl,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        service.makeRequest()
            .then((responseData) => {
            if (responseData) {
                const { records } = responseData;
                setClients(records);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.CLIENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchUsers = (code, cancelToken) => {
        const usersUrlOptions = {
            queryParams: {
                limit: '1000',
                search: '',
                client_code: code,
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/users',
        };
        const endpointUrl = buildUrl('', usersUrlOptions);
        const usersService = new UsersService({
            url: endpointUrl,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        usersService.makeRequest()
            .then((responseData) => {
            if (responseData) {
                const { records } = responseData;
                setUsers(records);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.USERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const handleFetchCurrencies = () => {
        const { ErrorLogger } = window;
        axios.get('/custody/currencies')
            .then((response) => {
            if (response.data.length) {
                const mapped = response.data
                    .map(c => Object
                    .assign({}, c, { label: `${c.code} - ${c.label}${cTypes[c.type] ? ` ${cTypes[c.type]}` : ''}` }));
                const btcIndex = mapped.findIndex(b => b.code === 'BTC');
                const btc = mapped.splice(btcIndex, 1)[0];
                const ethIndex = mapped.findIndex(b => b.code === 'ETH');
                const eth = mapped.splice(ethIndex, 1)[0];
                mapped.sort((a, b) => a.code.localeCompare(b.code));
                mapped.unshift(eth);
                mapped.unshift(btc);
                dispatch({ type: ADMIN_CURRENCIES_UPDATE, currencies: mapped });
            }
            else {
                dispatch({ type: ADMIN_CURRENCIES_UPDATE, currencies: [] });
            }
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            showNotification({
                message: `Failed to load currencies: ${e}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchAddresses = () => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                state: 'approved',
                sort_by: 'created',
                sort_direction: 'desc',
            },
            path: '/custody/withdrawals/addresses',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const depositAddressesService = new WithdrawalsAddressesService({
            url: endpointUrl, method: 'get',
        });
        return depositAddressesService.makeRequest()
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
            handleFetchCurrencies();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (!customerCode && masterTenant) {
            fetchClients(cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [customerCode]);
    useEffect(() => {
        if (selectedClient) {
            setActiveClient(selectedClient);
        }
        else {
            setActiveClient(null);
        }
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlOptionsTradingTransfers) {
            fetchTransfers(urlOptionsTradingTransfers, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlOptionsTradingTransfers]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        let code = '';
        if (clientFilters) {
            code = clientFilters.value;
        }
        else if (activeClient) {
            code = activeClient.value;
        }
        if (masterTenant) {
            fetchUsers(code, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [activeClient, clientFilters]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const canReadDetails = (features && features.includes('customer_details.read')) || false;
        let codeClient = '';
        if (clientFilters) {
            codeClient = clientFilters.value;
        }
        else if (activeClient) {
            codeClient = activeClient.value;
        }
        else if (clientInfo) {
            codeClient = clientInfo.code;
        }
        const params = customerUrlParams(canReadDetails, codeClient);
        fetchCustomers(params, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [features, activeClient, clientInfo, clientFilters]);
    return (React.createElement("div", { className: "content-body" },
        modalOpen
            && (React.createElement(DepositDialog, { open: modalOpen, toggle: toggleModal, fetchTransfers: fetchTransfers, params: urlOptionsTradingTransfers })),
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement(TransfersFilters, { users: users, clients: clients, features: features, customers: customers, currentPage: currentPage, clientData: activeClient, rowsPerPage: rowsPerPage, toggleModal: toggleModal, fetchClients: fetchClients, masterTenant: masterTenant, sortDirection: sortDirection, clientFilters: clientFilters, setCurrentPage: setCurrentPage, setClientFilters: setClientFilters }),
            React.createElement("div", null,
                React.createElement(TradingTransfersContent, { users: users, meCode: meCode, status: status, sortBy: sortBy, clients: clients, columns: columns, features: features, customers: customers, clientInfo: clientInfo, totalItems: totalItems, currentPage: currentPage, rowsPerPage: rowsPerPage, onSortHandle: onSortHandle, masterTenant: masterTenant, sortDirection: sortDirection, setCurrentPage: setCurrentPage, fetchTransfers: fetchTransfers, setRowsPerPage: setRowsPerPage, transfersToRender: transfersToRender, transfersWidgetHeight: transfersWidgetHeight, urlOptionsTradingTransfers: urlOptionsTradingTransfers })))));
};
const mapStateToProps = (state, ownProps) => ({
    meCode: state.client.meCode,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    customerCode: state.client.customer_code,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    transfersWidgetHeight: state.widgets[ownProps.widgetKey],
    urlOptionsTradingTransfers: state.admin.urlOptionsTradingTransfers,
});
const dispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, dispatchToProps)(TradingTransfersContainer);
