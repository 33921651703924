import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Checkbox, Chip, CircularProgress, Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import MinusIcon from 'mdi-react/MinusIcon';
const ManageStreamAccess = ({ clients, onClientSelect, customersLoading, selectedClient, stream, onSave, onCancel, }) => {
    var _a;
    const theme = useTheme();
    const [clientsTree, setClientsTree] = useState();
    const [currentNode, setCurrentNode] = useState();
    useEffect(() => {
        var _a, _b;
        if (currentNode) {
            currentNode.children = ((_b = (_a = clients.children) === null || _a === void 0 ? void 0 : _a.find((node) => node.id === currentNode.id)) === null || _b === void 0 ? void 0 : _b.children) || [];
        }
        setClientsTree((prevTree) => {
            // Find the node in the tree and update its expanded property
            if (!prevTree || !currentNode) {
                return clients;
            }
            const updatedTree = recursivelyUpdateExpanded(prevTree, currentNode.id, !currentNode.expanded);
            return Object.assign({}, updatedTree);
        });
    }, [clients]);
    const handleCheckedChange = (evt, node) => {
        if (!node)
            return;
        setClientsTree((prevTree) => {
            if (!prevTree)
                return;
            node.checked = !node.checked;
            const updatedTree = recursivelyUpdateChecked(prevTree, node.id, node.checked);
            return Object.assign({}, updatedTree);
        });
    };
    const recursivelyUpdateExpanded = (current, nodeId, newExpandedValue) => {
        if (current.id === nodeId) {
            // If the current node is the one we're looking for, update its expanded property
            return Object.assign(Object.assign({}, current), { expanded: newExpandedValue });
        }
        if (current.children) {
            // If the current node has children, recursively update their expanded properties
            return Object.assign(Object.assign({}, current), { children: current.children.map((child) => recursivelyUpdateExpanded(child, nodeId, newExpandedValue)) });
        }
        // If the current node is not the target node and has no children, return it as is
        return Object.assign({}, current);
    };
    const recursivelyUpdateChecked = (current, nodeId, newCheckedValue) => {
        if (current.id === nodeId) {
            // If the current node is the one we're looking for, update its expanded property
            if (!newCheckedValue && (current === null || current === void 0 ? void 0 : current.type) === 'client' && current.children) {
                current.children.forEach((c) => (c.checked = false));
            }
            return Object.assign(Object.assign({}, current), { checked: newCheckedValue });
        }
        if (current.children) {
            // If the current node has children, recursively update their expanded properties
            return Object.assign(Object.assign({}, current), { children: current.children.map((child) => recursivelyUpdateChecked(child, nodeId, newCheckedValue)) });
        }
        // If the current node is not the target node and has no children, return it as is
        return Object.assign({}, current);
    };
    useEffect(() => {
        if (currentNode) {
            onClientSelect(currentNode.id);
        }
    }, [currentNode]);
    const handleToggleNode = (node) => {
        var _a;
        if (node.type === 'customer')
            return;
        // if (expanded) return;
        if (node.children && ((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setClientsTree((prevTree) => {
                // Find the node in the tree and update its expanded property
                if (!prevTree) {
                    return clients;
                }
                const updatedTree = recursivelyUpdateExpanded(prevTree, node.id, !node.expanded);
                return Object.assign({}, updatedTree);
            });
        }
        else {
            setCurrentNode(node);
        }
    };
    const handleSave = () => {
        // console.log(clientsTree);
        const items = {
            clients: [],
            customers: [],
        };
        function saveNode(nodes) {
            var _a, _b;
            if (!nodes || !stream)
                return;
            const wasChecked = nodes.type === 'client'
                ? ((_a = stream.access) === null || _a === void 0 ? void 0 : _a.findIndex((s) => s.clientCode === nodes.id)) > -1
                : ((_b = stream.access) === null || _b === void 0 ? void 0 : _b.findIndex((s) => s.customerCode === nodes.id)) > -1;
            if (!nodes.checked && wasChecked) {
                nodes.type === 'client'
                    ? items.clients.push({ id: nodes.id, action: 'unassign' })
                    : items.customers.push({ id: nodes.id, action: 'unassign', clientCode: nodes.clientCode });
            }
            else if (nodes.checked && !wasChecked) {
                nodes.type === 'client'
                    ? items.clients.push({ id: nodes.id, action: 'assign' })
                    : items.customers.push({ id: nodes.id, action: 'assign', clientCode: nodes.clientCode });
            }
            if (!nodes.children)
                return;
            nodes === null || nodes === void 0 ? void 0 : nodes.children.map((node) => saveNode(node));
        }
        if (clientsTree === null || clientsTree === void 0 ? void 0 : clientsTree.children) {
            clientsTree === null || clientsTree === void 0 ? void 0 : clientsTree.children.map((c) => saveNode(c));
        }
        onSave(items);
    };
    const renderTree = (nodes, disabled) => {
        return (React.createElement(Box, { key: nodes.id, pl: nodes.type === 'customer' ? 2 : 0, ml: nodes.type === 'customer' ? 1.5 : 0, sx: { borderLeft: nodes.type === 'customer' ? '1px solid' : 0 } },
            React.createElement(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
                React.createElement(Box, { display: 'flex', alignItems: 'center', sx: { cursor: 'pointer', userSelect: 'none' }, onClick: () => {
                        handleToggleNode(nodes);
                    } },
                    customersLoading && (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) === nodes.id ? (React.createElement(CircularProgress, { size: 20, sx: { marginRight: '4px' } })) : nodes.type === 'client' ? (nodes.expanded ? (React.createElement(MinusIcon, null)) : (React.createElement(AddIcon, null))) : null,
                    nodes.name),
                React.createElement(Checkbox, { disabled: disabled, checked: nodes.checked, onChange: (e) => handleCheckedChange(e, nodes) })),
            React.createElement(Collapse, { in: nodes.expanded }, Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, !nodes.checked)) : null)));
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', sx: { height: '100%' } },
        React.createElement(Box, { sx: { backgroundColor: theme.palette.background.default, width: '100%', padding: '16px' } },
            React.createElement(Box, { display: 'flex', justifyContent: 'start', width: '100%', p: 1 },
                React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                    React.createElement(Box, { display: 'flex', gap: 2, alignItems: 'center' },
                        React.createElement(Chip, { label: `${stream === null || stream === void 0 ? void 0 : stream.base.toUpperCase()}/${stream === null || stream === void 0 ? void 0 : stream.quote.toUpperCase()}`, size: 'small', color: 'primary' }),
                        React.createElement(Typography, { variant: 'caption', overflow: 'hidden' }, stream === null || stream === void 0 ? void 0 : stream.label))))),
        React.createElement(Box, { sx: { overflow: 'auto', padding: '20px' } }, clientsTree && ((_a = clientsTree.children) === null || _a === void 0 ? void 0 : _a.map((n) => renderTree(n, false)))),
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1, sx: { backgroundColor: theme.palette.background.default, width: '100%', padding: '16px' } },
            React.createElement(Button, { variant: 'outlined', onClick: onCancel }, "Cancel"),
            React.createElement(Button, { color: 'primary', variant: 'contained', onClick: handleSave }, "Save changes"))));
};
export default ManageStreamAccess;
