import { call, put, takeLatest, delay, take, race, select, } from 'redux-saga/effects';
import { CUSTOMER_INFO_FETCH_SUCCESS, CUSTOMER_BALANCES_FETCH_FAIL, CUSTOMER_BALANCES_FETCH_SUCCESS, } from '../redux/actionTypes/clientActionTypes';
import Messages from '../shared/helpers/errorMessages';
import * as clientActions from '../redux/actions/clientActions';
import * as actions from '../redux/actions/customersSearchActions';
import { composeErrorMessage } from '../shared/helpers/interceptors';
import * as types from '../redux/actionTypes/customerSearchActionsTypes';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchCustomers(action) {
    try {
        if (action.type === types.CUSTOMER_SEARCH_FETCH_REQUEST) {
            const data = yield call(actions.fetchCustomers, action.params);
            yield put({ type: types.CUSTOMER_SEARCH_FETCH_SUCCESS, customers: data.records, total: data.total });
        }
    }
    catch (error) {
        const message = composeErrorMessage(error, Messages.CUSTOMERS_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.CUSTOMER_SEARCH_FETCH_FAIL, message });
    }
}
function* applySelectCustomer(action) {
    try {
        if (action.type === types.CUSTOMER_SELECT_SET_UPDATE) {
            yield put({ type: CUSTOMER_INFO_FETCH_SUCCESS, customer: action.customer });
            // yield put({ type: 'START_CUSTOMER_BALANCES_POLLING' });
        }
    }
    catch (e) {
        yield put({ type: types.CUSTOMER_SEARCH_FETCH_FAIL, message: e.message });
    }
}
function* pollCustomerBalances() {
    while (true) {
        try {
            const store = yield select();
            const { selectedCustomer } = store.customersSearch;
            const { customerBalanceLoading } = store.client;
            if (selectedCustomer === null) {
                return;
            }
            const balances = call(clientActions.fetchCustomerBalances, selectedCustomer.code);
            yield put({ type: CUSTOMER_BALANCES_FETCH_SUCCESS, balances });
            if (!customerBalanceLoading) {
                yield delay(5000);
            }
        }
        catch (error) {
            const message = composeErrorMessage(error, Messages.CUSTOMER_BALANCES_UPDATE);
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: CUSTOMER_BALANCES_FETCH_FAIL, message });
            yield put({ type: 'STOP_CUSTOMER_BALANCES_POLLING', error });
        }
    }
}
// eslint-disable-next-line no-unused-vars
function* pollTaskWatcher() {
    while (true) {
        yield take('START_CUSTOMER_BALANCES_POLLING');
        yield race([call(pollCustomerBalances), take('STOP_CUSTOMER_BALANCES_POLLING')]);
    }
}
// eslint-disable-next-line import/prefer-default-export
export const customerSearchSagas = [
    takeLatest(types.CUSTOMER_SEARCH_FETCH_REQUEST, fetchCustomers),
    takeLatest(types.CUSTOMER_SELECT_SET_UPDATE, applySelectCustomer),
    pollTaskWatcher(),
];
