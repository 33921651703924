export const widgetsRfq = [{
        name: 'Exchange balances',
        layoutProps: {
            w: 3,
            h: 17,
            x: 12,
            y: 19,
            minW: 3,
            minH: 17,
            i: 'exchangesBalancesRfq',
            moved: false,
            static: false,
        },
        type: 'exchangesBalances',
        widgetKey: 'exchangesBalancesRfq',
        settings: ['exchangesBalancesRfqTitle'],
    },
    {
        name: 'TradingView chart',
        layoutProps: {
            w: 9,
            h: 12,
            x: 0,
            y: 0,
            i: 'tradingView',
            minW: 9,
            minH: 12,
            moved: false,
            static: false,
        },
        type: 'tradingView',
        widgetKey: 'tradingView',
        settings: [
            'tradingViewRfqTitle',
            'tradingViewRfqIgnoreGlobal',
            'tradingViewRfqBase',
            'tradingViewRfqQuote',
            'tradingViewRfqExchanges',
        ],
    },
    {
        name: 'Balances',
        layoutProps: {
            w: 3,
            h: 13,
            x: 9,
            y: 16,
            minW: 3,
            minH: 13,
            i: 'balancesRfq',
            moved: false,
            static: false,
        },
        type: 'balances',
        widgetKey: 'balancesRfq',
        settings: ['balancesRfqTitle'],
    },
    {
        name: 'Customers search',
        layoutProps: {
            w: 3,
            h: 15,
            x: 9,
            y: 0,
            i: 'customerSearchRfq',
            minW: 3,
            minH: 15,
            moved: false,
            static: false,
        },
        type: 'customerSearch',
        widgetKey: 'customerSearchRfq',
        settings: ['customerSearchRfqTitle'],
    },
    {
        name: 'Client search',
        layoutProps: {
            w: 3,
            h: 17,
            x: 6,
            y: 34,
            i: 'clientSearchRfq',
            minW: 3,
            minH: 17,
            moved: false,
            static: false,
        },
        type: 'clientSearch',
        widgetKey: 'clientSearchRfq',
        settings: ['clientSearchRfqTitle'],
    },
    {
        name: 'Rfq Orders',
        layoutProps: {
            w: 9,
            h: 28,
            x: 0,
            y: 0,
            minW: 9,
            minH: 28,
            i: 'rfqOrders',
            moved: false,
            static: false,
        },
        type: 'rfqOrders',
        widgetKey: 'rfqOrders',
        settings: [
            'rfqOrdersTitle',
            'rfqOrdersCard',
        ],
    },
    {
        name: 'Orders list',
        layoutProps: {
            w: 9,
            h: 18,
            x: 0,
            y: 16,
            i: 'ordersListRfq',
            minW: 9,
            minH: 18,
            moved: false,
            static: false,
        },
        type: 'ordersList',
        widgetKey: 'ordersListRfq',
        settings: ['ordersListRfqTitle',
            'ordersListRfqRegularToCancel',
            'ordersListRfqRegularOpen',
            'ordersListRfqRegularFilled',
            'ordersListRfqRegularRejected',
            'ordersListRfqRegularCanceled',
            'ordersListRfqRegularCanceledPartiallyFilled',
            'ordersListRfqRegularDateFrom',
            'ordersListRfqRegularDateTo',
            'ordersListRfqRegularLimit',
            'ordersListRfqRegularMarket',
            'ordersListRfqRegularRfqMarket',
            'ordersListRfqRegularRfqLimit',
            'ordersListRfqRegularActionTypes',
            'ordersListRfqRegularAmountFrom',
            'ordersListRfqRegularAmountTo',
            'ordersListRfqRegularUSD',
            'ordersListRfqRegularEUR',
            'ordersListRfqRegularBTC',
            'ordersListRfqRegularXRP',
            'ordersListRfqRegularLTC',
            'ordersListRfqRegularETH',
            'ordersListRfqRegularETC',
            'ordersListRfqRegularQTUM',
            'ordersListRfqRegularXLM',
            'ordersListRfqPartialOrderbookOpen',
            'ordersListRfqPartialOrderbookFilled',
            'ordersListRfqPartialOrderbookExpired',
            'ordersListRfqPartialOrderbookCanceled',
            'ordersListRfqPartialOrderbookToCancel',
            'ordersListRfqPartialOrderbookCanceledPartiallyFilled',
            'ordersListRfqPartialOrderbookDateFrom',
            'ordersListRfqPartialOrderbookDateTo',
            'ordersListRfqPartialOrderbookBuy',
            'ordersListRfqPartialOrderbookSell',
            'ordersListRfqPartialOrderbookAmountFrom',
            'ordersListRfqPartialOrderbookAmountTo',
            'ordersListRfqPartialOrderbookUSD',
            'ordersListRfqPartialOrderbookEUR',
            'ordersListRfqPartialOrderbookBTC',
            'ordersListRfqPartialOrderbookXRP',
            'ordersListRfqPartialOrderbookLTC',
            'ordersListRfqPartialOrderbookETH',
            'ordersListRfqPartialOrderbookETC',
            'ordersListRfqPartialOrderbookQTUM',
            'ordersListRfqPartialOrderbookXLM',
            'ordersListRfqSubordersToCancel',
            'ordersListRfqSubordersOpen',
            'ordersListRfqSubordersFilled',
            'ordersListRfqSubordersRejected',
            'ordersListRfqSubordersCanceled',
            'ordersListRfqSubordersCanceledPartiallyFilled',
            'ordersListRfqSubordersDateFrom',
            'ordersListRfqSubordersDateTo',
            'ordersListRfqSubordersLimit',
            'ordersListRfqSubordersMarket',
            'ordersListRfqSubordersActionTypes',
            'ordersListRfqSubordersAmountFrom',
            'ordersListRfqSubordersAmountTo',
            'ordersListRfqSubordersUSD',
            'ordersListRfqSubordersEUR',
            'ordersListRfqSubordersBTC',
            'ordersListRfqSubordersXRP',
            'ordersListRfqSubordersLTC',
            'ordersListRfqSubordersETH',
            'ordersListRfqSubordersETC',
            'ordersListRfqSubordersQTUM',
            'ordersListRfqSubordersXLM',
            'ordersListRfqSubordersPrepared',
        ],
    },
];
export const widgets = [{
        name: 'Balances',
        layoutProps: {
            w: 3,
            h: 16,
            x: 3,
            y: 34,
            minW: 3,
            minH: 16,
            i: 'balances',
            moved: false,
            static: false,
        },
        type: 'balances',
        widgetKey: 'balances',
        settings: ['balancesTitle'],
    },
    {
        name: 'Exchange balances',
        layoutProps: {
            w: 3,
            h: 11,
            x: 0,
            y: 0,
            minW: 3,
            minH: 11,
            i: 'exchangesBalances',
            moved: false,
            static: false,
        },
        type: 'exchangesBalances',
        widgetKey: 'exchangesBalances',
        settings: ['exchangesBalancesTitle'],
    },
    {
        name: 'Trade history',
        layoutProps: {
            w: 3,
            h: 12,
            x: 9,
            y: 21,
            i: 'tradeHistory',
            minW: 3,
            minH: 12,
            moved: false,
            static: false,
        },
        type: 'tradeHistory',
        widgetKey: 'tradeHistory',
        settings: [
            'tradeHistoryTitle',
            'tradeHistoryIgnoreGlobal',
            'tradeHistoryBase',
            'tradeHistoryQuote',
            'tradeHistoryExchanges',
        ],
    },
    {
        name: 'Place order',
        layoutProps: {
            w: 3,
            h: 24,
            x: 0,
            y: 9,
            i: 'placeOrder',
            minW: 3,
            minH: 24,
            moved: false,
            static: false,
        },
        type: 'placeOrder',
        widgetKey: 'placeOrder',
        settings: ['placeOrderTitle'],
    },
    {
        name: 'Customers search',
        layoutProps: {
            w: 3,
            h: 16,
            x: 0,
            y: 33,
            i: 'customerSearch',
            minW: 3,
            minH: 16,
            moved: false,
            static: false,
        },
        type: 'customerSearch',
        widgetKey: 'customerSearch',
        settings: ['customerSearchTitle'],
    },
    {
        name: 'Client search',
        layoutProps: {
            w: 3,
            h: 17,
            x: 6,
            y: 34,
            i: 'clientSearch',
            minW: 3,
            minH: 17,
            moved: false,
            static: false,
        },
        type: 'clientSearch',
        widgetKey: 'clientSearch',
        settings: ['clientSearchTitle'],
    },
    {
        name: 'TradingView chart',
        layoutProps: {
            w: 6,
            h: 11,
            x: 3,
            y: 0,
            i: 'tradingView',
            minW: 6,
            minH: 11,
            moved: false,
            static: false,
        },
        type: 'tradingView',
        widgetKey: 'tradingView',
        settings: [
            'tradingViewTitle',
            'tradingViewIgnoreGlobal',
            'tradingViewBase',
            'tradingViewQuote',
            'tradingViewExchanges',
        ],
    },
    {
        name: 'Market overview',
        layoutProps: {
            w: 6,
            h: 9,
            x: 3,
            y: 25,
            i: 'marketOverview',
            minW: 6,
            minH: 9,
            moved: false,
            static: false,
        },
        type: 'marketOverview',
        widgetKey: 'marketOverview',
        settings: ['marketOverviewTitle'],
    },
    {
        name: 'Price Ladder',
        layoutProps: {
            w: 3,
            h: 19,
            x: 9,
            y: 0,
            i: 'aggregatedOrderbook',
            minW: 3,
            minH: 19,
            moved: false,
            static: false,
        },
        type: 'aggregatedOrderbook',
        widgetKey: 'aggregatedOrderbook',
        settings: [
            'aggregatedOrderbookTitle',
            'aggregatedOrderbookIgnoreGlobal',
            'aggregatedOrderbookBase',
            'aggregatedOrderbookQuote',
            'aggregatedOrderbookExchanges',
        ],
    },
    {
        name: 'Orders list',
        layoutProps: {
            w: 6,
            h: 17,
            x: 3,
            y: 11,
            i: 'ordersList',
            minW: 5,
            minH: 17,
            moved: false,
            static: false,
        },
        type: 'ordersList',
        widgetKey: 'ordersList',
        settings: ['ordersListTitle',
            'ordersListRegularToCancel',
            'ordersListRegularOpen',
            'ordersListRegularFilled',
            'ordersListRegularRejected',
            'ordersListRegularCanceled',
            'ordersListRegularPendingSign',
            'ordersListRegularCanceledPartiallyFilled',
            'ordersListRegularDateFrom',
            'ordersListRegularDateTo',
            'ordersListRegularKraken',
            'ordersListRegularBitstamp',
            'ordersListRegularCex',
            'ordersListRegularExmo',
            'ordersListRegularLmax',
            'ordersListRegularLimit',
            'ordersListRegularMarket',
            'ordersListRegularRfqMarket',
            'ordersListRegularRfqLimit',
            'ordersListRegularActionTypes',
            'ordersListRegularAmountFrom',
            'ordersListRegularAmountTo',
            'ordersListRegularUSD',
            'ordersListRegularEUR',
            'ordersListRegularBTC',
            'ordersListRegularXRP',
            'ordersListRegularLTC',
            'ordersListRegularETH',
            'ordersListRegularETC',
            'ordersListRegularQTUM',
            'ordersListRegularXLM',
            'ordersListRegularFakeBitstamp',
            'ordersListRegularFakeKraken',
            'ordersListPartialOrderbookOpen',
            'ordersListPartialOrderbookFilled',
            'ordersListPartialOrderbookExpired',
            'ordersListPartialOrderbookCanceled',
            'ordersListPartialOrderbookToCancel',
            'ordersListPartialOrderbookPendingSign',
            'ordersListPartialOrderbookCanceledPartiallyFilled',
            'ordersListPartialOrderbookDateFrom',
            'ordersListPartialOrderbookDateTo',
            'ordersListPartialOrderbookBuy',
            'ordersListPartialOrderbookSell',
            'ordersListPartialOrderbookAmountFrom',
            'ordersListPartialOrderbookAmountTo',
            'ordersListPartialOrderbookUSD',
            'ordersListPartialOrderbookEUR',
            'ordersListPartialOrderbookBTC',
            'ordersListPartialOrderbookXRP',
            'ordersListPartialOrderbookLTC',
            'ordersListPartialOrderbookETH',
            'ordersListPartialOrderbookETC',
            'ordersListPartialOrderbookQTUM',
            'ordersListPartialOrderbookXLM',
            'ordersListSubordersToCancel',
            'ordersListSubordersOpen',
            'ordersListSubordersFilled',
            'ordersListSubordersRejected',
            'ordersListSubordersCanceled',
            'ordersListSubordersPendingSign',
            'ordersListSubordersCanceledPartiallyFilled',
            'ordersListSubordersDateFrom',
            'ordersListSubordersDateTo',
            'ordersListSubordersKraken',
            'ordersListSubordersBitstamp',
            'ordersListSubordersCex',
            'ordersListSubordersExmo',
            'ordersListSubordersLmax',
            'ordersListSubordersLimit',
            'ordersListSubordersMarket',
            'ordersListSubordersActionTypes',
            'ordersListSubordersAmountFrom',
            'ordersListSubordersAmountTo',
            'ordersListSubordersUSD',
            'ordersListSubordersEUR',
            'ordersListSubordersBTC',
            'ordersListSubordersXRP',
            'ordersListSubordersLTC',
            'ordersListSubordersETH',
            'ordersListSubordersETC',
            'ordersListSubordersQTUM',
            'ordersListSubordersXLM',
            'ordersListSubordersFakeBitstamp',
            'ordersListSubordersFakeKraken',
            'ordersListSubordersPrepared',
        ],
    },
];
export const permissionsWidgets = [{
        name: 'Permissions',
        layoutProps: {
            w: 12,
            h: 66,
            x: 0,
            y: 0,
            i: 'permissions',
            minW: 12,
            minH: 66,
            moved: false,
            static: false,
        },
        type: 'permissions',
        widgetKey: 'permissions',
        settings: [
            'permissionsTitle',
            'permissionsColumnsResource',
            'permissionsColumnsCreate',
            'permissionsColumnsRead',
            'permissionsColumnsUpdate',
            'permissionsColumnsDelete',
            'permissionsColumnsCategory',
            'permissionsColumnsDescription',
        ],
    },
];
export const custodyWidgets = [{
        name: 'Wallets',
        layoutProps: {
            w: 9,
            h: 21,
            x: 0,
            y: 0,
            minW: 3,
            minH: 21,
            i: 'wallets',
            moved: false,
            static: false,
        },
        type: 'wallets',
        widgetKey: 'wallets',
        settings: ['walletsTitle',
            'walletsColumnsCurrency',
            'walletsColumnsDepositAddress',
            'walletsColumnsLabel',
            'walletsColumnsAccountingType',
            'walletsColumnsCreatedAt',
            'walletsColumnsBalanceAvailable',
            'walletsColumnsUsdAvailable',
            'walletsColumnsBalanceLocked',
            'walletsColumnsUsdLocked',
            'walletsColumnsBalanceTotal',
            'walletsColumnsUsdTotal',
            'walletsColumnsState',
            'walletsColumnsCustomer',
        ],
    }, {
        name: 'Deposits',
        layoutProps: {
            w: 6,
            h: 21,
            x: 0,
            y: 21,
            minW: 3,
            minH: 21,
            i: 'deposits',
            moved: false,
            static: false,
        },
        type: 'deposits',
        widgetKey: 'deposits',
        settings: [
            'depositsTitle',
            'depositsColumnsId',
            'depositsColumnsAmount',
            'depositsColumnsUsdAmount',
            'depositsColumnsAddress',
            'depositsColumnsLabel',
            'depositsColumnsTXHash',
            'depositsColumnsReceivedAt',
            'depositsColumnsReferenceId',
            'depositsColumnsState',
        ],
    }, {
        name: 'Withdrawals',
        layoutProps: {
            w: 6,
            h: 21,
            x: 6,
            y: 18,
            i: 'withdrawals',
            minW: 3,
            minH: 21,
            moved: false,
            static: false,
        },
        type: 'withdrawals',
        widgetKey: 'withdrawals',
        settings: [
            'withdrawalsTitle',
            'withdrawalsColumnsCode',
            'withdrawalsColumnsAmount',
            'withdrawalsColumnsUsdAmount',
            'withdrawalsColumnsAddress',
            'withdrawalsColumnsAddressLabel',
            'withdrawalsColumnsSourceAddress',
            'withdrawalsColumnsWalletCode',
            'withdrawalsColumnsLabel',
            'withdrawalsColumnsCustomer',
            'withdrawalsColumnsTXHash',
            'withdrawalsColumnsFee',
            'withdrawalsColumnsUsdFee',
            'withdrawalsColumnsType',
            'withdrawalsColumnsCreatedAt',
            'withdrawalsColumnsReferenceID',
            'withdrawalsColumnsState',
        ],
    }, {
        name: 'Addresses',
        layoutProps: {
            w: 12,
            h: 20,
            x: 0,
            y: 50,
            i: 'addresses',
            minW: 3,
            minH: 20,
            moved: false,
            static: false,
        },
        type: 'addresses',
        widgetKey: 'addresses',
        settings: [
            'addressesTitle',
            'addressesColumnsCode',
            'addressesColumnsAddress',
            'addressesColumnsLabel',
            'addressesColumnsCurrency',
            'addressesColumnsWalletLabel',
            'addressesColumnsCreatedAt',
            'addressesColumnsUpdatedAt',
            'addressesColumnsState',
        ],
    }, {
        name: 'Internal Transfers',
        layoutProps: {
            w: 12,
            h: 16,
            x: 0,
            y: 107,
            i: 'internalTransfers',
            minW: 3,
            minH: 16,
            moved: false,
            static: false,
        },
        type: 'internalTransfers',
        widgetKey: 'internalTransfers',
        settings: [
            'internalTransfersTitle',
            'internalTransfersColumnsCode',
            'internalTransfersColumnsClientCode',
            'internalTransfersColumnsCurrency',
            'internalTransfersColumnsDestination',
            'internalTransfersColumnsSource',
            'internalTransfersColumnsDirection',
            'internalTransfersColumnsState',
            'internalTransfersColumnsAmount',
            'internalTransfersColumnsCreated',
            'internalTransfersColumnsUpdated',
        ]
    }, {
        name: 'Approve',
        layoutProps: {
            w: 12,
            h: 21,
            x: 0,
            y: 68,
            i: 'approve',
            minW: 3,
            minH: 10,
            moved: false,
            static: false,
        },
        type: 'approve',
        widgetKey: 'approve',
        settings: [
            'approveTitle',
            'addressesApproveColumnsAddress',
            'addressesApproveColumnsAmount',
            'addressesApproveColumnsCurrency',
            'addressesApproveColumnsCreator',
            'addressesApproveColumnsCreated',
            'addressesApproveColumnsUpdated',
            'withdrawalsApproveColumnsAddress',
            'withdrawalsApproveColumnsAmount',
            'withdrawalsApproveColumnsCurrency',
            'withdrawalsApproveColumnsCreator',
            'withdrawalsApproveColumnsCreated',
            'withdrawalsApproveColumnsUpdated',
        ],
    }, {
        name: 'Clients',
        layoutProps: {
            w: 12,
            h: 21,
            x: 0,
            y: 86,
            i: 'clients',
            minW: 3,
            minH: 10,
            moved: false,
            static: false,
        },
        type: 'clients',
        widgetKey: 'clients',
        settings: [
            'clientsTitle',
            'clientsColumnsCurrency',
            'clientsColumnsDepositAddress',
            'clientsColumnsLabel',
            'clientsColumnsType',
            'clientsColumnsCreatedAt',
            'clientsColumnsBalanceAvailable',
            'clientsColumnsUsdAvailable',
            'clientsColumnsBalanceLocked',
            'clientsColumnsUsdLocked',
            'clientsColumnsBalanceTotal',
            'clientsColumnsUsdTotal',
            'clientsColumnsState',
            'clientsColumnsCustomer',
        ],
    }, {
        name: 'Balances',
        layoutProps: {
            w: 3,
            h: 21,
            x: 9,
            y: 0,
            minW: 3,
            minH: 16,
            i: 'balancesCustody',
            moved: false,
            static: false,
        },
        type: 'balancesCustody',
        widgetKey: 'balancesCustody',
        settings: ['balancesCustodyTitle'],
    },
];
export const manageOrdersWidgets = [{
        name: 'ManageOrders',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 24,
            i: 'manageOrders',
            moved: false,
            static: false,
        },
        type: 'manageOrders',
        widgetKey: 'manageOrders',
        settings: [
            'manageOrdersTitle',
            'manageOrdersColumnsActionType',
            'manageOrdersColumnsSize',
            'manageOrdersColumnsPair',
            'manageOrdersColumnsExchange',
            'manageOrdersColumnsExecutedPrice',
            'manageOrdersColumnsTotal',
            'manageOrdersColumnsCreated',
            'manageOrdersColumnsStatus',
        ],
    },
];
export const manageCustomersWidgets = [{
        name: 'ManageCustomers',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'manageCustomers',
            moved: false,
            static: false,
        },
        type: 'manageCustomers',
        widgetKey: 'manageCustomers',
        settings: [
            'manageCustomersTitle',
            'manageCustomersColumnsClientCode',
            'manageCustomersColumnsCompanyName',
            'manageCustomersColumnsContactPerson',
            'manageCustomersColumnsAddress',
            'manageCustomersColumnsCity',
            'manageCustomersColumnsCountry',
            'manageCustomersColumnsPhone',
            'manageCustomersColumnsEmail',
        ],
    },
];
export const strategiesWidgets = [{
        name: 'Strategies',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'strategies',
            moved: false,
            static: false,
        },
        type: 'strategies',
        widgetKey: 'strategies',
        settings: [
            'strategiesTitle',
            'strategiesColumnsCode',
            'strategiesColumnsName',
            'strategiesColumnsClientCode',
            'strategiesColumnsCustomerCode',
            'strategiesColumnsStatus',
            'strategiesColumnsCreated',
        ],
    },
];
export const manageUsersWidgets = [{
        name: 'ManageUsers',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'manageUsers',
            moved: false,
            static: false,
        },
        type: 'manageUsers',
        widgetKey: 'manageUsers',
        settings: [
            'manageUsersTitle',
            'manageUsersColumnsCode',
            'manageUsersColumnsStatus',
            'manageUsersColumnsEmail',
            'manageUsersColumnsName',
            'manageUsersColumnsRole',
            'manageUsersColumnsCustomer',
            'manageUsersColumnsCreatedAt',
        ],
    }, {
        name: 'ManageInvites',
        layoutProps: {
            w: 12,
            h: 20,
            x: 0,
            y: 22,
            minW: 6,
            minH: 20,
            i: 'manageInvites',
            moved: false,
            static: false,
        },
        type: 'manageInvites',
        widgetKey: 'manageInvites',
        settings: [
            'manageInvitesTitle',
            'manageInvitesColumnsCreatedAt',
            'manageInvitesColumnsEmail',
            'manageInvitesColumnsRole',
            'manageInvitesColumnsCustomer',
        ],
    },
];
export const manageLogbookWidgets = [{
        name: 'ManageLogbook',
        layoutProps: {
            w: 12,
            h: 21,
            x: 0,
            y: 0,
            minW: 3,
            minH: 21,
            i: 'manageLogbook',
            moved: false,
            static: false,
        },
        type: 'manageLogbook',
        widgetKey: 'manageLogbook',
        settings: [
            'manageLogbookTitle',
            'manageLogbookColumnsCode',
            'manageLogbookColumnsUserId',
            'manageLogbookColumnsApiSource',
            'manageLogbookColumnsEventName',
            'manageLogbookColumnsServiceName',
            'manageLogbookColumnsControllerName',
            'manageLogbookColumnsRequestCode',
            'manageLogbookColumnsCreatedAt',
        ],
    },
];
export const manageTokensWidgets = [{
        name: 'ManageTokens',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'manageTokens',
            moved: false,
            static: false,
        },
        type: 'manageTokens',
        widgetKey: 'manageTokens',
        settings: [
            'manageTokensTitle',
            'manageTokensColumnsLabel',
            'manageTokensColumnsPublicKey',
            'manageTokensColumnsCreatedAt',
        ],
    },
];
export const tradingTransfersWidgets = [{
        name: 'TradingTransfers',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'tradingTransfers',
            moved: false,
            static: false,
        },
        type: 'tradingTransfers',
        widgetKey: 'tradingTransfers',
        settings: [
            'tradingTransfersTitle',
            'tradingTransfersColumnsCurrency',
            'tradingTransfersColumnsAmount',
            'tradingTransfersColumnsTXCode',
            'tradingTransfersColumnsAddressLabel',
            'tradingTransfersColumnsTXDetails',
            'tradingTransfersColumnsDestination',
            'tradingTransfersColumnsCustomer',
            'tradingTransfersColumnsClient',
            'tradingTransfersColumnsCreator',
            'tradingTransfersColumnsCreated',
            'tradingTransfersColumnsState',
            'tradingTransfersColumnsType',
            'tradingTransfersColumnsDate',
        ],
    },
];
export const manageSchedulersWidgets = [{
        name: 'ManageSchedulers',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'manageSchedulers',
            moved: false,
            static: false,
        },
        type: 'manageSchedulers',
        widgetKey: 'manageSchedulers',
        settings: [
            'manageSchedulersTitle',
            'manageSchedulersColumnsName',
            'manageSchedulersColumnsRunningInterval',
            'manageSchedulersColumnsMaxExecutionDuration',
            'manageSchedulersColumnsLastExecutionDuration',
            'manageSchedulersColumnsLastError',
            'manageSchedulersColumnsLastRun',
        ],
    },
];
export const adminExchangeWidgets = [{
        name: 'AdminExchange',
        layoutProps: {
            w: 12,
            h: 22,
            x: 0,
            y: 0,
            minW: 3,
            minH: 22,
            i: 'adminExchange',
            moved: false,
            static: false,
        },
        type: 'adminExchange',
        widgetKey: 'adminExchange',
        settings: [
            'adminExchangeTitle',
            'adminExchangeColumnsBase',
            'adminExchangeColumnsQuote',
        ],
    },
];
export const manageComplianceWidgets = [{
        name: 'ManageCompliance',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 14,
            i: 'manageCompliance',
            moved: false,
            static: false,
        },
        type: 'manageCompliance',
        widgetKey: 'manageCompliance',
        settings: [
            'manageComplianceTitle',
        ],
    }, {
        name: 'ManageApprove',
        layoutProps: {
            w: 12,
            h: 21,
            x: 5,
            y: 24,
            minW: 3,
            minH: 18,
            i: 'manageApprove',
            moved: false,
            static: false,
        },
        type: 'manageApprove',
        widgetKey: 'manageApprove',
        settings: [
            'manageApproveTitle',
            'addressesApproveComplianceColumnsAddress',
            'addressesApproveComplianceColumnsAmount',
            'addressesApproveComplianceColumnsCreator',
            'withdrawalsApproveComplianceColumnsAddress',
            'withdrawalsApproveComplianceColumnsAmount',
            'withdrawalsApproveComplianceColumnsCreator',
        ],
    }, {
        name: 'ComplianceTransactions',
        layoutProps: {
            w: 12,
            h: 19,
            x: 0,
            y: 44,
            minW: 3,
            minH: 19,
            i: 'complianceTransactions',
            moved: false,
            static: false,
        },
        type: 'complianceTransactions',
        widgetKey: 'complianceTransactions',
        settings: [
            'complianceTransactionsTitle',
            'complianceTransactionsColumnsCode',
            'complianceTransactionsColumnsState',
            'complianceTransactionsColumnsCurrencyCode',
            'complianceTransactionsColumnsType',
            'complianceTransactionsColumnsTxHash',
            'complianceTransactionsColumnsAmount',
            'complianceTransactionsColumnsUsdAmount',
            'complianceTransactionsColumnsCreatedAt',
            'complianceTransactionsColumnsFee',
            'complianceTransactionsColumnsNote',
            'complianceTransactionsColumnsRiskScore',
        ],
    }, {
        name: 'ComplianceWallets',
        layoutProps: {
            w: 12,
            h: 21,
            x: 0,
            y: 63,
            minW: 3,
            minH: 21,
            i: 'complianceWallets',
            moved: false,
            static: false,
        },
        type: 'complianceWallets',
        widgetKey: 'complianceWallets',
        settings: [
            'complianceWalletsTitle',
            'complianceWalletsColumnsCurrency',
            'complianceWalletsColumnsDepositAddress',
            'complianceWalletsColumnsLabel',
            'complianceWalletsColumnsType',
            'complianceWalletsColumnsCreatedAt',
            'complianceWalletsColumnsBalanceAvailable',
            'complianceWalletsColumnsUsdAvailable',
            'complianceWalletsColumnsBalanceLocked',
            'complianceWalletsColumnsUsdLocked',
            'complianceWalletsColumnsBalanceTotal',
            'complianceWalletsColumnsUsdTotal',
            'complianceWalletsColumnsState',
            'complianceWalletsColumnsCustomer',
            'complianceWalletsColumnsNote',
        ],
    },
];
export const manageWhitelistingWidgets = [{
        name: 'ManageWhitelist',
        layoutProps: {
            w: 12,
            h: 21,
            x: 5,
            y: 24,
            minW: 3,
            minH: 18,
            i: 'manageWhitelist',
            moved: false,
            static: false,
        },
        type: 'manageWhitelist',
        widgetKey: 'manageWhitelist',
        settings: [
            'manageWhitelistTitle',
            'addressesApproveWhitelistColumnsAddress',
            'addressesApproveWhitelistColumnsAmount',
            'addressesApproveWhitelistColumnsCurrency',
            'addressesApproveWhitelistColumnsCreator',
            'addressesApproveWhitelistColumnsCreated',
            'withdrawalsApproveWhitelistColumnsAddress',
            'withdrawalsApproveWhitelistColumnsAmount',
            'withdrawalsApproveWhitelistColumnsCurrency',
            'withdrawalsApproveWhitelistColumnsCreator',
            'withdrawalsApproveWhitelistColumnsCreated',
            'withdrawalsApproveWhitelistColumnsUpdated',
            // 'addressesApproveColumnsAddress',
            // 'addressesApproveColumnsAmount',
            // 'addressesApproveColumnsCurrency',
            // 'addressesApproveColumnsCreator',
            // 'addressesApproveColumnsCreated',
            // 'addressesApproveColumnsUpdated',
            // 'withdrawalsApproveColumnsAddress',
            // 'withdrawalsApproveColumnsAmount',
            // 'withdrawalsApproveColumnsCurrency',
            // 'withdrawalsApproveColumnsCreator',
            // 'withdrawalsApproveColumnsCreated',
            // 'withdrawalsApproveColumnsUpdated',
        ],
    },
];
export const rfqManagementWidgets = [{
        name: 'QuoteMonitor',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 14,
            i: 'quoteMonitor',
            moved: false,
            static: false,
        },
        type: 'quoteMonitor',
        widgetKey: 'quoteMonitor',
        settings: [
            'quoteMonitorTitle',
            'quoteMonitorColumnsUL',
            'quoteMonitorColumnsRefExchange',
            'quoteMonitorColumnsLabel',
            'quoteMonitorColumnsStreamType',
            'quoteMonitorColumnsSpreadMode',
            'quoteMonitorColumnsCalculationMode',
            'quoteMonitorColumnsMinSpread',
            'quoteMonitorColumnsUsedSpread',
            'quoteMonitorColumnsWideningFactor',
            'quoteMonitorColumnsNotional',
            'quoteMonitorColumnsRefPrice',
            'quoteMonitorColumnsUsedRefPrice',
            'quoteMonitorColumnsGuaranteedQTY',
            'quoteMonitorColumnsULShift',
            'quoteMonitorColumnsMethod',
            'quoteMonitorColumnsMarketFee',
            'quoteMonitorColumnsRTExchSpread',
            'quoteMonitorColumnsStatus',
        ],
    },
];
export const rfqClientsManagementWidgets = [{
        name: 'ClientsManagement',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 14,
            i: 'clientsManagement',
            moved: false,
            static: false,
        },
        type: 'clientsManagement',
        widgetKey: 'clientsManagement',
        settings: [
            'clientsManagementTitle',
            'clientsManagementColumnsLabel',
            'clientsManagementColumnsStreamType',
            'clientsManagementColumnsUL',
            'clientsManagementColumnsRefExchange',
            'clientsManagementColumnsGuaranteedQTY',
            'clientsManagementColumnsMinSpread',
            'clientsManagementColumnsUsedSpread',
            'clientsManagementColumnsType',
            'clientsManagementColumnsCreated',
        ],
    },
];
export const currencyConfigWidgets = [{
        name: 'CurrencyConfig',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 14,
            i: 'currencyConfig',
            moved: false,
            static: false,
        },
        type: 'currencyConfig',
        widgetKey: 'currencyConfig',
        settings: [
            'currencyConfigTitle',
            'currencyConfigColumnsCode',
            'currencyConfigColumnsCurrencyCode',
            'currencyConfigColumnsLabel',
            'currencyConfigColumnsFee',
            'currencyConfigColumnsMinimumDepositSize',
            'currencyConfigColumnsPrecision',
            'currencyConfigColumnsFireblocksAssetId',
            'currencyConfigColumnsContractAddress',
            'currencyConfigColumnsExplorerUrl',
            'currencyConfigColumnsTestNet',
            'currencyConfigColumnsBlockchainNetwork',
        ],
    },
];
export const autohedgerBalanceWidgets = [{
        name: 'AutohedgerBalance',
        layoutProps: {
            w: 12,
            h: 19,
            x: 0,
            y: 0,
            minW: 6,
            minH: 14,
            i: 'autohedgerBalance',
            moved: false,
            static: false,
        },
        type: 'autohedgerBalance',
        widgetKey: 'autohedgerBalance',
        settings: [
            'autohedgerBalanceTitle',
            'autohedgerBalanceColumnsBase',
            'autohedgerBalanceColumnsQuote',
            'autohedgerBalanceColumnsTotalFiat',
            'autohedgerBalanceColumnsTotalCrypto',
            'autohedgerBalanceColumnsType',
        ],
    },
    {
        name: 'Autohedger',
        layoutProps: {
            w: 12,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 14,
            i: 'autohedger',
            moved: false,
            static: false,
        },
        type: 'autohedger',
        widgetKey: 'autohedger',
        settings: [
            'autohedgerTitle',
            'autohedgerColumnsAutoHedging',
            'autohedgerColumnsBase',
            'autohedgerColumnsQuote',
            'autohedgerColumnsMinAmount',
            'autohedgerColumnsDailyLimit',
            'autohedgerColumnsThreshold',
            'autohedgerColumnsCreated',
            'autohedgerColumnsUpdated',
        ],
    },
];
export const manageComplianceTradingWidgets = [
    {
        name: 'ManageComplianceTradingStats',
        layoutProps: {
            w: 6,
            h: 19,
            x: 0,
            y: 0,
            minW: 6,
            minH: 14,
            i: 'manageComplianceTrading',
            moved: false,
            static: false,
        },
        type: 'manageComplianceTrading',
        widgetKey: 'manageComplianceTrading',
        settings: [
            'manageComplianceTradingTitle',
        ],
    },
    {
        name: 'ManageComplianceTradingChart',
        layoutProps: {
            w: 6,
            h: 19,
            x: 6,
            y: 0,
            minW: 6,
            minH: 14,
            i: 'complianceTradingCharts',
            moved: false,
            static: false,
        },
        type: 'complianceTradingCharts',
        widgetKey: 'complianceTradingCharts',
        settings: [
            'complianceTradingChartsTitle',
        ],
    },
];
export const portfolioWidgets = [{
        name: 'Portfolio',
        layoutProps: {
            w: 8,
            h: 24,
            x: 0,
            y: 0,
            minW: 6,
            minH: 24,
            i: 'portfolio',
            moved: false,
            static: false,
        },
        type: 'portfolio',
        widgetKey: 'portfolio',
        settings: [
            'portfolioTitle',
        ],
    },
];
export const hedgeOrdersWidgets = [
    {
        name: 'HedgeOrders',
        layoutProps: {
            w: 4,
            h: 24,
            x: 0,
            y: 0,
            minW: 3,
            minH: 24,
            i: 'hedgeOrders',
            moved: false,
            static: false,
        },
        type: 'hedgeOrders',
        widgetKey: 'hedgeOrders',
        settings: [
            'hedgeOrdersTitle',
        ],
    },
    {
        name: 'PlaceOrders',
        layoutProps: {
            w: 12,
            h: 24,
            x: 12,
            y: 0,
            minW: 3,
            minH: 24,
            i: 'placeOrders',
            moved: false,
            static: false,
        },
        type: 'placeOrders',
        widgetKey: 'placeOrders',
        settings: [
            'placeOrdersTitle',
            'placeOrdersColumnsActionType',
            'placeOrdersColumnsSize',
            'placeOrdersColumnsPair',
            'placeOrdersColumnsExchange',
            'placeOrdersColumnsExecutedPrice',
            'placeOrdersColumnsTotal',
            'placeOrdersColumnsCreated',
            'placeOrdersColumnsStatus',
        ],
    },
];
export const defaultWorkspaceRecord = () => ({
    id: 'system',
    title: 'System workspace',
    widgets,
    widgetSettings: {
        byId: {
            balancesTitle: {
                id: 'balancesTitle',
                title: 'Widget title',
                type: 'text',
                value: 'balances',
            },
            exchangesBalancesTitle: {
                id: 'exchangesBalancesTitle',
                title: 'Widget title',
                type: 'text',
                value: 'reserves',
            },
            tradeHistoryTitle: {
                id: 'tradeHistoryTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trade history',
            },
            tradeHistoryIgnoreGlobal: {
                id: 'tradeHistoryIgnoreGlobal',
                name: 'Ignore global',
                label: '',
                title: 'Ignore global settings',
                type: 'checkbox',
                value: false,
            },
            tradeHistoryExchanges: {
                id: 'tradeHistoryExchanges',
                name: 'Exchanges',
                label: 'Exchanges',
                title: 'Exchanges',
                type: 'select',
                value: null,
                options: [],
            },
            tradeHistoryQuote: {
                id: 'tradeHistoryQuote',
                name: 'Quote',
                label: 'Quote',
                title: 'Quote',
                type: 'select',
                value: null,
                options: [],
            },
            tradeHistoryBase: {
                id: 'tradeHistoryBase',
                name: 'Base',
                label: 'Base',
                title: 'Base',
                type: 'select',
                value: null,
                options: [],
            },
            placeOrderTitle: {
                id: 'placeOrderTitle',
                title: 'Widget title',
                type: 'text',
                value: 'place order',
            },
            tradingViewTitle: {
                id: 'tradingViewTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trading overview',
            },
            tradingViewIgnoreGlobal: {
                id: 'tradingViewIgnoreGlobal',
                name: 'Ignore global',
                label: '',
                title: 'Ignore global settings',
                type: 'checkbox',
                value: false,
            },
            tradingViewBase: {
                id: 'tradingViewBase',
                name: 'Base',
                label: 'Base',
                title: 'Base',
                type: 'select',
                value: null,
                options: [],
            },
            tradingViewQuote: {
                id: 'tradingViewQuote',
                name: 'Quote',
                label: 'Quote',
                title: 'Quote',
                type: 'select',
                value: null,
                options: [],
            },
            tradingViewExchanges: {
                id: 'tradingViewExchanges',
                name: 'Exchanges',
                label: 'Exchanges',
                title: 'Exchanges',
                type: 'select',
                value: null,
                options: [],
            },
            // Regular orders
            ordersListTitle: {
                id: 'ordersListTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Orders',
            },
            ordersListRegularToCancel: {
                id: 'ordersListRegularToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'To Cancel',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularOpen: {
                id: 'ordersListRegularOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularFilled: {
                id: 'ordersListRegularFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularRejected: {
                id: 'ordersListRegularRejected',
                name: 'status',
                label: 'Rejected',
                title: 'Rejected',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularCanceled: {
                id: 'ordersListRegularCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularPendingSign: {
                id: 'ordersListRegularPendingSign',
                name: 'status',
                label: 'PendingSign',
                title: 'Pending Sign',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularCanceledPartiallyFilled: {
                id: 'ordersListRegularCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularDateFrom: {
                id: 'ordersListRegularDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListRegularDateTo: {
                id: 'ordersListRegularDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListRegularKraken: {
                id: 'ordersListRegularKraken',
                name: 'exchange_code',
                label: 'kraken',
                title: 'Kraken',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularBitstamp: {
                id: 'ordersListRegularBitstamp',
                name: 'exchange_code',
                label: 'bitstamp',
                title: 'Bitstamp',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularCex: {
                id: 'ordersListRegularCex',
                name: 'exchange_code',
                label: 'cex',
                title: 'Cex',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularExmo: {
                id: 'ordersListRegularExmo',
                name: 'exchange_code',
                label: 'exmo',
                title: 'Exmo',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularFakeBitstamp: {
                id: 'ordersListRegularFakeBitstamp',
                name: 'exchange_code',
                label: 'fake_bitstamp',
                title: 'Bitstamp*',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularFakeKraken: {
                id: 'ordersListRegularFakeKraken',
                name: 'exchange_code',
                label: 'fake_kraken',
                title: 'Kraken*',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularLmax: {
                id: 'ordersListRegularLmax',
                name: 'exchange_code',
                label: 'lmax',
                title: 'Lmax',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularLimit: {
                id: 'ordersListRegularLimit',
                name: 'order_type',
                label: 'limit',
                title: 'Limit',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularMarket: {
                id: 'ordersListRegularMarket',
                name: 'order_type',
                label: 'market',
                title: 'Market',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularRfqMarket: {
                id: 'ordersListRegularRfqMarket',
                name: 'order_type',
                label: 'rfq_market',
                title: 'RFQ Market',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularRfqLimit: {
                id: 'ordersListRegularRfqLimit',
                name: 'order_type',
                label: 'rfq_limit',
                title: 'RFQ Limit',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularActionTypes: {
                id: 'ordersListRegularActionTypes',
                type: 'radio',
                name: 'action_type',
                title: 'Action types',
                value: '',
                options: [{ label: 'All', value: '' },
                    { label: 'Buy', value: 'Buy' },
                    { label: 'Sell', value: 'Sell' },
                ],
            },
            ordersListRegularAmountFrom: {
                id: 'ordersListRegularAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListRegularAmountTo: {
                id: 'ordersListRegularAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListRegularUSD: {
                id: 'ordersListRegularUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularEUR: {
                id: 'ordersListRegularEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularBTC: {
                id: 'ordersListRegularBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularXRP: {
                id: 'ordersListRegularXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularLTC: {
                id: 'ordersListRegularLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularETH: {
                id: 'ordersListRegularETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularETC: {
                id: 'ordersListRegularETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularQTUM: {
                id: 'ordersListRegularQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListRegularXLM: {
                id: 'ordersListRegularXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            // partial orders
            ordersListPartialOrderbookOpen: {
                id: 'ordersListPartialOrderbookOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookFilled: {
                id: 'ordersListPartialOrderbookFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookExpired: {
                id: 'ordersListPartialOrderbookExpired',
                name: 'status',
                label: 'Expired',
                title: 'Expired',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookCanceled: {
                id: 'ordersListPartialOrderbookCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookToCancel: {
                id: 'ordersListPartialOrderbookToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'ToCancel',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookPendingSign: {
                id: 'ordersListPartialOrderbookPendingSign',
                name: 'status',
                label: 'PendingSign',
                title: 'Pending Sign',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookCanceledPartiallyFilled: {
                id: 'ordersListPartialOrderbookCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookDateFrom: {
                id: 'ordersListPartialOrderbookDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListPartialOrderbookDateTo: {
                id: 'ordersListPartialOrderbookDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListPartialOrderbookBuy: {
                id: 'ordersListPartialOrderbookBuy',
                name: 'action_type',
                label: 'Buy',
                title: 'Buy',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookSell: {
                id: 'ordersListPartialOrderbookSell',
                name: 'action_type',
                label: 'Sell',
                title: 'Sell',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookAmountFrom: {
                id: 'ordersListPartialOrderbookAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListPartialOrderbookAmountTo: {
                id: 'ordersListPartialOrderbookAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListPartialOrderbookUSD: {
                id: 'ordersListPartialOrderbookUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookEUR: {
                id: 'ordersListPartialOrderbookEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookBTC: {
                id: 'ordersListPartialOrderbookBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookXRP: {
                id: 'ordersListPartialOrderbookXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookLTC: {
                id: 'ordersListPartialOrderbookLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookETH: {
                id: 'ordersListPartialOrderbookETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookETC: {
                id: 'ordersListPartialOrderbookETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookQTUM: {
                id: 'ordersListPartialOrderbookQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListPartialOrderbookXLM: {
                id: 'ordersListPartialOrderbookXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            // sub orders
            ordersListSubordersToCancel: {
                id: 'ordersListSubordersToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'To Cancel',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersOpen: {
                id: 'ordersListSubordersOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersFilled: {
                id: 'ordersListSubordersFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersRejected: {
                id: 'ordersListSubordersRejected',
                name: 'status',
                label: 'Rejected',
                title: 'Rejected',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersCanceled: {
                id: 'ordersListSubordersCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersPendingSign: {
                id: 'ordersListSubordersPendingSign',
                name: 'status',
                label: 'PendingSign',
                title: 'Pending Sign',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersCanceledPartiallyFilled: {
                id: 'ordersListSubordersCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersDateFrom: {
                id: 'ordersListSubordersDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListSubordersDateTo: {
                id: 'ordersListSubordersDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListSubordersKraken: {
                id: 'ordersListSubordersKraken',
                name: 'exchange_code',
                label: 'kraken',
                title: 'Kraken',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersBitstamp: {
                id: 'ordersListSubordersBitstamp',
                name: 'exchange_code',
                label: 'bitstamp',
                title: 'Bitstamp',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersCex: {
                id: 'ordersListSubordersCex',
                name: 'exchange_code',
                label: 'cex',
                title: 'Cex',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersExmo: {
                id: 'ordersListSubordersExmo',
                name: 'exchange_code',
                label: 'exmo',
                title: 'Exmo',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersFakeBitstamp: {
                id: 'ordersListSubordersFakeBitstamp',
                name: 'exchange_code',
                label: 'fake_bitstamp',
                title: 'Bitstamp*',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersFakeKraken: {
                id: 'ordersListSubordersFakeKraken',
                name: 'exchange_code',
                label: 'fake_kraken',
                title: 'Kraken*',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersLmax: {
                id: 'ordersListSubordersLmax',
                name: 'exchange_code',
                label: 'lmax',
                title: 'Lmax',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersLimit: {
                id: 'ordersListSubordersLimit',
                name: 'order_type',
                label: 'limit',
                title: 'Limit',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersMarket: {
                id: 'ordersListSubordersMarket',
                name: 'order_type',
                label: 'market',
                title: 'Market',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersActionTypes: {
                id: 'ordersListSubordersActionTypes',
                type: 'radio',
                name: 'action_type',
                title: 'Action types',
                value: '',
                options: [{ label: 'All', value: '' },
                    { label: 'Buy', value: 'Buy' },
                    { label: 'Sell', value: 'Sell' },
                ],
            },
            ordersListSubordersAmountFrom: {
                id: 'ordersListSubordersAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListSubordersAmountTo: {
                id: 'ordersListSubordersAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListSubordersUSD: {
                id: 'ordersListSubordersUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersEUR: {
                id: 'ordersListSubordersEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersBTC: {
                id: 'ordersListSubordersBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersXRP: {
                id: 'ordersListSubordersXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersLTC: {
                id: 'ordersListSubordersLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersETH: {
                id: 'ordersListSubordersETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersETC: {
                id: 'ordersListSubordersETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersQTUM: {
                id: 'ordersListSubordersQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersXLM: {
                id: 'ordersListSubordersXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            ordersListSubordersPrepared: {
                id: 'ordersListSubordersPrepared',
                name: 'status',
                label: 'Prepared',
                title: 'Prepared',
                type: 'checkbox',
                value: false,
            },
            customerSearchTitle: {
                id: 'customerSearchTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Customers',
            },
            clientSearchTitle: {
                id: 'clientSearchTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Clients',
            },
            aggregatedOrderbookTitle: {
                id: 'aggregatedOrderbookTitle',
                title: 'Widget title',
                type: 'text',
                value: 'price ladder',
            },
            aggregatedOrderbookIgnoreGlobal: {
                id: 'aggregatedOrderbookIgnoreGlobal',
                name: 'Ignore global',
                label: '',
                title: 'Ignore global settings',
                type: 'checkbox',
                value: false,
            },
            aggregatedOrderbookBase: {
                id: 'aggregatedOrderbookBase',
                name: 'Base',
                label: 'Base',
                title: 'Base',
                type: 'select',
                value: null,
                options: [],
            },
            aggregatedOrderbookQuote: {
                id: 'aggregatedOrderbookQuote',
                name: 'Quote',
                label: 'Quote',
                title: 'Quote',
                type: 'select',
                value: null,
                options: [],
            },
            aggregatedOrderbookExchanges: {
                id: 'aggregatedOrderbookExchanges',
                name: 'Exchanges',
                label: 'Exchanges',
                title: 'Exchanges',
                type: 'select',
                value: null,
                options: [],
            },
            marketOverviewTitle: {
                id: 'marketOverviewTitle',
                title: 'Widget title',
                type: 'text',
                value: 'market overview',
            },
        },
        allIds: [],
    },
});
export const defaultCustodyRecord = () => ({
    id: 'custodySystem',
    title: 'System workspace',
    widgets: custodyWidgets,
    widgetSettings: {
        byId: {
            balancesCustodyTitle: {
                id: 'balancesCustodyTitle',
                title: 'Widget title',
                type: 'text',
                value: 'balances',
            },
            internalTransfersTitle: {
                id: 'internalTransfersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Internal Transfers',
            },
            walletsTitle: {
                id: 'walletsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Wallets',
            },
            depositsTitle: {
                id: 'depositsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Deposits',
            },
            withdrawalsTitle: {
                id: 'withdrawalsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Withdrawals',
            },
            addressesTitle: {
                id: 'addressesTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Withdrawal addresses',
            },
            approveTitle: {
                id: 'approveTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Approvals',
            },
            clientsTitle: {
                id: 'clientsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Clients',
            },
            walletsColumnsCurrency: {
                id: 'walletsColumnsCurrency',
                name: 'currency',
                key: 'currency_code',
                minWidth: '100px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsDepositAddress: {
                id: 'walletsColumnsDepositAddress',
                name: 'address',
                key: 'deposit_address',
                minWidth: '170px',
                title: 'Deposit address',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsLabel: {
                id: 'walletsColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '150px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsAccountingType: {
                id: 'walletsColumnsAccountingType',
                name: 'accounting_type',
                key: 'accounting_type',
                minWidth: '130px',
                title: 'Accounting Type',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsCreatedAt: {
                id: 'walletsColumnsCreatedAt',
                name: 'created',
                key: 'created_at',
                minWidth: '180px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsBalanceAvailable: {
                id: 'walletsColumnsBalanceAvailable',
                name: 'available',
                key: 'balance_available',
                minWidth: '120px',
                title: 'Balance available',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsUsdAvailable: {
                id: 'walletsColumnsUsdAvailable',
                name: 'available',
                key: 'usd_available',
                minWidth: '120px',
                title: 'Available (USD)',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsBalanceLocked: {
                id: 'walletsColumnsBalanceLocked',
                name: 'locked',
                key: 'balance_locked',
                minWidth: '120px',
                title: 'Balance locked',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsUsdLocked: {
                id: 'walletsColumnsUsdLocked',
                name: 'locked',
                key: 'usd_locked',
                minWidth: '120px',
                title: 'Locked (USD)',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsBalanceTotal: {
                id: 'walletsColumnsBalanceTotal',
                name: 'total',
                key: 'balance_total',
                minWidth: '120px',
                title: 'Balance total',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsUsdTotal: {
                id: 'walletsColumnsUsdTotal',
                name: 'total',
                key: 'usd_total',
                minWidth: '120px',
                title: 'Total (USD)',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsState: {
                id: 'walletsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '100px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            walletsColumnsCustomer: {
                id: 'walletsColumnsCustomer',
                name: 'customer',
                key: 'customer_code',
                minWidth: '160px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsCode: {
                id: 'withdrawalsColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '140px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsAmount: {
                id: 'withdrawalsColumnsAmount',
                name: 'requested_amount',
                key: 'requested_amount',
                minWidth: '130px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsUsdAmount: {
                id: 'withdrawalsColumnsUsdAmount',
                name: 'requested_amount_usd',
                key: 'requested_amount_usd',
                minWidth: '150px',
                title: 'Amount (USD)',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsAddress: {
                id: 'withdrawalsColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsAddressLabel: {
                id: 'withdrawalsColumnsAddressLabel',
                name: 'address_label',
                key: 'address_label',
                minWidth: '160px',
                title: 'Address Label',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsSourceAddress: {
                id: 'withdrawalsColumnsSourceAddress',
                name: 'source_address',
                key: 'source_address',
                minWidth: '160px',
                title: 'Source Address',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsWalletCode: {
                id: 'withdrawalsColumnsWalletCode',
                name: 'wallet_code',
                key: 'wallet_code',
                minWidth: '160px',
                title: 'Wallet Code',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsLabel: {
                id: 'withdrawalsColumnsLabel',
                name: 'wallet_label',
                key: 'wallet_label',
                minWidth: '160px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsCustomer: {
                id: 'withdrawalsColumnsCustomer',
                name: 'customer_code',
                key: 'customer_code',
                minWidth: '160px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsTXHash: {
                id: 'withdrawalsColumnsTXHash',
                name: 'tx_hash',
                key: 'tx_hash',
                minWidth: '150px',
                title: 'TX hash',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsFee: {
                id: 'withdrawalsColumnsFee',
                name: 'fee',
                key: 'fee',
                minWidth: '130px',
                title: 'Fee',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsUsdFee: {
                id: 'withdrawalsColumnsUsdFee',
                name: 'usd_fee',
                key: 'usd_fee',
                minWidth: '130px',
                title: 'Fee (USD)',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsType: {
                id: 'withdrawalsColumnsType',
                name: 'destination_type',
                key: 'destination_type',
                minWidth: '100px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsCreatedAt: {
                id: 'withdrawalsColumnsCreatedAt',
                name: 'created',
                key: 'created',
                minWidth: '150px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsReferenceID: {
                id: 'withdrawalsColumnsReferenceID',
                name: 'reference_id',
                key: 'reference_id',
                minWidth: '150px',
                title: 'Reference ID',
                type: 'checkbox',
                value: true,
            },
            withdrawalsColumnsState: {
                id: 'withdrawalsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '150px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsId: {
                id: 'depositsColumnsId',
                name: 'code',
                key: 'code',
                minWidth: '145px',
                title: 'ID',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsAmount: {
                id: 'depositsColumnsAmount',
                name: 'compliance_amount',
                key: 'compliance_amount',
                minWidth: '130px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsUsdAmount: {
                id: 'depositsColumnsUsdAmount',
                name: 'compliance_amount_usd',
                key: 'compliance_amount_usd',
                minWidth: '150px',
                title: 'Amount (USD)',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsAddress: {
                id: 'depositsColumnsAddress',
                name: 'deposit_address',
                key: 'deposit_address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsLabel: {
                id: 'depositsColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '160px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsTXHash: {
                id: 'depositsColumnsTXHash',
                name: 'compliance_tx_hash',
                key: 'compliance_tx_hash',
                minWidth: '150px',
                title: 'TX hash',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsReceivedAt: {
                id: 'depositsColumnsReceivedAt',
                name: 'compliance_received_at',
                key: 'compliance_received_at',
                minWidth: '150px',
                title: 'Received at',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsReferenceId: {
                id: 'depositsColumnsReferenceId',
                name: 'reference_id',
                key: 'reference_id',
                minWidth: '150px',
                title: 'Reference ID',
                type: 'checkbox',
                value: true,
            },
            depositsColumnsState: {
                id: 'depositsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '150px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            // Withdrawal addresses
            addressesColumnsCode: {
                id: 'addressesColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '160px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsAddress: {
                id: 'addressesColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsLabel: {
                id: 'addressesColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '120px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsCurrency: {
                id: 'addressesColumnsCurrency',
                name: 'currency',
                key: 'currency',
                minWidth: '120px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsWalletLabel: {
                id: 'addressesColumnsWalletLabel',
                name: 'wallet_label',
                key: 'wallet_label',
                minWidth: '150px',
                title: 'Assigned to wallet',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsCreatedAt: {
                id: 'addressesColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsUpdatedAt: {
                id: 'addressesColumnsUpdatedAt',
                name: 'updated_at',
                key: 'updated_at',
                minWidth: '160px',
                title: 'Updated at',
                type: 'checkbox',
                value: true,
            },
            addressesColumnsState: {
                id: 'addressesColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '120px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsAddress: {
                id: 'addressesApproveColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '300px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsAmount: {
                id: 'addressesApproveColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsCreator: {
                id: 'addressesApproveColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsCurrency: {
                id: 'addressesApproveColumnsCurrency',
                name: 'currency',
                key: 'currency',
                minWidth: '80px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsCreated: {
                id: 'addressesApproveColumnsCreated',
                name: 'created_at',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            addressesApproveColumnsUpdated: {
                id: 'addressesApproveColumnsUpdated',
                name: 'updated_at',
                key: 'updated_at',
                minWidth: '160px',
                title: 'Updated',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsAddress: {
                id: 'withdrawalsApproveColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsAmount: {
                id: 'withdrawalsApproveColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsCreator: {
                id: 'withdrawalsApproveColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsCurrency: {
                id: 'withdrawalsApproveColumnsCurrency',
                name: 'currency_code',
                key: 'currency_code',
                minWidth: '80px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsCreated: {
                id: 'withdrawalsApproveColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveColumnsUpdated: {
                id: 'withdrawalsApproveColumnsUpdated',
                name: 'updated',
                key: 'updated',
                minWidth: '160px',
                title: 'Updated',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsCurrency: {
                id: 'clientsColumnsCurrency',
                name: 'currency',
                key: 'currency_code',
                minWidth: '100px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsDepositAddress: {
                id: 'clientsColumnsDepositAddress',
                name: 'address',
                key: 'deposit_address',
                minWidth: '170px',
                title: 'Deposit address',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsLabel: {
                id: 'clientsColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '150px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsType: {
                id: 'clientsColumnsType',
                name: 'type',
                key: 'type',
                minWidth: '150px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsCreatedAt: {
                id: 'clientsColumnsCreatedAt',
                name: 'created',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsBalanceAvailable: {
                id: 'clientsColumnsBalanceAvailable',
                name: 'available',
                key: 'balance_available',
                minWidth: '180px',
                title: 'Balance available',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsUsdAvailable: {
                id: 'clientsColumnsUsdAvailable',
                name: 'available',
                key: 'usd_available',
                minWidth: '170px',
                title: 'Available (USD)',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsBalanceLocked: {
                id: 'clientsColumnsBalanceLocked',
                name: 'locked',
                key: 'balance_locked',
                minWidth: '170px',
                title: 'Balance locked',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsUsdLocked: {
                id: 'clientsColumnsUsdLocked',
                name: 'locked',
                key: 'usd_locked',
                minWidth: '170px',
                title: 'Locked (USD)',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsBalanceTotal: {
                id: 'clientsColumnsBalanceTotal',
                name: 'total',
                key: 'balance_total',
                minWidth: '150px',
                title: 'Balance total',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsUsdTotal: {
                id: 'clientsColumnsUsdTotal',
                name: 'total',
                key: 'usd_total',
                minWidth: '150px',
                title: 'Total (USD)',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsState: {
                id: 'clientsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '100px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            clientsColumnsCustomer: {
                id: 'clientsColumnsCustomer',
                name: 'customer',
                key: 'customer_code',
                minWidth: '160px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsCode: {
                id: 'internalTransfersColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '160px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsClientCode: {
                id: 'internalTransfersColumnsClientCode',
                name: 'client',
                key: 'client_code',
                minWidth: '160px',
                title: 'Client',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsCurrency: {
                id: 'internalTransfersColumnsCurrency',
                name: 'currency',
                key: 'currency_code',
                minWidth: '160px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsDestination: {
                id: 'internalTransfersColumnsDestination',
                name: 'destination',
                key: 'destination_code',
                minWidth: '160px',
                title: 'Destination',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsSource: {
                id: 'internalTransfersColumnsSource',
                name: 'source',
                key: 'source_code',
                minWidth: '160px',
                title: 'Source',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsDirection: {
                id: 'internalTransfersColumnsDirection',
                name: 'direction',
                key: 'direction',
                minWidth: '160px',
                title: 'Direction',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsState: {
                id: 'internalTransfersColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '160px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsAmount: {
                id: 'internalTransfersColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsCreated: {
                id: 'internalTransfersColumnsCreated',
                name: 'created',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created At',
                type: 'checkbox',
                value: true,
            },
            internalTransfersColumnsUpdated: {
                id: 'internalTransfersColumnsUpdated',
                name: 'updated',
                key: 'updated_at',
                minWidth: '160px',
                title: 'Updated At',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageOrdersRecord = () => ({
    id: 'manageOrdersSystem',
    title: 'System workspace',
    widgets: manageOrdersWidgets,
    widgetSettings: {
        byId: {
            manageOrdersTitle: {
                id: 'manageOrdersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Orders',
            },
            manageOrdersColumnsActionType: {
                id: 'manageOrdersColumnsActionType',
                name: 'action_type',
                key: 'action_type',
                minWidth: '150px',
                title: 'Buy/Sell',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsSize: {
                id: 'manageOrdersColumnsSize',
                name: 'amount',
                key: 'amount',
                minWidth: '150px',
                title: 'Size',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsPair: {
                id: 'manageOrdersColumnsPair',
                name: 'pair',
                key: 'pair',
                minWidth: '150px',
                title: 'Pair',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsExchange: {
                id: 'manageOrdersColumnsExchange',
                name: 'exchange',
                key: 'exchange',
                minWidth: '150px',
                title: 'Exchange',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsExecutedPrice: {
                id: 'manageOrdersColumnsExecutedPrice',
                name: 'executedPrice',
                key: 'executedPrice',
                minWidth: '150px',
                title: 'Price Per',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsTotal: {
                id: 'manageOrdersColumnsTotal',
                name: 'executedValue',
                key: 'executedValue',
                minWidth: '150px',
                title: 'Total',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsCreated: {
                id: 'manageOrdersColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            manageOrdersColumnsStatus: {
                id: 'manageOrdersColumnsStatus',
                name: 'status',
                key: 'status',
                minWidth: '150px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageCustomersRecord = () => ({
    id: 'manageCustomersSystem',
    title: 'System workspace',
    widgets: manageCustomersWidgets,
    widgetSettings: {
        byId: {
            manageCustomersTitle: {
                id: 'manageCustomersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Customers',
            },
            manageCustomersColumnsClientCode: {
                id: 'manageCustomersColumnsClientCode',
                name: 'client_code',
                key: 'client_code',
                minWidth: '160px',
                title: 'Client Code',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsCompanyName: {
                id: 'manageCustomersColumnsCompanyName',
                name: 'company_name',
                key: 'company_name',
                minWidth: '150px',
                title: 'Company name',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsContactPerson: {
                id: 'manageCustomersColumnsContactPerson',
                name: 'contact_person_name',
                key: 'contact_person_name',
                minWidth: '150px',
                title: 'Contact person',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsAddress: {
                id: 'manageCustomersColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '150px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsCity: {
                id: 'manageCustomersColumnsCity',
                name: 'city',
                key: 'city',
                minWidth: '150px',
                title: 'City',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsCountry: {
                id: 'manageCustomersColumnsCountry',
                name: 'country',
                key: 'country',
                minWidth: '150px',
                title: 'Country',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsPhone: {
                id: 'manageCustomersColumnsPhone',
                name: 'phone_number',
                key: 'phone_number',
                minWidth: '150px',
                title: 'Phone',
                type: 'checkbox',
                value: true,
            },
            manageCustomersColumnsEmail: {
                id: 'manageCustomersColumnsEmail',
                name: 'email',
                key: 'email',
                minWidth: '150px',
                title: 'Email',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultPermissionsRecord = () => ({
    id: 'permissionsSystem',
    title: 'System workspace',
    widgets: permissionsWidgets,
    widgetSettings: {
        byId: {
            permissionsTitle: {
                id: 'permissionsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Permissions',
            },
            permissionsColumnsResource: {
                id: 'permissionsColumnsResource',
                name: 'resource',
                key: 'resource',
                minWidth: '200px',
                title: 'Resource',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsCreate: {
                id: 'permissionsColumnsCreate',
                name: 'create',
                key: 'create',
                minWidth: '25px',
                title: 'Create',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsRead: {
                id: 'permissionsColumnsRead',
                name: 'read',
                key: 'read',
                minWidth: '25px',
                title: 'Read',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsUpdate: {
                id: 'permissionsColumnsUpdate',
                name: 'update',
                key: 'update',
                minWidth: '25px',
                title: 'Update',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsDelete: {
                id: 'permissionsColumnsDelete',
                name: 'delete',
                key: 'delete',
                minWidth: '25px',
                title: 'Delete',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsCategory: {
                id: 'permissionsColumnsCategory',
                name: 'category',
                key: 'category',
                minWidth: '150px',
                title: 'Category',
                type: 'checkbox',
                value: true,
            },
            permissionsColumnsDescription: {
                id: 'permissionsColumnsDescription',
                name: 'description',
                key: 'description',
                minWidth: '750px',
                title: 'Description',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultStrategiessRecord = () => ({
    id: 'strategiesSystem',
    title: 'System workspace',
    widgets: strategiesWidgets,
    widgetSettings: {
        byId: {
            strategiesTitle: {
                id: 'strategiesTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Strategies',
            },
            strategiesColumnsCode: {
                id: 'strategiesColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '160px',
                title: 'ID',
                type: 'checkbox',
                value: true,
            },
            strategiesColumnsName: {
                id: 'strategiesColumnsName',
                name: 'name',
                key: 'name',
                minWidth: '160px',
                title: 'Name',
                type: 'checkbox',
                value: true,
            },
            strategiesColumnsStatus: {
                id: 'strategiesColumnsStatus',
                name: 'status',
                key: 'status',
                minWidth: '100px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
            strategiesColumnsCreated: {
                id: 'strategiesColumnsCreated',
                name: 'created_at',
                key: 'created_at',
                minWidth: '120px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            strategiesColumnsClientCode: {
                id: 'strategiesColumnsClientCode',
                name: 'client_code',
                key: 'client_code',
                minWidth: '160px',
                title: 'Client',
                type: 'checkbox',
                value: true,
            },
            strategiesColumnsCustomerCode: {
                id: 'strategiesColumnsCustomerCode',
                name: 'customer_code',
                key: 'customer_code',
                minWidth: '160px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageUsersRecord = () => ({
    id: 'manageUsersSystem',
    title: 'System workspace',
    widgets: manageUsersWidgets,
    widgetSettings: {
        byId: {
            manageUsersTitle: {
                id: 'manageUsersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Users',
            },
            manageUsersColumnsCreatedAt: {
                id: 'manageUsersColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '150px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsCode: {
                id: 'manageUsersColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '150px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsStatus: {
                id: 'manageUsersColumnsStatus',
                name: 'is_enabled',
                key: 'is_enabled',
                minWidth: '40px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsEmail: {
                id: 'manageUsersColumnsEmail',
                name: 'email',
                key: 'email',
                minWidth: '150px',
                title: 'Email',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsName: {
                id: 'manageUsersColumnsName',
                name: 'name',
                key: 'name',
                minWidth: '150px',
                title: 'Name',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsRole: {
                id: 'manageUsersColumnsRole',
                name: 'role',
                key: 'role',
                minWidth: '150px',
                title: 'Role',
                type: 'checkbox',
                value: true,
            },
            manageUsersColumnsCustomer: {
                id: 'manageUsersColumnsCustomer',
                name: 'customer_code',
                key: 'customer_code',
                minWidth: '150px',
                title: 'Сustomer',
                type: 'checkbox',
                value: true,
            },
            manageInvitesTitle: {
                id: 'manageInvitesTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Unused Invites',
            },
            manageInvitesColumnsCreatedAt: {
                id: 'manageInvitesColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '150px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            manageInvitesColumnsEmail: {
                id: 'manageInvitesColumnsEmail',
                name: 'email',
                key: 'email',
                minWidth: '150px',
                title: 'Email',
                type: 'checkbox',
                value: true,
            },
            manageInvitesColumnsRole: {
                id: 'manageInvitesColumnsRole',
                name: 'role',
                key: 'role',
                minWidth: '150px',
                title: 'Role',
                type: 'checkbox',
                value: true,
            },
            manageInvitesColumnsCustomer: {
                id: 'manageInvitesColumnsCustomer',
                name: 'customer_code',
                key: 'customer_code',
                minWidth: '150px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageLogbookRecord = () => ({
    id: 'manageLogbookSystem',
    title: 'System workspace',
    widgets: manageLogbookWidgets,
    widgetSettings: {
        byId: {
            manageLogbookTitle: {
                id: 'manageLogbookTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Logbook',
            },
            manageLogbookColumnsCode: {
                id: 'manageLogbookColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '200px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsUserId: {
                id: 'manageLogbookColumnsUserId',
                name: 'creator',
                key: 'creator',
                minWidth: '200px',
                title: 'User Id',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsApiSource: {
                id: 'manageLogbookColumnsApiSource',
                name: 'api_source',
                key: 'api_source',
                minWidth: '100px',
                title: 'Api source',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsEventName: {
                id: 'manageLogbookColumnsEventName',
                name: 'event_name',
                key: 'event_name',
                minWidth: '150px',
                title: 'Event name',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsServiceName: {
                id: 'manageLogbookColumnsServiceName',
                name: 'service_name',
                key: 'service_name',
                minWidth: '120px',
                title: 'Service name',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsControllerName: {
                id: 'manageLogbookColumnsControllerName',
                name: 'controller_name',
                key: 'controller_name',
                minWidth: '150px',
                title: 'Controller name',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsRequestCode: {
                id: 'manageLogbookColumnsRequestCode',
                name: 'request_code',
                key: 'request_code',
                minWidth: '200px',
                title: 'Request code',
                type: 'checkbox',
                value: true,
            },
            manageLogbookColumnsCreatedAt: {
                id: 'manageLogbookColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '150px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageTokensRecord = () => ({
    id: 'manageTokensSystem',
    title: 'System workspace',
    widgets: manageTokensWidgets,
    widgetSettings: {
        byId: {
            manageTokensTitle: {
                id: 'manageTokensTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Tokens',
            },
            manageTokensColumnsLabel: {
                id: 'manageTokensColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '100px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            manageTokensColumnsPublicKey: {
                id: 'manageTokensColumnsPublicKey',
                name: 'public_key',
                key: 'public_key',
                minWidth: '250px',
                title: 'Public key',
                type: 'checkbox',
                value: true,
            },
            manageTokensColumnsCreatedAt: {
                id: 'manageTokensColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '100px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultTradingTransfersRecord = () => ({
    id: 'tradingTransfersSystem',
    title: 'System workspace',
    widgets: tradingTransfersWidgets,
    widgetSettings: {
        byId: {
            tradingTransfersTitle: {
                id: 'tradingTransfersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Transfers',
            },
            tradingTransfersColumnsTXCode: {
                id: 'tradingTransfersColumnsTXCode',
                name: 'code',
                key: 'code',
                minWidth: '75px',
                title: 'TX Code',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsAmount: {
                id: 'tradingTransfersColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '75px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsTXDetails: {
                id: 'tradingTransfersColumnsTXDetails',
                name: 'tx_details',
                key: 'tx_details',
                minWidth: '100px',
                title: 'TX Details',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsCurrency: {
                id: 'tradingTransfersColumnsCurrency',
                name: 'currency_code',
                key: 'currency_code',
                minWidth: '75px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsAddressLabel: {
                id: 'tradingTransfersColumnsLabel',
                name: 'address_label',
                key: 'address_label',
                minWidth: '75px',
                title: 'Address Label',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsDestination: {
                id: 'tradingTransfersColumnsDestination',
                name: 'requisites_details',
                key: 'requisites_details',
                minWidth: '150px',
                title: 'Destination',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsClient: {
                id: 'tradingTransfersColumnsClient',
                name: 'client_code',
                key: 'client_code',
                minWidth: '150px',
                title: 'Client',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsCustomer: {
                id: 'tradingTransfersColumnsCustomer',
                name: 'customer_code',
                key: 'customer_code',
                minWidth: '150px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsCreator: {
                id: 'tradingTransfersColumnsCreator',
                name: 'creator_code',
                key: 'creator_code',
                minWidth: '150px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsCreated: {
                id: 'tradingTransfersColumnsCreated',
                name: 'created_at',
                key: 'created_at',
                minWidth: '150px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsState: {
                id: 'tradingTransfersColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '100px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsType: {
                id: 'tradingTransfersColumnsType',
                name: 'type',
                key: 'type',
                minWidth: '100px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            tradingTransfersColumnsDate: {
                id: 'tradingTransfersColumnsDate',
                name: 'updated_at',
                key: 'updated_at',
                minWidth: '150px',
                title: 'Date',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageSchedulersRecord = () => ({
    id: 'manageSchedulersSystem',
    title: 'System workspace',
    widgets: manageSchedulersWidgets,
    widgetSettings: {
        byId: {
            manageSchedulersTitle: {
                id: 'manageSchedulersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Schedulers',
            },
            manageSchedulersColumnsName: {
                id: 'manageSchedulersColumnsName',
                name: 'name',
                key: 'name',
                minWidth: '100px',
                title: 'Name',
                type: 'checkbox',
                value: true,
            },
            manageSchedulersColumnsRunningInterval: {
                id: 'manageSchedulersColumnsRunningInterval',
                name: 'running_interval',
                key: 'running_interval',
                minWidth: '100px',
                title: 'Running interval',
                type: 'checkbox',
                value: true,
            },
            manageSchedulersColumnsMaxExecutionDuration: {
                id: 'manageSchedulersColumnsMaxExecutionDuration',
                name: 'max_execution_duration',
                key: 'max_execution_duration',
                minWidth: '170px',
                title: 'Max execution duration',
                type: 'checkbox',
                value: true,
            },
            manageSchedulersColumnsLastExecutionDuration: {
                id: 'manageSchedulersColumnsLastExecutionDuration',
                name: 'last_execution_duration',
                key: 'last_execution_duration',
                minWidth: '170px',
                title: 'Last execution duration',
                type: 'checkbox',
                value: true,
            },
            manageSchedulersColumnsLastError: {
                id: 'manageSchedulersColumnsLastError',
                name: 'last_error',
                key: 'last_error',
                minWidth: '200px',
                title: 'Last error',
                type: 'checkbox',
                value: true,
            },
            manageSchedulersColumnsLastRun: {
                id: 'manageSchedulersColumnsLastRun',
                name: 'last_run',
                key: 'last_run',
                minWidth: '100px',
                title: 'Last run',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultAdminExchangeRecord = () => ({
    id: 'adminExchangeSystem',
    title: 'System workspace',
    widgets: adminExchangeWidgets,
    widgetSettings: {
        byId: {
            adminExchangeTitle: {
                id: 'adminExchangeTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Exchanges',
            },
            adminExchangeColumnsBase: {
                id: 'adminExchangeColumnsBase',
                name: 'base',
                key: 'base',
                minWidth: '100px',
                title: 'Base',
                type: 'checkbox',
                value: true,
            },
            adminExchangeColumnsQuote: {
                id: 'adminExchangeColumnsQuote',
                name: 'quote',
                key: 'quote',
                minWidth: '100px',
                title: 'Quote',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageComplianceRecord = () => ({
    id: 'manageComplianceSystem',
    title: 'System workspace',
    widgets: manageComplianceWidgets,
    widgetSettings: {
        byId: {
            manageComplianceTitle: {
                id: 'manageComplianceTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Compliance View',
            },
            manageApproveTitle: {
                id: 'manageApproveTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Approve',
            },
            addressesApproveComplianceColumnsAddress: {
                id: 'addressesApproveComplianceColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            addressesApproveComplianceColumnsAmount: {
                id: 'addressesApproveComplianceColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            addressesApproveComplianceColumnsCreator: {
                id: 'addressesApproveComplianceColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveComplianceColumnsAddress: {
                id: 'withdrawalsApproveComplianceColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveComplianceColumnsAmount: {
                id: 'withdrawalsApproveComplianceColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveComplianceColumnsCreator: {
                id: 'withdrawalsApproveComplianceColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsTitle: {
                id: 'complianceTransactionsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Transactions',
            },
            complianceTransactionsColumnsCode: {
                id: 'complianceTransactionsColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '270px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsState: {
                id: 'complianceTransactionsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '140px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsCurrencyCode: {
                id: 'complianceTransactionsColumnsCurrencyCode',
                name: 'currency_code',
                key: 'currency_code',
                minWidth: '120px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsType: {
                id: 'complianceTransactionsColumnsType',
                name: 'type',
                key: 'type',
                minWidth: '120px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsTxHash: {
                id: 'complianceTransactionsColumnsTxHash',
                name: 'tx_hash',
                key: 'tx_hash',
                minWidth: '160px',
                title: 'Tx Hash',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsAmount: {
                id: 'complianceTransactionsColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsUsdAmount: {
                id: 'complianceTransactionsColumnsUsdAmount',
                name: 'amount',
                key: 'amount_usd',
                minWidth: '160px',
                title: 'Amount (USD)',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsCreatedAt: {
                id: 'complianceTransactionsColumnsCreatedAt',
                name: 'created_at',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsFee: {
                id: 'complianceTransactionsColumnsFee',
                name: 'fee',
                key: 'fee',
                minWidth: '120px',
                title: 'Fee',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsRiskScore: {
                id: 'complianceTransactionsColumnsRiskScore',
                name: 'risk_score',
                key: 'risk_score',
                minWidth: '100px',
                title: 'Risk score',
                type: 'checkbox',
                value: true,
            },
            complianceTransactionsColumnsNote: {
                id: 'complianceTransactionsColumnsNote',
                name: 'note',
                key: 'note',
                minWidth: '100px',
                title: 'Note',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsTitle: {
                id: 'complianceWalletsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Wallets',
            },
            complianceWalletsColumnsCurrency: {
                id: 'complianceWalletsColumnsCurrency',
                name: 'currency',
                key: 'currency_code',
                minWidth: '100px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsDepositAddress: {
                id: 'complianceWalletsColumnsDepositAddress',
                name: 'address',
                key: 'deposit_address',
                minWidth: '170px',
                title: 'Deposit address',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsLabel: {
                id: 'complianceWalletsColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '150px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsType: {
                id: 'complianceWalletsColumnsType',
                name: 'type',
                key: 'type',
                minWidth: '150px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsCreatedAt: {
                id: 'complianceWalletsColumnsCreatedAt',
                name: 'created',
                key: 'created_at',
                minWidth: '180px',
                title: 'Created at',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsBalanceAvailable: {
                id: 'complianceWalletsColumnsBalanceAvailable',
                name: 'available',
                key: 'balance_available',
                minWidth: '180px',
                title: 'Balance available',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsUsdAvailable: {
                id: 'complianceWalletsColumnsUsdAvailable',
                name: 'available',
                key: 'usd_available',
                minWidth: '170px',
                title: 'Available (USD)',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsBalanceLocked: {
                id: 'complianceWalletsColumnsBalanceLocked',
                name: 'locked',
                key: 'balance_locked',
                minWidth: '170px',
                title: 'Balance locked',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsUsdLocked: {
                id: 'complianceWalletsColumnsUsdLocked',
                name: 'locked',
                key: 'usd_locked',
                minWidth: '170px',
                title: 'Locked (USD)',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsBalanceTotal: {
                id: 'complianceWalletsColumnsBalanceTotal',
                name: 'total',
                key: 'balance_total',
                minWidth: '150px',
                title: 'Balance total',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsUsdTotal: {
                id: 'complianceWalletsColumnsUsdTotal',
                name: 'total',
                key: 'usd_total',
                minWidth: '150px',
                title: 'Total (USD)',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsState: {
                id: 'complianceWalletsColumnsState',
                name: 'state',
                key: 'state',
                minWidth: '100px',
                title: 'State',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsCustomer: {
                id: 'complianceWalletsColumnsCustomer',
                name: 'customer',
                key: 'customer_code',
                minWidth: '160px',
                title: 'Customer',
                type: 'checkbox',
                value: true,
            },
            complianceWalletsColumnsNote: {
                id: 'complianceWalletsColumnsNote',
                name: 'note',
                key: 'note',
                minWidth: '100px',
                title: 'Note',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageWhitelistRecord = () => ({
    id: 'manageWhitelistSystem',
    title: 'System workspace',
    widgets: manageWhitelistingWidgets,
    widgetSettings: {
        byId: {
            manageWhitelistTitle: {
                id: 'manageWhitelistTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Approve',
            },
            addressesApproveWhitelistColumnsAddress: {
                id: 'addressesApproveWhitelistColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            addressesApproveWhitelistColumnsAmount: {
                id: 'addressesApproveWhitelistColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            addressesApproveWhitelistColumnsCreator: {
                id: 'addressesApproveWhitelistColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            addressesApproveWhitelistColumnsCurrency: {
                id: 'addressesApproveWhitelistColumnsCurrency',
                name: 'currency',
                key: 'currency',
                minWidth: '80px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            addressesApproveWhitelistColumnsCreated: {
                id: 'addressesApproveWhitelistColumnsCreated',
                name: 'created_at',
                key: 'created_at',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsAddress: {
                id: 'withdrawalsApproveWhitelistColumnsAddress',
                name: 'address',
                key: 'address',
                minWidth: '160px',
                title: 'Address',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsAmount: {
                id: 'withdrawalsApproveWhitelistColumnsAmount',
                name: 'amount',
                key: 'amount',
                minWidth: '160px',
                title: 'Amount',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsCreator: {
                id: 'withdrawalsApproveWhitelistColumnsCreator',
                name: 'creator',
                key: 'creator',
                minWidth: '160px',
                title: 'Creator',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsCurrency: {
                id: 'withdrawalsApproveWhitelistColumnsCurrency',
                name: 'currency_code',
                key: 'currency_code',
                minWidth: '80px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsCreated: {
                id: 'withdrawalsApproveWhitelistColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            withdrawalsApproveWhitelistColumnsUpdated: {
                id: 'withdrawalsApproveWhitelistColumnsUpdated',
                name: 'updated',
                key: 'updated',
                minWidth: '160px',
                title: 'Updated',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultRFQManagementRecord = () => ({
    id: 'rfqManagementSystem',
    title: 'System workspace',
    widgets: rfqManagementWidgets,
    widgetSettings: {
        byId: {
            quoteMonitorTitle: {
                id: 'quoteMonitorTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Quote Monitor',
            },
            quoteMonitorColumnsRefExchange: {
                id: 'quoteMonitorColumnsRefExchange',
                name: 'exchange_name',
                key: 'exchange_name',
                minWidth: '105px',
                title: 'Exchange',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsLabel: {
                id: 'quoteMonitorColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '150px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsStreamType: {
                id: 'quoteMonitorColumnsStreamType',
                name: 'stream_type',
                key: 'stream_type',
                minWidth: '50px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsSpreadMode: {
                id: 'quoteMonitorColumnsSpreadMode',
                name: 'spread_mode',
                key: 'spread_mode',
                minWidth: '50px',
                title: 'Spread Mode',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsCalculationMode: {
                id: 'quoteMonitorColumnsCalculationMode',
                name: 'calculation_mode',
                key: 'calculation_mode',
                minWidth: '50px',
                title: 'Calculation Mode',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsMinSpread: {
                id: 'quoteMonitorColumnsMinSpread',
                name: 'min_spread_bps',
                key: 'min_spread_bps',
                minWidth: '50px',
                title: 'Min spread (bps)',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsWideningFactor: {
                id: 'quoteMonitorColumnsWideningFactor',
                name: 'widening_factor_bps',
                key: 'widening_factor_bps',
                minWidth: '50px',
                title: 'Widening factor',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsUsedSpread: {
                id: 'quoteMonitorColumnsUsedSpread',
                name: 'used_spread',
                key: 'used_spread',
                minWidth: '50px',
                title: 'Used spread',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsNotional: {
                id: 'quoteMonitorColumnsNotional',
                name: 'notional',
                key: 'notional',
                minWidth: '90px',
                title: 'Notional',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsRefPrice: {
                id: 'quoteMonitorColumnsRefPrice',
                name: 'ref_price',
                key: 'ref_price',
                minWidth: '80px',
                title: 'Ref price',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsUsedRefPrice: {
                id: 'quoteMonitorColumnsUsedRefPrice',
                name: 'used_ref_price',
                key: 'used_ref_price',
                minWidth: '80px',
                title: 'Used ref price',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsGuaranteedQTY: {
                id: 'quoteMonitorColumnsGuaranteedQTY',
                name: 'guaranteed_qty',
                key: 'guaranteed_qty',
                minWidth: '80px',
                title: 'Guaranteed QTY',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsULShift: {
                id: 'quoteMonitorColumnsULShift',
                name: 'ul_shift_bps',
                key: 'ul_shift_bps',
                minWidth: '80px',
                title: 'UL shift (bps)',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsMethod: {
                id: 'quoteMonitorColumnsMethod',
                name: 'method',
                key: 'method',
                minWidth: '80px',
                title: 'Method',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsMarketFee: {
                id: 'quoteMonitorColumnsMarketFee',
                name: 'market_fees_bps',
                key: 'market_fees_bps',
                minWidth: '80px',
                title: 'Market fee (bps)',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsRTExchSpread: {
                id: 'quoteMonitorColumnsRTExchSpread',
                name: 'extra_spread_bps',
                key: 'extra_spread_bps',
                minWidth: '80px',
                title: 'Extra spread (bps)',
                type: 'checkbox',
                value: true,
            },
            quoteMonitorColumnsStatus: {
                id: 'quoteMonitorColumnsStatus',
                name: 'status',
                key: 'status',
                minWidth: '80px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
            rfqRisksTitle: {
                id: 'rfqRisksTitle',
                title: 'Widget title',
                type: 'text',
                value: 'RFQ Risks',
            },
            rfqRisksColumnsUL: {
                id: 'rfqRisksColumnsUL',
                name: 'ul',
                key: 'ul',
                minWidth: '120px',
                title: 'UL',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsSource: {
                id: 'rfqRisksColumnsSource',
                name: 'source',
                key: 'source',
                minWidth: '120px',
                title: 'Source',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsCurrencyRealtimeUIPprice: {
                id: 'rfqRisksColumnsCurrencyRealtimeUIPprice',
                name: 'realtime_ui_price',
                key: 'realtime_ui_price',
                minWidth: '120px',
                title: 'Realtime ui price',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsPosition: {
                id: 'rfqRisksColumnsPosition',
                name: 'position',
                key: 'position',
                minWidth: '120px',
                title: 'Position',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsPosNotional: {
                id: 'rfqRisksColumnsPosNotional',
                name: 'pos_notional',
                key: 'pos_notional',
                minWidth: '120px',
                title: 'Pos notional',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsAvOpenPrice: {
                id: 'rfqRisksColumnsAvOpenPrice',
                name: 'av_open_price',
                key: 'av_open_price',
                minWidth: '120px',
                title: 'Av open price',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsRealtimePLDay: {
                id: 'rfqRisksColumnsRealtimePLDay',
                name: 'realtime_p_l_day',
                key: 'realtime_p_l_day',
                minWidth: '160px',
                title: 'Realtime P&L day',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsRealtimePLMonth: {
                id: 'rfqRisksColumnsRealtimePLMonth',
                name: 'realtime_p_l_month',
                key: 'realtime_p_l_month',
                minWidth: '160px',
                title: 'Realtime P&L month',
                type: 'checkbox',
                value: true,
            },
            rfqRisksColumnsRealtimePLYear: {
                id: 'rfqRisksColumnsRealtimePLYear',
                name: 'realtime_p_l_year',
                key: 'realtime_p_l_year',
                minWidth: '160px',
                title: 'Realtime P&L year',
                type: 'checkbox',
                value: true,
            },
            clientProtocolTitle: {
                id: 'clientProtocolTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Client Protocol',
            },
            clientProtocolColumnsUL: {
                id: 'clientProtocolColumnsUL',
                name: 'ul',
                key: 'ul',
                minWidth: '120px',
                title: 'UL',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsFloatMin: {
                id: 'clientProtocolColumnsFloatMin',
                name: 'float_min',
                key: 'float_min',
                minWidth: '120px',
                title: 'Float min',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsFix: {
                id: 'clientProtocolColumnsFix',
                name: 'fix',
                key: 'fix',
                minWidth: '120px',
                title: 'Fix',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsMinSpread: {
                id: 'clientProtocolColumnsMinSpread',
                name: 'min_spread',
                key: 'min_spread',
                minWidth: '120px',
                title: 'Min spread',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsSpreadWideningOn: {
                id: 'clientProtocolColumnsSpreadWideningOn',
                name: 'spread_widening_on',
                key: 'spread_widening_on',
                minWidth: '120px',
                title: 'Spread widening on',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsWideningFactor: {
                id: 'clientProtocolColumnsWideningFactor',
                name: 'widening_factor',
                key: 'widening_factor',
                minWidth: '120px',
                title: 'Widening factor',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsUsedSpread: {
                id: 'clientProtocolColumnsUsedSpread',
                name: 'used_spread',
                key: 'used_spread',
                minWidth: '120px',
                title: 'Used spread',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsAutoquoterOnOff: {
                id: 'clientProtocolColumnsAutoquoterOnOff',
                name: 'autoquoter_on_off',
                key: 'autoquoter_on_off',
                minWidth: '120px',
                title: 'Autoquoter on off',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsNotional: {
                id: 'clientProtocolColumnsNotional',
                name: 'notional',
                key: 'notional',
                minWidth: '120px',
                title: 'Notional',
                type: 'checkbox',
                value: true,
            },
            clientProtocolColumnsAddClientShift: {
                id: 'clientProtocolColumnsAddClientShift',
                name: 'add_client_shift',
                key: 'add_client_shift',
                minWidth: '120px',
                title: 'Add. client shift',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultRFQClientsManagementRecord = () => ({
    id: 'rfqClientsManagementSystem',
    title: 'System workspace',
    widgets: rfqClientsManagementWidgets,
    widgetSettings: {
        byId: {
            clientsManagementTitle: {
                id: 'clientsManagementTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Clients Management',
            },
            clientsManagementColumnsLabel: {
                id: 'clientsManagementColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '200px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsStreamType: {
                id: 'clientsManagementColumnsStreamType',
                name: 'stream_type',
                key: 'stream_type',
                minWidth: '150px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsUL: {
                id: 'clientsManagementColumnsUL',
                name: 'ul',
                key: 'ul',
                minWidth: '100px',
                title: 'Pair',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsRefExchange: {
                id: 'clientsManagementColumnsRefExchange',
                name: 'exchange_name',
                key: 'exchange_code',
                minWidth: '105px',
                title: 'Exchange',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsMinSpread: {
                id: 'clientsManagementColumnsMinSpread',
                name: 'min_spread_bps',
                key: 'min_spread_bps',
                minWidth: '100px',
                title: 'Min spread (bps)',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsUsedSpread: {
                id: 'clientsManagementColumnsUsedSpread',
                name: 'used_spread',
                key: 'used_spread',
                minWidth: '100px',
                title: 'Used spread',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsGuaranteedQTY: {
                id: 'clientsManagementColumnsGuaranteedQTY',
                name: 'guaranteed_qty',
                key: 'guaranteed_qty',
                minWidth: '100px',
                title: 'Guaranteed QTY',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsType: {
                id: 'clientsManagementColumnsType',
                name: 'stream_type',
                key: 'stream_type',
                minWidth: '100px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            clientsManagementColumnsCreated: {
                id: 'clientsManagementColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '100px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultCurrencyConfigRecord = () => ({
    id: 'currencyConfigSystem',
    title: 'System workspace',
    widgets: currencyConfigWidgets,
    widgetSettings: {
        byId: {
            currencyConfigTitle: {
                id: 'currencyConfigTitle',
                title: 'Widget title',
                type: 'text',
                value: 'currency config',
            },
            currencyConfigColumnsCode: {
                id: 'currencyConfigColumnsCode',
                name: 'code',
                key: 'code',
                minWidth: '130px',
                title: 'Code',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsLabel: {
                id: 'currencyConfigColumnsLabel',
                name: 'label',
                key: 'label',
                minWidth: '80px',
                title: 'Label',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsFee: {
                id: 'currencyConfigColumnsFee',
                name: 'fee_value',
                key: 'fee_value',
                minWidth: '80px',
                title: 'Fee',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsPrecision: {
                id: 'currencyConfigColumnsPrecision',
                name: 'precision',
                key: 'precision',
                minWidth: '80px',
                title: 'Precision',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsTestNet: {
                id: 'currencyConfigColumnsTestNet',
                name: 'test_net',
                key: 'test_net',
                minWidth: '80px',
                title: 'Test net',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsExplorerUrl: {
                id: 'currencyConfigColumnsExplorerUrl',
                name: 'explorer_url',
                key: 'explorer_url',
                minWidth: '80px',
                title: 'Explorer url',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsCurrencyCode: {
                id: 'currencyConfigColumnsCurrencyCode',
                name: 'currency_code',
                key: 'currency_code',
                minWidth: '80px',
                title: 'Currency',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsContractAddress: {
                id: 'currencyConfigColumnsContractAddress',
                name: 'contract_address',
                key: 'contract_address',
                minWidth: '80px',
                title: 'Contract address',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsBlockchainNetwork: {
                id: 'currencyConfigColumnsBlockchainNetwork',
                name: 'blockchain_network',
                key: 'blockchain_network',
                minWidth: '80px',
                title: 'Blockchain network',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsFireblocksAssetId: {
                id: 'currencyConfigColumnsFireblocksAssetId',
                name: 'fireblocks_asset_id',
                key: 'fireblocks_asset_id',
                minWidth: '80px',
                title: 'Fireblocks id',
                type: 'checkbox',
                value: true,
            },
            currencyConfigColumnsMinimumDepositSize: {
                id: 'currencyConfigColumnsMinimumDepositSize',
                name: 'minimum_deposit_size',
                key: 'minimum_deposit_size',
                minWidth: '80px',
                title: 'Minimum deposit',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultAutohedgerBalanceRecord = () => ({
    id: 'autohedgerBalanceSystem',
    title: 'System workspace',
    widgets: autohedgerBalanceWidgets,
    widgetSettings: {
        byId: {
            autohedgerBalanceTitle: {
                id: 'autohedgerBalanceTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Autohedger Balance',
            },
            autohedgerBalanceColumnsBase: {
                id: 'autohedgerBalanceColumnsBase',
                name: 'base',
                key: 'base',
                minWidth: '80px',
                title: 'Base',
                type: 'checkbox',
                value: true,
            },
            autohedgerBalanceColumnsQuote: {
                id: 'autohedgerBalanceColumnsQuote',
                name: 'quote',
                key: 'quote',
                minWidth: '80px',
                title: 'Quote',
                type: 'checkbox',
                value: true,
            },
            autohedgerBalanceColumnsTotalFiat: {
                id: 'autohedgerBalanceColumnsTotalFiat',
                name: 'total_fiat',
                key: 'total_fiat',
                minWidth: '100px',
                title: 'Total fiat',
                type: 'checkbox',
                value: true,
            },
            autohedgerBalanceColumnsTotalCrypto: {
                id: 'autohedgerBalanceColumnsTotalCrypto',
                name: 'total_crypto',
                key: 'total_crypto',
                minWidth: '100px',
                title: 'Total crypto',
                type: 'checkbox',
                value: true,
            },
            autohedgerBalanceColumnsType: {
                id: 'autohedgerBalanceColumnsType',
                name: 'type',
                key: 'type',
                minWidth: '100px',
                title: 'Type',
                type: 'checkbox',
                value: true,
            },
            autohedgerTitle: {
                id: 'autohedgerTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Autohedger',
            },
            autohedgerColumnsBase: {
                id: 'autohedgerColumnsBase',
                name: 'base',
                key: 'base',
                minWidth: '80px',
                title: 'Base',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsQuote: {
                id: 'autohedgerColumnsQuote',
                name: 'quote',
                key: 'quote',
                minWidth: '80px',
                title: 'Quote',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsMinAmount: {
                id: 'autohedgerColumnsMinAmount',
                name: 'min_amount',
                key: 'min_amount',
                minWidth: '100px',
                title: 'Min amount',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsDailyLimit: {
                id: 'autohedgerColumnsDailyLimit',
                name: 'daily_limit',
                key: 'daily_limit',
                minWidth: '100px',
                title: 'Daily limit',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsThreshold: {
                id: 'autohedgerColumnsThreshold',
                name: 'threshold',
                key: 'threshold',
                minWidth: '100px',
                title: 'Threshold',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsAutoHedging: {
                id: 'autohedgerColumnsAutoHedging',
                name: 'auto_hedging',
                key: 'auto_hedging',
                minWidth: '50px',
                title: 'Auto hedging',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsCreated: {
                id: 'autohedgerColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '100px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            autohedgerColumnsUpdated: {
                id: 'autohedgerColumnsUpdated',
                name: 'updated',
                key: 'updated',
                minWidth: '100px',
                title: 'Updated',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultManageComplianceTradingRecord = () => ({
    id: 'manageComplianceTradingSystem',
    title: 'System workspace',
    widgets: manageComplianceTradingWidgets,
    widgetSettings: {
        byId: {
            complianceTradingChartsTitle: {
                id: 'complianceTradingChartsTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trading chart',
            },
            manageComplianceTradingTitle: {
                id: 'manageComplianceTradingTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trading Stats',
            },
        },
        allIds: [],
    },
});
export const defaultPortfolioRecord = () => ({
    id: 'portfolioSystem',
    title: 'System workspace',
    widgets: portfolioWidgets,
    widgetSettings: {
        byId: {
            portfolioTitle: {
                id: 'portfolioTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Holdings',
            },
        },
        allIds: [],
    },
});
export const defaultRfqWorkspaceRecord = () => ({
    id: 'systemRfq',
    title: 'Rfq workspace',
    widgets: widgetsRfq,
    widgetSettings: {
        byId: {
            balancesRfqTitle: {
                id: 'balancesRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'balances',
            },
            exchangesBalancesRfqTitle: {
                id: 'exchangesBalancesRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'reserves',
            },
            ordersListRfqTitle: {
                id: 'ordersListRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Orders',
            },
            ordersListRfqRegularToCancel: {
                id: 'ordersListRfqRegularToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'To Cancel',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularOpen: {
                id: 'ordersListRfqRegularOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularFilled: {
                id: 'ordersListRfqRegularFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularRejected: {
                id: 'ordersListRfqRegularRejected',
                name: 'status',
                label: 'Rejected',
                title: 'Rejected',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularCanceled: {
                id: 'ordersListRfqRegularCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularCanceledPartiallyFilled: {
                id: 'ordersListRfqRegularCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularDateFrom: {
                id: 'ordersListRfqRegularDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListRfqRegularDateTo: {
                id: 'ordersListRfqRegularDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListRfqRegularLimit: {
                id: 'ordersListRfqRegularLimit',
                name: 'order_type',
                label: 'limit',
                title: 'Limit',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularMarket: {
                id: 'ordersListRfqRegularMarket',
                name: 'order_type',
                label: 'market',
                title: 'Market',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularRfqMarket: {
                id: 'ordersListRfqRegularRfqMarket',
                name: 'order_type',
                label: 'rfq_market',
                title: 'RFQ Market',
                type: 'checkbox',
                value: true,
            },
            ordersListRfqRegularRfqLimit: {
                id: 'ordersListRfqRegularRfqLimit',
                name: 'order_type',
                label: 'rfq_limit',
                title: 'RFQ Limit',
                type: 'checkbox',
                value: true,
            },
            ordersListRfqRegularActionTypes: {
                id: 'ordersListRfqRegularActionTypes',
                type: 'radio',
                name: 'action_type',
                title: 'Action types',
                value: '',
                options: [{ label: 'All', value: '' },
                    { label: 'Buy', value: 'Buy' },
                    { label: 'Sell', value: 'Sell' },
                ],
            },
            ordersListRfqRegularAmountFrom: {
                id: 'ordersListRfqRegularAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListRfqRegularAmountTo: {
                id: 'ordersListRfqRegularAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListRfqRegularUSD: {
                id: 'ordersListRfqRegularUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularEUR: {
                id: 'ordersListRfqRegularEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularBTC: {
                id: 'ordersListRfqRegularBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularXRP: {
                id: 'ordersListRfqRegularXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularLTC: {
                id: 'ordersListRfqRegularLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularETH: {
                id: 'ordersListRfqRegularETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularETC: {
                id: 'ordersListRfqRegularETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularQTUM: {
                id: 'ordersListRfqRegularQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqRegularXLM: {
                id: 'ordersListRfqRegularXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            // partial orders
            ordersListRfqPartialOrderbookOpen: {
                id: 'ordersListRfqPartialOrderbookOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookFilled: {
                id: 'ordersListRfqPartialOrderbookFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookExpired: {
                id: 'ordersListRfqPartialOrderbookExpired',
                name: 'status',
                label: 'Expired',
                title: 'Expired',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookCanceled: {
                id: 'ordersListRfqPartialOrderbookCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookToCancel: {
                id: 'ordersListRfqPartialOrderbookToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'ToCancel',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookCanceledPartiallyFilled: {
                id: 'ordersListRfqPartialOrderbookCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookDateFrom: {
                id: 'ordersListRfqPartialOrderbookDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListRfqPartialOrderbookDateTo: {
                id: 'ordersListRfqPartialOrderbookDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListRfqPartialOrderbookBuy: {
                id: 'ordersListRfqPartialOrderbookBuy',
                name: 'action_type',
                label: 'Buy',
                title: 'Buy',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookSell: {
                id: 'ordersListRfqPartialOrderbookSell',
                name: 'action_type',
                label: 'Sell',
                title: 'Sell',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookAmountFrom: {
                id: 'ordersListRfqPartialOrderbookAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListRfqPartialOrderbookAmountTo: {
                id: 'ordersListRfqPartialOrderbookAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListRfqPartialOrderbookUSD: {
                id: 'ordersListRfqPartialOrderbookUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookEUR: {
                id: 'ordersListRfqPartialOrderbookEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookBTC: {
                id: 'ordersListRfqPartialOrderbookBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookXRP: {
                id: 'ordersListRfqPartialOrderbookXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookLTC: {
                id: 'ordersListRfqPartialOrderbookLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookETH: {
                id: 'ordersListRfqPartialOrderbookETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookETC: {
                id: 'ordersListRfqPartialOrderbookETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookQTUM: {
                id: 'ordersListRfqPartialOrderbookQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqPartialOrderbookXLM: {
                id: 'ordersListRfqPartialOrderbookXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            // sub orders
            ordersListRfqSubordersToCancel: {
                id: 'ordersListRfqSubordersToCancel',
                name: 'status',
                label: 'ToCancel',
                title: 'To Cancel',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersOpen: {
                id: 'ordersListRfqSubordersOpen',
                name: 'status',
                label: 'Open',
                title: 'Open',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersFilled: {
                id: 'ordersListRfqSubordersFilled',
                name: 'status',
                label: 'Filled',
                title: 'Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersRejected: {
                id: 'ordersListRfqSubordersRejected',
                name: 'status',
                label: 'Rejected',
                title: 'Rejected',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersCanceled: {
                id: 'ordersListRfqSubordersCanceled',
                name: 'status',
                label: 'Canceled',
                title: 'Canceled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersCanceledPartiallyFilled: {
                id: 'ordersListRfqSubordersCanceledPartiallyFilled',
                name: 'status',
                label: 'CanceledPartiallyFilled',
                title: 'Canceled Partially Filled',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersDateFrom: {
                id: 'ordersListRfqSubordersDateFrom',
                name: 'from',
                label: 'from',
                title: 'Date from',
                type: 'date',
                value: '',
            },
            ordersListRfqSubordersDateTo: {
                id: 'ordersListRfqSubordersDateTo',
                name: 'to',
                label: 'to',
                title: 'Date to',
                type: 'date',
                value: '',
            },
            ordersListRfqSubordersLimit: {
                id: 'ordersListRfqSubordersLimit',
                name: 'order_type',
                label: 'limit',
                title: 'Limit',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersMarket: {
                id: 'ordersListRfqSubordersMarket',
                name: 'order_type',
                label: 'market',
                title: 'Market',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersActionTypes: {
                id: 'ordersListRfqSubordersActionTypes',
                type: 'radio',
                name: 'action_type',
                title: 'Action types',
                value: '',
                options: [{ label: 'All', value: '' },
                    { label: 'Buy', value: 'Buy' },
                    { label: 'Sell', value: 'Sell' },
                ],
            },
            ordersListRfqSubordersAmountFrom: {
                id: 'ordersListRfqSubordersAmountFrom',
                name: 'amount_from',
                label: 'amount_from',
                title: 'Amount from',
                type: 'number',
                value: '',
            },
            ordersListRfqSubordersAmountTo: {
                id: 'ordersListRfqSubordersAmountTo',
                name: 'amount_to',
                label: 'amount_to',
                title: 'Amount to',
                type: 'number',
                value: '',
            },
            ordersListRfqSubordersUSD: {
                id: 'ordersListRfqSubordersUSD',
                name: 'quote',
                label: 'usd',
                title: 'USD',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersEUR: {
                id: 'ordersListRfqSubordersEUR',
                name: 'quote',
                label: 'eur',
                title: 'EUR',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersBTC: {
                id: 'ordersListRfqSubordersBTC',
                name: 'base',
                label: 'btc',
                title: 'BTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersXRP: {
                id: 'ordersListRfqSubordersXRP',
                name: 'base',
                label: 'xrp',
                title: 'XRP',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersLTC: {
                id: 'ordersListRfqSubordersLTC',
                name: 'base',
                label: 'ltc',
                title: 'LTC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersETH: {
                id: 'ordersListRfqSubordersETH',
                name: 'base',
                label: 'eth',
                title: 'ETH',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersETC: {
                id: 'ordersListRfqSubordersETC',
                name: 'base',
                label: 'etc',
                title: 'ETC',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersQTUM: {
                id: 'ordersListRfqSubordersQTUM',
                name: 'base',
                label: 'qtum',
                title: 'QTUM',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersXLM: {
                id: 'ordersListRfqSubordersXLM',
                name: 'base',
                label: 'xlm',
                title: 'XLM',
                type: 'checkbox',
                value: false,
            },
            ordersListRfqSubordersPrepared: {
                id: 'ordersListRfqSubordersPrepared',
                name: 'status',
                label: 'Prepared',
                title: 'Prepared',
                type: 'checkbox',
                value: false,
            },
            customerSearchRfqTitle: {
                id: 'customerSearchRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Customers',
            },
            clientSearchRfqTitle: {
                id: 'clientSearchRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Clients',
            },
            tradeHistoryRfqTitle: {
                id: 'tradeHistoryRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trade history',
            },
            tradeHistoryRfqIgnoreGlobal: {
                id: 'tradeHistoryRfqIgnoreGlobal',
                name: 'Ignore global',
                label: '',
                title: 'Ignore global settings',
                type: 'checkbox',
                value: false,
            },
            tradeHistoryRfqExchanges: {
                id: 'tradeHistoryRfqExchanges',
                name: 'Exchanges',
                label: 'Exchanges',
                title: 'Exchanges',
                type: 'select',
                value: null,
                options: [],
            },
            tradeHistoryRfqQuote: {
                id: 'tradeHistoryRfqQuote',
                name: 'Quote',
                label: 'Quote',
                title: 'Quote',
                type: 'select',
                value: null,
                options: [],
            },
            tradeHistoryRfqBase: {
                id: 'tradeHistoryRfqBase',
                name: 'Base',
                label: 'Base',
                title: 'Base',
                type: 'select',
                value: null,
                options: [],
            },
            tradingViewRfqTitle: {
                id: 'tradingViewRfqTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Trading overview',
            },
            tradingViewRfqIgnoreGlobal: {
                id: 'tradingViewRfqIgnoreGlobal',
                name: 'Ignore global',
                label: '',
                title: 'Ignore global settings',
                type: 'checkbox',
                value: true,
            },
            tradingViewRfqBase: {
                id: 'tradingViewRfqBase',
                name: 'Base',
                label: 'Base',
                title: 'Base',
                type: 'select',
                value: null,
                options: [],
            },
            tradingViewRfqQuote: {
                id: 'tradingViewRfqQuote',
                name: 'Quote',
                label: 'Quote',
                title: 'Quote',
                type: 'select',
                value: null,
                options: [],
            },
            tradingViewRfqExchanges: {
                id: 'tradingViewRfqExchanges',
                name: 'Exchanges',
                label: 'Exchanges',
                title: 'Exchanges',
                type: 'select',
                value: null,
                options: [],
            },
            rfqOrdersTitle: {
                id: 'rfqOrdersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Rqf orders',
            },
            rfqOrdersCard: {
                id: 'rfqOrdersCard',
                name: 'rfqOrdersCard',
                label: 'rfqOrdersCard',
                title: '',
                type: 'list',
                value: [],
            },
        },
        allIds: [],
    },
});
export const defaultHedgeOrdersRecord = () => ({
    id: 'placeOrdersSystem',
    title: 'System workspace',
    widgets: hedgeOrdersWidgets,
    widgetSettings: {
        byId: {
            placeOrdersTitle: {
                id: 'placeOrdersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Orders',
            },
            hedgeOrdersTitle: {
                id: 'hedgeOrdersTitle',
                title: 'Widget title',
                type: 'text',
                value: 'Place Order',
            },
            placeOrdersColumnsActionType: {
                id: 'placeOrdersColumnsActionType',
                name: 'action_type',
                key: 'action_type',
                minWidth: '150px',
                title: 'Buy/Sell',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsSize: {
                id: 'placeOrdersColumnsSize',
                name: 'amount',
                key: 'amount',
                minWidth: '150px',
                title: 'Size',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsPair: {
                id: 'placeOrdersColumnsPair',
                name: 'pair',
                key: 'pair',
                minWidth: '150px',
                title: 'Pair',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsExchange: {
                id: 'placeOrdersColumnsExchange',
                name: 'exchange',
                key: 'exchange',
                minWidth: '150px',
                title: 'Exchange',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsExecutedPrice: {
                id: 'placeOrdersColumnsExecutedPrice',
                name: 'executedPrice',
                key: 'executedPrice',
                minWidth: '150px',
                title: 'Price Per',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsTotal: {
                id: 'placeOrdersColumnsTotal',
                name: 'executedValue',
                key: 'executedValue',
                minWidth: '150px',
                title: 'Total',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsCreated: {
                id: 'placeOrdersColumnsCreated',
                name: 'created',
                key: 'created',
                minWidth: '160px',
                title: 'Created',
                type: 'checkbox',
                value: true,
            },
            placeOrdersColumnsStatus: {
                id: 'placeOrdersColumnsStatus',
                name: 'status',
                key: 'status',
                minWidth: '150px',
                title: 'Status',
                type: 'checkbox',
                value: true,
            },
        },
        allIds: [],
    },
});
export const defaultDashboards = () => ([
    {
        workspaces: [],
        name: 'custody',
        activeWorkspace: 'custodySystem',
    }, {
        workspaces: [],
        name: 'trading',
        activeWorkspace: 'system',
    }, {
        workspaces: [],
        name: 'tradingRfq',
        activeWorkspace: 'systemRfq',
    }, {
        workspaces: [],
        name: 'manageOrders',
        activeWorkspace: 'manageOrdersSystem',
    }, {
        workspaces: [],
        name: 'placeOrders',
        activeWorkspace: 'placeOrdersSystem',
    }, {
        workspaces: [],
        name: 'manageCustomers',
        activeWorkspace: 'manageCustomersSystem',
    }, {
        workspaces: [],
        name: 'manageUsers',
        activeWorkspace: 'manageUsersSystem',
    }, {
        workspaces: [],
        name: 'tradingTransfers',
        activeWorkspace: 'tradingTransfersSystem',
    }, {
        workspaces: [],
        name: 'manageLogbook',
        activeWorkspace: 'manageLogbookSystem',
    }, {
        workspaces: [],
        name: 'manageTokens',
        activeWorkspace: 'manageTokensSystem',
    }, {
        workspaces: [],
        name: 'manageSchedulers',
        activeWorkspace: 'manageSchedulersSystem',
    }, {
        workspaces: [],
        name: 'adminExchange',
        activeWorkspace: 'adminExchangeSystem',
    }, {
        workspaces: [],
        name: 'manageComplianceTrading',
        activeWorkspace: 'manageComplianceTradingSystem',
    }, {
        workspaces: [],
        name: 'manageCompliance',
        activeWorkspace: 'manageComplianceSystem',
    }, {
        workspaces: [],
        name: 'manageWhitelist',
        activeWorkspace: 'manageWhitelistSystem',
    }, {
        workspaces: [],
        name: 'quoteMonitor',
        activeWorkspace: 'rfqManagementSystem',
    }, {
        workspaces: [],
        name: 'clientsManagement',
        activeWorkspace: 'rfqClientsManagementSystem',
    }, {
        workspaces: [],
        name: 'autohedger',
        activeWorkspace: 'autohedgerSystem',
    }, {
        workspaces: [],
        name: 'autohedgerBalance',
        activeWorkspace: 'autohedgerBalanceSystem',
    }, {
        workspaces: [],
        name: 'currencyConfig',
        activeWorkspace: 'currencyConfigSystem',
    }, {
        workspaces: [],
        name: 'portfolio',
        activeWorkspace: 'portfolioSystem',
    }, {
        workspaces: [],
        name: 'permissions',
        activeWorkspace: 'permissionsSystem',
    }, {
        workspaces: [],
        name: 'strategies',
        activeWorkspace: 'strategiesSystem',
    },
]);
