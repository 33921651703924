import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { connect } from 'react-redux';
import { getTradingBases } from '../../../../../redux/selectors/selectors';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../styling/style';
import { getCustodyWallets, getCustodyCurrencies } from '../../../../../redux/selectors/custodySelectors';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const parentTypes = ['erc20', 'bep20', 'erc20_polygon'];
const typeOptyons = [
    { label: 'Regular', value: 'regular' },
    { label: 'Trading', value: 'trading' },
];
const getWalletsOptions = (wallets, currencyFilter, typeFilter) => wallets
    .filter(w => w.currency_code === currencyFilter && w.accounting_type === typeFilter)
    .map((w) => {
    const address = w.compliance_addresses.find(a => a.state === 'active');
    const addressData = address ? address.address : '';
    return { label: `${w.label} ${addressData}`, value: w.code };
});
const WalletModal = ({ open, loading, customers, toggleFunc, allWallets, currencies, optionsBase, customerCode, allCurrencies, handleAddWallet, dispatchLoadMarkets, dispatchLoadExchanges, }) => {
    const [label, setLabel] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [requireParentWalletCurrency, setRequireParentWalletCurrency] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [parentWallets, setParentWallets] = useState([]);
    const [type, setType] = useState(typeOptyons[0]);
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [currency, setCurrency] = useState(allCurrencies.length ? allCurrencies[0] : null);
    const helperTextStyles = useHelperTextStyles();
    const addNewWallet = () => {
        const payload = {
            label,
            accounting_type: (type === null || type === void 0 ? void 0 : type.value) || '',
            currency_code: (currency === null || currency === void 0 ? void 0 : currency.value) || '',
            customer_code: (customer === null || customer === void 0 ? void 0 : customer.value) || '',
            fee_source_wallet_code: (selectedWallet === null || selectedWallet === void 0 ? void 0 : selectedWallet.value) || '',
        };
        handleAddWallet(payload);
        setLabel('');
        setType(null);
        setSelectedWallet(null);
        setCurrency(allCurrencies.length ? allCurrencies[0] : null);
    };
    const onFieldChange = (evt) => {
        const { value, name } = evt.target;
        if (name === 'label') {
            setLabel(value);
        }
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrency(newValue);
    };
    const handleCustomerChange = (event, newValue) => {
        setCustomer(newValue);
    };
    const handleSelectWallet = (event, newValue) => {
        setSelectedWallet(newValue);
    };
    const handleSelectType = (event, newValue) => {
        setType(newValue);
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (currency && (currency === null || currency === void 0 ? void 0 : currency.value) != "") {
            const currencyType = currencies.find(c => c.ticker.toUpperCase() === (currency === null || currency === void 0 ? void 0 : currency.value.toUpperCase()));
            switch (currencyType === null || currencyType === void 0 ? void 0 : currencyType.type.toUpperCase()) {
                case 'ERC20':
                    setParentWallets(getWalletsOptions(allWallets, 'eth', (type === null || type === void 0 ? void 0 : type.value) || ''));
                    break;
                case 'BEP20':
                    setParentWallets(getWalletsOptions(allWallets, 'bnb_bsc', (type === null || type === void 0 ? void 0 : type.value) || ''));
                    break;
                case 'ERC20_POLYGON':
                    setParentWallets(getWalletsOptions(allWallets, 'matic_polygon', (type === null || type === void 0 ? void 0 : type.value) || ''));
                    break;
                case 'BASE':
                    setParentWallets([]);
                    break;
                default:
                    console.error('Unknown currency type ' + (currencyType === null || currencyType === void 0 ? void 0 : currencyType.type));
                    setParentWallets([]);
            }
        }
    }, [currency, allWallets, type]);
    useEffect(() => {
        if ((!selectedWallet
            && requireParentWalletCurrency.includes(((currency === null || currency === void 0 ? void 0 : currency.value) || '').toUpperCase()))
            || !label || !currency) {
            setDisabled(true);
        }
        else if ((type === null || type === void 0 ? void 0 : type.value) === 'trading' && !customer && !customerCode) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [currency, selectedWallet, label, customerCode, type, customer]);
    useEffect(() => {
        if (currencies.length) {
            setRequireParentWalletCurrency(currencies.filter(c => parentTypes.includes(c.type)).map(c => c.code.toUpperCase()));
        }
        else {
            setRequireParentWalletCurrency([]);
        }
    }, [currencies]);
    useEffect(() => {
        if (currency) {
            setSelectedWallet(null);
        }
    }, [currency]);
    useEffect(() => {
        if ((type === null || type === void 0 ? void 0 : type.value) === 'trading') {
            const filtered = allCurrencies.filter(c => optionsBase.find(b => b.value === c.value));
            if (filtered.length) {
                setCurrenciesOptions([{ label: 'Select currency', value: '' }, ...filtered]);
            }
            else {
                setCurrenciesOptions([]);
            }
        }
    }, [type, allCurrencies, optionsBase]);
    useEffect(() => {
        if ((type === null || type === void 0 ? void 0 : type.value) === 'trading') {
            setCurrency(currenciesOptions.length ? currenciesOptions[0] : null);
        }
    }, [type, currenciesOptions]);
    if (loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Create new Wallet"),
        React.createElement(DialogContent, null,
            React.createElement(Autocomplete, { id: "type", size: "small", value: type, options: typeOptyons, placeholder: "Select type", style: { marginTop: '10px' }, onChange: handleSelectType, getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Type", variant: "outlined" }))) }),
            React.createElement(Autocomplete, { id: "currency", size: "small", value: currency, placeholder: "Select currency", style: { marginTop: '10px' }, onChange: handleCurrencyChange, getOptionLabel: option => option.label, options: (type === null || type === void 0 ? void 0 : type.value) === 'trading' ? currenciesOptions : allCurrencies, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Currency", variant: "outlined", FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${(currency === null || currency === void 0 ? void 0 : currency.value) === '' || !currency ? 'Field is required' : ''}` }))) }),
            React.createElement(TextField, { fullWidth: true, size: "small", name: "label", margin: "normal", value: label, label: "Label", variant: "outlined", onChange: onFieldChange, InputLabelProps: { shrink: true } }),
            parentWallets.length > 0 ? (React.createElement(Autocomplete, { id: "wallet", size: "small", value: selectedWallet, options: parentWallets, placeholder: "Select wallet", style: { marginTop: '10px' }, onChange: handleSelectWallet, getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Wallet", variant: "outlined" }))) })) : null,
            customerCode ? null : (React.createElement(Autocomplete, { id: "customer", size: "small", value: customer, options: customers, disabled: !!customerCode, placeholder: "Select customer", style: { marginTop: '10px' }, onChange: handleCustomerChange, getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Customer", variant: "outlined" }))) }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "outlined", disabled: disabled, onClick: addNewWallet }, "Create"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggleFunc() }, "Close"))));
};
const mapStateToProps = (state) => ({
    optionsBase: getTradingBases(state),
    allWallets: getCustodyWallets(state),
    currencies: state.custody.currencies,
    customerCode: state.client.customer_code,
    allCurrencies: [{ label: 'Select currency', value: '' }, ...getCustodyCurrencies(state)],
    customers: getCustomersSelectedInputInterface(state),
});
const dispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, dispatchToProps)(WalletModal);
