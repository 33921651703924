/* eslint-disable camelcase, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call,
@typescript-eslint/ban-ts-comment, no-nested-ternary, @typescript-eslint/no-unsafe-return,
@typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState, useCallback, } from 'react';
import { debounce, isEqual } from 'lodash';
import RGL from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useResizeDetector } from 'react-resize-detector';
import { SCHEMA_WORKING_SET_UPDATE, SCHEMA_APPLY_ALL_SETTINGS, } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import Block from './Block';
import CreateRfq from './CreateRfq';
import { PanelContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import RfqSettingsService from '../../../../../services/rfqSettingsService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import BlockReverse from './BlockReverse';
import { Alert, Box, CircularProgress } from '@mui/material';
const useStyles = makeStyles({
    rglRoot: (props) => ({
        width: '100%',
        minWidth: '100%',
        overflow: 'hidden',
    }),
});
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const RfqOrders = ({ settings, widgetKey, customers, clientInfo, features, selectedClient, dashboard, rfqType, masterTenant, }) => {
    const dispatch = useDispatch();
    const [rglCols, setRglCols] = useState(0);
    const prevSettings = usePrevious(settings);
    const [rglWidth, setRglWidth] = useState(0);
    const [blocks, setBlocks] = useState([]);
    const [layout, setLayout] = useState([]);
    const [loadingRfqSettings, setLoadingRfqSettings] = useState(false);
    const [rfqSettings, setRfqSettings] = useState([]);
    const [abortController, setAbortController] = useState(new AbortController());
    const classes = useStyles({ cards: blocks.length });
    const { width, ref } = useResizeDetector();
    const cardWidth = 330;
    let resizeListener = false;
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const addItem = (key, customer) => {
        const cardSetting = rfqSettings.find(s => s.settings_code === key);
        const id = `${key}#${customer.value}#${layout.length}`;
        const item = {
            x: layout.length % rglCols,
            y: Math.floor(layout.length / rglCols),
            w: 1,
            h: 1,
            i: id,
        };
        setLayout([...layout, item]);
        if (cardSetting) {
            setBlocks([
                ...blocks,
                {
                    key: id,
                    customer,
                    base: cardSetting === null || cardSetting === void 0 ? void 0 : cardSetting.base,
                    quote: cardSetting === null || cardSetting === void 0 ? void 0 : cardSetting.quote,
                    guaranteed_qty: cardSetting === null || cardSetting === void 0 ? void 0 : cardSetting.guaranteed_qty,
                    stream_type: cardSetting === null || cardSetting === void 0 ? void 0 : cardSetting.stream_type,
                },
            ]);
        }
        const newSettings = settings.find(s => s.id.includes('Card'));
        const newItems = newSettings ? newSettings.value : [];
        if (Array.isArray(newItems)) {
            dispatch({
                type: SCHEMA_WORKING_SET_UPDATE,
                settingId: `${widgetKey}Card`,
                settingValue: [
                    ...newItems,
                    {
                        key: id,
                        customer,
                        layout: item,
                    },
                ],
            });
            dispatch({
                type: SCHEMA_APPLY_ALL_SETTINGS,
                dashboard: 'trading',
            });
        }
    };
    const hadleRemoveCard = (id) => {
        const cardIndex = layout.findIndex(l => l.i === id);
        const newLayout = [...layout];
        const newBlocks = [...blocks];
        newLayout.splice(cardIndex, 1);
        newBlocks.splice(cardIndex, 1);
        setLayout(newLayout);
        setBlocks(newBlocks);
        const cardSettings = settings.find(s => s.id.includes('Card'));
        const settingValue = cardSettings === null || cardSettings === void 0 ? void 0 : cardSettings.value;
        const settingIndex = settingValue.findIndex(c => c.key === id);
        settingValue.splice(settingIndex, 1);
        dispatch({
            type: SCHEMA_WORKING_SET_UPDATE,
            settingId: `${widgetKey}Card`,
            settingValue,
        });
        dispatch({
            type: SCHEMA_APPLY_ALL_SETTINGS,
            dashboard: 'trading',
        });
    };
    const fetchRfqSettings = (codeClient) => {
        const rfqService = new RfqSettingsService({
            url: `/trade/rfq/assigned`,
            method: 'get',
            params: { client_code: codeClient }
        });
        setLoadingRfqSettings(true);
        rfqService.makeRequest()
            .then((data) => {
            setRfqSettings(data.records);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingRfqSettings(false);
        });
    };
    const createRfqOrder = (customer, key) => {
        if (customer) {
            addItem(key, customer);
        }
    };
    const handleLayoutChange = (rglLayout) => {
        const cardSettings = settings.find(s => s.id.includes('Card'));
        const settingValues = cardSettings === null || cardSettings === void 0 ? void 0 : cardSettings.value;
        if (settingValues.length && rglLayout && rglLayout.length) {
            const workspaceSettingValues = settingValues.map(s => ({
                key: s.key,
                customer: s.customer,
                layout: rglLayout.find(rgl => rgl.i === s.key) || s.layout,
            }));
            dispatch({
                type: SCHEMA_WORKING_SET_UPDATE,
                settingId: `${widgetKey}Card`,
                settingValue: workspaceSettingValues,
            });
            dispatch({
                type: SCHEMA_APPLY_ALL_SETTINGS,
                dashboard: 'tradingRfq',
            });
        }
    };
    const buildSystemLayout = (cards) => {
        if (cards.length && (customers === null || customers === void 0 ? void 0 : customers.length)) {
            if (cards.length) {
                const cardsData = { layoutItems: [], blocks: [] };
                let row = -1;
                let rfqStandardIndex = -1;
                let rfqReverseQuotationIndex = -1;
                cards.forEach((record, i) => {
                    rfqStandardIndex = record.stream_type === 'standard' ? rfqStandardIndex + 1 : rfqStandardIndex;
                    rfqReverseQuotationIndex = record.stream_type === 'reverse_quotation'
                        ? rfqReverseQuotationIndex + 1 : rfqReverseQuotationIndex;
                    const viewIndex = record.stream_type === 'standard' ? rfqStandardIndex : rfqReverseQuotationIndex;
                    row = (viewIndex % rglCols) === 0 ? row + 1 : row;
                    const key = `${record.settings_code}#${customers[0].value}#${viewIndex}`;
                    cardsData.layoutItems.push({
                        x: viewIndex % rglCols,
                        y: row,
                        w: 1,
                        h: 1,
                        i: key,
                    });
                    cardsData.blocks.push({
                        key,
                        customer: customers[0],
                        base: record.base,
                        quote: record.quote,
                        guaranteed_qty: record.guaranteed_qty,
                        stream_type: record.stream_type,
                    });
                });
                const viewBlocks = [...cardsData.blocks.map((b, i) => (Object.assign(Object.assign({}, b), { layout: cardsData.layoutItems[i] })))];
                const filteredviewBlocks = viewBlocks.filter(v => v.stream_type === rfqType);
                setBlocks(viewBlocks.filter(v => v.stream_type === rfqType));
                setLayout(cardsData.layoutItems.filter(l => filteredviewBlocks.findIndex(v => v.key === l.i) > -1));
                const workspaceSettingValues = [...viewBlocks.map((b, i) => ({
                        key: b.key,
                        customer: b.customer,
                        layout: b.layout,
                    }))];
                dispatch({
                    type: SCHEMA_WORKING_SET_UPDATE,
                    settingId: `${widgetKey}Card`,
                    settingValue: workspaceSettingValues,
                });
                dispatch({
                    type: SCHEMA_APPLY_ALL_SETTINGS,
                    dashboard: 'tradingRfq',
                });
            }
        }
    };
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        ;
        const elementWidth = ref.current ? ref.current.offsetWidth : 0;
        const cols = Math.floor(elementWidth / cardWidth);
        setRglCols(cols);
        setRglWidth(cardWidth * cols);
    }, [ref.current]);
    const handleWidthChange = useRef(debounce((layoutWidth, layoutItems) => {
        if (!layoutWidth) {
            return;
        }
        const cols = Math.floor(layoutWidth / cardWidth);
        setRglCols(cols);
        setRglWidth(cardWidth * cols);
        if (layoutItems.length >= 1) {
            const first = layoutItems[0];
            if (first) {
                first.moved = !first.moved;
                // eslint-disable-next-line no-param-reassign
                layoutItems.forEach((l, i) => { l.x = i % cols; l.y = Math.floor(i / cols); });
                if (layoutItems.length > 1) {
                    layoutItems.splice(0, 1);
                    setLayout([...layoutItems, first]);
                }
                else {
                    setLayout([first]);
                }
            }
        }
    }, 300)).current;
    const buildLayout = () => {
        if (!settings || !settings.length)
            return;
        const cardSettings = settings.find(s => s && s.id.includes('Card'));
        const settingsToBlocks = (cardSettings === null || cardSettings === void 0 ? void 0 : cardSettings.value)
            .map((block) => {
            const setting = rfqSettings.find(s => s.settings_code === block.key.split('#')[0]);
            return {
                key: block.key,
                customer: block.customer,
                base: (setting === null || setting === void 0 ? void 0 : setting.base) || '',
                quote: (setting === null || setting === void 0 ? void 0 : setting.quote) || '',
                stream_type: (setting === null || setting === void 0 ? void 0 : setting.stream_type) || '',
                guaranteed_qty: (setting === null || setting === void 0 ? void 0 : setting.guaranteed_qty) || '',
                layout: block.layout,
            };
        })
            .filter(v => v.stream_type === rfqType);
        if (settingsToBlocks.length === 0 && (dashboard === null || dashboard === void 0 ? void 0 : dashboard.activeWorkspace) !== 'systemRfq') {
            setLayout([]);
            setBlocks([]);
            return;
        }
        if (settingsToBlocks.length) {
            const settingsToLayouts = settingsToBlocks
                .map(s => s.layout);
            setLayout(settingsToLayouts);
            setBlocks(settingsToBlocks);
        }
        else if ((dashboard === null || dashboard === void 0 ? void 0 : dashboard.activeWorkspace) === 'systemRfq') {
            buildSystemLayout(rfqSettings);
        }
        if (!resizeListener) {
            window.addEventListener('resize', () => { var _a; return handleWidthChange((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth, layout); }, { signal: abortController.signal });
            resizeListener = true;
        }
    };
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);
    useEffect(() => {
        setBlocks([]);
        setLayout([]);
        fetchRfqSettings(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value);
    }, [selectedClient]);
    useEffect(() => {
        if (rglCols === 0 || !width) {
            return;
        }
        if (!rfqSettings || rfqSettings.length === 0) {
            return;
        }
        if (masterTenant) {
            buildSystemLayout(rfqSettings);
        }
        else {
            buildLayout();
        }
        setLoadingRfqSettings(false);
    }, [rfqSettings]);
    useEffect(() => {
        if (rglCols === 0 || !width) {
            return;
        }
        if (isEqual(settings, prevSettings)) {
            return;
        }
        buildLayout();
    }, [settings]);
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement(CreateRfq, { customers: customers, createRfq: createRfqOrder, loadingRfqSettings: loadingRfqSettings, rfqType: rfqType, rfqSettings: rfqSettings, selectedClient: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || null, arrangeCards: () => handleWidthChange(width, layout) }),
            React.createElement("div", { className: classes.rglRoot, ref: ref }, layout.length > 0 ? (React.createElement(RGL, { layout: layout, rowHeight: rfqType === 'standard' ? 350 : 370, width: rglWidth, className: "layout", isResizable: false, autoSize: true, cols: rglCols, draggableHandle: ".paperBox", onLayoutChange: handleLayoutChange, onDragStart: (l, oldItem, newItem, placeholder, e, element) => {
                    e.stopPropagation();
                } }, blocks.map((b) => (React.createElement("div", { key: `${b.key}` }, rfqType === 'standard' ? (React.createElement(Block, { base: b.base, quote: b.quote, blockKey: b.key, settings: settings, features: features, customer: b.customer, widgetKey: widgetKey, guaranteedQty: b.guaranteed_qty, removeCard: hadleRemoveCard })) : (React.createElement(BlockReverse, { base: b.base, quote: b.quote, blockKey: b.key, settings: settings, features: features, customer: b.customer, widgetKey: widgetKey, guaranteedQty: b.guaranteed_qty, removeCard: hadleRemoveCard }))))))) : React.createElement(Box, { p: 1, display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }, loadingRfqSettings ?
                React.createElement(CircularProgress, null) : React.createElement(Alert, { severity: "info" }, "No RFQ streams available"))))));
};
export default RfqOrders;
