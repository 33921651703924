var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import Decimal from 'decimal.js';
import axios from 'axios';
import buildUrl from 'build-url';
import { groupBy } from 'lodash';
import { Box, Chip, Collapse, Drawer } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialPopoverWrap, WalletsClientContainer } from '../../../../Custody/styling/style';
import { Status } from './RfqModels';
import StreamsFilters from './StreamsFilters';
import RfqSettingModal from './RfqSettingModal';
import QuoteMonitorItem from './WidgetQuoteMonitorItem';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import RfqSettingsService from '../../../../../../services/rfqSettingsService';
import InstrumentsService from '../../../../../../services/instrumentsService';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { getAllCustomers } from '../../../../../../redux/selectors/customerSearchSelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { CUSTOMER_SEARCH_FETCH_REQUEST } from '../../../../../../redux/actionTypes/customerSearchActionsTypes';
import { CLIENTS_SET_UPDATE, SET_SELECTED_CLIENT, } from '../../../../../../redux/actionTypes/clientSearchActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
import ManageStreamAccess from './ManageStreamAccess';
import { getClientsTree } from './accessMap';
const setClients = (clients) => ({
    type: CLIENTS_SET_UPDATE,
    clients,
});
const setClient = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'boolean' && typeof b[orderBy] === 'boolean') {
        if (a[orderBy] === b[orderBy])
            return 0;
        return a[orderBy] ? -1 : 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const mergeInstruments = (arr) => arr.map((i, index) => {
    var _a;
    return ({
        assigned: ((_a = i.access) === null || _a === void 0 ? void 0 : _a.length) > 0 ? Status.ASSIGNED : Status.UNASIGNED,
        access: i.access,
        action: '',
        base: i.base,
        notional: '',
        method: 'Mid',
        ref_price: '',
        quote: i.quote,
        id: `${index}`,
        label: i.label,
        status: i.status,
        used_ref_price: '',
        created: i.created,
        spread_mode: i.spread_mode,
        calculation_mode: i.calculation_mode,
        stream_type: i.stream_type,
        ul_shift_bps: i.ul_shift_bps,
        exchange_name: i.exchange_name,
        exchange_code: i.exchange_code,
        settings_code: i.settings_code,
        guaranteed_qty: i.guaranteed_qty,
        min_spread_bps: i.min_spread_bps,
        market_fees_bps: i.market_fees_bps,
        extra_spread_bps: i.extra_spread_bps,
        widening_factor_bps: i.widening_factor_bps,
        ul: `${i.base.toUpperCase()}/${i.quote.toUpperCase()}`,
        used_spread: new Decimal(i.widening_factor_bps).mul(i.min_spread_bps).toFixed(2),
    });
});
const getOptionsByKey = (arr, key) => {
    if (arr.length) {
        return arr
            .map((s) => ({ value: `${s[key]}`, label: `${s[key]}` }))
            .filter((s, index, self) => index === self.findIndex((m) => m.value === s.value && m.label === s.label));
    }
    return [];
};
const useStyles = makeStyles((theme) => createStyles({
    customTableRow: {
        '& > tr:nth-of-type(2n)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));
/* @typescript-eslint/no-unsafe-call */
const QuoteMonitor = ({ columns, features, selectedClient, customers, customersLoading, clients, dispatchClientSet, }) => {
    const [loading, setLoading] = useState(false);
    const [rfqModalOpen, setRfqModalOpen] = useState(false);
    const [viewSettings, setViewSettings] = useState([]);
    const [filtersState, setFiltersState] = useState([]);
    const [settings, setSettings] = useState([]);
    const [baseOptions, setBaseOptions] = useState([]);
    const [quoteOptions, setQuoteOptions] = useState([]);
    const [settingsToRender, setSettingsToRender] = useState([]);
    const [settingsFiltered, setSettingsFiltered] = useState([]);
    const [groupedStreams, setGroupedStreams] = useState([]);
    const [collapsedRows, setCollapsedRows] = useState({});
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [exchangeOptions, setExchangeOptions] = useState([]);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('exchange_name');
    const [collapsed, setCollapsed] = useState(true);
    const [selectedStreamId, setSelectedStreamId] = useState('');
    const [clientsTree, setClientsTree] = useState();
    const theme = useTheme();
    const classes = useStyles();
    const toggleCreateRfqModal = () => {
        setRfqModalOpen(!rfqModalOpen);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortByBase = (arr, key = 'name') => {
        arr.sort((a, b) => a[key].localeCompare(b[key]));
        const sorter = (a, b) => {
            if (a[key].toUpperCase().includes('BTC')) {
                return -1;
            }
            if (a[key].toUpperCase().includes('ETH') && b[key].toUpperCase().includes('BTC')) {
                return -1;
            }
            return a[key].localeCompare(b[key]);
        };
        arr.sort(sorter);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find((c) => c.key === colKey);
        const colIndex = columnsToRender.findIndex((c) => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('quoteMonitor'));
    };
    const handleCollapseAll = () => {
        setCollapsed(!collapsed);
        const groups = [...groupedStreams];
        groups.forEach((g) => {
            g.collapsed = collapsed;
        });
    };
    const fetchRfqSettings = () => {
        setLoading(true);
        const rfqService = new RfqSettingsService({
            url: '/trade/rfq',
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        rfqService
            .makeRequest()
            .then((data) => {
            setLoading(false);
            setSettings(data.records);
            const bases = getOptionsByKey(data.records, 'base');
            setBaseOptions(bases);
            sortByBase(bases, 'label');
            setQuoteOptions(getOptionsByKey(data.records, 'quote'));
            setExchangeOptions(getOptionsByKey(data.records, 'exchange_code'));
            const res = mergeInstruments(data.records);
            if (res.length) {
                setViewSettings(res);
            }
            else {
                setViewSettings([]);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_FETCH);
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: `Error: ${message}`,
            });
            setLoading(false);
        });
    };
    const createRfqSetting = (payload) => {
        const service = new InstrumentsService({
            url: '/trade/rfq/',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        service
            .makeRequest()
            .then(() => {
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'RFQ Setting successfully created',
            });
            fetchRfqSettings();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_CREATE);
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: `Error: ${message}`,
            });
        });
    };
    const updateRfqSetting = (code, payload) => {
        const service = new InstrumentsService({
            url: `/trade/rfq/${code}`,
            method: 'put',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        service
            .makeRequest()
            .then(() => {
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'RFQ Setting successfully updated',
            });
            fetchRfqSettings();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_UPDATE);
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: `Error: ${message}`,
            });
        });
    };
    const deleteRfqSetting = (settingCode) => {
        const service = new InstrumentsService({
            url: `/trade/rfq/${settingCode}`,
            method: 'delete',
            baseURL: getEnvSettings().adminApiUrl,
        });
        service
            .makeRequest()
            .then(() => {
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'RFQ Setting successfully deleted',
            });
            fetchRfqSettings();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_DELETE);
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: `Error: ${message}`,
            });
        });
    };
    const updateFilter = useCallback((filters) => {
        setFiltersState([...filters]);
    }, [filtersState]);
    useEffect(() => {
        fetchRfqSettings();
    }, []);
    useEffect(() => {
        var _a;
        if (!selectedStreamId || !customers)
            return;
        const tree = getClientsTree(((_a = settings.find((s) => s.settings_code === selectedStreamId)) === null || _a === void 0 ? void 0 : _a.access) || [], customers || [], clients || []);
        setClientsTree(tree);
    }, [customers, selectedStreamId]);
    useEffect(() => {
        if (settingsFiltered.length) {
            const groupedItems = Object.entries(groupBy(settingsFiltered, 'ul')).map((group) => ({
                name: group[0],
                streams: group[1],
                collapsed: collapsedRows[group[0]] !== undefined ? collapsedRows[group[0]] : false,
            }));
            sortByBase(groupedItems, 'name');
            setGroupedStreams(groupedItems);
            setSettingsToRender(settingsFiltered);
        }
        else {
            setSettingsToRender([]);
            setGroupedStreams([]);
        }
    }, [settingsFiltered]);
    const customerUrlParams = (canReadDetails, client_code) => buildUrl('', {
        queryParams: {
            page: '1',
            search: '',
            client_code,
            limit: '1000',
            status: 'enabled',
            sort_by: 'created',
            details: canReadDetails ? 'true' : 'false',
        },
    });
    const handleClientSelect = (code) => {
        dispatchClientSet(code);
    };
    useEffect(() => {
        const canReadDetails = (features === null || features === void 0 ? void 0 : features.includes('customer_details.read')) || false;
        if (selectedClient) {
            const params = customerUrlParams(canReadDetails, selectedClient.value);
            dispatch({ type: CUSTOMER_SEARCH_FETCH_REQUEST, params });
        }
        else {
            const params = customerUrlParams(canReadDetails, '');
            dispatch({ type: CUSTOMER_SEARCH_FETCH_REQUEST, params });
        }
    }, [selectedClient, features]);
    useEffect(() => {
        setCollapsed(true);
        if (filtersState.length) {
            const filtered = viewSettings.filter((w) => filtersState.every((f) => w[f.name] === f.value));
            setSettingsFiltered(filtered);
        }
        else {
            setSettingsFiltered(viewSettings);
        }
    }, [filtersState, viewSettings]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter((c) => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    const handleCollapse = (index) => {
        const updatedStreams = [...groupedStreams];
        updatedStreams[index].collapsed = !updatedStreams[index].collapsed;
        if (collapsedRows) {
            collapsedRows[updatedStreams[index].name] = updatedStreams[index].collapsed;
            setCollapsedRows(collapsedRows);
        }
        setGroupedStreams(updatedStreams);
    };
    const requestUpdateAccess = (id, clientId, status, type) => __awaiter(void 0, void 0, void 0, function* () {
        const requestBody = type === 'clients'
            ? {
                client_code: id,
            }
            : {
                customer_code: id,
                client_code: clientId,
            };
        const config = {
            url: `/trade/rfq/${selectedStreamId}/${status}`,
            method: 'post',
            data: requestBody,
            baseURL: getEnvSettings().adminApiUrl,
        };
        return axios(config)
            .then()
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const msg = status ? Messages.RFQ_SETTINGS_UNASSIGN : Messages.RFQ_SETTINGS_ASSIGN;
            const message = composeErrorMessage(e, msg);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const assignSettings = (arr, type) => __awaiter(void 0, void 0, void 0, function* () {
        if (arr.length) {
            let n = 0;
            while (n < arr.length) {
                const setting = arr[n];
                yield requestUpdateAccess(setting.id, setting.clientCode || '', setting.action, type);
                n += 1;
            }
            showNotification({
                message: `Settings were successfully saved`,
                color: 'success',
                dispatch: errorNotice,
            });
        }
    });
    const handleSave = (items) => __awaiter(void 0, void 0, void 0, function* () {
        setSelectedStreamId('');
        setLoading(true);
        yield assignSettings(items.customers, 'customers');
        yield assignSettings(items.clients, 'clients');
        fetchRfqSettings();
    });
    return (React.createElement(Box, { sx: { position: 'relative', height: '100%' } },
        loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, sx: {
                position: 'fixed',
                top: 0,
                bottom: 0,
                width: 'calc(100% - 240px)',
                backgroundColor: theme.palette.background.paper,
                zIndex: 10,
            } },
            React.createElement(CircularProgress, { size: '33px' }))) : null,
        React.createElement(Box, { p: 0.6 },
            rfqModalOpen ? (React.createElement(RfqSettingModal, { open: rfqModalOpen, toggleFunc: toggleCreateRfqModal, settingsToRender: settingsToRender, createRfqSetting: createRfqSetting })) : null,
            React.createElement(StreamsFilters, { features: features, baseOptions: baseOptions, quoteOptions: quoteOptions, onFiltersChange: updateFilter, exchangeOptions: exchangeOptions, toggleModal: toggleCreateRfqModal }),
            React.createElement(WalletsClientContainer, null,
                React.createElement(Box, { display: 'flex' },
                    React.createElement(Box, { style: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' } },
                        collapsed ? 'Hide all' : 'Show all',
                        "\u00A0",
                        React.createElement(IconButton, { onClick: () => handleCollapseAll(), size: 'large' }, collapsed ? React.createElement(UnfoldLess, null) : React.createElement(UnfoldMore, null))),
                    React.createElement("span", { className: 'custody_clients_table_settings' },
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: 'large' },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: 'nav', "aria-label": 'more actions', className: 'mui_table_settings_wrap' }, columns.map((col, i) => (React.createElement(ListItem, { key: i },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: 'end', size: 'small', color: 'default', onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find((c) => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                    React.createElement(TableBody, null, settingsToRender.length === 0 ? (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                            React.createElement(Typography, { className: 'transactions_typography_padded', variant: 'subtitle1' }, "No items found")))) : (groupedStreams.map((setting, settingIndex) => (React.createElement(React.Fragment, { key: settingIndex },
                        React.createElement(TableRow, { style: { cursor: 'pointer', background: theme.palette.action.selected }, onClick: () => handleCollapse(settingIndex) },
                            React.createElement(TableCell, { style: { maxWidth: '25px', width: '25px', borderBottom: 0 } }, !setting.collapsed ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null)),
                            React.createElement(TableCell, { style: { borderBottom: 0 } },
                                React.createElement(Chip, { label: setting.name, size: 'small', color: 'primary' }))),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { style: { padding: 0, borderBottom: 0 }, colSpan: selectedClient ? columnsToRender.length + 1 : columnsToRender.length },
                                React.createElement(Collapse, { in: !setting.collapsed, unmountOnExit: true, timeout: 0 },
                                    React.createElement(TableContainer, null,
                                        React.createElement(Table, { size: 'small', stickyHeader: true },
                                            React.createElement(TableHead, null,
                                                React.createElement(TableRow, null,
                                                    React.createElement(React.Fragment, null,
                                                        React.createElement(TableCell, null,
                                                            React.createElement(TableSortLabel, { active: orderBy === 'assigned', direction: orderBy === 'assigned' ? order : 'asc', onClick: createSortHandler('assigned') }, "Assigned"))),
                                                    columnsToRender.map((column, i) => (React.createElement(TableCell, { key: i, style: { minWidth: column.minWidth }, align: 'right' },
                                                        React.createElement(TableSortLabel, { active: orderBy === column.key, direction: orderBy === column.key ? order : 'asc', onClick: createSortHandler(column.key) }, column.title)))),
                                                    React.createElement(TableCell, { align: 'right', key: 'action', style: { minWidth: '100px' } }, "Action"))),
                                            React.createElement(TableBody, { className: classes.customTableRow }, stableSort(setting.streams, getComparator(order, orderBy)).map((stream, streamIndex) => {
                                                var _a;
                                                return (React.createElement(QuoteMonitorItem, { key: streamIndex, columnsToRender: columnsToRender, deleteRfqSetting: deleteRfqSetting, updateRfqSetting: updateRfqSetting, setting: stream, access: ((_a = settings.find((s) => s.settings_code === stream.settings_code)) === null || _a === void 0 ? void 0 : _a.access) || [], features: features, customers: customers, clients: clients, onClientSelect: handleClientSelect, selectedClient: selectedClient, assigned: stream.assigned, fetchRfqSettings: fetchRfqSettings, customersLoading: customersLoading, onManageAccess: () => setSelectedStreamId(stream.settings_code) }));
                                            }))))))))))))))),
        React.createElement(Drawer, { anchor: 'right', open: !!selectedStreamId, onClose: () => setSelectedStreamId('') }, clientsTree && (React.createElement(ManageStreamAccess, { stream: settings.find((s) => s.settings_code === selectedStreamId), customersLoading: customersLoading, selectedClient: selectedClient, onClientSelect: handleClientSelect, clients: clientsTree, onSave: handleSave, onCancel: () => setSelectedStreamId('') })))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    selectedClient: getSelectedClientInputValue(state),
    clients: state.clientSearch.allClients,
    customers: getAllCustomers(state),
    customersLoading: state.customersSearch.loading,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchClientsSet: (clients) => dispatch(setClients(clients)),
    dispatchClientSet: (code) => dispatch(setClient(code)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuoteMonitor);
