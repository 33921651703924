import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Box, Chip, Drawer, IconButton, List, ListItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import TopbarProfile from './TopbarProfile';
import TopBarConnection from './TopBarConnection';
import Permissions from '../AdminSidebar/Permissions';
import { getEnvSettings } from '../../../config/environmentSettings';
import { TopbarLinkContainer } from '../styling/styledComponents';
import { WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { createStyles, makeStyles } from '@mui/styles';
import ClientSearchSelect from '../AdminSidebar/components/ClientSearch';
import { CLIENTS_SET_UPDATE, SET_SELECTED_CLIENT, } from '../../../redux/actionTypes/clientSearchActionTypes';
import { ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE } from '../../../redux/actionTypes/adminActionTypes';
import buildUrl from 'build-url';
import ClientsService from '../../../services/clientsService';
import showNotification from '../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { getSelectedClient } from '../../../redux/selectors/clientSearchSelectors';
const okcoinUsers = [
    'tobias.rethmeyer@steubing.com',
    'adrian.hurler@steubing.com',
    'paul.huelsmann@finexity.com',
    'patrick.grewe@plutos.de',
    'andreas.othmer@plutos.de',
];
const setClients = (clients) => ({
    type: CLIENTS_SET_UPDATE,
    clients,
});
const adminClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const pageOfItemsUpdate = (pageOfItemsAdminOrders) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders,
});
const finoaEnv = getEnvSettings().env === 'finoa';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        background: theme.palette.background.paper,
        position: 'fixed',
        height: '60px',
        width: '100%',
        top: 0,
        zIndex: 10,
        fontSize: '0.875rem',
        '& .logo': {
            maxWidth: '270px',
            minWidth: '125px',
            width: '100%',
            height: '46px',
            display: 'inline-block',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            '&.dlt': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/main_logo_light.png)` : 'url(/img/logo/main_logo_dark.png)',
            },
            '&.okcoin': {
                backgroundImage: `url(/img/logo/steubing_logo.png)`,
            },
            '&.finoa': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/logo_finoa.svg)` : 'url(/img/logo/logo_finoa_l.svg)',
            },
        },
        '& .menu-item': {
            height: '60px',
            color: theme.palette.text.primary,
            borderRadius: '2px',
            '&-active': {
                color: theme.palette.primary.main,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
    },
    drawer: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.text.primary,
        },
        '& a.drawer__link-active': {
            color: theme.palette.primary.main,
        },
    },
}));
/* eslint-disable camelcase */
const TopbarWithNavigation = ({ features, masterTenant, clientInfo, selectedClient, clients, dispatchClientsSet, dispatchClientSelect, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [logoLink, setLogoLink] = useState('trading');
    const [logoClass, setLogoClass] = useState('dlt');
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [clientSelectValue, setClientSelectValue] = useState(null);
    const [selectClient, setSelectClient] = useState(false);
    const handleLinkClick = (dashboard) => {
        dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard });
        setDrawerOpened(false);
    };
    useEffect(() => {
        if (features && (features === null || features === void 0 ? void 0 : features.length) > 0 && !features.includes('order.read')) {
            setLogoLink('custody');
        }
    }, [features]);
    useEffect(() => {
        if (!masterTenant) {
            return;
        }
        let isCancelled = false;
        const urlOptions = {
            queryParams: {
                limit: '1000',
                sort_by: 'created',
                sort_direction: 'desc',
            },
            path: '/user/clients',
        };
        const endpointUrl = buildUrl('', urlOptions);
        // setLoading(true);
        const clientsService = new ClientsService({
            url: endpointUrl,
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService
            .makeRequest()
            .then((responseData) => {
            const { records } = responseData;
            if (!isCancelled) {
                dispatchClientsSet(records);
            }
        })
            .catch((e) => {
            if (!isCancelled) {
                // setLoading(false);
                showNotification({
                    color: 'error',
                    message: `Something went wrong, error: ${e}`,
                    dispatch: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
                });
            }
        });
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (clientInfo && okcoinUsers.includes(clientInfo.email)) {
            setLogoClass('okcoin');
        }
        else if (clientInfo && finoaEnv) {
            setLogoClass('finoa');
        }
    }, [clientInfo]);
    useEffect(() => {
        const value = selectedClient ? {
            value: selectedClient.code,
            label: selectedClient.company_name || selectedClient.code,
            enabled: selectedClient.is_enabled,
        } : null;
        setClientSelectValue(value);
    }, [selectedClient]);
    const mapClients = (clients) => {
        return clients === null || clients === void 0 ? void 0 : clients.map((c) => ({
            value: c.code,
            label: c.company_name || c.code,
            enabled: c.is_enabled,
        }));
    };
    const handleClientSelect = (selectedClient) => {
        dispatchClientSelect((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || null);
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 1 },
            React.createElement(Box, { flex: 'auto' },
                React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' } } },
                    React.createElement(Link, { className: `logo ${logoClass}`, to: '/', onClick: () => handleLinkClick(logoLink) })),
                React.createElement(Box, { sx: { display: { xs: 'flex', md: 'none' } } },
                    React.createElement(IconButton, { edge: 'start', color: 'inherit', "aria-label": 'menu', onClick: () => setDrawerOpened(!drawerOpened), size: 'large' },
                        React.createElement(MenuIcon, null)))),
            React.createElement(Drawer, { anchor: 'left', open: drawerOpened, onClose: () => setDrawerOpened(false), classes: { paper: classes.drawer } },
                React.createElement("div", null,
                    React.createElement(List, { component: 'nav' },
                        React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('tradingRfq') },
                                React.createElement(NavLink, { className: 'drawer__link', activeClassName: 'drawer__link-active', exact: true, to: '/rfq' }, "RFQ Trading"))),
                        !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('trading') },
                                React.createElement(NavLink, { exact: true, to: '/dma', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('trading') }, "DMA Trading")))) : null,
                        React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('custody') },
                                React.createElement(NavLink, { to: '/custody', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('custody') }, "Custody"))),
                        React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('manageOrders') },
                            React.createElement(NavLink, { to: '/manage', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('manageOrders') }, "Manage")),
                        masterTenant ? (React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('portfolio') },
                            React.createElement(NavLink, { to: '/portfolio', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('portfolio') }, "Portfolio"))) : null,
                        masterTenant ? (React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('manageSchedulers') },
                            React.createElement(NavLink, { to: '/mastertenant', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('manageSchedulers') }, "Mastertenant"))) : null))),
            React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' }, height: '100%' } },
                React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { exact: true, to: '/rfq', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('tradingRfq') }, "RFQ Trading"))),
                !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { exact: true, to: '/dma', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('trading') }, "DMA Trading")))) : null,
                React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { to: '/custody', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('custody') },
                            React.createElement("span", { className: 'topbar_link_title' }, "Custody")))),
                React.createElement(TopbarLinkContainer, null,
                    React.createElement(NavLink, { to: '/manage', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('manageOrders') },
                        React.createElement("span", { className: 'topbar_link_title' }, "Manage"))),
                masterTenant ? (React.createElement(TopbarLinkContainer, null,
                    React.createElement(NavLink, { to: '/portfolio', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('portfolio') },
                        React.createElement("span", { className: 'topbar_link_title' }, "Portfolio")))) : null,
                masterTenant ? (React.createElement(TopbarLinkContainer, null,
                    React.createElement(NavLink, { to: '/mastertenant', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('manageSchedulers') },
                        React.createElement("span", { className: 'topbar_link_title' }, "Mastertenant")))) : null),
            React.createElement("div", { className: 'topbar__profile_wrap' },
                React.createElement(TopBarConnection, null),
                !masterTenant ? null : (React.createElement(Box, { flex: 'auto', sx: { minWidth: '180px' }, display: 'flex', justifyContent: 'flex-end' }, !selectedClient || selectClient ? (React.createElement(ClientSearchSelect, { selectedValue: clientSelectValue, clients: mapClients(clients), onSelect: (client) => { handleClientSelect(client); setSelectClient(false); } })) : (React.createElement(Chip, { size: 'small', color: 'primary', label: clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.label, onDelete: () => setSelectClient(true), deleteIcon: React.createElement(EditIcon, null) })))),
                React.createElement("div", { className: 'topbar__profile_divider' }),
                React.createElement(TopbarProfile, null)))));
};
const stateToProps = (state) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    clients: state.clientSearch.allClients,
    selectedClient: getSelectedClient(state),
});
const mapDispatchToProps = (dispatch) => ({
    dispatchClientsSet: (clients) => dispatch(setClients(clients)),
    dispatchpageOfItemsAdminOrders: (page) => dispatch(pageOfItemsUpdate(page)),
    dispatchClientSelect: (client) => dispatch(adminClientUpdate(client)),
});
export default connect(stateToProps, mapDispatchToProps)(TopbarWithNavigation);
