import React, { useCallback } from 'react';
import { Box, Button, } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const CreateSettlement = ({ item, clientCode, toggle }) => {
    const { ErrorLogger } = window;
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onConfirm = () => {
        createSettlement();
    };
    const createSettlement = () => {
        var _a, _b;
        const wallet_code = (_b = (_a = item.accounts) === null || _a === void 0 ? void 0 : _a.find((item) => item.type === 'trading_wallet')) === null || _b === void 0 ? void 0 : _b.code;
        const payload = {
            client_code: clientCode,
            wallet_code,
        };
        axios
            .post('/custody/wallets/settlements/create_manually', payload, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            showNotification({
                message: `Settlement successfully created`,
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.TRADING_WITHDRAWAL_CREATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            toggle();
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        "Are you sure you want to settle this wallet?",
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
            React.createElement(Button, { onClick: onConfirm, variant: 'contained' }, "Yes"),
            React.createElement(Button, { onClick: toggle }, "Cancel"))));
};
export default CreateSettlement;
