import { call, put, takeLatest } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import showNotification from '../shared/helpers/notifications';
import * as orderActions from '../redux/actions/orderBookActions';
import * as types from '../redux/actionTypes/orderBookActionTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
/* eslint-disable import/prefer-default-export, @typescript-eslint/restrict-template-expressions */
function* cancelOrder(action) {
    try {
        if (action.type === types.ORDERS_CANCEL_REQUEST) {
            const data = yield call(orderActions.cancelRegularOrder, action.code);
            // todo add to notification center
            yield put({ type: types.ORDERS_CANCEL_SUCCESS, data });
            showNotification({
                message: 'Order successfully cancelled',
                color: 'success',
                dispatch: action.dispatchCloseNotice,
            });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.ORDER_CANCEL);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.ORDERS_CANCEL_FAIL, message });
    }
}
function* cancelAll(action) {
    try {
        if (action.type === types.ADMIN_ORDERS_CANCEL_ALL_REQUEST) {
            const response = yield call(orderActions.apiCancelAllOrders);
            yield put({ type: types.ORDERS_CANCEL_ALL_SUCCESS, response });
            showNotification({
                message: 'All orders successfully cancelled',
                color: 'success',
                dispatch: action.dispatchCloseNotice,
            });
        }
    }
    catch (error) {
        if (action.type === types.ADMIN_ORDERS_CANCEL_ALL_REQUEST) {
            const message = composeAnyErrorMessage(error, Messages.ORDERS_ALL_CANCEL);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: action.dispatchCloseNotice,
            });
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: types.ORDERS_CANCEL_ALL_FAIL, message });
        }
    }
}
function* cancelAllOrders(action) {
    try {
        if (action.type === types.ORDERS_CANCEL_ALL_REQUEST) {
            const response = yield call(orderActions.apiRemoveCancelAllOrders, action.clientCode, action.payload);
            yield put({ type: types.ORDERS_CANCEL_ALL_SUCCESS, response });
            showNotification({
                message: 'All orders successfully cancelled',
                color: 'success',
                dispatch: action.dispatchCloseNotice,
            });
        }
    }
    catch (error) {
        if (action.type === types.ORDERS_CANCEL_ALL_REQUEST) {
            const message = composeAnyErrorMessage(error, Messages.ORDERS_ALL_CANCEL);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: action.dispatchCloseNotice,
            });
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: types.ORDERS_CANCEL_ALL_FAIL, message });
        }
    }
}
export const ordersSaga = [
    takeLatest(types.ORDERS_CANCEL_ALL_REQUEST, cancelAllOrders),
    takeLatest(types.ADMIN_ORDERS_CANCEL_ALL_REQUEST, cancelAll),
    takeLatest(types.ORDERS_CANCEL_REQUEST, cancelOrder),
];
