import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
class MainWrapper extends PureComponent {
    render() {
        const { theme, children } = this.props;
        const wrapperClass = classNames({
            wrapper: true,
            'squared-corner-theme': false,
            'blocks-with-shadow-theme': false,
            'top-navigation': true, // TODO hardcoded atm
        });
        const direction = 'ltr';
        return (React.createElement("div", { className: `${theme.className} ${direction}-support`, dir: direction },
            React.createElement("div", { className: wrapperClass }, children)));
    }
}
const mapStateToProps = (state) => ({
    theme: state.theme,
});
export default connect(mapStateToProps)(withRouter((MainWrapper)));
