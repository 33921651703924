import React, { useState } from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Chip, Icon } from '@mui/material';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
/* eslint-disable camelcase */
const ClearerWithdrawalsItem = ({ item }) => {
    const [icon, setIcon] = useState('file_copy');
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    const { address, exchange_code, status, currency_code, created, code, } = item;
    return (React.createElement("tr", { key: code },
        React.createElement("td", null,
            React.createElement("span", { className: "common_padding_r_10" }, address),
            address ? (React.createElement(Icon, { onClick: () => handleCopy(`${address}`), fontSize: "small" }, icon)) : null),
        React.createElement("td", null, exchange_code),
        React.createElement("td", null, currency_code),
        React.createElement("td", null, created ? moment(created).format('YYYY-MM-DD H:mm:ss') : '------'),
        React.createElement("td", null,
            React.createElement(Chip, { size: "small", label: status, style: statusBadgeColor(statusBadgeData.stateBackgroundsClearerWithdrawals[status], statusBadgeData.stateBordersClearerWithdrawals[status], statusBadgeData.stateColorsClearerWithdrawals[status]).badge }))));
};
export default ClearerWithdrawalsItem;
