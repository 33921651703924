export function getCurrency(code, currencies) {
    return currencies.find((c) => c.code.toUpperCase() === code.toUpperCase());
}
export function getExplorerURL(code, currencies) {
    if (code === '') {
        return '';
    }
    const curr = getCurrency(code, currencies);
    if (curr !== undefined) {
        return curr.tx_explorer_url;
    }
    return '';
}
