import React, { useState, useEffect, useCallback, useMemo, } from 'react';
import shortid from 'shortid';
import buildUrl from 'build-url';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Chip, CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, } from '../../../../Custody/styling/style';
import { PanelContainer, } from '../../../styling/style';
import CustomersTableCell from './CustomersTableCell';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import CustomersService from '../../../../../../services/customersService';
import showNotification from '../../../../../../shared/helpers/notifications';
import ClientModal from '../../../../Crypto/components/ClientSearch/ClientModal';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const Customers = ({ columns, features, clientInfo, selectedClient, masterTenant, customerCode, customersWidgetHeight, }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [urlParams, setUrlParams] = useState('');
    const [maxHeight, setMaxHeight] = useState(480);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [client, setClient] = useState(null);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const columnsOpen = Boolean(anchorColumns);
    const ColumnUUID = useMemo(() => [
        {
            title: 'UUID', name: 'code', key: 'code', minWidth: '150px',
        },
    ], []);
    const onChangePage = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageCustomers'));
    };
    const fetchCustomers = (url, cancelToken) => {
        setLoading(true);
        const service = new CustomersService({
            url,
            method: 'get',
            cancelToken,
        });
        service.makeRequest()
            .then((responseData) => {
            setLoading(false);
            const { records, total } = responseData;
            setCustomers(records);
            setTotalCustomers(+total);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const createCustomer = (payload, setErr) => {
        const service = new CustomersService({
            url: '/customers',
            method: 'post',
            data: payload,
        });
        service.makeRequest()
            .then(() => {
            fetchCustomers(urlParams);
            showNotification({
                message: 'Customer successfully created',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_CREATE);
            setErr(message);
        });
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const urlOptions = {
            queryParams: {
                limit: `${rowsPerPage}`,
                page: `${page + 1}`,
                sort_by: 'created',
                sort_direction: 'desc',
                client_code: '',
            },
            path: '/customers',
        };
        const canReadDetails = features && Array.isArray(features) && features.includes('customer_details.read');
        if (client) {
            urlOptions.queryParams.client_code = client.value;
        }
        else if (selectedClient) {
            urlOptions.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptions.queryParams.client_code = clientInfo.code;
        }
        if (canReadDetails) {
            urlOptions.queryParams.details = 'true';
        }
        const endpointUrl = buildUrl('', urlOptions);
        setUrlParams(endpointUrl);
        fetchCustomers(endpointUrl, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [clientInfo, selectedClient, client, page, features, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(customersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [customersWidgetHeight]);
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, null,
            regularClient ? (React.createElement(Box, { p: 1 },
                React.createElement(Chip, { size: "small", color: "primary", label: client ? client.label : selectedClient
                        ? selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.label : clientInfo && clientInfo.company_name }))) : null,
            modalOpen ? (React.createElement(ClientModal, { user: "customer", open: modalOpen, currentUser: null, toggleFunc: toggleModal, clientFromCustomers: client, createClient: createCustomer })) : null,
            React.createElement("div", { className: "card-body" }, loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
                    customerCode ? null : (React.createElement(Permissions, { features: features, permissions: ['customer.create'] },
                        React.createElement(Box, { p: 1 },
                            React.createElement(Button, { variant: "outlined", size: "small", type: "button", color: "primary", onClick: () => toggleModal() }, "Create customer")))),
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))),
                            React.createElement(Permissions, { showIfNoPermission: true, features: features, permissions: ['customer_details.read'] },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, ColumnUUID.map(col => (React.createElement(ListItem, { key: col.key },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, { className: "mui_table_root" },
                        React.createElement(TableContainer, { style: { maxHeight } },
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'client_code' ? 'left' : 'right' }, column.title)))))),
                                React.createElement(Permissions, { showIfNoPermission: true, features: features, permissions: ['customer_details.read'] },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null,
                                            React.createElement(TableCell, { key: "code", align: "left", className: "tokens_table_cell_width" }, "UUID")))),
                                React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
                                    React.createElement(TableBody, null, customers.map(customer => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() }, columnsToRender.map((column, i) => {
                                        const key = column.key;
                                        const value = key ? customer[key] : undefined;
                                        return (React.createElement(CustomersTableCell, { key: i, columnKey: key, value: value }));
                                    })))))),
                                React.createElement(Permissions, { showIfNoPermission: true, features: features, permissions: ['customer_details.read'] },
                                    React.createElement(TableBody, null, customers.map(customer => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                                        React.createElement(CustomersTableCell, { columnKey: "code", value: customer.code, key: shortid.generate() })))))))),
                        React.createElement(TablePagination, { page: page, component: "div", count: totalCustomers, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    customerCode: state.client.customer_code,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    customersWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(Customers);
