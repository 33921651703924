import React from 'react';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { Box, Button, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
const SearchInput = ({ onChange, toggleModal, searchString, features, }) => (React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pt: 0, gap: 1 },
    React.createElement(Box, { flex: '1 0 auto' },
        React.createElement(FormControl, { size: "small" },
            React.createElement(OutlinedInput, { value: searchString, onChange: onChange, endAdornment: React.createElement(InputAdornment, { position: "end" },
                    React.createElement(SearchIcon, null)), placeholder: 'Type to search', inputProps: {
                    'aria-label': 'weight',
                } }))),
    React.createElement(Permissions, { features: features, permissions: ['customer.create'] },
        React.createElement(Box, null,
            React.createElement(Button, { variant: "outlined", onClick: toggleModal, color: "primary", startIcon: React.createElement(PersonAddIcon, { fontSize: "small" }) }, "Create")))));
export default SearchInput;
