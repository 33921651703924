import { CssBaseline, StyledEngineProvider, createTheme } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import React from "react";
import "@mui/styles";
const ThemeComponent = ({ children, themeName }) => {
    const theme = createTheme({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    }
                }
            },
        },
        palette: Object.assign({ mode: themeName === 'theme-dark' ? 'dark' : 'light' }, (themeName === 'theme-dark' ? {
            primary: {
                main: '#60dbb8',
            },
            secondary: {
                main: '#b2ccc1',
            },
            success: {
                main: '#3DF571',
            },
            info: {
                main: '#a8cbe2',
            },
            error: {
                main: '#F53D3D',
            },
            background: {
                paper: '#1c243fe6',
                default: '#090a31ff',
            },
        } : {
            primary: {
                main: '#006b55',
            },
            secondary: {
                main: '#4b635a',
            },
            info: {
                main: '#406376'
            },
            error: {
                main: '#ba1a1a',
            },
            background: {
                paper: '#fbfcffe6',
                default: '#fbfdfa',
            },
        })),
        typography: {
            fontFamily: 'ProximaNova, sans-serif',
        },
    });
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            children)));
};
export const ConnectedThemeComponent = connect((state) => ({
    themeName: state.theme.className,
}))(ThemeComponent);
