import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import InstrumentsService from '../../../../../../services/instrumentsService';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../../../Custody/styling/style';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
export const streamTypes = [
    { label: 'Standard', value: 'standard' },
    { label: 'Reverse quotation', value: 'reverse_quotation' },
];
export const spreadModeTypes = [
    { label: 'Soft', value: 'soft' },
    { label: 'Strict', value: 'strict' },
];
export const calculationModeTypes = [
    { label: 'TOB', value: 'tob' },
    { label: 'Mid', value: 'mid' },
];
const RfqSettingModal = ({ open, toggleFunc, createRfqSetting, settingsToRender }) => {
    const dirty = useRef(false);
    const [label, setLabel] = useState('');
    const [ulShift, setUlShift] = useState('');
    const [minSpread, setMinSpread] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [marketFees, setMarketFees] = useState('');
    const [extraSpread, setExtraSpread] = useState('');
    const [guaranteedQty, setGuaranteedQty] = useState('');
    const [wideningFactor, setWideningFactor] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const [loadingInstruments, setLoadingInstruments] = useState(false);
    const [instruments, setInstruments] = useState([]);
    const [streamType, setStreamType] = useState(null);
    const [instrument, setInstrument] = useState(null);
    const [spreadMode, setSpreadMode] = useState(null);
    const [calculationMode, setCalculationMode] = useState(null);
    const [fullInstrument, setFullInstrument] = useState(null);
    const [instrumentsOptions, setInstrumentsOptions] = useState([]);
    const helperTextStyles = useHelperTextStyles();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const fetchInstruments = () => {
        const service = new InstrumentsService({
            url: '/trade/instruments',
            method: 'get',
        });
        setLoadingInstruments(true);
        service
            .makeRequest()
            .then((data) => {
            setLoadingInstruments(false);
            setInstruments(data.records);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.INSTRUMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingInstruments(false);
        });
    };
    const addNewSetting = () => {
        dirty.current = true;
        const errors = validateFields({
            ulShift,
            minSpread,
            extraSpread,
            guaranteedQty,
            wideningFactor,
            label,
        });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const payload = {
            label,
            ul_shift_bps: ulShift,
            min_spread_bps: minSpread,
            market_fees_bps: marketFees,
            extra_spread_bps: extraSpread,
            guaranteed_qty: guaranteedQty,
            widening_factor_bps: wideningFactor,
            spread_mode: (spreadMode === null || spreadMode === void 0 ? void 0 : spreadMode.value) || '',
            calculation_mode: (calculationMode === null || calculationMode === void 0 ? void 0 : calculationMode.value) || '',
            stream_type: (streamType === null || streamType === void 0 ? void 0 : streamType.value) || '',
            base_code: (fullInstrument === null || fullInstrument === void 0 ? void 0 : fullInstrument.base) || '',
            quote_code: (fullInstrument === null || fullInstrument === void 0 ? void 0 : fullInstrument.quote) || '',
            exchange_code: (fullInstrument === null || fullInstrument === void 0 ? void 0 : fullInstrument.exchange_code) || '',
        };
        if (instrument) {
            createRfqSetting(payload);
        }
        setLabel('');
        setUlShift('');
        setMinSpread('');
        setExtraSpread('');
        setGuaranteedQty('');
        setWideningFactor('');
        setInstrument(null);
        setMarketFees('');
        toggleFunc();
    };
    const onInstrumentChange = (event, newValue) => {
        setInstrument(newValue);
    };
    const onStreamTypeChange = (event, newValue) => {
        setStreamType(newValue);
    };
    const onSpreadModeChange = (event, newValue) => {
        setSpreadMode(newValue);
    };
    const onCalculationModeChange = (event, newValue) => {
        setCalculationMode(newValue);
    };
    const onValueChange = (evt) => {
        const val = evt.target.value.trim();
        const { name } = evt.target;
        if (isNaN(Number(val))) {
            return;
        }
        if (name === 'minSpread') {
            setMinSpread(val);
        }
        if (name === 'extraSpread') {
            setExtraSpread(val);
        }
        if (name === 'guaranteedQty') {
            setGuaranteedQty(val);
        }
        if (name === 'wideningFactor') {
            setWideningFactor(val);
        }
        if (name === 'marketFees') {
            setMarketFees(val);
        }
    };
    const onUlShiftChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value)) && value !== '-') {
            return;
        }
        setUlShift(value);
    };
    const onLabelChange = (evt) => {
        setLabel(evt.target.value);
    };
    useEffect(() => {
        fetchInstruments();
    }, []);
    useEffect(() => {
        if (instrument) {
            const item = instruments.find((i) => i.instrument_code === instrument.value);
            if (item) {
                setFullInstrument(item);
            }
        }
    }, [instruments, instrument]);
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({
                ulShift,
                minSpread,
                extraSpread,
                guaranteedQty,
                wideningFactor,
                label,
            });
            setInvalidFields([...errors]);
        }
        if (!ulShift ||
            !minSpread ||
            !extraSpread ||
            !guaranteedQty ||
            !wideningFactor ||
            !instrument ||
            !marketFees ||
            !label ||
            !streamType ||
            !spreadMode) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [
        ulShift,
        minSpread,
        extraSpread,
        guaranteedQty,
        wideningFactor,
        instrument,
        marketFees,
        label,
        streamType,
        spreadMode,
    ]);
    useEffect(() => {
        if (instruments.length) {
            const mapped = instruments.map((i) => ({
                label: `${i.base}/${i.quote} - ${i.exchange_name}`,
                value: i.instrument_code,
            }));
            if (settingsToRender.length) {
                const filtered = mapped.filter((item) => !settingsToRender.map((s) => s.settings_code).includes(item.value));
                setInstrumentsOptions(filtered);
            }
            else {
                setInstrumentsOptions(mapped);
            }
        }
    }, [instruments, settingsToRender]);
    if (loadingInstruments) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: '33px' }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: 'sm', fullWidth: true },
        React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Create new RFQ Setting"),
        React.createElement(DialogContent, null,
            React.createElement(Autocomplete, { size: 'small', id: 'instrument', value: instrument, loading: loadingInstruments, options: instrumentsOptions, style: { marginTop: '10px' }, onChange: onInstrumentChange, placeholder: 'Select Instrument', className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Instrument', variant: 'outlined' })) }),
            React.createElement(TextField, { fullWidth: true, size: 'small', name: 'label', value: label, label: 'Label', margin: 'normal', variant: 'outlined', onChange: onLabelChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('label') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', name: 'ulShift', margin: 'normal', value: ulShift, label: 'Ul Shift', variant: 'outlined', onChange: onUlShiftChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('ulShift') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', name: 'minSpread', value: minSpread, variant: 'outlined', label: 'Min Spread', onChange: onValueChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('minSpread') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'extraSpread', value: extraSpread, label: 'Extra Spread', onChange: onValueChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('extraSpread') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'guaranteedQty', value: guaranteedQty, label: 'Guaranteed Qty', onChange: onValueChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('guaranteedQty') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'wideningFactor', value: wideningFactor, label: 'Widening Factor', onChange: onValueChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('wideningFactor') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'marketFees', value: marketFees, label: 'Market Fees', onChange: onValueChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('marketFees') ? 'Field is required' : ''}` }),
            React.createElement(Autocomplete, { size: 'small', id: 'streamType', value: streamType, options: streamTypes, style: { marginTop: '10px' }, onChange: onStreamTypeChange, placeholder: 'Select stream type', className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Stream type', variant: 'outlined' })) }),
            React.createElement(Autocomplete, { size: 'small', id: 'spreadMode', value: spreadMode, options: spreadModeTypes, style: { marginTop: '10px' }, onChange: onSpreadModeChange, placeholder: 'Select spread mode', className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Spread mode', variant: 'outlined' })) }),
            React.createElement(Autocomplete, { size: 'small', id: 'calculationMode', value: calculationMode, options: calculationModeTypes, style: { marginTop: '10px' }, onChange: onCalculationModeChange, placeholder: 'Select calculation mode', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Calculation mode', variant: 'outlined' })) })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: 'primary', variant: 'contained', disabled: disabled, onClick: () => addNewSetting() }, "Create"),
            React.createElement(Button, { variant: 'outlined', onClick: () => toggleFunc() }, "Cancel"))));
};
export default RfqSettingModal;
