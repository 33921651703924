/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
import axios from 'axios';
import LogoutService from '../../services/logoutService';
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types */
export const errorCodes = {
    401: 'Unauthenticated for invalid credentials',
    404: 'Path was not found',
    406: 'Unsupported media type requested, only [application/json] are available',
    601: 'Validation field error',
    602: 'Validation field error',
    603: 'Validation field error',
    604: 'Validation field error',
    605: 'Reference ID in body should match only Latin letters and digits',
    606: 'Validation parameters error',
    // ErrorInternalError unhandled errors (internal)
    // ErrorForbidden handled errors
    9015: 'Public key not found',
    9016: 'Invite not found',
    9017: 'Token not found',
    9018: 'Role not found',
    9031: 'Withdrawal address not found',
    9111: 'Invalid public key',
    9112: 'Invalid signature',
    9199: 'Invalid TOTP code',
    // ErrorInternalError unhandled errors (internal)
    7000: 'Failed to authenticate, invalid email or password',
    7101: 'No valid seesions',
    8000: 'Internal error',
    8001: 'Empty principals error',
    8002: 'Empty ACL data',
    8003: 'RBAC error',
    8004: 'Network error',
    8005: 'RPCC Client error',
    // ErrorForbidden handled errors
    9000: 'Forbidden',
    9011: 'User not found',
    9012: 'Client not found',
    9013: 'Customer not found',
    9014: 'Currency not found',
    // todo: temporary use 9051+ for specific errors of
    // brokerdealer service, make some better
    9051: 'Exchange not found',
    9052: 'Instrument not found',
    9053: 'Order not found',
    9054: 'Partial order not found',
    9055: 'Cancel batch not found',
    9056: 'Deposit holder not found',
    9061: 'Client rfq settings not found',
    // todo: temporary use 9151+ for specific errors of
    // brokerdealer service, make some better
    9100: 'Validation error',
    9101: 'Disabled error',
    9102: 'Duplicate error',
    9151: 'Insufficient balance',
    9152: 'Orderbook is too shallow',
    9153: 'Exceeded partial order limit',
    9154: 'Exchange bulk cancel',
    9156: 'Insufficient customer balance',
    // todo: temporary use 9501+ for specific errors of brokerdealer
    // service that are returned from exchange and can be handled, make some better
    9501: 'Exchange validation error',
    9502: 'Exchange insufficient balance',
    9503: 'Exchange limit exceeded',
    9504: 'Exchange connection refused',
    9505: 'Exchange API problem',
    9506: 'Exchange user not verified',
    9588: 'Exchange disabled',
    9599: 'Exchange undefined',
    // Custody
    9032: 'Client custody wallet not found',
    9034: 'Client custody related wallet not found',
    9601: 'Custody insufficient balance',
    9602: 'Custody withdrawal amount too low',
    9603: 'Custody unknown wallet ID',
    9604: 'Custody wallet already exists',
    9611: 'Custody currency not supported',
    9612: 'Custody fee source wallet already used',
    9689: 'Custody not found',
    9691: 'Custody address invalid',
    9694: 'Custody eth gas source wallet insufficient balance',
    9699: 'Custody undefined error',
    9701: 'Custody withdrawal address already signed',
    9702: 'Custody withdrawal address sign forbidden',
    9703: 'Custody withdrawal address already approved',
    9704: 'Custody withdrawal address missed sign',
    9705: 'Custody withdrawal address approve forbidden',
    9706: 'Custody withdrawal address aml check failed',
    9707: 'Custody withdrawal address invalid',
    9708: 'Address could not be processed and AML check could not be conducted. Admin will be informed.',
    9709: 'Custody withdrawal address wrong state',
    9801: 'Custody wallet customer already assigned',
    9802: 'Custody wallet not active',
    9804: 'Custody wallet has in progress deposits',
    9803: 'Custody wallet invalid type',
    9805: 'Custody wallet manual creation not supported',
    9806: 'Custody wallet children already assigned to customers',
    9807: 'Custody wallet parent wallet already assigned',
    9901: 'Custody withdrawal already signed',
    9902: 'Custody withdrawal sign forbidden',
};
export const composeErrorMessage = (error, messageText) => {
    if (error.response && error.response.data) {
        const { code, desc, value } = error.response.data;
        const codeDescription = errorCodes[code] || 'Unknown';
        let description = desc || '';
        if (code === 9100) {
            description = desc || value || '';
        }
        return `${messageText}, because of Error: ${codeDescription}; ${description}`;
    }
    return error.message;
};
export const composeAnyErrorMessage = (error, messageText) => {
    if (axios.isAxiosError(error)) {
        return composeErrorMessage(error, messageText);
    }
    if (typeof error === 'string') {
        return error;
    }
    if (error instanceof TypeError) {
        return error.message;
    }
    return 'Unknown error';
};
const logoutService = new LogoutService();
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
    var _a;
    if (!['post', 'put', 'delete', 'patch'].includes(((_a = config.method) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '')) {
        return config;
    }
    const csrf = window.localStorage.getItem('csrfToken');
    const loginSession = window.sessionStorage.getItem('xLoginSession');
    if (csrf && config.headers) {
        config.headers['x-csrf'] = csrf;
    }
    if (loginSession && config.headers) {
        config.headers['x-login'] = loginSession;
    }
    return config;
}, error => Promise.reject(error));
axios.interceptors.response.use((response) => {
    if (response.headers['x-csrf']) {
        window.localStorage.setItem('csrfToken', response.headers['x-csrf']);
    }
    return response;
}, (error) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const status = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
    if ((_b = error.response) === null || _b === void 0 ? void 0 : _b.headers['x-csrf']) {
        window.localStorage.setItem('csrfToken', error.response.headers['x-csrf']);
    }
    if (((_d = (_c = error.config) === null || _c === void 0 ? void 0 : _c.url) === null || _d === void 0 ? void 0 : _d.includes('login'))
        || ((_f = (_e = error.config) === null || _e === void 0 ? void 0 : _e.url) === null || _f === void 0 ? void 0 : _f.includes('logout'))
        || ((_h = (_g = error.config) === null || _g === void 0 ? void 0 : _g.url) === null || _h === void 0 ? void 0 : _h.includes('check2fa'))) {
        return Promise.reject(error);
    }
    if (status && status === 401) {
        return logoutService.logout();
    }
    return Promise.reject(error);
});
