import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { TransfersCellItem } from '../../../Custody/styling/style';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import { createStyles, makeStyles } from '@mui/styles';
const headCells = [
    {
        id: 'settlementCode',
        numeric: false,
        disablePadding: true,
        label: 'Code',
    },
    {
        id: 'clientId',
        numeric: false,
        disablePadding: true,
        label: 'Client',
    },
    {
        id: 'clientName',
        numeric: false,
        disablePadding: true,
        label: 'Client Name',
    },
    {
        id: 'customerName',
        numeric: true,
        disablePadding: true,
        label: 'Customer Name',
    },
    {
        id: 'walletId',
        numeric: true,
        disablePadding: true,
        label: 'Wallet Id',
    },
    {
        id: 'currency',
        numeric: true,
        disablePadding: true,
        label: 'Currency',
    },
    {
        id: 'amountToSettle',
        numeric: true,
        disablePadding: true,
        label: 'Amount to Settle',
    },
    {
        id: 'direction',
        numeric: false,
        disablePadding: true,
        label: 'To/From',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    },
    {
        id: 'failedReason',
        numeric: false,
        disablePadding: true,
        label: 'Failed Reason',
    },
];
const useStyles = makeStyles(() => createStyles({
    customTableRow: {
        '& > tr:last-of-type > td': {
            borderBottom: 0,
        },
    },
}));
const SettlementsTableBody = ({ rows, type, executeSettlement }) => {
    const theme = useTheme();
    const classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": 'tableTitle', size: 'small' },
            React.createElement(TableHead, { style: { cursor: 'pointer', background: theme.palette.background.default } },
                React.createElement(TableRow, null,
                    headCells.map((headCell) => (React.createElement(TableCell, { key: headCell.id }, headCell.label))),
                    React.createElement(TableCell, null, "Actions"))),
            React.createElement(TableBody, { className: classes.customTableRow }, rows.map((row, index) => {
                const labelId = `settlement-${index}`;
                return (React.createElement(TableRow, { hover: true, tabIndex: -1, key: labelId },
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(CopyValue, { value: row.settlementCode }),
                            React.createElement(TransfersCellItem, { style: { maxWidth: '100px' } }, row.settlementCode))),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(CopyValue, { value: row.clientId }),
                            React.createElement(TransfersCellItem, { style: { maxWidth: '100px' } }, row.clientId))),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.clientName)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(CopyValue, { value: row.customerName }),
                            React.createElement(TransfersCellItem, { style: { maxWidth: '100px' } }, row.customerName))),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(CopyValue, { value: row.walletId }),
                            React.createElement(TransfersCellItem, { style: { maxWidth: '100px' } }, row.walletId))),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.currency)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.amountToSettle)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.direction)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.status)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.failedReason)),
                    React.createElement(TableCell, { align: 'left' }, type === 'created' ? (React.createElement(Button, { onClick: () => (executeSettlement ? executeSettlement(row.settlementCode) : null) }, "Execute")) : null)));
            })))));
};
export default SettlementsTableBody;
